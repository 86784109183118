import { connect } from "react-redux";
import { PushChannel as PushChannelAction } from "../store/global/actions"

function PushChannel(props) {
    let channel = props.channel

    if (!channel) {
        channel = props.socket.channel("jpush:customize")
        channel.join()
        props.join(channel)
    }

    return <></>
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        channel: global.pushChannel
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        join: (channel) => {
            dispatch(PushChannelAction.join(channel))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PushChannel)