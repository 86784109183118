import { Form, Input, Button, Modal, Radio, message } from "antd"
import React from 'react'
import { useState } from "react"

import RewardConvertItem from "../components/reward_convert_item"

const { Column } = Form

class RewardConvertAuditFormModel extends React.Component {

    state = {
        // isModalVisible: false,
        rewardConvert: this.props.rewardConvert,
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.rewardConvert !== this.state.rewardConvert)
            this.setState({ rewardConvert: nextProps.rewardConvert })
    }

    handleCancel() {
        this.props.close()
    }

    show(rewardConvert) {
        this.setState({ tabKey: null, rewardConvert })
    }

    render() {
        return <Modal title="提取审核" width={600} destroyOnClose centered footer={null} open={!!this.state.rewardConvert} onOk={false} onCancel={this.handleCancel.bind(this)}>
            {this.state.rewardConvert && <RewardConvertAuditForm rewardConvert={this.state.rewardConvert} inModal {...this.props} onFinish={this.handleCancel.bind(this)}></RewardConvertAuditForm>}
        </Modal>
    }
}


const formItemLayout = {
    labelCol: {
        xs: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 17 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 4,
            offset: 6,
        },
    },
};

function RewardConvertAuditForm({ channel, rewardConvert, onRefresh, onFinish, ...props }) {

    const [formData, setFormDate] = useState({})

    // 处理提取审核表单
    const handleRewardConvertAuditFormSubmit = (invite_reward_convert_id, values) => {
        channel.push("audit:convert", { invite_reward_convert_id, ...values })
            .receive("ok", () => {
                if (!!onRefresh)
                    onRefresh()
                message.success("提交了审核");
                if (!!onFinish)
                    onFinish()
            })
    }

    const handleFormonValuesChange = (_changed_values, allValues) => {
        setFormDate(allValues)
    }

    return <div>
        <Form {...formItemLayout} onFinish={(values) => handleRewardConvertAuditFormSubmit(rewardConvert.id, values)} onValuesChange={handleFormonValuesChange}>
            <Form.Item label="申请">
                <RewardConvertItem reward={rewardConvert} />
            </Form.Item>
            <Form.Item name="status" label="审核意见" rules={[{ required: true }]}>
                <Radio.Group>
                    <Radio value="wait_release">通过</Radio>
                    <Radio value="failed">不通过</Radio>
                </Radio.Group>
            </Form.Item>
            {
                formData.status === "failed" && <>
                    <Form.Item name="failed_message" label="不通过原因" help="可不填">
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item name="return" initialValue={false} label="返还积分" rules={[{ required: true }]}>
                        <Radio.Group>
                            <Radio value={true}>返还</Radio>
                            <Radio value={false}>不返还</Radio>
                        </Radio.Group>
                    </Form.Item>
                </>
            }

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" disabled={!formData.status}>
                    提交
                </Button>
            </Form.Item>
        </Form>
    </div>
}

export { RewardConvertAuditForm, RewardConvertAuditFormModel }