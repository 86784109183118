import React from "react";
import TimeAgo from "react-timeago"
import frenchStrings from './local_time_ago_zh'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import dayjs from "dayjs";
const formatter = buildFormatter(frenchStrings)

class UTCTimeAgo extends React.Component {

    render() {
        const date = dayjs(this.props.date).add(8, 'hour')
        return <TimeAgo formatter={formatter} maxPeriod={60} {...this.props} date={date} />;
    }
}

export default UTCTimeAgo;