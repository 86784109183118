import { Select, Spin, Tag } from 'antd';
import debounce from 'lodash/debounce';
import React from 'react';
import { connect } from "react-redux";

function groupBy(array, id) {
    let groups = {};
    array.forEach(function (o) {
        let group = o[id];
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });
    return groups;
}

// 远程链接选择器
function InternalLinkRemoteSelect({ debounceTimeout = 500, params = {}, ...props }) {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchRef = React.useRef(0);
    let channel = props.channel

    function fetchUserList(name) {
        return channel.push(`search-internal-link`, { ...params, name })
    }

    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchUserList(value).receive("ok", ({ data }) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                var groupByTypeName = groupBy(data, "type_name")
                var data = []
                for (var groupName in groupByTypeName) {
                    data.push({
                        label: groupName,
                        options: groupByTypeName[groupName].map((item) => {
                            return {
                                id: item.id,
                                value: JSON.stringify(item),
                                label: item.name,
                                type: item.type
                            }
                        })
                    })
                }


                setOptions(data);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchUserList, debounceTimeout]);

    return (
        <Select
            labelInValue
            filterOption={false}
            onSearch={(value) => value.length > 1 && debounceFetcher(value)}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
        />
    );
}

const mapStateToProps = ({ global }) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(InternalLinkRemoteSelect)