import { Modal, message } from "antd";
import AuditForm from "./AuditForm";
import React from "react"
import { Provider } from 'react-redux';
import { legacy_createStore as createStore } from "redux"
import reducer from '../../store';
const store = createStore(reducer)

let auditModal = (reportChannel, simulatorChannel, report) => {
    let formRef = React.createRef()

    let modalTitle = null;


    switch (report.reference_type) {
        case "post":
            switch (report.reference?.type) {
                case "answer":
                    modalTitle = "回答详情"
                    break;
                case "question":
                    modalTitle = "提问详情"
                    break;

                default:
                    break;
            }
            break
        case "comment":
            modalTitle = "评论审核"
            break;
    }


    Modal.confirm({
        width: 1200,
        title: modalTitle,
        content: <Provider store={store}><AuditForm report={report} formRef={formRef} simulatorChannel={simulatorChannel} /></Provider>,
        okText: null,
        closable: true,
        okText: "提交",
        onOk: (close) => {
            const form = formRef.current
            form
                .validateFields()
                .then(values => {
                    form.resetFields();
                    reportChannel.push("audit-submit", { ...values, id: report.id })
                        .receive("ok", () => {
                            close()
                            message.success("提交成功")
                        })
                })
                .catch(info => {
                    console.log('Validate Failed:', info);
                });
            return false
        }
    });
}

export { auditModal }