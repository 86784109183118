import React from "react";
import { connect } from "react-redux";
import UserAvatar from "../../components/user/UserAvatar";
import { message, Popover, Row, Col, Statistic, Tooltip, Button, Table, Divider, Tag, Form, InputNumber, Input, Select, Radio } from 'antd';
import 'jsoneditor-react/es/editor.min.css';
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import UTCTimeAgo from "../../components/utc_time_ago"
import dayjs from "dayjs";
import ConvertAuditForm from "./convert_audit_form";
import LocalTimeAgo from "../../components/local_time_ago";
import ContractItem from "./contract_item";

const formItemLayout = {
    labelCol: {
        xs: { span: 10 },
    },
    wrapperCol: {
        xs: { span: 14 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 4,
            offset: 10,
        },
    },
};
class InviteCodeSim extends React.Component {

    state = {
        data: null,
        plan_rewards: [],
        invite_code_logs: [],
        invite_code_reward_converts: []
    }

    componentDidMount() {
        this.channelJoin(this.props.code)
        this.setState({ code: this.props.code })
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }


    UNSAFE_componentWillReceiveProps(props) {
        if (props.code !== this.state.code) {
            this.setState({ code: props.code })
            this.channelJoin(props.code)
        }
    }

    pushEvent(event, params, opts = null) {
        return this.state.channel.push(event, params)
    }

    channelJoin(code) {
        if (!!this.state.channel) {
            this.state.channel.off("data")
            this.state.channel.leave()
        }

        const channel = this.props.socket.channel(`marketing:invite_code:detail:${code}`)
        channel.on("data", ({ data }) => this.setState({ data }))
        channel.join()
        this.setState({ channel }, () => this.handleRefresh())
    }

    // 结算类型翻译
    settleTypeLabel = (value) => {
        switch (value) {
            case "cycle":
                return "循环"

            case "single":
                return "单次"
            default:
                break;
        }
    }

    // 结算单位翻译
    settleUnitLabel = (value) => {
        switch (value) {
            case "money":
                return "金额:分"

            case "coin":
                return "积分"
            default:
                break;
        }
    }

    // 结算周期状态
    settleCycleStatusLabel = (value) => {
        switch (value) {
            case "active":
                return "累计中"

            case "settled":
                return "已结算"
        }
    }

    // 周期元数据翻译
    durationMetaLabel = (value) => {
        switch (value) {
            case "acupoint:duration::day":
                return "天"

            case "acupoint:duration::month":
                return "月"

            case "acupoint:duration::year":
                return "年"

            case "acupoint:duration::lifelong":
                return "终身"
            default:
                break;
        }
    }

    // 签订合同
    handleSignContract() {
        this.pushEvent("accept:contract", {})
            .receive("ok", () => {
                this.pushEvent("refresh", {})
                this.handleRefresh()
                message.success("签订了合同")
            })
    }

    // 注销合同
    handleRevokeContract(id) {
        this.pushEvent("revoke:contract", { invite_code_contract_id: id })
            .receive("ok", () => {
                this.pushEvent("refresh", {})
                this.handleRefresh()
                message.success("注销了合同")
            })
    }

    // 点击售卖
    handleClickSell(sale) {
        this.pushEvent("sell", { sale_ref_type: sale.reference_type, sale_ref_id: sale.reference_id, code: this.props.code })
            .receive("ok", () => {
                this.pushEvent("refresh", {})
                this.handleRefresh()
                message.success("销售了一次")
            })
    }

    // 刷新数据
    handleRefresh() {
        this.pushEvent("get:invite_log", {})
            .receive("ok", (data) => this.setState({ invite_code_logs: data }))
        this.pushEvent("get:reward_converts", {})
            .receive("ok", (data) => this.setState({ invite_code_reward_converts: data }))
    }

    // 提交提取表格
    handleRewardConvertFormSubmit(reward_key, values) {
        this.pushEvent("apply-for:convert", { reward_key, ...values })
            .receive("ok", () => {
                this.handleRefresh()
                message.success("提交了申请")
            })
    }


    // 处理提取确认发放
    handleRewardConvertRelease(invite_reward_convert_id) {
        this.pushEvent("release:convert", { invite_reward_convert_id })
            .receive("ok", () => {
                this.handleRefresh()
                message.success("确认了发放")
            })
    }


    // 立即结算
    handleSettleInviteLog(invite_log_id, status) {
        this.pushEvent("settle:inivte_log", { invite_log_id, status })
            .receive("ok", () => {
                this.handleRefresh()
                message.success("提交了结算")
            })
    }

    // 提取表单
    convertForm(reward_key) {
        return <Form {...formItemLayout} onFinish={(values) => this.handleRewardConvertFormSubmit(reward_key, values)}>
            <Form.Item name="num" label="申请结算数量" rules={[{ required: true }]}>
                <InputNumber min={0.01} />
            </Form.Item>
            <Form.Item name="reason" label="申请原因" initialValue="" >
                <Input.TextArea />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" >
                    提交
                </Button>
            </Form.Item>
        </Form>
    }

    render() {
        const data = this.state.data
        return <>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: 200, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <UserAvatar nickname={data?.user?.nickname} size={80} avatar={data?.user?.avatar} id={data?.user?.id} vip={data?.user?.group === "vip"} />
                </div>

                <div style={{ flexGrow: 1 }}>
                    <Row>
                        {data?.name ?
                            <Col span={6}>
                                <Statistic title="名称" value={data?.name} />
                            </Col> :
                            <Col span={6}>
                                <Statistic title="营销计划名" value={data?.plan.display_name} />
                            </Col>
                        }
                        <Col span={6}>
                            <Statistic title="积分余额" value={data?.coin_balance} />
                        </Col>
                        <Col span={6}>
                            <Statistic title="有效时间" value={`${dayjs(data?.active_at).format("YYYY-MM-DD")}`} />
                        </Col>
                        <Col span={6}>
                            <Statistic title="过期时间" value={`${dayjs(data?.expired_at).format("YYYY-MM-DD")}`} />
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col span={6}>
                            <Tooltip title="结算周期，分为单次和循环，表示此计划下的营销行为可完成单次还是无限次。">
                                <Statistic title={<span>结算周期 <InfoCircleOutlined /></span>} value={this.settleTypeLabel(data?.plan.settle_type)} />
                            </Tooltip>
                        </Col>
                        <Col span={6}>
                            <Statistic title="销售总量" value={data?.total_price} />
                        </Col>
                        <Col span={6}>
                            <Statistic title="限制销售次数" value={data?.limit_count || "无限制"} />
                        </Col>
                        <Col span={6}>
                            <Statistic title="积分名称" value={data?.plan.coin_unit} />
                        </Col>
                    </Row>
                </div>
            </div>
            <Divider orientation="left">一、签订合同</Divider>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {data?.plan_contract &&
                    <div style={{ flexGrow: 1, border: "1px solid #dfdfdf", marginRight: 10, width: 300 }}>
                        <ContractItem readonly item={data?.plan_contract}></ContractItem>
                        {data?.plan_contract.signed ?
                            <Button block type="link" disabled>已签订</Button>
                            : <Button block type="link" onClick={this.handleSignContract.bind(this)}>签订</Button>}
                    </div>}

                <Table bordered title={() => "签订历史"} dataSource={this.state.data?.signed_contract} rowKey={d => d.id} style={{ flexGrow: 1 }}>
                    <Table.Column align="center" title="签订时间" dataIndex="inserted_at" key="inserted_at" render={text => <UTCTimeAgo date={text} />} />
                    <Table.Column align="center" title="更新时间" dataIndex="updated_at" key="updated_at" render={text => <UTCTimeAgo date={text} />} />
                    <Table.Column align="center" title="IP" dataIndex="ip" key="ip" />
                    <Table.Column align="center" title="内容" dataIndex="content" key="content" render={text => <Popover content={<div style={{ height: 400, overflowY: "auto" }} dangerouslySetInnerHTML={{ __html: text }}></div>}><Button type="link">查看</Button></Popover>} />
                    <Table.Column align="center" title="状态" dataIndex="status" key="status" render={(text, item) => {
                        switch (text) {
                            case "sign":
                                return "已签订";
                            case "revoke":
                                return "已注销";
                        }
                    }} />
                    <Table.Column align="center" title="操作" key="operate" render={(_, item) => {
                        switch (item.status) {
                            case "sign":
                                return <Popover content={<Button type="link" danger onClick={() => this.handleRevokeContract(item.id)}>确认</Button>}>
                                    <Button type="link">注销</Button>
                                </Popover>
                            default:
                                return null;
                        }
                    }} />
                </Table>
            </div>
            <Divider orientation="left">二、售卖阶段</Divider>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Table bordered title={() => "可兑换报酬"} dataSource={data?.plan_rewards} style={{ flexGrow: 1, marginRight: 10 }} rowKey={d => d.reference_id} pagination={false} >
                    <Table.Column align="center" title="报酬名" dataIndex="name" key="name" />
                    <Table.Column align="center" title="兑换需要积分" dataIndex="coin_num" key="coin_num" />
                    <Table.Column title="兑换" width={80} align="center" key="convert" render={(_text, item) => {
                        return <Popover content={this.convertForm(item.reward_key)}>
                            <Button type="link" disabled={item.coin_num > data?.coin_balance}>兑换一次</Button>
                        </Popover>

                    }} />
                </Table>
                <Table bordered title={() => "计划可用营销品"} dataSource={data?.plan_sales} style={{ flexGrow: 1 }} rowKey={d => d.reference_id} pagination={false}>
                    <Table.Column align="center" title="商品名" dataIndex="name" key="name" />
                    <Table.Column align="center" title="销售价格(分)" dataIndex="price" key="price" />
                    {/* <Table.Column align="center" title="标准价格(分)" dataIndex="amount" key="amount" /> */}
                    <Table.Column align="center" title="可得积分" dataIndex="coin_num" key="coin_num" />
                    <Table.Column align="center" title="操作" key="operate" render={(_, item) => <>
                        <Button type="link" onClick={() => this.handleClickSell(item)}>销售一次</Button>
                    </>} />
                </Table>
            </div>
            <Divider orientation="left">三、结算阶段</Divider>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Table bordered title={() => "销售历史"} dataSource={this.state.invite_code_logs} rowKey={d => d.id} style={{ flexGrow: 1 }}>
                    <Table.Column align="center" title="销售时间" dataIndex="inserted_at" key="inserted_at" render={text => <UTCTimeAgo date={text} />} />
                    <Table.Column align="center" title="销售价格" dataIndex="price" key="price" />
                    <Table.Column align="center" title="预计获得积分" dataIndex="adding_num" key="adding_num" />
                    <Table.Column align="center" title="获得积分" dataIndex="coin_num" key="coin_num" />
                    <Table.Column align="center" title="结算时间" dataIndex="settle_at" key="settle_at" render={text => <LocalTimeAgo date={text}></LocalTimeAgo>} />
                    <Table.Column align="center" title="状态" dataIndex="status" key="status" render={(text, item) => {
                        switch (text) {
                            case "pending":
                                return "等待结算";
                            case "success":
                                return "结算完成";
                            case 'failed':
                                return <Tooltip title={item.reason}>结算失败 <QuestionCircleOutlined /></Tooltip>;
                        }
                    }} />

                    <Table.Column align="center" title="操作" key="operate" render={(_, item) => {
                        switch (item.status) {
                            case "pending":
                                return <>
                                    <Button type="link" onClick={() => this.handleSettleInviteLog(item.id, "success")}>结算成功</Button>
                                    <Divider type="vertical" />
                                    <Button type="link" danger onClick={() => this.handleSettleInviteLog(item.id, "failed")}>结算失败</Button>
                                </>
                            case "success":
                                return null;
                            case 'failed':
                                return null;
                        }
                    }} />
                </Table>
            </div>
            <Divider orientation="left">四、报酬发放阶段，人工介入</Divider>
            <br />
            <Table bordered title={() => "申请提取列表"} dataSource={this.state.invite_code_reward_converts} rowKey={d => d.id} style={{ flexGrow: 1 }}>
                <Table.Column align="center" title="申请时间" dataIndex="inserted_at" key="inserted_at" render={text => <UTCTimeAgo date={text} />} />
                <Table.Column align="center" title="报酬名" dataIndex="reward_name" key="reward_name" />
                <Table.Column align="center" title="申请量" dataIndex="convert_num" key="convert_num" />
                <Table.Column align="center" title="积分消耗" dataIndex="coin_cost" key="coin_cost" />
                <Table.Column align="center" title="状态" dataIndex="status" key="status" render={(text) => {
                    switch (text) {
                        case "pending":
                            return "等待审核";
                        case "wait_release":
                            return "等待发放";
                        case 'finish':
                            return "发放完成";
                        case "failed":
                            return "审核失败";
                    }
                }} />
                <Table.Column align="center" title="申请原因" dataIndex="reason" key="reason" />
                <Table.Column align="center" title="审核拒绝原因" dataIndex="failed_message" key="failed_message" />
                <Table.Column align="center" title="审核" render={(_, item) => {
                    const auditForm = <Popover content={<ConvertAuditForm rewardConvertID={item.id} channel={this.state.channel} onRefresh={this.handleRefresh.bind(this)} />}>
                        <Button type="link">审核</Button>
                    </Popover>
                    switch (item.status) {
                        case "pending":
                            return auditForm
                        case "failed":
                            return auditForm
                        case "wait_release":
                            return <Popover content={<Button type="primary" onClick={() => this.handleRewardConvertRelease(item.id)}>二次确认</Button>}>
                                <Button type="link">报酬确认发放</Button>
                            </Popover>

                        default:
                            break;
                    }

                }} />
            </Table>
        </>
    }
}



const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(InviteCodeSim)