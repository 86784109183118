import React from "react";
import { Table, Button, Form, Radio, DatePicker, Divider } from 'antd';
import ButtonGroup from "antd/lib/button/button-group";
import { connect } from 'react-redux';
import dayjs from "dayjs"
import locale from 'antd/es/date-picker/locale/zh_CN';
import './ActiveRecords.css'
import UserAvatar from "../../components/user/UserAvatar";


const { Column } = Table;
const { RangePicker } = DatePicker
class ActiveRecords extends React.Component {
    constructor(props) {
        super(props);
        this.filterFormRef = React.createRef();
    }

    state = {
        channel: null,
        data: {},
        loading: false,
        page: 1,
        size: 10,
        query: {},
        channelCount: {},
        channels: []
    }

    loadData(page, size) {
        page = page || this.state.data.page_number
        size = size || this.state.data.page_size
        this.state.channel.push("paged-data", { page, size, query: this.state.query })
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    componentDidMount() {
        const channel = this.props.socket.channel("statistics:register")
        channel.join()
        channel.on("paged-data", (data) => {
            this.setState({ data })
        })

        channel.on("channel-count", (data) => {
            this.setState({ channelCount: data })
        })

        channel.on("channel-data", ({ data }) => {
            this.setState({ channels: data })
        })

        const defaultActiveAt = [dayjs().startOf("date"), dayjs().add(1, 'day').startOf("date")]

        this.filterFormRef.current.setFieldsValue({ active_at: defaultActiveAt })

        this.setState({ channel, query: { active_at: defaultActiveAt } }, this.loadData)
    }

    // 搜索
    handleOnSearch(values) {
        let params = {}

        if (values.channel !== 'all') params["channel"] = values.channel
        if (values.group !== 'all') params["group"] = values.group
        if (!!values.active_at) params["active_at"] = [
            values.active_at[0].set({ hour: 0, minute: 0, second: 0 }),
            values.active_at[1].set({ hour: 0, minute: 0, second: 0 })
        ]

        this.setState({ query: params }, () => {
            this.loadData()
        })
    }

    // 重置搜索
    handleSearchReset() {
        this.setState({ query: {} }, () => { this.loadData() })
    }

    handleRelativeDate(begin, end) {
        this.filterFormRef.current.setFieldsValue({ active_at: [begin, end] })
        this.filterFormRef.current.submit()
    }

    handleOnTableChange(_pagination, _filters, sorter) {
        console.log(sorter)
    }

    render() {
        let total = 0
        for (let channel in this.state.channelCount)
            total = total + this.state.channelCount[channel]
        return <div className="page register-records">
            <h1>激活用户</h1>
            <div className="filter">
                <Form name="basic" ref={this.filterFormRef} layout="inline" onReset={this.handleSearchReset.bind(this)}
                    initialValues={{ channel: "all", group: "all" }}
                    onFinish={this.handleOnSearch.bind(this)}>
                    <Form.Item name="channel" label={false} className="channel">
                        <Radio.Group buttonStyle="solid" size="small">
                            <Radio.Button value="all">全部 <span className="badge">{total}</span></Radio.Button>

                            {
                                this.state.channels.map(channel =>
                                    <Radio.Button key={channel.key} value={channel.key}>{channel.name} <span className="badge">{this.state.channelCount[channel.key]}</span></Radio.Button>)
                            }
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="group" label={false}>
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="all">全部</Radio.Button>
                            <Radio.Button value="1">会员</Radio.Button>
                            <Radio.Button value="0">非会员</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="active_at" label={false}>
                        <RangePicker format="YYYY-MM-DD" locale={locale} />
                    </Form.Item>
                    <Form.Item>
                        <ButtonGroup>
                            <Button type="primary" htmlType="submit" >查询</Button>
                            <Button type="ghost" htmlType="reset">重置</Button>
                        </ButtonGroup>
                    </Form.Item>

                    <Form.Item label={false} className="quick-date">
                        <ButtonGroup>
                            <Button onClick={() => this.handleRelativeDate(dayjs().startOf("date"), dayjs().add(1, 'day').startOf("date"))}>今日</Button>
                            <Button onClick={() => this.handleRelativeDate(dayjs().add(-1, "day").startOf("date"), dayjs().startOf("date"))}>昨日</Button>
                            <Button onClick={() => this.handleRelativeDate(dayjs().startOf("week"), dayjs().endOf(1, 'week'))}>本周</Button>
                            <Button onClick={() => this.handleRelativeDate(dayjs().add(-1, "week").startOf("week"), dayjs().add(-1, "week").endOf("week"))}>上周</Button>
                            <Button onClick={() => this.handleRelativeDate(dayjs().startOf("month"), dayjs().endOf("month"))}>本月</Button>
                            <Button onClick={() => this.handleRelativeDate(dayjs().add(-1, "month").startOf("month"), dayjs().add(-1, "month").endOf("month"))}>上月</Button>
                            <Button onClick={() => this.handleRelativeDate(dayjs().add(-7, "days").startOf("date"), dayjs())}>最近7天</Button>
                            <Button onClick={() => this.handleRelativeDate(dayjs().add(-1, "month").startOf("date"), dayjs())}>最近30天</Button>
                        </ButtonGroup>
                    </Form.Item>
                </Form>
            </div>

            {
                this.state.data.entries && <Table dataSource={this.state.data.entries} rowKey={item => item.id}
                    onChange={this.handleOnTableChange.bind(this)}
                    size="middle"
                    scroll={{ y: document.documentElement.clientHeight - 500 }}
                    pagination={{
                        onChange: (page, size) => { this.loadData(page, size) },
                        total: this.state.data.total_entries,
                        current: this.state.data.page_number,
                        pageSize: this.state.data.page_size,
                        showQuickJumper: true
                    }}>
                    <Column title="ID" align="center" dataIndex="id" width={50} key="id" />
                    <Column title="用户" width={80} align="center" dataIndex="avatar" key="avatar" render={(_text, obj) =>
                        <UserAvatar id={obj.id} nickname={obj.nickname} size={50} avatar={obj.avatar} vip={obj.group === "vip"} />} />
                    <Column title="VIP时间" align="center" dataIndex="vip_ended_at" width={100} key="vip_ended_at" />
                    <Column title="渠道" align="center" dataIndex="channel" width={250} key="channel" render={(_text, obj) => <span>
                        {obj.channel} <Divider type="vertical" /> {obj.nt_channel}
                    </span>} />
                    <Column title="版本" align="center" dataIndex="version" width={100} key="version" />
                    <Column title="品牌" align="center" dataIndex={["created_app_info", "device_brand"]} width={100} key={["created_app_info", "device_brand"]} />
                    <Column title="型号" align="center" dataIndex={["created_app_info", "device_model"]} width={100} key={["created_app_info", "device_model"]} />
                    <Column title="系统" align="center" dataIndex={["created_app_info", "device_version"]} width={100} key={["created_app_info", "device_version"]} />
                    <Column title="激活时间" align="center" dataIndex="active_at" width={150} key="active_at" render={text => dayjs(`${text}`).format("YY-MM-DD HH:mm:ss")} />
                    <Column title="注册时间" align="center" dataIndex="created_at" width={150} key="created_at" render={text => dayjs(`${text}`).format("YY-MM-DD HH:mm:ss")} />
                </Table>
            }
        </div >
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ActiveRecords);