import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Button, Form, Modal, message, Avatar, List } from "antd"
import { PlusSquareTwoTone, } from '@ant-design/icons'
import { Menu as MenuAction } from '../../store/global/actions'
import PasswordConfirm from "../../components/passwordConfirm"

import { Provider } from 'react-redux';
import { legacy_createStore as createStore } from "redux"
import reducer from '../../store';
import configFunc from "../../config";

import ChannelForm from "./components/ChannelForm"
import { Badge } from "antd"

const store = createStore(reducer)


function NotificationChannel(props) {
    const [channel, setChannel] = useState(null)
    let [data, setData] = useState([])
    let [channelForm] = Form.useForm()

    props?.setActiveKey("NOTIFICATION-CHANNEL")

    useEffect(() => {
        let _channel = props.socket.channel("notification:channel")
        _channel.join()
        setChannel(_channel)

        _channel.on("data", ({ data }) => { setData(data) })

        return () => {
            if (!!_channel) _channel.leave()
        }
    }, [])

    // 添加版本
    const handleForm = (data) => {
        channelForm.setFieldsValue(data)

        Modal.confirm({
            width: 800,
            title: '频道管理',
            content: <Provider store={store}>
                <ChannelForm form={channelForm} data={data} />
            </Provider>,
            cancelText: "取消",
            okText: "提交",
            onCancel: () => {
                channelForm.resetFields();
            },
            onOk: (close) => {
                const form = channelForm
                form
                    .validateFields()
                    .then(values => {
                        if (!!data.id)
                            channel.push("update", { ...values })
                                .receive("ok", (resp) => {
                                    close()
                                    message.success("修改成功")
                                    form.resetFields();
                                })
                        else
                            channel.push("create", { ...values })
                                .receive("ok", (resp) => {
                                    close()
                                    message.success("新建成功")
                                    form.resetFields();
                                })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    return <div className="page operation version">
        <h1>
            消息通知频道配置
        </h1>

        <div className="actions" style={{ display: "flex" }}>
            <Button icon={<PlusSquareTwoTone />} type="link" onClick={handleForm}>
                新增频道
            </Button>
        </div>
        <br />
        <div style={{ width: 450, height: 600, border: "5px solid #efefef", borderRadius: "10px", padding: 10 }}>
            <List dataSource={data}
                renderItem={(item) => (
                    <List.Item actions={[
                        <Badge count={item.index}></Badge>,
                        <Button type="link" onClick={() => handleForm(item)}>修改</Button>
                    ]}>
                        <List.Item.Meta
                            avatar={<Avatar size="large" src={item.icon} />}
                            title={item.name}
                            description="这里会显示最新一条消息的摘要。"
                        />
                    </List.Item>
                )}>

            </List>
        </div>
    </div >
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NotificationChannel)