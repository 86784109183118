import React from "react";
import { Menu, Tag } from 'antd';
import { AimOutlined, UnorderedListOutlined, HistoryOutlined, AreaChartOutlined, ExceptionOutlined } from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";
import { connect } from 'react-redux';
import RequireRolesOr from '../../components/require_roles_or'

function SideMenu() {
    const location = useLocation()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")
    const fullPathName = location.pathname

    return <Menu style={{ width: 256 }} defaultSelectedKeys={[pathName, fullPathName]} mode="inline" >
        <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::finance"]}>
            <Menu.ItemGroup key="cache" title="缓存">
                <Menu.Item key="/tech/database/cache/Acupoint.Cache" icon={<UnorderedListOutlined style={{ color: "#1890ff" }} />}>
                    <Link to="/tech/database/cache/Acupoint.Cache">基础缓存</Link>
                </Menu.Item>
            </Menu.ItemGroup>
            <RequireRolesOr require={["acupoint:role::manager"]}>
                <Menu.ItemGroup key="exception" title="异常">
                    <Menu.Item key="/tech/exception" icon={<ExceptionOutlined style={{ color: "#1890ff" }} />}>
                        <Link to="/tech/exception">异常日志</Link>
                    </Menu.Item>
                </Menu.ItemGroup>
            </RequireRolesOr>
            <Menu.ItemGroup key="push" title="推送">
                <Menu.Item key="/tech/push/customize" icon={<AimOutlined style={{ color: "#1890ff" }} />}>
                    <Link to="/tech/push/customize">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>自定义推送</span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/tech/push/history" icon={<HistoryOutlined style={{ color: "#1890ff" }} />}>
                    <Link to="/tech/push/history">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>推送历史</span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/tech/push/template" icon={<AreaChartOutlined style={{ color: "#1890ff" }} />} >
                    <Link to="/tech/push/template">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>推送模板</span>
                        </div>
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="log" title="日志">
                <Menu.Item key="/tech/operation_log" icon={<UnorderedListOutlined style={{ color: "#1890ff" }} />}>
                    <Link to="/tech/operation_log">操作日志</Link>
                </Menu.Item>
            </Menu.ItemGroup>
        </RequireRolesOr>
    </Menu >
}


const mapStateToProps = ({ global }) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);