import { HashRouter as Router, Route } from "react-router-dom";
import { connect } from 'react-redux';

import SideMenu from "./components/side_menu"
import React from "react";

import PushHistory from "./Push/History";
import PushTemplate from "./Push/TemplateList";
import PushCustomize from "./Push/Customize"
import Exception from "./Exception";
import TechOperationLog from "./OperationLog";

import Cache from "./Cache";

class Tech extends React.Component {

    componentDidMount() {
    }

    render() {
        return <Router>
            <div className="module module-push">
                <div className="side-menu">
                    <SideMenu />
                </div>
                <div className="content">
                    <Route path="/tech/database/cache/:name" exact component={Cache}></Route>
                    <Route path="/tech/push/customize/template/:template_id" exact component={PushCustomize}></Route>
                    <Route path="/tech/push/customize" exact component={PushCustomize}></Route>
                    <Route path="/tech/push/history" exact component={PushHistory}></Route>
                    <Route path="/tech/push/template" exact component={PushTemplate}></Route>
                    <Route path="/tech/exception" exact component={Exception}></Route>
                    <Route path="/tech/operation_log" exact component={TechOperationLog}></Route>
                    {/* <Route path="/resource/list" exact component={ResourceList}></Route>
                    <Route path="/resource/video-course-list" exact component={VideoCourseList}></Route>
                    <Route path="/resource/video-course-list/:id/editor" exact component={VideoCourseEditor}></Route> */}
                </div>
            </div>
        </Router>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Tech)