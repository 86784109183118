const Menu = {
    setActive: (key) => {
        return { type: "MenuSetActive", key: key }
    }
}

const Socket = {
    set: (socket) => {
        return { type: "GlobalSocket", socket: socket }
    }
}


const Session = {
    signedIn: (user) => {
        return { type: "Session:SignedIn", user: user }
    },
    logOut: () => {
        return { type: "Session:LogOut" }
    }
}

const OnlineUsers = {
    set: (users) => {
        return { type: "Online:User", users }
    },
    routeUpdate: (user_id, route) => {
        return { type: "Online:User:Route:Update", user_id, route }
    }
}

// 用户信息模态框
const userInformationModal = {
    show: (userId) => {
        return { type: "UserInformationMadal:show", userId }
    },

    close: () => {
        return { type: "UserInformationMadal:close", userId: null }
    }
}


// 客服消息频道
const CustomerOperationChannel = {
    join: (channel) => {
        return { type: "CustomerOperationChannel:Connect", channel }
    },

    leave: () => {
        return { type: "CustomerOperationChannel:Disconnect" }
    },
    Conversation: {
        // 激活某个房主的id
        active: (customer_id) => {
            return { type: "CustomerOperationChannel:Conversation:Active", customer_id }
        }
    }
}

// 消息提示频道
const NotificationChannel = {
    join: (channel) => {
        return { type: "NotificationChannel:Connect", channel }
    },

    leave: () => {
        return { type: "NotificationChannel:Disconnect" }
    }
}

// 在线用户频道
const AppUserChannel = {
    join: (channel) => {
        return { type: "AppUserChannel:Connect", channel }
    },

    leave: () => {
        return { type: "AppUserChannel:Disconnect" }
    }
}


// 用户运维
const UserOpsChannel = {
    join: (channel) => {
        return { type: "UserOpsChannel:Connect", channel }
    },

    leave: () => {
        return { type: "UserOpsChannel:Disconnect" }
    }
}

// 全局频道
const GlobalChannel = {
    join: (channel) => {
        return { type: "GlobalChannel:Connect", channel }
    },

    leave: () => {
        return { type: "GlobalChannel:Disconnect" }
    }
}


// 推送频道
const PushChannel = {
    join: (channel) => {
        return { type: "PushChannel:Connect", channel }
    },

    leave: () => {
        return { type: "PushChannel:Disconnect" }
    }
}

export { Socket, Menu, Session, CustomerOperationChannel, NotificationChannel, AppUserChannel, OnlineUsers, userInformationModal, UserOpsChannel, PushChannel, GlobalChannel }