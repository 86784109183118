import { Tag, Input, Tooltip } from 'antd';
import React from "react";
import { PlusOutlined } from '@ant-design/icons';

class UserTags extends React.Component {
    state = {
        // tags: ['Tag 2', 'Tag 3'],
        inputVisible: false,
        inputValue: ''
    };

    constructor(props) {
        super(props);
        this.saveInputRef = React.createRef();
    }

    handleClose = title => {
        this.props.pushEvent("tag-remove", { title: title });
    };

    showInput = () => {
        this.setState({ inputVisible: true }, () => this.saveInputRef.current.focus());
    };

    handleInputChange = e => {
        this.setState({ inputValue: e.target.value });
    };

    handleInputConfirm = () => {
        const { inputValue } = this.state;

        this.props.pushEvent("tag-add", { title: inputValue });
        this.setState({
            inputVisible: false,
            inputValue: '',
        });
    };

    handleEditInputChange = e => {
        this.setState({ editInputValue: e.target.value });
    };

    render() {
        const { inputVisible, inputValue } = this.state;
        return (
            <>
                {this.props.tags.map((tag) => {
                    const isLongTag = tag.title.length > 20;

                    const tagElem = (
                        <Tag
                            className="edit-tag"
                            key={tag.id}
                            closable
                            onClose={() => this.handleClose(tag.title)}
                        >
                            {isLongTag ? `${tag.title.slice(0, 20)}...` : tag.title}
                        </Tag>
                    );
                    return isLongTag ? (
                        <Tooltip title={tag.title} key={tag.id}>
                            {tagElem}
                        </Tooltip>
                    ) : (
                        tagElem
                    );
                })}
                {inputVisible && (
                    <Input
                        ref={this.saveInputRef}
                        type="text"
                        size="small"
                        style={{ width: 78, marginRight: 8, verticalAlign: "top" }}
                        className="tag-input"
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                    />
                )}
                {!inputVisible && (
                    <Tag className="site-tag-plus" onClick={this.showInput}>
                        <PlusOutlined /> 新增
                    </Tag>
                )}
            </>
        );
    }
}

export default UserTags;