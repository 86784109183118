import React from "react";
import { Menu, Tag } from 'antd';
import { UnorderedListOutlined, AreaChartOutlined, AppleFilled, ShareAltOutlined } from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";
import { connect } from 'react-redux';

import RequireRolesOr from '../../components/require_roles_or'

function SideMenu() {
    const location = useLocation()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")

    return <Menu style={{ width: 256 }} defaultSelectedKeys={[pathName]} mode="inline" >
        <Menu.ItemGroup key="statistic" title="统计">
            <Menu.Item key="/statistics/register-" icon={<AreaChartOutlined />} disabled>
                <Link to="/statistics/register-">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>分发渠道</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>

        {
            RequireRolesOr.allow(["acupoint:role::operator", "acupoint:role::finance"]) &&
            <Menu.ItemGroup key="history" title="记录">
                {
                    RequireRolesOr.allow(["acupoint:role::operator"]) &&
                    <Menu.Item key="/statistics/register-records" icon={<UnorderedListOutlined />}>
                        <Link to="/statistics/register-records">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>激活用户</span>
                                <span><Tag color="blue">实时</Tag></span>
                            </div>
                        </Link>
                    </Menu.Item>
                }
                {
                    RequireRolesOr.allow(["acupoint:role::operator"]) &&
                    <Menu.Item key="/statistics/devices-records" disabled icon={<UnorderedListOutlined />}>
                        <Link to="/statistics/devices-records">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>设备</span>
                                <span><Tag color="blue">实时</Tag></span>
                            </div>
                        </Link>
                    </Menu.Item>
                }
                {
                    RequireRolesOr.allow(["acupoint:role::operator"]) &&
                    <Menu.Item key="/statistics/nineton-logs" icon={<UnorderedListOutlined />}>
                        <Link to="/statistics/nineton-logs">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>九顿中台日志</span>
                            </div>
                        </Link>
                    </Menu.Item>
                }
                {
                    RequireRolesOr.allow(["acupoint:role::operator"]) &&
                    <Menu.Item key="/statistics/apple-notify-logs" icon={<AppleFilled />}>
                        <Link to="/statistics/apple-notify-logs">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>苹果回调日志</span>
                            </div>
                        </Link>
                    </Menu.Item>
                }
                {
                    RequireRolesOr.allow(["acupoint:role::operator"]) &&
                    <Menu.Item key="/statistics/graph-logs" icon={<ShareAltOutlined />}>
                        <Link to="/statistics/graph-logs">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>知识图谱日志</span>
                            </div>
                        </Link>
                    </Menu.Item>
                }
            </Menu.ItemGroup>
        }
        {
            RequireRolesOr.allow(["acupoint:role::operator", "acupoint:role::customer-service"]) &&
            <Menu.ItemGroup key="xun-ai" title="寻艾">
                <Menu.Item key="/statistics/tongue-analysis" icon={<UnorderedListOutlined />}>
                    <Link to="/statistics/tongue-analysis">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>舌诊</span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/statistics/acupoint-detect" icon={<UnorderedListOutlined />}>
                    <Link to="/statistics/acupoint-detect">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>穴位检测</span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/statistics/hand-analysis" icon={<UnorderedListOutlined />}>
                    <Link to="/statistics/hand-analysis">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>手诊</span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/statistics/face-analysis" icon={<UnorderedListOutlined />}>
                    <Link to="/statistics/face-analysis">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>面诊</span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/statistics/corporeity-analysis" icon={<UnorderedListOutlined />}>
                    <Link to="/statistics/corporeity-analysis">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>体质分析</span>
                        </div>
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>
        }
    </Menu >
}


const mapStateToProps = ({ global }) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);