
import React from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import { Menu as MenuAction } from '../store/global/actions'
import { connect } from 'react-redux';

import Articles from './Articles'
import ArticleForm from './ArticleForm'
import SideMenu from "./components/SideMenu"
import VideoCourseList from './VideoCourse/List'
import VideoCourseEditor from './VideoCourse/Editor'
import VideoCourseReport from './VideoCourse/Report'
import MeridianList from "./Meridian/List"
import AcupointList from "./Acupoint/List"
import IllnessList from "./Illness/List"
import FoodList from "./Food/List"
import HerbList from "./Herb/List"
import EntryList from "./Entry"
import DoctorList from "./Term/Doctor"
import Terminology from "./Term/Terminology";
import DietTherapy from "./Term/DietTherapy";
import AncientBook from "./Term/AncientBook";
import BasicTheory from "./Term/BasicTheory";
import Regimen from "./Term/Regimen";
import Poetics from "./Term/Poetics"

import Cortex from "./Term/Cortex";
import Divergence from "./Term/Divergence";
import Sinew from "./Term/Sinew";
import Channel from "./Term/Channel";
import Book from "./Read/Book";

class CMS extends React.Component {

    componentDidMount() {
        this.props.setActiveKey("TERM")
    }

    render() {
        return <Router>
            <div className="module module-cms">
                <div className="side-menu">
                    <SideMenu />
                </div>
                <div className="content">
                    <Route path="/cms/article/new" exact component={ArticleForm}></Route>
                    <Route path="/cms/:column_title/articles/new" exact component={ArticleForm}></Route>
                    <Route path="/cms/:column_title/articles" exact component={Articles}></Route>
                    <Route path="/cms/:column_title/:article_title/edit" exact component={ArticleForm}></Route>
                    <Route path="/cms/video-course-list" exact component={VideoCourseList}></Route>
                    <Route path="/cms/video-course/report" exact component={VideoCourseReport}></Route>
                    <Route path="/cms/video-course-list/:id/editor" exact component={VideoCourseEditor}></Route>
                    <Route path="/cms/entry/list" exact component={EntryList}></Route>

                    <Route path="/cms/meridian/:type" exact component={MeridianList}></Route>
                    <Route path="/cms/meridian/:type/:name" exact component={MeridianList}></Route>
                    <Route path="/cms/acupoint/:type" exact component={AcupointList}></Route>
                    <Route path="/cms/acupoint/:type/:name" exact component={AcupointList}></Route>
                    <Route path="/cms/illness" exact component={IllnessList}></Route>
                    <Route path="/cms/illness/:name" exact component={IllnessList}></Route>
                    <Route path="/cms/food" exact component={FoodList}></Route>
                    <Route path="/cms/food/:name" exact component={FoodList}></Route>
                    <Route path="/cms/herb" exact component={HerbList}></Route>
                    <Route path="/cms/herb/:name" exact component={HerbList}></Route>
                    <Route path="/cms/term/Doctor" exact component={DoctorList}></Route>
                    <Route path="/cms/term/Doctor/:name" exact component={DoctorList}></Route>
                    <Route path="/cms/term/Terminology" exact component={Terminology}></Route>
                    <Route path="/cms/term/Terminology/:name" exact component={Terminology}></Route>
                    <Route path="/cms/term/AncientBook" exact component={AncientBook}></Route>
                    <Route path="/cms/term/AncientBook/:name" exact component={AncientBook}></Route>
                    <Route path="/cms/term/DietTherapy" exact component={DietTherapy}></Route>
                    <Route path="/cms/term/DietTherapy/:name" exact component={DietTherapy}></Route>
                    <Route path="/cms/term/BasicTheory" exact component={BasicTheory}></Route>
                    <Route path="/cms/term/BasicTheory/:name" exact component={BasicTheory}></Route>
                    <Route path="/cms/term/Regimen" exact component={Regimen}></Route>
                    <Route path="/cms/term/Regimen/:name" exact component={Regimen}></Route>
                    <Route path="/cms/term/Poetics" exact component={Poetics}></Route>
                    <Route path="/cms/term/Poetics/:name" exact component={Poetics}></Route>

                    <Route path="/cms/term/Divergence" exact component={Divergence}></Route>
                    <Route path="/cms/term/Divergence/:name" exact component={Divergence}></Route>
                    <Route path="/cms/term/Sinew" exact component={Sinew}></Route>
                    <Route path="/cms/term/Sinew/:name" exact component={Sinew}></Route>
                    <Route path="/cms/term/Cortex" exact component={Cortex}></Route>
                    <Route path="/cms/term/Cortex/:name" exact component={Cortex}></Route>
                    <Route path="/cms/term/Channel" exact component={Channel}></Route>
                    <Route path="/cms/term/Channel/:name" exact component={Channel}></Route>

                    <Route path="/cms/book" exact component={Book}></Route>

                </div>
            </div>
        </Router>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        activeKey: global.navActiveKey
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CMS)

