import { Select } from "antd";
import { Input } from "antd";
import { Form, Button, Radio } from "antd"
import { useEffect } from "react";


function QueryForm(props) {
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue(props.values)
    }, [])

    return <div>
        <Form form={form} autoComplete="off" size="small" layout="inline" onFinish={props.onSearch} initialValues={props.values}>
            <Form.Item name="title" label="标题">
                <Input placeholder="模糊搜索" allowClear />
            </Form.Item>
            <Form.Item name="status" label="状态">
                <Radio.Group buttonStyle="solid">
                    <Radio.Button value="draft">草稿</Radio.Button>
                    <Radio.Button value="publish">发布</Radio.Button>
                    <Radio.Button value="reported">被举报下架</Radio.Button>
                    <Radio.Button value="deleted">被删除</Radio.Button>
                    <Radio.Button value="risky">有风险</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item name="reviewed" label="复查">
                <Radio.Group buttonStyle="solid">
                    <Radio.Button value="true">复查通过</Radio.Button>
                    <Radio.Button value="false">未复查</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item name="recommend" label="精帖">
                <Radio.Group buttonStyle="solid">
                    <Radio.Button value={null}>全部</Radio.Button>
                    <Radio.Button value={true}>精帖</Radio.Button>
                    <Radio.Button value={false}>普通</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* <Form.Item name="status" label="状态">
                <Radio.Group buttonStyle="solid">
                    <Radio.Button value="pending">待审核</Radio.Button>
                    <Radio.Button value="settle">已审核</Radio.Button>
                </Radio.Group>
            </Form.Item> */}
            <Button.Group>
                <Button type="primary" htmlType="submit">查询</Button>
            </Button.Group>
        </Form>
    </div>
}

export default QueryForm;