
import React from "react";
import { message, Button, Form, Input, Radio } from 'antd';

const formItemLayout = {
    labelCol: {
        xs: { span: 10 },
    },
    wrapperCol: {
        xs: { span: 14 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 4,
            offset: 10,
        },
    },
};

function ConvertAuditForm({ channel, rewardConvertID, onRefresh, ...props }) {

    // 处理提取审核表单
    const handleRewardConvertAuditFormSubmit = (invite_reward_convert_id, values) => {
        channel.push("audit:convert", { invite_reward_convert_id, ...values })
            .receive("ok", () => {
                if (!!onRefresh)
                    onRefresh()
                message.success("提交了审核")
            })
    }

    return <Form {...formItemLayout} onFinish={(values) => handleRewardConvertAuditFormSubmit(rewardConvertID, values)}>
        <Form.Item name="status" initialValue="wait_release" label="审核意见" rules={[{ required: true }]}>
            <Radio.Group>
                <Radio value="wait_release">通过</Radio>
                <Radio value="failed">不通过</Radio>
            </Radio.Group>
        </Form.Item>
        <Form.Item name="failed_message" label="不通过原因" help="可不填">
            <Input.TextArea />
        </Form.Item>
        <Form.Item name="return" initialValue={false} label="返还积分" rules={[{ required: true }]}>
            <Radio.Group>
                <Radio value={true}>返还</Radio>
                <Radio value={false}>不返还</Radio>
            </Radio.Group>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" >
                提交
            </Button>
        </Form.Item>
    </Form>
}

export default ConvertAuditForm