import React from "react";
import BuyingListLive from './BuyingList'

import Dashboad from './Dashboard'
import { HashRouter as Router, Route } from "react-router-dom";
import UserInformation from "../components/user/UserInformation"
import { Menu as MenuAction } from '../store/global/actions'

import './CustomerService.css'
import { connect } from 'react-redux';

import RequireRolesOr from '../components/require_roles_or'
class CustomerService extends React.Component {

    componentDidMount() {
        this.props.setActiveKey("Operation")
    }

    render() {
        return <Router>
            <div className="page page-dashboard">
                <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::finance"]}>
                    <div className="buying-list">
                        <BuyingListLive {...this.props} history={this.props.history} />
                    </div>
                </RequireRolesOr>
                <div className="search-user">
                    <Dashboad {...this.props} history={this.props.history} />
                    <div style={{ height: 10 }}></div>
                    <Router>
                        <Route path="/operation/:id" render={routeProps => <UserInformation {...routeProps} />}></Route>
                    </Router>
                </div>
            </div>
        </Router>
    }
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        activeKey: global.navActiveKey
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CustomerService)


