import { useEffect, useState } from "react"
import { Menu as MenuAction } from '../../store/global/actions'
import { connect } from "react-redux"
import { Quill } from "react-quill"
import { List, Button, Divider, Tooltip, Tag, Pagination, Segmented, Table } from "antd"
import UserAvatar from "../../components/user/UserAvatar";
import Config from "../../config"
import dayjs from "dayjs"
import useLocalStorageState from "use-local-storage-state"
import QueryForm from "./QueryForm"
import "./index.css"
import { CommentOutlined, EyeOutlined, LikeOutlined, ClockCircleOutlined, CompassOutlined, CheckOutlined, BlockOutlined, StarOutlined } from "@ant-design/icons"

import { Link } from "react-router-dom/cjs/react-router-dom"

function Post({ socket, ...props }) {
    let [channel, setChannel] = useState(null)
    let [data, setData] = useState({})
    let [query, setQuery] = useLocalStorageState("/community/post/query", { status: "publish", reviewed: "false", recommend: null })
    const [loading, setLoading] = useState(true)
    const [displayMode, setDisplayMode] = useLocalStorageState("/community/post/display_mode", "卡片")

    const type = props.match?.params.type
    useEffect(() => {
        if (!!query.reviewed && query.reviewed === "true")
            query = { ...query, reviewed: true }
        else
            query = { ...query, reviewed: false }

        let _channel = socket.channel("community:post", { ...query, type })
        _channel.on("data", ({ data }) => {
            setLoading(false)
            setData(data)
        })
        _channel.join()
        setChannel(_channel)
        return () => {
            _channel.leave()
        }
    }, [type])

    const handlePageTo = (page, size) => {
        setLoading(true)
        channel.push("page-to", { page, size })
    }

    const handleSearch = (values) => {
        setQuery(values)
        if (!!values.reviewed && values.reviewed === "true")
            values = { ...values, reviewed: true }
        else
            values = { ...values, reviewed: false }
        channel.push("query", values)
    }

    // 审查完成
    const handleReview = (id) => {
        channel.push("review_success", { id })
    }

    // 查看详情
    const viewDetailURL = ({ id, parent_id }) => {
        switch (type) {
            case "answer":
                return `/community/simulator/question/${parent_id}/answer/${id}`
            case "question":
                return `/community/simulator/question/${id}`

            default:
                break;
        }
    }

    // 配置加精
    const setRecommend = (id, recommend) => {
        channel.push("update-recommend", { id, recommend })
    }

    // 数据展示
    const dataRender = () => {

        switch (displayMode) {
            case "卡片":
                return <>
                    <div style={{ paddingRight: 20, paddingBottom: 20, marginTop: 20, overflowY: "auto", overflowX: "hidden" }}>
                        <List loading={loading} dataSource={data?.entries} pagination={false} itemLayout="vertical" key="versions-table" rowKey={item => item.id} size="middle"
                            grid={{ gutter: 16, column: 3 }}
                            renderItem={(item) => {
                                const tempCont = document.createElement("div");
                                (new Quill(tempCont)).setContents(item?.content?.delta);
                                const contentHtml = tempCont.getElementsByClassName("ql-editor")[0].innerHTML.replaceAll(/src="(?!(http\:\/\/|https\:\/\/))(.+)"/g, `src="${Config.AttachUrl("")}/$2"`);

                                return <List.Item className="post-item" style={{ borderBottom: "1px solid #eee", paddingBottom: 10 }} key={item.id} actions={[
                                    <Button type="link" onClick={() => handleReview(item.id)}><CheckOutlined /> 没问题</Button>,
                                    <Button type="link" disabled>处理 </Button>,
                                    <Link to={viewDetailURL(item)} target="_blank"><BlockOutlined /> 查看</Link>
                                ]}>
                                    <List.Item.Meta avatar={<UserAvatar nickname={item.owner_name} size={40} avatar={item.owner_avatar} id={item.owner_id} />}
                                        title={<div className="title"> <span>{item.title}</span></div>}
                                        description={<div>
                                            <div>
                                                <span> <CompassOutlined /> [{item.ip_belongs_text || "未知"}]</span>
                                                <Divider type="vertical" />
                                                <span><ClockCircleOutlined /> {dayjs(item.inserted_at).format("YYYY-MM-DD HH:mm")}</span>
                                                <Divider type="vertical" />
                                                <Tooltip title="阅读数"><span><EyeOutlined /> {item.view_count}</span></Tooltip>
                                                <Divider type="vertical" />
                                                <Tooltip title="赞同数"><span><LikeOutlined /> {item.like_count}</span></Tooltip>
                                                <Divider type="vertical" />
                                                <Tooltip title="评论数"><span><CommentOutlined /> {item.comment_count}</span></Tooltip>
                                                {/* <span>状态：{statusText(item.status)}</span> */}
                                            </div>
                                            <div style={{ height: 20 }}>
                                                {item.topics?.map(topic => <Tag key={topic}>{topic}</Tag>)}
                                            </div>
                                        </div>} />
                                    <div className="content-wrap">
                                        <div className="content" dangerouslySetInnerHTML={{ __html: contentHtml }}></div>
                                    </div>
                                </List.Item>
                            }} />

                    </div>
                    <Pagination onChange={(page, size) => { handlePageTo(page, size) }} total={data?.total_entries} current={data?.page_number} pageSize={data?.page_size || 1} showQuickJumpe />
                </>
            case "列表":
                return <div style={{ paddingRight: 20, paddingBottom: 20, marginTop: 20, overflowY: "auto", overflowX: "hidden" }}>
                    <Table dataSource={data?.entries} size="small" loading={loading} rowKey={item => item.id} pagination={{
                        onChange: (page, size) => { handlePageTo(page, size) },
                        total: data?.total_entries,
                        current: data?.page_number,
                        pageSize: data?.page_size || 1
                    }}
                        scroll={{ y: document.documentElement.clientHeight - 350 }}>
                        <Table.Column title="发帖时间" width={180} dataIndex="inserted_at" key="inserted_at" render={text => dayjs(text).format("YYYY-MM-DD HH:mm")}></Table.Column>
                        <Table.Column title="发帖人" width={80} dataIndex="owner_name" key="owner_name" render={(_, item) => <UserAvatar nickname={item.owner_name} avatar={item.owner_avatar} id={item.owner_id} />}></Table.Column>
                        <Table.Column title="标题" dataIndex="title" key="title"></Table.Column>
                        <Table.Column title="阅读数" width={80} dataIndex="view_count" key="view_count"></Table.Column>
                        <Table.Column title="赞同数" width={80} dataIndex="like_count" key="like_count"></Table.Column>
                        <Table.Column title="评论数" width={80} dataIndex="comment_count" key="comment_count"></Table.Column>
                        <Table.Column title="所属地" width={80} dataIndex="ip_belongs_text" key="ip_belongs_text" render={text => text || "未知"}></Table.Column>
                        <Table.Column title="主题" width={80} dataIndex="topics" key="topics" render={topics => topics?.map(topic => <Tag key={topic}>{topic}</Tag>)}></Table.Column>
                        <Table.Column title="操作" render={(_, item) => <>
                            {item.recommend ? <Button type="link" onClick={() => setRecommend(item.id, false)} style={{ color: "red" }}><StarOutlined /> 减精</Button> : <Button type="link" onClick={() => setRecommend(item.id, true)} style={{ color: "orange" }}><StarOutlined /> 加精</Button>}
                            <Divider type="vertical" />
                            <Button type="link" onClick={() => handleReview(item.id)}><CheckOutlined /> 没问题</Button>
                            <Divider type="vertical" />
                            <Button type="link" disabled>处理 </Button>
                            <Divider type="vertical" />
                            <Link to={viewDetailURL(item)} target="_blank"><BlockOutlined /> 查看</Link>
                        </>} />
                    </Table>
                </div>

            default:
                break;
        }


    }

    // 现实模式的改变
    const displayModeChange = (value) => {
        setDisplayMode(value)
    }

    return <div className="page operation post">
        <h1>发帖</h1>

        <div style={{ display: "flex", justifyContent: "space-between", paddingRight: 20 }}>
            <QueryForm values={query} onSearch={handleSearch} />
            <div>
                <Segmented options={["卡片", "列表"]} value={displayMode} onChange={(value) => displayModeChange(value)} />
            </div>
        </div>

        {dataRender()}

    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Post)