import React, { useEffect } from "react";
import { Divider, Image, Input, InputNumber, Tabs, TreeSelect, Form, Button, Tag, Select } from 'antd';

import { UserOutlined } from '@ant-design/icons';
import configFunc from "../../../config"

import dayjs from "dayjs";
import { useState } from "react";

export function BookDetail({ internationalCode, cateTree, user, channel }) {
    const [book, setBook] = useState(null)
    const [form] = Form.useForm()

    useEffect(() => {
        channel.push("detail", { international_code: internationalCode })
            .receive("ok", ({ data }) => {
                setBook(data)
            })
    }, [])

    const handleSubmit = (values) => {
        channel.push("save", { international_code: internationalCode, ...values })
            .receive("ok", () => {
                channel.push("detail", { international_code: internationalCode })
                    .receive("ok", ({ data }) => {
                        setBook(data)
                    })
            })
    }

    const treeLeaf = (chapters, level) => {
        console.log(level)
        return chapters?.map(chapter => {
            return <div >
                <a target="_blank" style={{ borderBottom: "1px solid #efefef", display: "block", padding: 5, textIndent: 20 * level }} href={`${configFunc.HttpEndpoint(chapter.url)}?token=${user.token}`}>{chapter.title}{!chapter.enable ? <span style={{ color: "#afafaf" }}>(会员)</span> : ""}</a>
                {treeLeaf(chapter.children, level + 1)}
            </div>
        })
    }

    return <div style={{ display: "flex", gap: 30, lineHeight: "2em" }}>
        <div style={{ flex: 2 }}>
            <div style={{ display: "flex", justifyContent: "center" }}><Image src={book?.cover_url} width={200}></Image></div>
            <h3>{book?.title}</h3>
            <div></div>
            <div><UserOutlined /> {book?.author} <Divider type="vertical"></Divider>{book?.publisher}<Divider type="vertical"></Divider>ISBN: {book?.international_code}</div>
            <div>价格：¥ {(book?.price / 100).toFixed(2)} <Divider type="vertical"></Divider>字数：{book?.words_count}<Divider type="vertical"></Divider>发行日期: {dayjs(book?.issue_date).format("YYYY-MM-DD")}</div>
            <div>分类：{book?.categories.map(cate => <Tag>{cate}</Tag>)}</div>
            <Divider />

            {book && <Form form={form} size="small" name="basic" initialValues={book} onFinish={handleSubmit} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} autoComplete="off">
                <Form.Item label="书名" name="title" rules={[{ required: true, message: '不能为空' }]}>
                    <Input size="small" />
                </Form.Item>
                <Form.Item label="状态" name="status" rules={[{ required: true, message: '不能为空' }]}>
                    <Select>
                        <Select.Option value="on_shelf">上架</Select.Option>
                        <Select.Option value="off_shelf">下架</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="价格" name="price" rules={[{ required: true, message: '不能为空' }]}>
                    <InputNumber style={{ width: 150 }} addonBefore="¥" addonAfter="分" size="small" min={0} />
                </Form.Item>
                {book?.categories && <Form.Item label="分类" name="categories" rules={[{ required: true, message: '不能为空' }]}>
                    <TreeSelect placeholder="点击筛选分类" defaultValue={book?.categories} size="small" listHeight="60vh" treeCheckable treeCheckStrictly treeDefaultExpandAll showCheckedStrategy={TreeSelect.SHOW_ALL} style={{ minWidth: 300, maxWidth: 600 }} treeData={cateTree?.map((item) => ({
                        title: item.name,
                        value: item.name,
                        key: item.key,
                        children: item.children?.map((item) => ({
                            title: item.name,
                            value: item.name,
                            key: item.key,
                        }))
                    }))}>
                    </TreeSelect>
                </Form.Item>}
                <Form.Item label="简介" name="abstract" rules={[{ required: true, message: '不能为空' }]}>
                    <Input.TextArea autoSize size="small" />
                </Form.Item>

                <Form.Item wrapperCol={{ xs: { span: 16, offset: 4 } }}>
                    <Button type="primary" htmlType="submit" block>保存</Button>
                </Form.Item>

            </Form>}
            {/* <div style={{ textIndent: "2em", lineHeight: "2em" }}>{book?.abstract}</div> */}

        </div>
        <div style={{ flex: 1 }}>
            <Tabs defaultActiveKey="epub">
                {
                    book?.resources?.map(item => {
                        return <Tabs.TabPane tab={item.file_content_type} key={item.file_content_type}>
                            {treeLeaf(item.chapters, 0)}
                        </Tabs.TabPane>
                    })
                }
            </Tabs>

        </div>
    </div>
}
