import { Input } from "antd"
import { Radio } from "antd";
import { Form } from "antd"
import { useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill"
import Sha256Encode from "../../components/sha256";

const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
    ],
    imageUploader: {
        upload: (file) => {
            return new Promise(async (resolve, reject) => {
                const file_content = await file.arrayBuffer()
                const file_name = await Sha256Encode(file_content)
                
                uploadFile(window.store.getState().global.jsonRequest, `community/guideline/${file_name}`, "acupoint-attach", file, (path) => {
                    resolve(config.AttachUrl(path));
                }, () => message.error("上传失败"))
            });
        },
    }
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
]

function GuidelinesForm({ formRef, quillRef, commitReason, content, status, ...props }) {
    const [value, setValue] = useState(content || '');
    return <div>
        <Form ref={formRef} initialValues={{ "commit_reason": commitReason, status: status || "draft" }} labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}>
            <Form.Item label="更新原因" name="commit_reason" rules={[{ required: true, message: '不能为空，只后台可见，方便管理' }]} >
                <Input.TextArea placeholder="为什么要发布新版本，更新了什么东西" autoSize />
            </Form.Item>
            <Form.Item label="内容" rules={[{ required: true, message: '不能为空' }]} >
                <ReactQuill ref={quillRef} placeholder="公约的内容" theme="snow" style={{ height: 400, marginBottom: 60 }}
                    className="editor" value={value} modules={modules} onChange={setValue} formats={formats} />
            </Form.Item>
            <Form.Item label="存为草稿" name="status" rules={[{ required: true, message: '不能为空' }]} >
                <Radio.Group buttonStyle="solid">
                    <Radio.Button value="draft">草稿</Radio.Button>
                    <Radio.Button value="publish">发布</Radio.Button>
                </Radio.Group>
            </Form.Item>
        </Form>
    </div>
}

export default GuidelinesForm