import React from "react";
import TimeAgo from "react-timeago"
import frenchStrings from './local_time_ago_zh'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
const formatter = buildFormatter(frenchStrings)

// <LocalTimeAgo date={data.root.inserted_at} />
class LocalTimeAgo extends React.Component {
    render() {
        return <TimeAgo formatter={formatter} maxPeriod={60} {...this.props} date={this.props.date} />;
    }
}

export default LocalTimeAgo;