import React, { useEffect, useState, version } from "react"
import { Table } from "antd"
import { connect } from "react-redux"
import { Switch, Radio, Button, Form, InputNumber, Modal, Input, DatePicker, ConfigProvider, message, Select } from "antd"
import { CloudDownloadOutlined, PlusSquareTwoTone, DeleteOutlined, RocketOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { Menu as MenuAction } from '../store/global/actions'
import PasswordConfirm from "../components/passwordConfirm"
import zhCN from 'antd/lib/locale/zh_CN';
import dayjs from "dayjs"
import { Divider } from "antd"
import { Popover } from "antd"
import "./Version.css"
import { QRCode } from "antd"

const { Column } = Table

function Version(props) {
    const [channel, setChannel] = useState(null)
    let [data, setData] = useState({})
    let [channels, setChannels] = useState([])
    let [newVersionForm] = Form.useForm()
    let [newVersionChannelForm] = Form.useForm()

    props?.setActiveKey("APP-VERSION")

    useEffect(() => {
        let _channel = props.socket.channel("ops:release:version")
        _channel.join()
        setChannel(_channel)

        _channel.on("data", ({ data }) => { setData(data) })
        _channel.on("channel-data", ({ data }) => { setChannels(data) })

        return () => {
            if (!!_channel) _channel.leave()
        }
    }, [])

    const handlePageTo = (page, size) => {
        channel.push("page-to", { page, size })
    }

    // 设置状态
    const handleSetStatus = (code, value) => {
        channel.push("change-status", { version_code: code, value: value })
    }

    // 设置为强制更新
    const handleSetDeprecated = (code, value) => {
        channel.push("change-deprecated", { version_code: code, value: value })
    }

    // 设置为强制更新
    const handleSetManualMembershipAgreement = (code, value) => {
        channel.push("change-manual_membership_agreement", { version_code: code, value: value })
    }

    // 设置状态
    const handleSetChannelStatus = (version_code, channel_key, value) => {
        channel.push("change-channel-status", { version_code, channel_key, value })
    }

    // 设置为强制更新
    const handleSetChannelDeprecated = (version_code, channel_key, value) => {
        channel.push("change-channel-deprecated", { version_code, channel_key, value })
    }
    // 设置手动接受会员协议
    const handleSetChhannelManualMembershipAgreement = (version_code, channel_key, value) => {
        channel.push("change-channel-manual_membership_agreement", { version_code, channel_key, value })
    }


    // 添加版本
    const handleNewVersionAdd = () => {
        let minVersionCode = data?.entries?.[0]?.code || 1;
        Modal.confirm({
            width: 800,
            title: '新增版本',
            content: <ConfigProvider locale={zhCN}>
                <Form form={newVersionForm} name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} autoComplete="off">
                    <Form.Item label="版本名" name="name" rules={[{ required: true, message: '必填' }]}>
                        <Input placeholder="如: 1.0.0" prefix="v" />
                    </Form.Item>
                    <Form.Item label="版本号" name="code" initialValue={minVersionCode + 1} rules={[{ required: true, message: '必填' }]}>
                        <InputNumber min={minVersionCode + 1} step={1} placeholder={`最小${minVersionCode + 1}`} />
                    </Form.Item>
                    <Form.Item label="发行时间" name="release_at" initialValue={dayjs()} rules={[{ required: true, message: '必填' }]} >
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>
                    <Form.Item label="下载地址" name="download_url" rules={[{ required: true, message: '必填' }]} help="直接下载的地址">
                        <Input prefix="https://" />
                    </Form.Item>
                    <Form.Item label="状态" name="status" initialValue="pending" rules={[{ required: true, message: '必填' }]} >
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="pending">审核中</Radio.Button>
                            <Radio.Button value="released">已上线</Radio.Button>
                            <Radio.Button value="unavailable">下架</Radio.Button>
                            <Radio.Button value="pending_cheating">审核模式</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="版本描述" name="description" rules={[{ required: true, message: '必填' }]} >
                        <Input.TextArea autoSize />
                    </Form.Item>
                </Form>
            </ConfigProvider>,
            onCancel: () => {
                newVersionForm.resetFields();
            },
            onOk: (close) => {
                const form = newVersionForm
                form
                    .validateFields()
                    .then(values => {
                        values = { ...values, name: `v${values.name}`, download_url: `https://${values.download_url}` }

                        channel.push("create", { ...values })
                            .receive("ok", (resp) => {
                                close()
                                message.success("新增成功")
                                form.resetFields();
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }


    const dropPasswordConfirmRef = React.createRef()
    const handleDrop = (code) => {
        PasswordConfirm({
            ref: dropPasswordConfirmRef,
            title: '密码确认',
            message: "删除版本会被记录在操作日志里，请谨慎操作。",
            onOk: ({ password }, close) => {
                channel.push("drop", { code, password }, 100000)
                    .receive("ok", () => {
                        message.success({ content: '已删除版本', duration: 3 })
                        close()
                    })
            }
        })
    }

    // 添加版本
    const handleNewVersionChannelAdd = (version_code) => {
        Modal.confirm({
            width: 600,
            title: '新增版本',
            content: <ConfigProvider locale={zhCN}>
                <Form form={newVersionChannelForm} name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} autoComplete="off">
                    <Form.Item label="渠道" name="channel_key" rules={[{ required: true, message: '必填' }]}>
                        <Select showSearch optionLabelProp="label" options={channels.map(item => {
                            return { label: item.name, key: item.key, value: JSON.stringify({ channel: item.key, platform: item.platform }) }
                        })}></Select>
                    </Form.Item>
                    <Form.Item label="发行时间" name="release_at" initialValue={dayjs()} rules={[{ required: true, message: '必填' }]} >
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>
                    <Form.Item label="下载地址" name="download_url" rules={[{ required: true, message: '必填' }]} help="直接下载的地址">
                        <Input prefix="https://" />
                    </Form.Item>
                    <Form.Item label="状态" name="status" initialValue="pending" rules={[{ required: true, message: '必填' }]} >
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="pending">审核中</Radio.Button>
                            <Radio.Button value="released">已上线</Radio.Button>
                            <Radio.Button value="unavailable">下架</Radio.Button>
                            <Radio.Button value="pending_cheating">审核模式</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </ConfigProvider>,
            onCancel: () => {
                newVersionChannelForm.resetFields();
            },
            onOk: (close) => {
                const form = newVersionChannelForm
                form
                    .validateFields()
                    .then(values => {
                        let channel_and_platform = JSON.parse(values.channel_key)
                        values = { ...values, download_url: `https://${values.download_url}`, channel_key: channel_and_platform.channel, platform: channel_and_platform.platform, version_code }

                        channel.push("create-channel", { ...values })
                            .receive("ok", (resp) => {
                                close()
                                message.success("新增成功")
                                form.resetFields();
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }


    const dropChannelPasswordConfirmRef = React.createRef()
    const handleDropChannel = (version_code, channel_key) => {
        PasswordConfirm({
            ref: dropChannelPasswordConfirmRef,
            title: '密码确认',
            message: "删除版本会被记录在操作日志里，请谨慎操作。",
            onOk: ({ password }, close) => {
                channel.push("drop-channel", { version_code, channel_key, password }, 100000)
                    .receive("ok", () => {
                        message.success({ content: '已删除版本', duration: 3 })
                        close()
                    })
            }
        })
    }

    // 测试版本更新
    const handleVersionUpdateVersionSubmit = (version_code, channel_key) => {
        channel.push("test-version-update", { version_code, channel: channel_key })
            .receive("ok", (latestVersion) => {
                Modal.success({
                    title: `新版本${latestVersion.version_name}诚邀体验`,
                    content: <div>
                        <p>
                            介绍：<pre>{latestVersion.description}</pre>
                        </p>
                        <p>
                            强制更新： {latestVersion.force_update ? <CheckCircleOutlined style={{ color: "green" }} /> : <CloseCircleOutlined style={{ color: "red" }} />}
                        </p>
                        <p>
                            发行时间: {dayjs(latestVersion.release_at).add(8, "hour").format("YYYY-MM-DD")}
                        </p>
                        <p>
                            数据: <pre><code>{JSON.stringify(latestVersion, null, 2)}</code></pre>
                        </p>

                    </div>
                })
            })
    }

    return <div className="page operation version">
        <h1>APP发行版本管理</h1>

        <div className="actions" style={{ display: "flex" }}>
            <Button icon={<PlusSquareTwoTone />} type="link" onClick={handleNewVersionAdd}>
                新增版本
            </Button>
        </div>
        <br />
        <div style={{ minWidth: 1500 }}>
            {!!data.entries &&
                <Table dataSource={data.entries} key="versions-table" rowKey={item => item.code}

                    scroll={{ y: document.documentElement.clientHeight - 350 }}
                    size="middle"
                    defaultExpandAllRows
                    rowClassName="version-row"
                    expandable={{
                        rowExpandable: (item) => (item?.channels || []).length > 0,
                        defaultExpandAllRows: true,
                        expandedRowRender: (item) => {
                            return <div style={{ padding: "20px 0 20px 32px" }} key={item.channel_key}>
                                <Table dataSource={item.channels} pagination={false} bordered rowKey={item => item.channel_key} size="middle">
                                    <Column title="渠道" dataIndex="channel_key" key="channel_key" width={100} align="center" />
                                    <Column title="平台类型" dataIndex="platform" key="platform" width={100} align="center" />
                                    <Column title="发行日期" dataIndex="release_at" key="release_at" width={150} render={(text) => dayjs(`${text}`).add(8, "hour").format("YYYY-MM-DD")} />
                                    <Column title="下载地址" dataIndex="download_url" key="download_url" align="center" width={100} render={(value) => <Popover content={<QRCode value={value}></QRCode>}><a href={value} target="_blank"></a><a href={value} target="_blank"><CloudDownloadOutlined /></a></Popover>} />
                                    <Column title="状态" dataIndex="status" key="status" width={300} render={(value, item) => <Radio.Group onChange={(e) => handleSetChannelStatus(item.version_code, item.channel_key, e.target.value)} size="small" value={value} buttonStyle="solid">
                                        <Radio.Button value="pending">审核中</Radio.Button>
                                        <Radio.Button value="released">已上线</Radio.Button>
                                        <Radio.Button value="unavailable">下架</Radio.Button>
                                        <Radio.Button value="pending_cheating">审核模式</Radio.Button>
                                    </Radio.Group>} />
                                    <Column title="强制升级到最新版" width={150} dataIndex="deprecated" key="deprecated" render={(value, item) => <Switch onChange={(v) => handleSetChannelDeprecated(item.version_code, item.channel_key, v)} checked={value} unCheckedChildren="否" checkedChildren="是" size="small" ></Switch>} />
                                    <Column title="手动接受会员协议" dataIndex="manual_membership_agreement" key="manual_membership_agreement" render={(value, item) => <Switch onChange={(v) => handleSetChhannelManualMembershipAgreement(item.version_code, item.channel_key, v)} checked={value} unCheckedChildren="否" checkedChildren="是" size="small" ></Switch>} />

                                    <Column title="操作" width={330} render={(text, item) => <>

                                        <Button size="small" type="link" danger icon={<DeleteOutlined />} onClick={() => handleDropChannel(item.version_code, item.channel_key)}>删除渠道</Button>
                                    </>} />
                                </Table>
                            </div>
                        }
                    }}
                    pagination={{
                        onChange: (page, size) => { handlePageTo(page, size) },
                        total: data.total_entries,
                        current: data.page_number,
                        pageSize: data.page_size || 0,
                        showQuickJumper: true
                    }}>
                    <Column title="版本名" dataIndex="name" key="name" width={80} align="center" />
                    <Column title="版本号" dataIndex="code" key="code" width={100} align="center" />
                    <Column title="发行日期" dataIndex="release_at" key="release_at" width={150} render={(text) => dayjs(`${text}`).add(8, "hour").format("YYYY-MM-DD")} />
                    <Column title="下载地址" dataIndex="download_url" key="download_url" align="center" width={100} render={(value) => <Popover content={<QRCode value={value}></QRCode>}><a href={value} target="_blank"><CloudDownloadOutlined /></a></Popover>} />
                    <Column title="状态" dataIndex="status" key="status" width={300} render={(value, item) => <Radio.Group onChange={(e) => handleSetStatus(item.code, e.target.value)} size="small" value={value} buttonStyle="solid">
                        <Radio.Button value="pending">审核中</Radio.Button>
                        <Radio.Button value="released">已上线</Radio.Button>
                        <Radio.Button value="unavailable">下架</Radio.Button>
                        <Radio.Button value="pending_cheating">审核模式</Radio.Button>
                    </Radio.Group>} />
                    <Column title="强制升级到最新版" align="center" width={150} dataIndex="deprecated" key="deprecated" render={(value, item) => <Switch onChange={(v) => handleSetDeprecated(item.code, v)} checked={value} checkedChildren="是" unCheckedChildren="否" size="small" ></Switch>} />
                    <Column title="手动接受会员协议" width={150} dataIndex="manual_membership_agreement" key="manual_membership_agreement" render={(value, item) => <Switch onChange={(v) => handleSetManualMembershipAgreement(item.code, v)} checked={value} unCheckedChildren="否" checkedChildren="是" size="small" ></Switch>} />

                    <Column title="版本描述" dataIndex="description" key="description" render={text => <pre style={{ whiteSpace: "break-spaces", maxHeight: 150, fontSize: 11 }}>{text}</pre>} />
                    <Column title="操作" width={350} render={(text, item) => <>
                        <Button type="link" size="small" onClick={() => handleNewVersionChannelAdd(item.code)}>新增渠道</Button>
                        <Popover trigger="click" content={<div>
                            <Select style={{ minWidth: 200 }} onChange={(channel) => handleVersionUpdateVersionSubmit(item.code, channel)} showSearch optionLabelProp="label" options={channels.map(item => {
                                return { label: item.name, key: item.key, value: item.key }
                            })}></Select>
                        </div>}>
                            <Button type="link" size="small" icon={<RocketOutlined />}>模拟更新</Button>
                        </Popover>
                        <Button type="link" size="small" danger icon={<DeleteOutlined />} onClick={() => handleDrop(item.code)}>删除版本</Button>
                    </>} />
                </Table>
            }
        </div>
    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Version)