import { Select, Form } from "antd";
import { Input } from "antd";
import { Modal } from "antd";

function Report({ onSubmit, initial = {}, ...props }) {
    let formInstance = null
    Modal.confirm({
        width: 600,
        title: '举报',
        content: <Form ref={(instance) => formInstance = instance} name="basic" initialValues={initial} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
            <Form.Item label="举报类型" name="type" rules={[{ required: true, message: '不能为空' }]}>
                <Select>
                    <Select.Option value="违法违规">违法违规</Select.Option>
                    <Select.Option value="垃圾广告">垃圾广告</Select.Option>
                    <Select.Option value="虚假不实">虚假不实</Select.Option>
                    <Select.Option value="侵权投诉">侵权投诉</Select.Option>
                    <Select.Option value="引人不适">引人不适</Select.Option>
                    <Select.Option value="谩骂攻击">谩骂攻击</Select.Option>
                    <Select.Option value="扰乱社区秩序">扰乱社区秩序</Select.Option>
                    <Select.Option value="其他">其他</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item label="举报描述" name="content" rules={[{ required: true, message: '不能为空' }]}>
                <Input.TextArea autoFocus style={{ minHeight: 200 }} />
            </Form.Item>
        </Form>,
        onOk: (close) => {
            formInstance
                .validateFields()
                .then((v) => {
                    if (onSubmit(v)) {
                        close()
                    }
                })
                .catch(info => {
                    console.log('Validate Failed:', info);
                });
            return false
        }
    })
}

export default Report;