import React from 'react'
import { Statistic, Col, Row, Card, Tag } from 'antd'
import { connect } from 'react-redux'

class Dashboard extends React.Component {

    state = {
        channel: null,
        data: { today: {}, yesterday: {}, week: {}, month: {} }
    }

    componentDidMount() {
        const channel = this.props.socket.channel("ops:sqec:dashboard")
        channel.on("data", (data) => this.setState({ data }))
        channel.join()
        this.setState({ channel })
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    block(property) {
        const data = this.state.data

        return <Row gutter={16}>
            <Col span={4}>
                <Statistic title="活跃会员数" value={data[property].active_user_num} />
            </Col>
            <Col span={4}>
                <Statistic title="创建课程数" value={data[property].course_num} />
            </Col>
            <Col span={4}>
                <Statistic title="完成学时数" value={data[property].lesson_num} />
            </Col>
            <Col span={4}>
                <Statistic title="错误率" precision={2} value={data[property].wrong_percent} />
            </Col>
            <Col span={4}>
                <Statistic title="精通率" precision={2} value={data[property].master_percent} />
            </Col>
            <Col span={4}>
                <Statistic title="提示率" precision={2} value={data[property].hint_percent} />
            </Col>
        </Row>
    }

    render() {
        return <>
            <h1>仪表盘 Dashboard <Tag color="blue">实时</Tag></h1>
            <Card>
                <h2>今日</h2>
                {this.block("today")}
            </Card>
            <br></br>
            <Card>
                <h2>昨日</h2>
                {this.block("yesterday")}
            </Card>
            <br></br>
            <Card>
                <h2>最近一周</h2>
                {this.block("week")}
            </Card>
            <br></br>
            <Card>
                <h2>最近一月</h2>
                {this.block("month")}
            </Card>
        </>
    }
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)