


import React from "react"
import { Menu as MenuAction } from '../store/global/actions'
import { Modal, message, Button, Divider, Input, Form, Upload, Select, InputNumber, Switch, Slider, DatePicker } from "antd"
import { PlusCircleTwoTone, FormOutlined, DeleteOutlined, CalendarTwoTone, MobileTwoTone, HourglassTwoTone } from '@ant-design/icons';
import { SketchPicker } from 'react-color';
import dayjs from "dayjs";

import { connect } from "react-redux"
import "./LaunchScreen.css"
import logo from "../images/logo.png"
import locale from 'antd/es/date-picker/locale/zh_CN';
import Sha256Encode from "../components/sha256";

const { RangePicker } = DatePicker

class LaunchScreen extends React.Component {

    state = {
        channel: null,
        data: [],
        availabelChannels: [],
        loading: true,
        screen: {
            width: 360,
            height: 780,
            model: "360780"
        },
        colorPickerShow: {

        },
        zoomRatio: 0.8
    }
    addChannelFormRef = React.createRef();
    screenFormRef = React.createRef();

    componentDidMount() {
        this.props.setActiveKey("LAUNCH-SCREEN")

        let channel = this.props.socket.channel("operation:launch-screen")
        channel.on("data", ({ data }) => {
            this.setState({ data: data, loading: false })
        })
        channel.on("channel-data", ({ data }) => {
            this.setState({ availabelChannels: data })
        })
        channel.join()
        this.setState({ channel })
    }

    componentWillUnmount() {
        if (this.state.channel)
            this.state.channel.leave()
    }

    screenGroupForm(screen) {
        return <Form ref={this.screenFormRef} name="basic" labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} autoComplete="off">
            <Form.Item label="开屏名" name="name" initialValue={screen?.nickname} rules={[{ required: true, message: '必填' }]}>
                <Input placeholder="标题，运维可见" />
            </Form.Item>
            <Form.Item label="最小版本" name="min_version_code" initialValue={screen?.min_version_code} rules={[{ required: true, message: '必填' }]}>
                <InputNumber placeholder="找刘峰填" />
            </Form.Item>
            <Form.Item label="最大版本" name="max_version_code" initialValue={screen?.max_version_code} rules={[{ required: true, message: '必填' }]}>
                <InputNumber placeholder="找刘峰填, 可以尽可能的大" />
            </Form.Item>
            <Form.Item label="有效时间" name="begin_and_end" initialValue={[dayjs(screen?.begin_at), dayjs(screen?.end_at)]} rules={[{ required: true, message: '必填' }]}>
                <RangePicker format="YYYY-MM-DD HH:mm:ss" locale={locale} />
            </Form.Item>

            <Form.Item label="可跳过延迟" name={["data", "remain"]} initialValue={screen?.data?.remain || 5} rules={[{ required: true, message: '必填' }]} >
                <InputNumber min={1} addonAfter="秒" />
            </Form.Item>

            <Form.Item label="最大展示次数" name="max_display_times" initialValue={screen?.max_display_times || 5} rules={[{ required: true, message: '必填' }]} >
                <InputNumber min={1} addonAfter="次" />
            </Form.Item>
            <Form.Item label="APP打开路径" name={["data", "path"]} initialValue={screen?.data?.path}>
                <Input placeholder="找刘峰填" />
            </Form.Item>
            <Form.Item label="APP路由参数" name={["data", "path_arguments"]} initialValue={screen?.data?.path_arguments && JSON.stringify(screen?.data?.path_arguments) || "{}"}>
                <Input placeholder="找刘峰填" />
            </Form.Item>
        </Form>
    }

    screenChannelForm(initial) {
        return <Form ref={this.addChannelFormRef} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} autoComplete="off">
            <Form.Item label="渠道" name="channel_code" initialValue={initial?.channel_code} rules={[{ required: true, message: '必填' }]}>
                <Select disabled={!!initial}>
                    {this.state.availabelChannels.map(channel => <Select.Option key={channel.key} value={channel.key}>
                        {channel.name}
                    </Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item label="可跳过延迟" name={["data", "remain"]} initialValue={initial?.data?.remain} rules={[{ required: true, message: '必填' }]} >
                <InputNumber min={1} addonAfter="秒" />
            </Form.Item>
            <Form.Item label="APP打开路径" name={["data", "path"]} initialValue={initial?.data?.path}>
                <Input placeholder="找刘峰填" />
            </Form.Item>
            <Form.Item label="APP路由参数" name={["data", "path_arguments"]} initialValue={initial?.data?.path_arguments && JSON.stringify(initial?.data?.path_arguments)}>
                <Input placeholder="找刘峰填" />
            </Form.Item>
        </Form>
    }

    handleChangeScreen(model, width, height) {
        this.setState({ screen: { width, height, model } })
    }


    handleUploadFile = (obj, launch_screen_id, channel_code) => {
        const file = obj.file

        const allow_file = ['image/png', 'image/jpeg'].indexOf(file.type) > -1;
        if (!allow_file) {
            return message.error("只能上传jpg或者png文件")
        }

        message.loading({ content: "上传中，请稍后...", key: "handleUploadFile", duration: 0 })
        file.arrayBuffer().then(file_content => {
            Sha256Encode(file_content).then(file_name => {
                var data = new FormData()
                data.append('file', file)
                data.append('filename', file_name)
                data.append('owner_type', "LaunchScreen")
                data.append('owner_id', `${launch_screen_id}_${channel_code || 0}`)

                this.props.jsonRequest.file(`/api/operation/media/upload`, data)
                    .then(resp => resp.json())
                    .then(({ data }) => {
                        const { redirect_url } = data
                        this.state.channel.push("change-cover", { launch_screen_id, channel_code, path: redirect_url }).receive("ok", () => {
                            message.success({ content: "上传成功", key: "handleUploadFile", duration: 2 })
                        })
                    })
            })
        })
    }

    handleBackgroundColorChange(launch_screen_id, channel_code, color) {
        this.state.channel.push("change-background-color", { channel_code, launch_screen_id, color: color.hex.replace("#", "") + "ff" })
            .receive("ok", () => {
                this.setState({ colorPickerShow: { ...this.state.colorPickerShow, [`launch-${launch_screen_id}-${channel_code}`]: false } })
            })
    }

    handleEnableChange(launch_screen_id, channel_code, enable) {
        this.state.channel.push("change-enable", { channel_code, launch_screen_id, enable })
            .receive("ok", () => {
                this.setState({ colorPickerShow: { ...this.state.colorPickerShow, [`launch-${launch_screen_id}-${channel_code}`]: false } })
            })
    }

    // 编辑渠道
    handleClickEditChannel(channel) {
        Modal.confirm({
            title: '修改渠道',
            content: <>
                {this.screenChannelForm(channel)}
            </>,
            onOk: (close) => {
                const form = this.addChannelFormRef.current
                form
                    .validateFields()
                    .then(values => {
                        values = { ...values, data: { ...values.data, path_arguments: JSON.parse(values.data.path_arguments || "{}") } }
                        this.state.channel.push("edit-channel", { launch_screen_id: channel.launch_screen_id, ...values })
                            .receive("ok", (resp) => {
                                close()
                                message.success("修改成功")
                                form.resetFields();
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    // 添加渠道
    handleClickAddChannel(launch_screen_id) {
        Modal.confirm({
            title: '添加独立渠道',
            content: <>
                {this.screenChannelForm()}
            </>,
            onOk: (close) => {
                const form = this.addChannelFormRef.current
                form
                    .validateFields()
                    .then(values => {
                        values = { ...values, data: { ...values.data, path_arguments: JSON.parse(values.data.path_arguments || "{}") } }
                        this.state.channel.push("add-channel", { launch_screen_id, ...values })
                            .receive("ok", (resp) => {
                                close()
                                message.success("添加成功")
                                form.resetFields();
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }


    // 编辑渠道
    handleClickEditScreen(screen) {
        Modal.confirm({
            width: 600,
            title: '修改开屏页',
            content: <>
                {this.screenGroupForm(screen)}
            </>,
            onOk: (close) => {
                const form = this.screenFormRef.current
                form
                    .validateFields()
                    .then(values => {
                        values = { ...values, data: { ...values.data, path_arguments: JSON.parse(values.data.path_arguments || "{}") } }
                        this.state.channel.push("edit-screen", { id: screen.id, ...values })
                            .receive("ok", (resp) => {
                                close()
                                message.success("修改成功")
                                form.resetFields();
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    // 添加渠道
    handleClickAddScreen() {
        Modal.confirm({
            width: 600,
            title: '添加开屏页',
            content: <>
                {this.screenGroupForm()}
            </>,
            onOk: (close) => {
                const form = this.screenFormRef.current
                form
                    .validateFields()
                    .then(values => {
                        values = { ...values, data: { ...values.data, path_arguments: JSON.parse(values.data.path_arguments || "{}") } }
                        this.state.channel.push("add-screen", { ...values })
                            .receive("ok", (resp) => {
                                close()
                                message.success("添加成功")
                                form.resetFields();
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    handleClickDeleteGroup(launch_screen_id) {
        Modal.confirm({
            title: '删除确认',
            content: <>
                删除组无法恢复，确认继续？
            </>,
            onOk: (close) => {
                this.state.channel.push("drop-screen-group", { launch_screen_id })
                    .receive("ok", (resp) => {
                        message.success("删除组成功")
                        close()
                    })
                return false
            }
        });
    }

    handleClickDeleteChannel(channel_id) {
        Modal.confirm({
            title: '删除确认',
            content: <>
                删除无法恢复，确认继续？
            </>,
            onOk: (close) => {
                this.state.channel.push("drop-channel", { channel_id })
                    .receive("ok", (resp) => {
                        message.success("删除成功")
                        close()
                    })
                return false
            }
        });
    }

    // 点击筛选用户群组
    handleClickFilterUserGroup(launch_screen_id, userGroup) {
        this.state.channel.push("set-filter-user-group", { launch_screen_id, user_group: userGroup })
            .receive("ok", (resp) => {
                message.success("设置成功")
            })
    }

    screenRender(launch, item) {
        const colorPickerShowKey = `launch-${launch.id}-${item.channel_code}`

        return <div className="launch-item" key={`l-${launch.id}-channels-${item.channel_code || "main"}`}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ backgroundColor: `#${item.data?.bg?.color || "000000"}`, height: 20, width: 20, cursor: "pointer" }}
                        onClick={() => this.setState({ colorPickerShow: { ...this.state.colorPickerShow, [colorPickerShowKey]: true } })}>
                    </div>
                    <Divider type="vertical" ></Divider>
                    <span style={{ width: 150 * this.state.zoomRatio, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{item.channel_code || "默认"}</span>
                </div>
                <div>
                    <Switch checked={item.enable} checkedChildren="启用" unCheckedChildren="禁用" size="small" onChange={(checked) => this.handleEnableChange(launch.id, item.channel_code, checked)}></Switch>

                    {!item?.channel_code && <>
                        <Divider type="vertical" />
                        <Button type="link" icon={<FormOutlined />} size="small" onClick={() => this.handleClickEditScreen(launch)}></Button>
                    </>}
                    {!!item?.channel_code && <>
                        <Divider type="vertical" />
                        <Button type="link" icon={<FormOutlined />} size="small" onClick={() => this.handleClickEditChannel(item)}></Button>
                        <Divider type="vertical" />
                        <Button type="link" icon={<DeleteOutlined />} danger size="small" onClick={() => this.handleClickDeleteChannel(item.id)}></Button>
                    </>}
                </div>
                {this.state.colorPickerShow[colorPickerShowKey] && <div style={{ position: "absolute", zIndex: 10 }}>
                    <SketchPicker color={item?.data?.bg?.color} onChangeComplete={c => this.handleBackgroundColorChange(launch.id, item.channel_code, c)} />
                    <Button size="small" block onClick={() => this.setState({ colorPickerShow: { ...this.state.colorPickerShow, [colorPickerShowKey]: false } })}>取消</Button>
                </div>
                }
            </div>

            <div className="delay-close">
                {item.data?.remain}秒
            </div>

            <div style={{
                display: "flex", flexDirection: "column",
                width: this.state.screen.width * this.state.zoomRatio,
                height: this.state.screen.height * this.state.zoomRatio
            }}>
                <Upload name="cover" className="pic-uploader" style={{
                }}
                    maxCount={1}
                    listType="picture-card"
                    showUploadList={false}
                    customRequest={(obj) => this.handleUploadFile(obj, launch.id, item.channel_code)}
                >
                    {
                        item.data?.cover ?
                            <div className="cover" style={{
                                background: `url(${item.data?.cover}) center no-repeat`,
                                backgroundSize: "contain",
                                backgroundColor: `#${item.data?.bg?.color}`,
                                height: "100%",
                                width: "100%"
                            }}>
                            </div> :
                            <div style={{ backgroundColor: `#${item.data?.bg?.color}` }}></div>
                    }
                </Upload>
                <div style={{ height: 150 * this.state.zoomRatio, width: "100%", background: "#fff", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={logo} height={80 * this.state.zoomRatio} />
                        <div>
                            <div style={{ fontSize: 16 * this.state.zoomRatio, fontWeight: 600 }}>中济经络穴位</div>
                            <div style={{ fontSize: 12 * this.state.zoomRatio, color: "#888" }}>经络穴位针灸的立体活地图</div>
                        </div>
                    </div>
                    <div style={{ fontSize: 12, color: "#888", transform: `scale(${this.state.zoomRatio})` }}>
                        Copyright &copy; 2022 All Rights Reserved.
                    </div>
                </div>
            </div>
        </div >
    }


    render() {
        return <div className="page operation launch-screen">
            <h1>开屏页 </h1>
            <div>
                <p style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <InputNumber value={this.state.screen.width} addonBefore="宽" onChange={v => this.setState({ screen: { ...this.state.screen, width: v } })} />
                        <Divider type="vertical" />
                        <InputNumber value={this.state.screen.height} addonBefore="高" onChange={v => this.setState({ screen: { ...this.state.screen, height: v } })} />
                    </div>
                    <Divider type="vertical" />
                    <Button.Group>
                        <Button type={this.state.screen.model === "360780" ? "primary" : ""} onClick={() => this.handleChangeScreen("360780", 360, 780)}>安卓多数机型、iPhone Mini</Button>
                        <Button type={this.state.screen.model === "iphonepro" ? "primary" : ""} onClick={() => this.handleChangeScreen("iphonepro", 390, 844)}>iPhone普通款、Pro </Button>
                        <Button type={this.state.screen.model === "iphonepromax" ? "primary" : ""} onClick={() => this.handleChangeScreen("iphonepromax", 428, 926)}>iPhone Pro Max</Button>
                        <Button type={this.state.screen.model === "iphonese" ? "primary" : ""} onClick={() => this.handleChangeScreen("iphonese", 375, 667)}>iPhone SE</Button>
                    </Button.Group>
                </p>
                <p>
                    <Slider
                        min={0.3}
                        max={2.5}
                        marks={{ 1: "x 1", 0.5: "x 0.5", 0.8: "x 0.8", 2: "x 2" }}
                        onChange={(v) => this.setState({ zoomRatio: v })}
                        value={this.state.zoomRatio}
                        step={0.01}
                    />
                </p>
                <div className="launch-screen-group" onClick={() => this.handleClickAddScreen()} style={{
                    height: 80,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer"
                }} >
                    <span style={{ fontSize: 48 }}>
                        <PlusCircleTwoTone />
                    </span>
                </div>
                {
                    this.state.data.map(launch => {
                        return <div className="launch-screen-group" key={`launch-group-${launch.id}`}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <h3 style={{ margin: 0 }}>{launch.nickname}</h3>
                                    <Divider type="vertical" />
                                    <small><CalendarTwoTone /> {dayjs(launch.begin_at).add(8, 'hour').format("YYYY-MM-DD HH:mm")} - {dayjs(launch.end_at).add(8, 'hour').format("YYYY-MM-DD HH:mm")}</small>
                                    <Divider type="vertical" />
                                    <small><MobileTwoTone /> {launch.min_version_code} - {launch.max_version_code} <span style={{ color: "#aaa" }}>系统暂未管理App版本号，用Version Code代展示</span></small>
                                    <Divider type="vertical" />
                                    <small><HourglassTwoTone /> {launch.max_display_times} 次</small>
                                </div>
                                <div>
                                    <Button.Group size="small">
                                        <Button onClick={() => this.handleClickFilterUserGroup(launch.id, 0)} type={launch.filter?.user_group?.indexOf(0) > -1 ? "primary" : "dashed"}>潜客</Button>
                                        <Button onClick={() => this.handleClickFilterUserGroup(launch.id, 1)} type={launch.filter?.user_group?.indexOf(1) > -1 ? "primary" : "dashed"}>会员</Button>
                                    </Button.Group>
                                    <Divider type="vertical" />
                                    <Button type="link" onClick={() => this.handleClickDeleteGroup(launch.id)} danger>删除组</Button>
                                </div>
                            </div>
                            <div className="launches">
                                {this.screenRender(launch, launch)}
                                {launch.channels.map((channel) => this.screenRender(launch, channel))}
                                <div className="launch-item add">
                                    <div>添加独立渠道</div>
                                    <div className="cover" onClick={() => this.handleClickAddChannel(launch.id)} style={{
                                        width: this.state.screen.width * this.state.zoomRatio,
                                        height: this.state.screen.height * this.state.zoomRatio,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer"
                                    }} >
                                        <span style={{ fontSize: 64 }}>
                                            <PlusCircleTwoTone />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div >
    }
}
const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket,
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LaunchScreen)