import React, { useEffect, useState } from "react"
import { AutoComplete, Spin, Avatar } from 'antd'
import { connect } from "react-redux";
import debounce from "debounce"

import { SmileTwoTone, CrownTwoTone, AlipayOutlined, WechatOutlined, AppleFilled } from '@ant-design/icons';
import { Session } from '../store/global/actions'
import { Link, useHistory } from "react-router-dom"
import Config from "../config"

function GlobalSearchInput(props) {
    const [globalResourceData, setGlobalResourceData] = useState({})
    const [globalUserData, setGlobalUserData] = useState([])
    const [globalOrderData, setGlobalOrderData] = useState([])

    const history = useHistory()

    useEffect(() => {
        props.globalChannel.on("global-search:resources", ({ data }) => {
            setGlobalResourceData(data)
        })
        props.globalChannel.on("global-search:user", ({ data }) => {
            setGlobalUserData(data)
        })
        props.globalChannel.on("global-search:order", ({ data }) => {
            setGlobalOrderData(data)
        })
        return () => {
            props.globalChannel.off("global-search:resources")
            props.globalChannel.off("global-search:user")
            props.globalChannel.off("global-search:order")
        }
    })

    const financeOrderIcon = (trade_channel_meta) => {
        switch (trade_channel_meta) {
            case "acupoint:trade:channel::wechat":
                return <WechatOutlined style={{ color: "green" }} />;

            case "acupoint:trade:channel::alipay":
                return <AlipayOutlined style={{ color: "#027AFF" }} />;

            default:
                return <AppleFilled style={{ color: "black" }} />;
        }
    }

    const handleGlobalSearch = (value) => {
        props.globalChannel.push("search", { query: value })
    }

    const userSelectionView = () => {
        return {
            label: "用户",
            options: globalUserData.map((user) => {
                return {
                    key: user.id,
                    value: user.nickname,
                    label: (
                        <div onClick={() => { history.push("/operation/" + user.id); }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span> <span style={{ color: "#aaa" }}>{user.id}</span> {user.group !== "vip" ? <SmileTwoTone /> : <CrownTwoTone twoToneColor="#eb2f96" />}  {user.nickname || user.id}  {user.vip_ended_at ? <span style={{ color: "#aaa" }}>@ {user.vip_ended_at}</span> : ""} </span>
                            <Avatar src={Config.AvatarUrl(user.avatar)} />
                        </div>
                    ),
                }
            })
        }
    }

    const orderSelectionView = () => {
        return {
            label: "订单",
            options: globalOrderData.map((order) => {
                const show_no = order.wechat_id || order.alipay_id || order.apple_id || order.no
                return {
                    value: show_no,
                    label: (
                        <div onClick={() => history.push("/operation/" + (order.owner_id))} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>
                                {financeOrderIcon(order.trade_channel_meta)} {show_no}
                            </span>
                            <span>
                                {order.amount / 100}
                            </span>
                        </div>
                    ),
                }
            })
        }
    }

    const ownerView = (item) => {
        let name = ""
        switch (item.type) {
            case "Illness":
            case "illness":
                name = <Link key={item.name} to={`/cms/illness/${item.name}`}>{item.name}</Link>
                break;
            case "Acupoint":
            case "acupoint":
                name = <Link key={item.name} to={`/cms/acupoint/ancestor/${item.name}`}>{item.name}</Link>
                break;
            case "Meridian":
            case "meridian":
                name = <Link key={item.name} to={`/cms/meridian/ancestor/${item.name}`}>{item.name}</Link>
                break;
            case "Herb":
            case "herb":
                name = <Link key={item.name} to={`/cms/herb/${item.name}`}>{item.name}</Link>
                break;
            case "Food":
            case "food":
                name = <Link key={item.name} to={`/cms/food/${item.name}`}>{item.name}</Link>
                break;

            case "Elixir.Acupoint.Model.STD.Term.BasicTheory":
                name = <Link key={item.name} to={`/cms/term/BasicTheory/${item.name}`}>{item.name}</Link>
                break;
            case "Elixir.Acupoint.Model.STD.Term.Terminology":
                name = <Link key={item.name} to={`/cms/term/Terminology/${item.name}`}>{item.name}</Link>
                break;
            case "Elixir.Acupoint.Model.STD.Term.Doctor":
                name = <Link key={item.name} to={`/cms/term/Doctor/${item.name}`}>{item.name}</Link>
                break;
            case "Elixir.Acupoint.Model.STD.Term.AncientBook":
                name = <Link key={item.name} to={`/cms/term/AncientBook/${item.name}`}>{item.name}</Link>
                break;
            case "Elixir.Acupoint.Model.STD.Term.DietTherapy":
                name = <Link key={item.name} to={`/cms/term/DietTherapy/${item.name}`}>{item.name}</Link>
                break;
            case "Elixir.Acupoint.Model.STD.Term.Regimen":
                name = <Link key={item.name} to={`/cms/term/Regimen/${item.name}`}>{item.name}</Link>
                break;
        }

        return {
            key: `${item.name}-${item.id}`,
            value: item.name,
            label: name
        }
    }

    const resourcesView = () => {
        // let exactView = {
        //     label: "精确查找",
        //     options: globalResourceData.exact?.map((item) => {
        //         return {
        //             key: `exact-${item.id}`,
        //             value: item.name,
        //             label: <Link key={item.name} to={`/cms/food/${item.name}`}>{item.name}</Link>
        //         }
        //     }) || []
        // }

        let similarView = globalResourceData?.similar?.map((item) => {
            if (item.type === "video_course") {
                return {
                    label: "视频课程",
                    options: item.data?.map(album => {
                        return {
                            key: `${album.lesson.id}`,
                            value: album.lesson.name,
                            label: <div key={`${album.name}-${album.lesson.name}`} style={{ display: "flex" }}>
                                <Link to={`/cms/video-course-list/${album.id}/editor`}>{album.name} - {album.lesson.name}</Link>
                            </div>
                        }
                    })
                }
            } else {
                return {
                    label: item.label,
                    options: item.data?.map(ownerView)
                }
            }
        }) || []


        return similarView
    }

    const optionView = () => {
        return [userSelectionView(), orderSelectionView(), ...resourcesView()]

    }

    return <AutoComplete style={{ width: 300 }} placeholder="全局搜索"
        listHeight="80vh"
        popupClassName="certain-category-search-dropdown"
        notFoundContent={<Spin />}
        options={optionView()}
        onSearch={debounce((text) => handleGlobalSearch(text), 500)}
        allowClear></AutoComplete>
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        activeKey: global.navActiveKey,
        user: global.user,
        jsonRequest: global.jsonRequest,
        globalChannel: global.globalChannel
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logOut: () => {
            dispatch(Session.logOut())
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearchInput);