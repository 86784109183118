

import { Image as AntdImage } from 'antd';
import { useEffect, useState } from 'react';
function drawCircle(ctx, x, y, radius, fill, stroke, strokeWidth) {
    ctx.beginPath()
    ctx.arc(x, y, radius, 0, 2 * Math.PI, false)
    if (fill) {
        ctx.fillStyle = fill
        ctx.fill()
    }
    if (stroke) {
        ctx.lineWidth = strokeWidth
        ctx.strokeStyle = stroke
        ctx.stroke()
    }
}

function ImageWithPoints(props) {
    const [imageUrl, setImageUrl] = useState("")

    useEffect(() => {
        var image = new Image();
        image.src = props.src;
        image.crossOrigin = "anonymous";
        image.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0)

            props.positions.forEach(({ x, y }) => {
                x = Number.parseInt(x)
                y = Number.parseInt(y)
                drawCircle(ctx, x, y, 10, "red", "#000", 2)
            });
            setImageUrl(canvas.toDataURL())
        }
    }, [props.src])

    return <AntdImage {...props} src={imageUrl}></AntdImage>
}

export default ImageWithPoints