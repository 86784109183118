import {
    Chart as ChartJS, registerables
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useEffect, useState } from "react";
import dayjs from 'dayjs'
import { DatePicker, Button, Radio, Divider, Switch } from "antd"

import { Paired12 } from 'chartjs-plugin-colorschemes-v3/src/colorschemes/colorschemes.brewer';
import { connect } from 'react-redux'
import useLocalStorageState from 'use-local-storage-state';
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
const { RangePicker } = DatePicker;

ChartJS.register(...registerables);
function Revenue(props) {
    const endOfToday = dayjs().add(1, "day").startOf("day")
    // 报表数据
    const [chartData, setChartData] = useState(null)
    // 筛选项
    const [filter, setFilter] = useState({ time: [dayjs().add(-1, "day").add(1, "hour").startOf("hour"), dayjs().add(1, "hour")], unit: "hours" })
    // 频道
    const [channel, setChannel] = useState(props.channel)
    // 报表堆栈显示
    const [chartStack, setChartStack] = useLocalStorageState("Revenue.Filter.ChartStack", false)

    const disableHour = filter.time[1].diff(filter.time[0], "day") > 1
    const disableDay = filter.time[1].diff(filter.time[0], "month") > 1
    const disableYear = filter.time[1].diff(filter.time[0], "year") > 1

    // 快速选择
    const handleQuickQuery = (unit, time) => {
        let newFilter = { unit, time }

        const [min, max] = newFilter.time

        // 如果时长超过一个月，则单位改成月
        if (max.diff(min, "year") > 1 && ["month", "hours", "days"].indexOf(newFilter.unit) >= 0) {
            newFilter = { ...newFilter, unit: "years" }
        } else if (max.diff(min, "month") > 1 && ["hours", "days"].indexOf(newFilter.unit) >= 0) {
            newFilter = { ...newFilter, unit: "months" }
        } else if (max.diff(min, "day") > 1 && ["hours"].indexOf(newFilter.unit) >= 0) {
            newFilter = { ...newFilter, unit: "days" }
        }

        setFilter(newFilter)
        loadData(newFilter)
    }

    // 变更某个值
    const handleFilterChange = (key, value) => {
        let cloneFilter = { ...filter, [key]: value }

        handleQuickQuery(cloneFilter.unit, cloneFilter.time)
    }


    // 点击快速移动一个单位
    const handleClickQuickMove = (unit_num) => {
        let [begin, end] = filter.time
        switch (filter.unit) {
            case "days":
                [begin, end] = [begin.add(unit_num, "day"), end.add(unit_num, "day")]
                break;
            case "months":
                [begin, end] = [begin.add(unit_num, "months"), end.add(unit_num, "months")]
                break;
            case "hours":
                [begin, end] = [begin.add(unit_num, "hours"), end.add(unit_num, "hours")]
                break;
            case "years":
                [begin, end] = [begin.add(unit_num, "years"), end.add(unit_num, "years")]
                break;
            default:
                break;
        }
        handleFilterChange("time", [begin, end])
    }

    // 加载数据
    const loadData = (filter, channel_ = null) => {
        const format = filter.unit === "hours" ? "YYYY-MM-DD HH:00:00" : "YYYY-MM-DD 00:00:00"

        const time = [filter.time[0].format(format), filter.time[1].format(format)]
        channel_ = channel_ || channel
        channel_.push("revenue-chart-data", { ...filter, time }).receive("ok", (data) => { chartInit(data) })
    }

    // 可见的时间格式
    const timeFormatByUnit = (unit) => {
        switch (unit) {
            case "hours": return "DD日HH时";
            case "days": return "MM月DD日";
            case "months": return "YYYY年MM月";
            case "years": return "YYYY年"

            default:
                break;
        }
    }

    // 初始化报表
    const chartInit = ({ times, data, unit, averages }) => {
        const datasets = Object.keys(data).map((key, i) => {
            return {
                label: key,
                data: data[key],
                backgroundColor: Paired12[i % 12]
            }
        })

        const averagesColors = ["#ff6700a0", "#b2df8aa0", "#76c5ef60"]

        // 给数据加上均线
        averages && averages.map(({ data: average, unit, name }, i) => {
            let data = {}
            // 判断均线单位
            switch (unit) {
                case "days":
                case "months":
                    data = Object.keys(average).reduce((acc, time, i) => {
                        return { ...acc, [dayjs(time).format(timeFormatByUnit(unit))]: average[time] }
                    }, {})
                    break;

                case "hours":
                    const amountData = Object.keys(average).map(time => average[time])
                    // 时间要偏移，均线时间时从0点开始，一直到23点。需要与当前开始时间进行适配
                    const firstHour = dayjs(times[0]).hour()
                    const tailAverage = amountData.slice(firstHour)
                    const headAverage = amountData.slice(0, firstHour)
                    data = tailAverage.concat(headAverage)

                default:
                    break;
            }

            // 30 天均线
            datasets.unshift({
                type: 'line',
                label: name,
                borderColor: averagesColors[i],
                backgroundColor: averagesColors[i],
                borderWidth: (i + 1) * 2,
                fill: false,
                data: data
            })
        })


        const chartData = {
            labels: times.map(time => dayjs(time).format(timeFormatByUnit(unit))),
            datasets: datasets
        }

        setChartData(chartData)
    }

    // This will run only once
    useEffect(() => {
        let initChannel = channel
        if (!!initChannel) {
            initChannel = props.socket.channel("ops:dashboard", {})
            initChannel.join().receive("ok", () => { })
            setChannel(initChannel)
        }
        loadData(filter, initChannel)
    }, []);

    return <>
        <p>
            <span>
                单位：
                <Radio.Group size='small'
                    onChange={(e) => handleFilterChange("unit", e.target.value)}
                    value={filter.unit}
                    optionType="button"
                    buttonStyle="solid"
                >
                    <Radio.Button value="hours" disabled={disableHour}>小时</Radio.Button>
                    <Radio.Button value="days" disabled={disableDay}>天</Radio.Button>
                    <Radio.Button value="months" disabled={disableYear}>月</Radio.Button>
                    <Radio.Button value="years">年</Radio.Button>
                </Radio.Group>
            </span>

            <Divider type="vertical" />
            <span>
                时间：
                <RangePicker format="YYYY-MM-DD 00:00:00" size='small' value={filter.time} onChange={(value) => handleFilterChange("time", value)} />
            </span>
            <Divider type="vertical" />
            <span>
                <Button onClick={() => handleClickQuickMove(-1)} icon={<DoubleLeftOutlined />} type="link" size='small'></Button>
                <Button onClick={() => handleClickQuickMove(1)} icon={<DoubleRightOutlined />} type="link" size='small'></Button>
            </span>


            <Divider type="vertical" />
            <span>
                快捷选择：
                <Button.Group size='small'>
                    <Button
                        onClick={() => handleQuickQuery("hours", [dayjs().add(-1, "day").add(1, "hour").startOf("hour"), dayjs().add(1, "hour")])}>最近一天</Button>
                    <Button onClick={() => handleQuickQuery("days", [dayjs().add(-7, "day").endOf("day"), endOfToday])}>最近七天</Button>
                    <Button onClick={() => handleQuickQuery("days", [dayjs().add(-1, "month").endOf("day"), endOfToday])}>最近一个月</Button>
                    <Button
                        onClick={() => handleQuickQuery("months", [dayjs().add(-1, "year").add(1, "month").startOf("month"), endOfToday])}>最近一年</Button>
                    <Button></Button>
                    <Button onClick={() => handleQuickQuery("hours", [dayjs().startOf("day"), endOfToday])}>今天</Button>
                    <Button onClick={() => handleQuickQuery("hours", [dayjs().startOf("week"), endOfToday])}>本周</Button>
                    <Button onClick={() => handleQuickQuery("hours", [dayjs().startOf("month"), endOfToday])}>本月</Button>
                    <Button onClick={() => handleQuickQuery("hours", [dayjs().startOf("year"), endOfToday])}>本年</Button>
                </Button.Group>
            </span>
            <Divider type="vertical" />
            <Switch checked={chartStack} checkedChildren="堆栈累计" unCheckedChildren="点击堆栈累计" onChange={value => setChartStack(value)}></Switch>
        </p>

        {chartData && <Bar height={80} options={{
            responsive: true,
            scales: {
                x: {
                    stacked: chartStack,
                },
                y: {
                    stacked: chartStack
                }
            },
            plugins: {
                title: { display: true, text: "营收额报表" }
            }
        }} data={chartData} />}

    </>
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Revenue);