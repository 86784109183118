import { Tag, Button, List, Form, Modal, Input, Alert, message } from 'antd'
import { MergeCellsOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'

// import useLocalStorageState from 'use-local-storage-state';
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import UserAvatar from '../UserAvatar';

import RequireRolesOr from '../../require_roles_or'

function handleUserSearch(props, q, setSuggestUsers) {
    props.pushEvent("user-search", { q })
        .receive("ok", (data) => {
            if (data.suggest_users.length === 0)
                message.warning("没找到相关用户")
            else
                setSuggestUsers(data.suggest_users)
        })
}

// 加载微信昵称相似凭证
function handleSimilarUserLoad(props, setSimillarUsers) {
    props.pushEvent("similar-user-list", {})
        .receive("ok", (data) => {
            setSimillarUsers(data)
        })
}


// 处理用户合并迁移
function handleIdentityMigrate(form, props, account, sourceUserId, targetUserId) {
    Modal.confirm({
        title: '迁移用户',
        content: <>
            <Alert message="确定迁移吗？迁移凭据并不会合并两个账号的会员时长。" banner type="warning" showIcon />
            <p></p>
            <Form form={form} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off">
                <Form.Item label="账号">
                    <span className="ant-form-text">{account}</span>
                </Form.Item>
                <Form.Item label="确认密码" name="password" rules={[{ required: true, message: '密码不能为空' }]}>
                    <Input.Password />
                </Form.Item>
            </Form>
        </>,
        onOk: (close) => {
            form
                .validateFields()
                .then(values => {
                    form.resetFields();
                    props.pushEvent("identity-migrate", { source_user_id: sourceUserId, target_user_id: targetUserId, account, password: values.password })
                        .receive("ok", () => {
                            close()
                            message.success("迁移成功")
                        })
                })
                .catch(info => {
                    console.log('Validate Failed:', info);
                });
            return false
        }
    });
}

function SimilarUsermerge(props) {
    // 微信相关凭证
    const [simillarUsers, setSimillarUsers] = useState([])
    // 搜索的用户
    const [suggestUsers, setSuggestUsers] = useState([])
    // 当前登录的凭证
    // const [currentEvidence] = useLocalStorageState('current-evidence', null)
    const currentEvidence = JSON.parse(localStorage.getItem('current-evidence') || 'null')
    // 表单ref
    const [form] = Form.useForm();


    useEffect(() => {
        handleSimilarUserLoad(props, setSimillarUsers);
    }, [props.userId])


    return <>
        <RequireRolesOr require={["acupoint:role::operator"]}>
            <div style={{ "background": "#f5fcff", "padding": 5 }}>
                <Input.Search enterButton="查询" placeholder="查询用户、手机号、昵称" onSearch={(q) => handleUserSearch(props, q, setSuggestUsers)}></Input.Search>

                <br></br>
                <List>
                    {suggestUsers.map(user =>
                        <List.Item key={user.id} actions={[
                            user.id.toString() !== props.userId && <Link to={`/operation/${user.id}`}><UserOutlined /></Link>,
                            user.group === "vip" && user.id.toString() !== props.userId ? <Button onClick={() => handleIdentityMigrate(form, props, currentEvidence.account, props.userId, user.id)} icon={<MergeCellsOutlined />} type="link">迁移</Button> : null
                        ]} >
                            <List.Item.Meta
                                avatar={<UserAvatar size={40} id={user.id} avatar={user.avatar} vip={user.group === "vip"} />}
                                title={<span>{user.nickname} <span className="secondary">@{user.id} {user.id.toString() === props.userId ? <sup>自己</sup> : ""}</span> </span>}
                                description={<div>
                                    <div><Tag>创建时间：{dayjs(user.created_at).format("YYYY-MM-DD HH:mm")}</Tag></div>
                                </div>}
                            />
                        </List.Item>)}
                </List>
            </div>
        </RequireRolesOr>

        <List
            className="list-item"
            dataSource={simillarUsers}
            renderItem={(user) => (
                <List.Item key={user.id} actions={[
                    user.id.toString() !== props.userId && <Link target="_blank" to={`/operation/${user.id}`}><UserOutlined /></Link>,
                    RequireRolesOr.allow(["acupoint:role::operator"]) && user.id.toString() !== props.userId ? <Button onClick={() => handleIdentityMigrate(form, props, currentEvidence.account, props.userId, user.id)} icon={<MergeCellsOutlined />} type="link">迁移凭据</Button> : null
                ]} >
                    <List.Item.Meta
                        avatar={<UserAvatar size={40} id={user.id} avatar={user.avatar} vip={user.group === "vip"} />}
                        title={<span>{user.nickname} <span className="secondary">@{user.id} {user.id.toString() === props.userId ? <sup>自己</sup> : ""}</span> </span>}
                        description={<div>
                            <div><Tag>创建时间：{dayjs(user.created_at).format("YYYY-MM-DD HH:mm")}</Tag></div>
                        </div>}
                    />
                </List.Item>
            )}
        >
        </List>
    </>

}

export default SimilarUsermerge;