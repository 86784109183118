import React from "react";
import { Button, Image, Divider, Tooltip, Popover } from 'antd';
import 'jsoneditor-react/es/editor.min.css';
import { BarcodeOutlined, ClockCircleOutlined, CheckCircleOutlined, QuestionCircleOutlined, FileImageOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import UserAvatar from "../../components/user/UserAvatar";
import MarketingPlanRewardBG from "../../images/marketing_plan_reward_bg.png"
import "./reward_convert_item.css"

function RewardConvertItem({ reward, onClickShowInviteCode, onClickShowAuditForm, onClickShowReleaseForm }) {
    // 周期元数据翻译
    const statusLabel = (value, item) => {
        switch (value) {
            case "pending":
                return <span style={{ color: "orange" }}>等待审核 <ClockCircleOutlined /></span>

            case "wait_release":
                return <span style={{ color: "orange" }}>等待发放 <ClockCircleOutlined /></span>

            case "finish":
                return <span style={{ color: "green" }}>已完成 <CheckCircleOutlined /></span>

            case "failed":
                return <Tooltip title={item.failed_message}> <span style={{ color: "red" }}>审核失败 <QuestionCircleOutlined /> </span></Tooltip>

            default:
                return value
        }
    }

    let auditForm = <Button type="link" onClick={() => onClickShowAuditForm(reward)}>审核</Button>
    switch (reward.status) {
        case "pending": break;
        case "failed":
            auditForm = <Button type="link" onClick={() => onClickShowAuditForm(reward)}>重新审核</Button>;
            break;
        case "finish":
            auditForm = reward.data?.release_img_url ? <Popover content={<Image src={reward.data?.release_img_url} style={{ maxWidth: 500, maxHeight: 500 }} />}>
                <Button type="link" icon={<FileImageOutlined />}></Button>
            </Popover> : null
            break;
        case "wait_release":
            auditForm = <Button type="link" onClick={() => onClickShowReleaseForm(reward)}>发放</Button>

        default:
            break;
    }


    return <div className="invite-code reward-convert-item">
        <div className="business-no">{reward.business_no}</div>
        <h2 className="title">{reward.reward_name} x {reward.convert_num}</h2>
        <div className="request-at">{dayjs(reward.inserted_at).add(8, "hour").format("YYYY-MM-DD HH:mm:ss")}</div>
        <div className="coin">
            <span className="cost">
                {reward.coin_cost}
            </span>
            <span className="apply" style={{ color: "#aaa" }}>
                应扣 {reward.coin_num * reward.convert_num}
            </span>
        </div>
        <div className="reward-logo">
            <Image className="image" src={reward.plan_reward_data?.logo_url}></Image>
        </div>
        <UserAvatar style={{ position: "absolute", top: 20, right: 20 }} nickname={reward.bearer?.nickname} size={60} avatar={reward.bearer?.avatar} id={reward.bearer?.id} vip={reward.bearer?.group === "vip"} />
        <div className="footer">
            {statusLabel(reward.status, reward)}
            {onClickShowAuditForm && <>
                <Divider type="vertical" />
                {auditForm}
            </>}
            {onClickShowInviteCode && <>
                <Divider type="vertical" />
                <Button icon={<BarcodeOutlined />} onClick={() => onClickShowInviteCode(reward.code)} type="link"></Button>
            </>}
        </div>
    </div >
}

export default RewardConvertItem