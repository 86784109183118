import React, { useState, useEffect } from "react"
import { Timeline, Pagination, Tooltip, Empty } from 'antd'
import { Comment } from '@ant-design/compatible';
import dayjs from 'dayjs';

import "./SearchLog.css"

// 当评价页数变更时执行
function handlePageChange(setPagedData, props, page, pageSize) {
    props.pushEvent("search-log-list", { page, size: pageSize })
        .receive("ok", ({ data }) => {
            setPagedData(data)
        })
}

function SearchLog(props) {
    // 记事
    const [pagedData, setPagedData] = useState({})

    useEffect(() => {
        handlePageChange(setPagedData, props, 1, 10)
    }, [props.userId])


    return !!pagedData && pagedData.total_entries > 0 ?
        <div className="user-information-tabs search-logs">
            <Pagination size="small" onChange={(page, size) => handlePageChange(setPagedData, props, page, size)} showQuickJumper pageSize={pagedData?.page_size} current={pagedData?.page_number} total={pagedData?.total_entries} />
            <br />
            <Timeline>
                {pagedData.entries.map((log) => {
                    return <Timeline.Item key={log.id}  >
                        <Comment
                            // actions={item.actions}
                            author={(() => {
                                switch (log.module) {
                                    case "Elixir.Acupoint.Business.STD.Search.FullTextSearch":
                                        return <span style={{ color: "orange" }}>全文搜索</span>
                                    case "Elixir.Acupoint.Business.STD.Search.Model3D":
                                        return <span style={{ color: "green" }}>模型搜索</span>

                                    default:
                                        break;
                                }
                            })()}
                            // avatar={""}
                            content={<>
                                {log.text}
                            </>}
                            datetime={<Tooltip title={dayjs(log.inserted_at).add(8, 'hours').format("YYYY-MM-DD HH:mm:ss")}>
                                <span>{dayjs(log.inserted_at).add(8, 'hours').fromNow()}</span>
                            </Tooltip>}
                        />
                    </Timeline.Item>
                })}
            </Timeline>
            <Pagination size="small" onChange={(page, size) => handlePageChange(setPagedData, props, page, size)} showQuickJumper pageSize={pagedData?.page_size} current={pagedData?.page_number} total={pagedData?.total_entries} />
        </div>
        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
}

export default SearchLog;