import { default as React, useEffect, useRef } from 'react';
import EditorJS from '@editorjs/editorjs';

// import Table from '@editorjs/table'
import Paragraph from '@editorjs/paragraph'
import List from '@editorjs/list'
// import LinkTool from '@editorjs/link'
import LinkAutocomplete from "@editorjs/link-autocomplete"
import Image from '@editorjs/image'
import Raw from '@editorjs/raw'
import Header from '@editorjs/header'
// import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import Delimiter from '@editorjs/delimiter'
import SimpleImage from '@editorjs/simple-image'
import Underline from '@editorjs/underline'
import FootnotesTune from '@editorjs/footnotes'
import { connect } from "react-redux";
import Config from '../../config'

const DEFAULT_INITIAL_DATA = (text = "文章标题请修改此处") => {
    return {
        "time": new Date().getTime(),
        "blocks": [
            {
                "type": "paragraph",
                "data": {
                    "text": "来添加第一句话~",
                }
            },
        ]
    }
}

const EDITTOR_HOLDER_ID = 'editorjs';

const Editor = (props) => {
    const ejInstance = useRef();
    const [editorData, setEditorData] = React.useState(DEFAULT_INITIAL_DATA);

    const EDITOR_JS_TOOLS = {
        // table: Table,
        paragraph: {
            class: Paragraph,
            tunes: ['footnotes']
        },
        list: {
            class: List,
            tunes: ['footnotes'], // note that stirng here should be the same as tools object key for Tune  
        },
        // linkTool: LinkTool,
        link: {
            class: LinkAutocomplete,
            config: {
                endpoint: Config.HttpEndpoint(`/api/cms/operation/search/article?token=${props.user.token}`),
                queryParam: 'text'
            }
        },
        image: {
            class: Image,
            config: {
                endpoints: {
                    byFile: Config.HttpEndpoint(`/api/attach/editorjs/image?token=${props.user.token}`), // Your backend file uploader endpoint
                    // byUrl: 'http://localhost:8008/fetchUrl', // Your endpoint that provides uploading by Url
                }
            }
        },
        raw: Raw,
        header: Header,
        marker: Marker,
        delimiter: Delimiter,
        simpleImage: SimpleImage,
        underline: Underline,
        footnotes: FootnotesTune
    }

    // This will run only once
    useEffect(() => {
        if (!ejInstance.current) {
            initEditor();
        }
        return () => {
            ejInstance.current.destroy();
            ejInstance.current = null;
        }
    }, []);

    const initEditor = () => {
        const editor = new EditorJS({
            holder: EDITTOR_HOLDER_ID,
            logLevel: "ERROR",
            data: editorData,
            onReady: () => {
                ejInstance.current = editor;
                (props.instanceRef || (() => null))(editor)
                props.onChange(editorData);
            },
            onChange: () => {
                editor.save().then(content => {
                    props.onChange(content);
                    setEditorData(content);
                });
            },
            autofocus: true,
            tools: EDITOR_JS_TOOLS,
            i18n: {
                messages: {
                    tools: {
                        image: {
                            'Upload an image': '上传图片',
                        },
                        LinkAutocomplete: {
                            'Paste or search': '粘贴或搜索',
                            'Paste a link': '拷贝一个连接',
                            'Cannot process search request because of': '请求报错因为',
                            'Server responded with invalid data': '服务器返回无效格式',
                            'Link URL is invalid': '链接地址有误'
                        }
                    }
                }
            }
        });
    };

    return (
        <React.Fragment>
            <div id={EDITTOR_HOLDER_ID}> </div>
        </React.Fragment>
    );
}



const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        user: global.user,
        jsonRequest: global.jsonRequest,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Editor)