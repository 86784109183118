import React, { useEffect, useState } from "react"
import { Divider, Pagination, Timeline, Button, Form, InputNumber, message, Input } from 'antd'
import dayjs from "dayjs"
import PasswordConfirm from "../../../components/passwordConfirm";

function DistributedAccountFlow(props) {
    // 校验日志数据
    const [data, setDate] = useState({})
    const [account, setAccount] = useState({})
    let manualCostRef = React.createRef()

    const handlePageTo = (page) => {
        props.pushEvent("distributed-finance-account-flow", { page, currency_meta: "zy:application:acupoint:currency::coin" })
            .receive("ok", ({ data, account }) => {
                setDate(data)
                setAccount(account)
            })
    }

    useEffect(() => {
        handlePageTo(1)
    }, [props.userId])

    // 手动扣除积分
    const handleClickManualCost = () => {
        PasswordConfirm({
            ref: manualCostRef,
            title: '密码确认',
            message: "充值或消耗积分会记录到操作日志中，请谨慎操作",
            externalItems: <>
                <Form.Item label="积分" name="coin_num" rules={[{ required: true, message: '扣除积分不能为空' }]} help={`由负数呈现，最多扣 ${account.total_balance * -1}`}>
                    <InputNumber max={4000} min={account.total_balance * -1} />
                </Form.Item>
                <Form.Item label="扣除原因" name="summary" rules={[{ required: true, message: '必填' }]}>
                    <Input />
                </Form.Item>
            </>,
            onOk: ({ password, coin_num, summary }, close) => {
                message.loading({ content: '请求数据中...', key: "exporting", duration: 0 })
                props.pushEvent("distributed-finance-manual-cost", { password, coin_num, summary })
                    .receive("ok", () => {
                        handlePageTo(1)
                        message.success({ content: '已扣除', key: "exporting", duration: 3 })
                        close()
                    })
            }
        })
    }

    return <>
        <Pagination current={data?.page_number} showQuickJumper size="small" total={data?.total_count} pageSize={data?.page_size || 10} onChange={(page, size) => handlePageTo(page)} />
        <br></br>
        <p> 账户总余额：{account.total_balance}，可用余额：{account.available_balance}，冻结余额：{account.freeze_balance}</p>
        <Button size="small" type="primary" onClick={() => handleClickManualCost()}>手动入账 / 出账</Button>
        <Timeline>
            {!!data.entries && data.entries.map((log) => {
                return <Timeline.Item key={log.transaction_id}>
                    <p style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>入账：{log.amount}<Divider type="vertical" /> 余额：{log.balance}</span>
                        <span>{dayjs(log.occur_at * 1000).format("YYYY-MM-DD HH:mm")}</span>
                    </p>
                    <div>
                        {log.summary}
                    </div>
                </Timeline.Item>
            })}
        </Timeline>
        <Pagination current={data?.page_number} showQuickJumper size="small" total={data?.total_count} pageSize={data?.page_size || 10} onChange={(page, size) => handlePageTo(page)} />
    </>
}

export default DistributedAccountFlow;