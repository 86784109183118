import { Button, message, Form, Input } from "antd"
import { useEffect, useRef, useState } from "react"
import Editor from "./editor"
import edjsParser from '../../components/editjs_html'

function ContractForm(props) {
    const editor = useRef();
    const [content, setConttent] = useState("")

    const contract = props.contract

    const formItemLayout = {
        labelCol: {
            xs: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 16 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 4,
                offset: 8,
            },
        },
    };

    const onEditorInit = (editor) => {
        console.log(contract)
        console.log(contract.data.editorjs)
        editor.render(contract.data.editorjs)
    }

    const handleFormSubmit = (values) => {
        props.channel.push("save:contract", { ...values, content: edjsParser.parse(content).join(""), data: { editorjs: content } })
            .receive("ok", () => {
                message.success("提交成功")
                props.closeModal && props.closeModal()
            })
    }

    return <Form {...formItemLayout} onFinish={handleFormSubmit}>
        <Form.Item name="key" initialValue={contract?.key} label="合同KEY" rules={[{ required: true }]} help="软主键">
            <Input disabled={!!contract?.id} />
        </Form.Item>
        <Form.Item name="name" initialValue={contract?.name} label="合同名" rules={[{ required: true }]} >
            <Input />
        </Form.Item>
        <Form.Item label="内容">
            <Editor editorID={`editorjs-${contract?.key || Math.random()}`} onChange={setConttent} onInit={onEditorInit} instanceRef={(instance) => editor.current = instance} />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" >
                保存
            </Button>
        </Form.Item>
    </Form >
}


export default ContractForm