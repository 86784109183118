
import { Button, Badge, Pagination, Divider, message, Spin, Form, Input } from "antd";
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import UserAvatar from "../components/user/UserAvatar"
import UserAdvanceFilter from "../components/user_advance_filter";
import "./CoinUnAchievedCloakIn.css"
import PasswordConfirm from "../components/passwordConfirm";


function CoinUnAchievedCloakIn(props) {
    const [channel, setChannel] = useState(null)
    const [releaseChannels, setReleaseChannels] = useState([])
    const [versionCodes, setVersionCodes] = useState(null)
    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    const [query, setQuery] = useState([])

    useEffect(() => {
        let _channel = props.socket.channel("finance:coin:pool", { page: 1, size: 15 })
        _channel.join().receive("ok", () => {
            // handlePageTo(1, 120, _channel)

            _channel.push("data-version-code").receive("ok", ({ data }) => { setVersionCodes(data) })
            _channel.push("data-release-channel").receive("ok", ({ data }) => { setReleaseChannels(data) })
        })

        setChannel(_channel)
        return () => {
            !!_channel && _channel.leave()
        }
    }, [])

    const handlePageTo = (page, size, channel, query = {}) => {
        setQuery(query)
        setLoading(true)
        channel.push("list-unachieved-clock-in", { page, page_size: size, query })
            .receive("ok", ({ data }) => {
                setLoading(false)
                setData(data)
            })
    }

    const handleQuery = (v) => {
        handlePageTo(1, 120, channel, v)
    }

    const handleClickPush = () => {
        const passwordConfirmRef = React.createRef()
        PasswordConfirm({
            ref: passwordConfirmRef,
            title: '推送确认',
            message: `本次推送人数 ${data?.total_entries}。 请确认，操作会被记录`,
            externalItems: <>
                <Form.Item label="推送内容" name="content" initialValue="现在签到可获得2积分，快来和我们一起吧！" rules={[{ required: true, message: '内容不能为空' }]}>
                    <Input.TextArea size="large" showCount autoSize />
                </Form.Item>
            </>,
            onOk: ({ password, content }, close) => {
                message.loading({ content: '提交中...', key: "revoking", duration: 3 })

                channel.push("push-user-filters", { query, password, content })
                    .receive("ok", () => {
                        message.success({ content: "已提交推送，勿重复提交", key: "revoking", duration: 3 })
                        close()
                    })
            }
        })
    }

    return channel && <div className="page coin-unachieved-cloak-in" style={{ display: "flex", flexDirection: "column", maxWidth: 1600 }}>
        <h1>当期未签到用户 <Badge count={data?.total_entries} overflowCount={9999999999}></Badge></h1>

        <div className="filter">
            <UserAdvanceFilter channelData={releaseChannels} versionData={versionCodes?.entries} className="form" onChange={handleQuery} fixedValue={{ version_code_gte: 102 }} handles={<>
                <Divider type="vertical" />
                <Button onClick={handleClickPush} type="link" disabled={data?.total_entries <= 0}>推送筛选结果</Button>
            </>} />

        </div>
        <Spin spinning={loading}>
            <div className="content" style={{ display: "flex", flexWrap: "wrap", gap: 20, overflowY: "auto", padding: "10px 0" }}>

                {data?.entries?.map(user => <div style={{ width: 60, height: 80 }}>
                    <UserAvatar key={user.id} nickname={user.nickname} size={60} avatar={user.avatar} id={user.id} vip={user.group === "vip"}></UserAvatar>
                </div>)}
            </div>
        </Spin>
        <Pagination onChange={(page, size) => { handlePageTo(page, size, channel, query) }} total={data?.total_entries} current={data?.page_number || 1} pageSize={data?.page_size || 1} />
    </div>
}



const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CoinUnAchievedCloakIn);