import React from "react";
import { Table, Button, Form, Radio, DatePicker, Tag, Tooltip, Select } from 'antd';
import ButtonGroup from "antd/lib/button/button-group";
import { connect } from 'react-redux';
import dayjs from "dayjs"
import locale from 'antd/es/date-picker/locale/zh_CN';
import './FinanceOrder.css'
import { CrownTwoTone, RestTwoTone, WarningTwoTone, WechatOutlined, ShoppingCartOutlined, AlipayOutlined, AppleFilled, ChromeOutlined, MoneyCollectOutlined, HarmonyOSOutlined } from '@ant-design/icons';
import UserAvatar from "../components/user/UserAvatar";


const { Column } = Table;
const { RangePicker } = DatePicker
class FinanceOrders extends React.Component {
    constructor(props) {
        super(props);
        this.filterFormRef = React.createRef();
    }

    state = {
        channel: null,
        data: {},
        loading: false,
        page: 1,
        size: 10,
        query: {},
        channelCount: {},
        channels: [],
        pricings: {},
        filterValues: { channel: "all", trade_channel_meta: "all", status: "all", meta: "all", pricing_id: "all" }
    }

    loadData(page, size) {
        page = page || this.state.data.page_number
        size = size || this.state.data.page_size
        this.state.channel.push("paged-data", { page, size, query: this.state.query })
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    componentDidMount() {
        const channel = this.props.socket.channel("statistics:finance_order")
        channel.join()
        channel.on("paged-data", (data) => {
            this.setState({ data })
        })
        channel.on("channel-count", (data) => {
            this.setState({ channelCount: data })
        })

        channel.on("pricing-data", ({ data }) => {
            this.setState({ pricings: data })
        })

        channel.on("channel-data", ({ data }) => {
            this.setState({ channels: data })
        })

        const defaultCreatedAt = [dayjs().startOf("date"), dayjs().add(1, 'day').startOf("date")]

        this.filterFormRef.current.setFieldsValue({ created_at: defaultCreatedAt })

        this.setState({ channel, query: { created_at: defaultCreatedAt } }, this.loadData)
    }

    // 搜索
    handleOnSearch(values) {
        let params = {}

        if (values.meta !== 'all') params["meta"] = values.meta
        if (values.channel !== 'all') params["channel"] = values.channel
        if (values.trade_channel_meta !== 'all') params["trade_channel_meta"] = values.trade_channel_meta
        if (values.status !== 'all') params["status"] = values.status
        if (!!values.created_at) params["created_at"] = [
            values.created_at[0].set({ hour: 0, minute: 0, second: 0 }),
            values.created_at[1].set({ hour: 0, minute: 0, second: 0 })
        ]
        if (values.pricing_id !== 'all') params["pricing_id"] = values.pricing_id

        this.setState({ query: params }, () => {
            this.loadData()
        })
    }

    // 重置搜索
    handleSearchReset() {
        this.setState({ query: {}, filterValues: { channel: "all", trade_channel_meta: "all", status: "all", meta: "all", pricing_id: "all" } }, () => { this.loadData() })
    }

    handleRelativeDate(begin, end) {
        this.filterFormRef.current.setFieldsValue({ created_at: [begin, end] })
        this.filterFormRef.current.submit()
    }

    handleOnTableChange(_pagination, _filters, sorter) {
        console.log(sorter)
    }

    handleFilterFormChange(changedValues, allValues) {
        this.setState({ filterValues: { ...allValues, pricing_id: changedValues.pricing_id || "all" } })
    }

    statusIcon(text) {
        switch (text) {
            case "pending": return <span className="icon"><WarningTwoTone className="timeline-clock-icon" twoToneColor="orange" /></span>
            case "finished": return <span className="icon"><CrownTwoTone twoToneColor="#eb2f96" className="timeline-clock-icon" /></span>
            case "cancel": return <span className="icon"><RestTwoTone className="timeline-clock-icon" twoToneColor="silver" /></span>
            default: return <></>
        }
    }

    tradeOrderIcon(text) {
        switch (text) {
            case "acupoint:trade:channel::wechat":
                return <Tooltip title="微信支付" color="green"><span className="icon"><WechatOutlined style={{ color: "green" }} /></span></Tooltip>

            case "acupoint:trade:channel::alipay":
                return <Tooltip title="支付宝" color="#027AFF"><span className="icon"><AlipayOutlined style={{ color: "#027AFF" }} /></span></Tooltip>

            case "zy:trade::balance":
                return <Tooltip title="商城余额" color="orange"><span className="icon"><ShoppingCartOutlined style={{ color: "orange" }} /></span></Tooltip>;
                
            case "acupoint:trade:channel::huawei":
                return <Tooltip title="华为支付" color="orange"><span className="icon"><HarmonyOSOutlined style={{ color: "black" }} /></span></Tooltip>;
            default:
                return <Tooltip title="苹果" color="orange"><span className="icon"><AppleFilled style={{ color: "black" }} /></span></Tooltip>
        }
    }

    currencyIcon(text) {
        switch (text) {
            case "acupoint:trade:currency::RMB":
                return "人民币"

            case "zy:application:acupoint:currency::coin":
                return "积分"
        }
    }


    render() {
        let total = 0
        for (let channel in this.state.channelCount)
            total = total + this.state.channelCount[channel]

        total = Math.round(total)
        return <div className="page trade-order-records">
            <h1 style={{ margin: 0 }}>订单历史</h1>
            <div className="filter">
                <Form name="basic" ref={this.filterFormRef} size="small" layout="inline" onReset={this.handleSearchReset.bind(this)}
                    initialValues={this.state.filterValues}
                    onValuesChange={this.handleFilterFormChange.bind(this)}
                    onFinish={this.handleOnSearch.bind(this)}>

                    <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>

                        <Form.Item name="meta" label="">
                            <Radio.Group buttonStyle="solid" value={this.state.filterValues.meta}>
                                <Radio.Button value="all">全部</Radio.Button>
                                <Radio.Button value="acupoint:shelve::vip">会员</Radio.Button>
                                <Radio.Button value="acupoint:shelve::tongue">舌诊</Radio.Button>
                                <Radio.Button value="acupoint:shelve::hand_diagnosis">手诊</Radio.Button>
                                <Radio.Button value="acupoint:shelve::face_diagnosis">面诊</Radio.Button>
                                <Radio.Button value="acupoint:shelve::corporeity_diagnosis">体质检测</Radio.Button>
                                <Radio.Button value="acupoint:shelve::video_course">视频课程</Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        {this.state.pricings?.[this.state.filterValues?.meta] &&
                            <Form.Item name="pricing_id" label="">
                                <Radio.Group buttonStyle="solid" value={this.state.filterValues.pricing_id}>
                                    <Radio.Button value="all">全部</Radio.Button>
                                    {this.state.pricings?.[this.state.filterValues?.meta].map(({ id, name }) => {
                                        return <Radio.Button value={id} key={id}>{name}</Radio.Button>
                                    })}
                                </Radio.Group>
                            </Form.Item>
                        }

                    </div>
                    <Form.Item name="channel" label={false} className="channel">
                        <Select value={this.state.filterValues.channel} listHeight="60vh">
                            <Select.Option value="all">全部 <span style={{ color: "#eb2f96" }}>{total}</span></Select.Option>
                            {
                                this.state.channels.map(channel => <Select.Option key={channel.key} value={channel.key}>{channel.name} <span style={{ color: "#eb2f96" }}>{this.state.channelCount[channel.key]}</span></Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="status" label="">
                        <Radio.Group buttonStyle="solid" value={this.state.filterValues.status}>
                            <Radio.Button value="all">全部</Radio.Button>
                            <Radio.Button value="finished">已购买</Radio.Button>
                            <Radio.Button value="cancel">手动取消</Radio.Button>
                            <Radio.Button value="pending">下单未支付</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item name="trade_channel_meta" label="">
                        <Radio.Group buttonStyle="solid" value={this.state.filterValues.trade_channel_meta}>
                            <Radio.Button value="all">全部</Radio.Button>
                            <Radio.Button value="acupoint:trade:channel::wechat">微信支付</Radio.Button>
                            <Radio.Button value="acupoint:trade:channel::alipay">支付宝支付</Radio.Button>
                            <Radio.Button value="acupoint:trade:channel::apple">苹果支付</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item name="created_at" label="">
                        <RangePicker format="YYYY-MM-DD" locale={locale} value={this.state.filterValues.created_at} />
                    </Form.Item>

                    <Form.Item>
                        <ButtonGroup>
                            <Button type="primary" htmlType="submit" >查询</Button>
                            <Button type="ghost" htmlType="reset">重置</Button>
                        </ButtonGroup>
                    </Form.Item>

                    <Form.Item label={false} className="quick-date">
                        <ButtonGroup>
                            <Button onClick={() => this.handleRelativeDate(dayjs().startOf("date"), dayjs().add(1, 'day').startOf("date"))}>今日</Button>
                            <Button onClick={() => this.handleRelativeDate(dayjs().add(-1, "day").startOf("date"), dayjs().startOf("date"))}>昨日</Button>
                            <Button onClick={() => this.handleRelativeDate(dayjs().startOf("week"), dayjs().endOf(1, 'week'))}>本周</Button>
                            <Button onClick={() => this.handleRelativeDate(dayjs().add(-1, "week").startOf("week"), dayjs().add(-1, "week").endOf("week"))}>上周</Button>
                            <Button onClick={() => this.handleRelativeDate(dayjs().startOf("month"), dayjs().endOf("month"))}>本月</Button>
                            <Button onClick={() => this.handleRelativeDate(dayjs().add(-1, "month").startOf("month"), dayjs().add(-1, "month").endOf("month"))}>上月</Button>
                            <Button onClick={() => this.handleRelativeDate(dayjs().add(-7, "days").startOf("date"), dayjs())}>最近7天</Button>
                            <Button onClick={() => this.handleRelativeDate(dayjs().add(-1, "month").startOf("date"), dayjs())}>最近30天</Button>
                        </ButtonGroup>
                    </Form.Item>
                </Form>
            </div>

            {
                this.state.data.entries && <Table dataSource={this.state.data.entries} rowKey={item => item.no}
                    onChange={this.handleOnTableChange.bind(this)}
                    rowClassName={obj => `status-${obj.status}`}
                    scroll={{ y: document.documentElement.clientHeight - 410 }}
                    style={{ overflowY: "auto" }}
                    size="middle"
                    pagination={{
                        onChange: (page, size) => { this.loadData(page, size) },
                        total: this.state.data.total_entries,
                        current: this.state.data.page_number,
                        pageSize: this.state.data.page_size,
                        showQuickJumper: true
                    }}>
                    <Column title="状态" align="center" dataIndex="status" width={50} key="status" render={this.statusIcon.bind(this)} />
                    <Column title="订单编号" align="center" dataIndex="no" width={200} key="no" />
                    <Column title="用户" width={80} align="center" dataIndex={["user", "avatar"]} key={["user", "avatar"]} render={(_text, obj) =>
                        <UserAvatar id={obj.user.id} nickname={obj.user.nickname} size={40} avatar={obj.user.avatar} vip={obj.user.group === "vip"} />} />
                    <Column title="购买项目" align="center" dataIndex={["pricing", "name"]} width={100} key={["pricing", "name"]} />
                    <Column title="下单价" align="center" dataIndex="amount" width={100} key="amount" render={(text, obj) => <>
                        <div>{text && (text / 100)}</div>
                        <div style={{ color: "#bbb" }}>{obj.data?.pricing?.message && `(${obj.data?.pricing?.message})`}</div>
                    </>} />
                    <Column title="货币" align="center" dataIndex="currency_meta" width={100} key="currency_meta" render={this.currencyIcon.bind(this)} />
                    <Column title="支付价格" className="paid" align="center" dataIndex="payer_amount" width={100} key="payer_amount" render={(text, obj) => {
                        switch (obj.currency_meta) {
                            case "acupoint:trade:currency::RMB":
                                return text && (text / 100)

                            case "zy:application:acupoint:currency::coin":
                                return text
                        }
                    }} />
                    <Column title="请求支付" align="center" dataIndex="trade_channel_meta" width={100} key="trade_channel_meta" render={this.tradeOrderIcon.bind(this)} />
                    <Column title="渠道" align="center" dataIndex="channel" width={120} key="channel" />
                    <Column title="版本" align="center" dataIndex="app_version" width={100} key="app_version" />
                    <Column title="下单 / 支付时间" align="center" dataIndex="created_at" width={160} key="created_at" render={(text, obj) => {
                        const created_at = dayjs(`${obj.created_at}`)
                        const pay_at = obj.pay_at && dayjs(`${obj.pay_at}`)
                        return <div>
                            <div>{created_at.format("YYYY-MM-DD HH:mm:ss")}</div>
                            {obj.pay_at && <div>{pay_at.format("YYYY-MM-DD HH:mm:ss")}</div>}
                        </div>
                    }} />
                    <Column title="考虑时长 / 下单路径" align="center" dataIndex="app_version" width={250} key="app_version" render={(text, obj) => {
                        const created_at = dayjs(obj.created_at)
                        const active_at = obj.user?.active_at && dayjs(obj.user.active_at)
                        const duration = active_at && dayjs.duration(created_at.diff(active_at))
                        return <div>
                            {obj.user?.active_at && <div>
                                <div>{duration.format('M月D天 HH:mm:ss')}</div>
                            </div>
                            }
                            {obj.tags && <div>
                                {obj.tags.split(",").map(tag => <Tag>{tag}</Tag>)}
                            </div>}
                        </div>
                    }} />
                </Table>
            }
        </div >
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FinanceOrders);