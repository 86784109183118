
import { Table, Form, DatePicker, Button } from "antd";
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import dayjs from "dayjs";
import UserAvatar from "../components/user/UserAvatar"

function DistributedAccountFlow(props) {

    const [channel, setChannel] = useState(null)
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const [query, setQuery] = useState({})
    let [form] = Form.useForm()

    useEffect(() => {
        let _channel = props.socket.channel("finance:distributed:account:flow", { page: 1, size: 10 })
        _channel.join()

        _channel.on("data", ({ data }) => {
            setLoading(false)
            setData(data)
        })

        setChannel(_channel)
        return () => {
            !!_channel && _channel.leave()
        }
    }, [])

    const handlePageTo = (page, size) => {
        channel.push("page-to", { page, size })
    }

    const handleQuery = (values) => {
        setQuery(values)
        channel.push("query", values)
    }

    return <div className="page coin-code-table">
        <h1>财务（积分）服务 - 账户流水</h1>

        <div style={{ marginBottom: 20 }}>
            <Form form={form} layout="inline" onFinish={handleQuery} initialValues={query}>
                <Form.Item label="产生时间段" name="occur_at">
                    <DatePicker.RangePicker></DatePicker.RangePicker>
                </Form.Item>

                <Button.Group>
                    <Button type="primary" htmlType="submit">查询</Button>
                    <Button onClick={() => handleQuery({})} htmlType="reset">清空</Button>
                </Button.Group>
            </Form>
        </div>

        <div>
            <Table dataSource={data?.entries} loading={loading} pagination={{
                onChange: (page, size) => { handlePageTo(page, size) },
                total: data.total_count,
                current: data.page_number,
                pageSize: data.page_size,
                showQuickJumper: true
            }} scroll={{ y: document.documentElement.clientHeight - 380 }}>
                <Table.Column width={100} align="center" title="用户" dataIndex="owner" key="owner" render={v => !!v && <UserAvatar nickname={v.nickname} size={40} avatar={v.avatar} id={v.id} />} />
                <Table.Column width={100} align="center" title="货币" dataIndex={["currency", "name"]} key={["currency", "name"]} />
                <Table.Column width={200} align="center" title="金额" dataIndex="amount" key="amount" render={(v, i) => `${v} ${i.currency.unit}`} />
                <Table.Column width={200} align="center" title="入账后余额" dataIndex="balance" key="balance" render={(v, i) => `${v} ${i.currency.unit}`} />
                <Table.Column align="left" title="交易渠道" dataIndex="trade_channel_meta" key="trade_channel_meta" />
                <Table.Column align="left" title="简要" dataIndex="summary" key="summary" />
                <Table.Column width={200} align="center" title="产生时间" dataIndex="occur_at" key="occur_at" render={(v) => !!v && dayjs(v * 1000).format("YYYY-MM-DD HH:mm")} />
            </Table>
        </div>
    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DistributedAccountFlow);