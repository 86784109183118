import React from 'react'

function RequireRolesOr({ require, children, ...props }) {
    const childrenWithProps = React.Children.map(children, child => {
        return React.cloneElement(child, props)
    })

    return RequireRolesOr.allow(require) ? <>{childrenWithProps}</> : null
}

RequireRolesOr.allow = function (require) {
    const roles = window.store.getState().global.user.roles.map((r) => r.meta)

    if (!(require instanceof Array))
        return false

    if (require.length < 1)
        return true

    if (roles.includes("acupoint:role::manager"))
        return true
    // console.log(require, roles)

    if (roles.filter(x => require.includes(x)).length > 0) {
        return true
    }

    return false
}

export default RequireRolesOr