import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Button, List } from "antd"
import { } from '@ant-design/icons'
import { Menu as MenuAction } from '../../store/global/actions'
import QueryForm from "./QueryForm"
import { useParams } from "react-router-dom"
import { auditModal } from "./AuditModal"
import PostListItem from "./component/PostListItem"
import CommentListItem from "./component/CommentListItem"

function Reports(props) {
    const [channel, setChannel] = useState(null)
    const [simulatorChannel, setSimulatorChannel] = useState(null)
    const [loading, setLoading] = useState(true)
    let [data, setData] = useState({})
    // reference_type举报关联的类型， 如 post、comment， status状态，pending、settle
    const { reference_type, status } = useParams()
    const [queryParams, setQueryParams] = useState({ type: "" })

    props?.setActiveKey("COMMUNITY")

    useEffect(() => {
        let _channel = props.socket.channel("community:reports", { ...queryParams, status, reference_type, type: null })
        let _simulatorChannel = props.socket.channel("community:simulator")
        _simulatorChannel.join()
        _channel.join()
        setChannel(_channel)
        setSimulatorChannel(_simulatorChannel)

        _channel.on("data", ({ data }) => {
            setData(data);
            setLoading(false);
        })

        return () => {
            if (!!_channel) _channel.leave()
            if (!!_simulatorChannel) _simulatorChannel.leave()
        }
    }, [reference_type, status])

    const handlePageTo = (page, size) => {
        channel.push("page-to", { page, size })
    }

    const handleQuerySubmit = (values) => {
        values = { ...values, type: values.type === "" ? null : values.type }
        setQueryParams(values)
        channel.push("query", values)
    }

    const listItem = (item) => {
        switch (item.reference_type) {
            case "post":
                return <PostListItem item={item} simulatorChannel={simulatorChannel} channel={channel} />
            case "comment":
                return <CommentListItem item={item} simulatorChannel={simulatorChannel} channel={channel} />

            default:
                break;
        }
    }

    return <div className="page operation version">
        <h1>举报发帖</h1>

        <QueryForm onSearch={handleQuerySubmit} values={queryParams} />
        <br />
        <div style={{ minWidth: 1500, overflowY: "auto", overflowX: "hidden" }}>
            <List loading={loading} dataSource={data.entries} itemLayout="vertical" key="versions-table" rowKey={item => item.id} size="middle" pagination={{
                onChange: (page, size) => { handlePageTo(page, size) },
                total: data.total_entries,
                current: data.page_number,
                pageSize: data.page_size || 0,
                showQuickJumper: true
            }}
                grid={{ gutter: 16, column: 2 }}
                renderItem={(item) => {
                    return listItem(item)
                }} />
        </div>
    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Reports)