

function ContentType() {

}

ContentType.toText = (type) => {
    switch (type) {
        case "video_course": return "视频课程";
        case "acupoint_ancestor": return "经典穴位";
        case "acupoint_dongs": return "经典穴位";
        case "meridian_ancestor": return "经典经络";
        case "food": return "食物";
        case "herb": return "中草药";
        case "term_terminology": return "术语";
        case "term_doctor": return "医家";
        case "term_ancient_book": return "典籍";
        case "term_diet_therapy": return "药膳";
        case "term_basic_theory": return "基础理论";
        case "term_regimen": return "养生";
        default: return "未知";
    }
}

export default ContentType