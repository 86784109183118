import { Modal, message, Button } from "antd"
import TopicForm from '../components/TopicForm'
import reducer from '../../store';
import { Provider } from 'react-redux';
import { legacy_createStore as createStore } from "redux"
import { PlusSquareTwoTone } from "@ant-design/icons";
const store = createStore(reducer)

let addTopicModal = (channel, onCreated = () => null) => {
    let formInstance = null
    Modal.confirm({
        width: 600,
        title: '添加新话题',
        content: <Provider store={store}><TopicForm setForm={(form) => formInstance = form} /></Provider>,
        onOk: (close) => {
            formInstance
                .validateFields()
                .then(values => {
                    channel.push("add-draft", { ...values })
                        .receive("ok", () => {
                            message.success("创建成功，在列表中编辑")
                            onCreated()
                            close()
                        })
                })
            return false
        }
    });
}

export { addTopicModal }


function AddTopicButton({ channel, onCreated = () => null }) {
    return <Button icon={<PlusSquareTwoTone />} type="link" onClick={() => addTopicModal(channel, onCreated)}>
        新增
    </Button>
};

export default AddTopicButton