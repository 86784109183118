import React, { useState } from "react"
import { Form, InputNumber, Input, ConfigProvider } from "antd"
import { PlusOutlined } from '@ant-design/icons'
import UploadComponent from "../../../components/UploadComponent";
import configFunc from "../../../config";
import zhCN from 'antd/lib/locale/zh_CN';

function NotificationChannelForm({ form, data, ...props }) {
    const [icon, setIcon] = useState(data?.icon)

    const handleUploadSuccess = ([file]) => {
        form.setFieldsValue({ icon: configFunc.ResourceUrl(file.url) })
        setIcon(configFunc.ResourceUrl(file.url))
    }

    return <ConfigProvider locale={zhCN}>
        <Form form={form} name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} autoComplete="off">
            <Form.Item label="频道名" name="name" rules={[{ required: true, message: '必填' }]}>
                <Input placeholder="如: 系统 会员 社区 点赞" />
            </Form.Item>
            <Form.Item label="唯一键" name="key" rules={[{ required: true, message: '必填' }]}>
                <Input placeholder="如：SYSTEM COMMUNITY COMMENT" disabled={!!data?.id} />
            </Form.Item>
            <Form.Item label="群组排序" name="index" rules={[{ required: true, message: '必填' }]} initialValue={0} help="值越大越靠前">
                <InputNumber min={0} step={1} />
            </Form.Item>

            <Form.Item label="频道图标" rules={[{ required: true, message: '不能为空' }]}>
                <UploadComponent path={["notification", "channel"].join("/")} bucket="acupoint-resource" maxCount={1}
                    listType="picture-card" showUploadList={false}
                    onChange={(fileList) => handleUploadSuccess(fileList)}>
                    {icon ? <img src={icon} alt="avatar" style={{ width: '100%' }} /> : <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>拖到这里</div>
                    </div>}
                </UploadComponent>
            </Form.Item>

            <Form.Item label="图标地址" name="icon">
                <Input disabled />
            </Form.Item>
        </Form>
    </ConfigProvider>
}

export default NotificationChannelForm;