import React, { useState, useEffect } from "react"
import { Pagination, Timeline, Tag } from 'antd'
import useLocalStorageState from 'use-local-storage-state'
import dayjs from 'dayjs'


// 当评价页数变更时执行
function handleOnlineLogPagedDataPageChange(props, setOnlineLogPagedData, setfilterAuthenticateBy, page, pageSize = 10, by) {
    props.pushEvent("online-log-list", { page, page_size: pageSize })
        .receive("ok", (data) => {
            setOnlineLogPagedData(data)
            setfilterAuthenticateBy(by)
        })
}


function OnlineLogPagedDatas(props) {
    // 标签记录
    const [filterAuthenticateBy, setfilterAuthenticateBy] = useLocalStorageState('filterAuthenticateBy', undefined)
    // 校验日志数据
    const [onlineLogPagedData, setOnlineLogPagedData] = useState({})


    useEffect(() => {
        handleOnlineLogPagedDataPageChange(props, setOnlineLogPagedData, setfilterAuthenticateBy, 1, 10, filterAuthenticateBy)
    }, [props.userId])

    return <>
        <Pagination current={onlineLogPagedData?.page_number} showQuickJumper size="small" total={onlineLogPagedData?.total_count} pageSize={onlineLogPagedData?.page_size || 10} onChange={(page, size) => handleOnlineLogPagedDataPageChange(props, setOnlineLogPagedData, setfilterAuthenticateBy, page, size, filterAuthenticateBy)} />
        <br></br>
        <Timeline>
            {!!onlineLogPagedData.entries && onlineLogPagedData.entries.map((log) => {
                const duration = dayjs.duration(log.seconds * 1000).$d
                return <Timeline.Item key={log.id}>
                    <p style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>{dayjs(log.online_at).format("YYYY-MM-DD HH:mm")}</span>
                        <span>{duration.hours} 小时 {duration.minutes} 分钟 {duration.seconds} 秒</span>
                    </p>
                    <span style={{ display: "flex", justifyContent: "space-between" }}>
                        <span><Tag>{log.app_info?.version}</Tag> {log.app_info?.device_brand && <Tag>{log.app_info?.device_brand}</Tag>}<Tag>{log.app_info?.device_model}</Tag> </span>
                    </span>
                </Timeline.Item>
            })}
        </Timeline>
        <Pagination current={onlineLogPagedData?.page_number} showQuickJumper size="small" total={onlineLogPagedData?.total_count} pageSize={onlineLogPagedData?.page_size || 10} onChange={(page, size) => handleOnlineLogPagedDataPageChange(props, setOnlineLogPagedData, setfilterAuthenticateBy, page, size, filterAuthenticateBy)} />
    </>
}

export default OnlineLogPagedDatas;