import React from "react";
import { Menu, Tag } from 'antd';
import { DashboardTwoTone, ProjectTwoTone, HighlightTwoTone } from '@ant-design/icons';
import { useHistory, useLocation } from "react-router-dom";
import RequireRolesOr from '../../components/require_roles_or'

export default function SideMenu() {
    const location = useLocation()
    const history = useHistory()

    const linkTo = (path) => {
        history.push(path)
    }

    return <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::editor"]} >
        <Menu style={{ width: 256 }} defaultSelectedKeys={[location.pathname]} mode="inline"    >
            <Menu.Item key="/sqec/dashboard" onClick={() => linkTo("/sqec/dashboard")} icon={<DashboardTwoTone />}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>仪表盘</span>
                    <span><Tag color="blue">实时</Tag></span>
                </div>
            </Menu.Item>
            <Menu.ItemGroup key="question" title="题库">
                <Menu.Item key="/sqec/question/list" onClick={() => linkTo("/sqec/question/list")} icon={<ProjectTwoTone />} disabled>列表</Menu.Item>
                <Menu.Item key="/sqec/correction/list" onClick={() => linkTo("/sqec/correction/list")} icon={<HighlightTwoTone />} disabled> 修订反馈</Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="question_set" title="题集" disabled>
                <Menu.Item key="/sqec/question/set" disabled>列表</Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="plan" title="学习计划" disabled>
                <Menu.Item key="/sqec/plan" disabled>列表</Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="course" title="课程">
                <Menu.Item key="/sqec/course/list" onClick={() => linkTo("/sqec/course/list")} icon={<ProjectTwoTone />}>

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>用户当前课程</span>
                        <span><Tag color="blue">实时</Tag></span>
                    </div>
                </Menu.Item>
                <Menu.Item key="Elixir.AcupointWeb.SQEC.ShowCaseListLive" disabled>陈列柜</Menu.Item>
            </Menu.ItemGroup>
        </Menu>
    </RequireRolesOr>
}