
import { PlusSquareOutlined, SaveOutlined, DeleteOutlined, LeftSquareTwoTone, RightSquareTwoTone } from '@ant-design/icons';
import { useState } from 'react';
import { message, Modal, Button } from 'antd'
import Medium from './medium'

const ButtonGroup = Button.Group

function Group({
    groupID,
    tabName,
    groupChildren: medicineInfoList,
    height,
    editable = false,
    channel,
    ownerType, // 所属类别
    ownerID,  // 所属的id
    onClickMoveLeft = () => null,
    onClickMoveRight = () => null
}) {
    const [children, setChildren] = useState(medicineInfoList)
    // 已修改内容
    const [dirty, setDirty] = useState(false)

    // 删除组
    const handleDeleteGroup = (title, id) => {
        Modal.confirm({
            title: `确定删除「${title}」吗`,
            content: "删除无法找回，请确认",
            onOk: (close) => {
                channel.push("delete-group", { id }).receive("ok", () => {
                    message.success("删除成功")
                    close()
                })

                return false
            }
        })
    }

    const handleOnSubmit = () => {
        channel.push("save-group", { parent_id: groupID, children, owner_type: ownerType, owner_id: ownerID })
            .receive("ok", () => {
                message.success("保存成功")
                setDirty(false)
            })
    }

    // 处理添加块
    const handleAddBlock = (index = -1) => {
        const new_children = [...children]
        new_children.splice(index + 1, 0, null)

        setChildren(new_children)
    }

    // 处理媒体保存
    const handleOnMeiumSave = (index, value) => {
        setDirty(true)
        const new_children = [...children]
        new_children.splice(index, 1, value)

        setChildren(new_children)
    }

    // 媒体点击删除时
    const handleOnMediumRemove = (index) => {
        setDirty(true)
        const new_children = [...children]
        new_children.splice(index, 1)
        setChildren(new_children)
    }

    // 上移
    const handleClickUp = (index) => {
        if (index < 1) return;
        setDirty(true)
        const new_children = [...children]

        const element = new_children[index];
        new_children.splice(index, 1);
        new_children.splice(index - 1, 0, element);
        setChildren(new_children)
    }

    // 下移
    const handleClickDown = (index) => {
        const new_children = [...children]

        if (index > new_children.length - 1) return;
        setDirty(true)
        const element = new_children[index];
        new_children.splice(index, 1);
        new_children.splice(index + 1, 0, element);
        setChildren(new_children)
    }

    return <div key={`${groupID}-${ownerType}-${ownerID}-add-block-${tabName}`} className="group-content" style={{ height: height || 500 }}>
        {
            editable && <div className="function-block">
                <ButtonGroup size='small' style={{ display: "flex" }}>
                    <Button className='left-move' onClick={onClickMoveLeft} icon={<LeftSquareTwoTone />}>左移</Button>
                    <Button className='right-move' onClick={onClickMoveRight} icon={<RightSquareTwoTone />}>右移</Button>
                </ButtonGroup>
                <ButtonGroup size='small' style={{ display: "flex" }}>
                    <Button className='add' onClick={handleAddBlock} icon={<PlusSquareOutlined />}>顶部添加块</Button>
                    {
                        dirty &&
                        <Button className='save' icon={<SaveOutlined />} onClick={handleOnSubmit} >保存组</Button>

                    }
                    <Button className='delete' icon={<DeleteOutlined />} onClick={() => handleDeleteGroup(tabName, groupID)}>删除组</Button>
                </ButtonGroup>
            </div>
        }

        {
            children.map((medium, index) => {
                return <>
                    <Medium
                        ownerType={ownerType}
                        ownerID={ownerID}
                        channel={channel}
                        key={`${ownerType}-${ownerID}-${medium?.name || Math.random()}-${index}`}
                        index={index}
                        medium={medium}
                        editable={editable}
                        onMoveUp={() => handleClickUp(index)}
                        onMoveDown={() => handleClickDown(index)}
                        onDelete={() => handleOnMediumRemove(index)}
                        onSave={(value) => handleOnMeiumSave(index, value)} />
                    {editable && <Button key={`${ownerType}-${ownerID}-${medium?.name || Math.random()}-${index}-after`} onClick={() => handleAddBlock(index)} className='add-block-line' block type='link'>
                        <PlusSquareOutlined /> 这里添加块
                    </Button>}
                </>
            })
        }
    </div>
}

export default Group;