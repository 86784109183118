import React from "react"
import { connect } from 'react-redux'
import { Menu as MenuAction } from '../store/global/actions'

import Revenue from "./Revenue"

class Dashboard extends React.Component {
    state = {
        channel: null,
        data: [],
        editable: false,
        loading: false,
        perHourChannelAmountChartData: null
    }

    joinChannel(props) {
        this.setState({ loading: true })
        const channel = this.props.socket.channel("ops:dashboard", {})
        channel.join()
            .receive("ok", () => { })
        this.setState({ channel })
    }

    componentDidMount() {
        this.props.setActiveKey("Dashboard")
        this.joinChannel(this.props)
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    // handleSetCharData(chartData, key, data) {
    //     const newChartdata = { ...chartData }
    //     newChartdata[key] = data
    //     return newChartdata;
    // }

    // // 渠道每小时营收额
    // amountByChannelAndHour(min, max, cb = () => null) {
    //     this.state.channel.push("chart-group-channel-per-hour", { time: [min, max] })
    //         .receive("ok", ({ data }) => { cb(data) })
    // }

    // amountByChannelAndHourToday() {
    //     this.amountByChannelAndHour(dayjs().add(-1, "day").format("YYYY-MM-DD HH:00:00"), dayjs().add(1, "hour").format("YYYY-MM-DD HH:00:00"), (data) => {
    //         const labels = data.map((i) => i.time).filter((v, i, s) => s.indexOf(v) === i)

    //         // 给渠道分组
    //         const channelGroup = data.reduce((acc, item) => {
    //             const bcc = { ...acc }
    //             bcc[item.channel] = bcc[item.channel] || {}
    //             // 简化数据，key是时间，value是金额
    //             bcc[item.channel][item.time] = Number(item.amount || 0) / 100
    //             return bcc
    //         }, {})

    //         // 组合成图表数据
    //         const datasets = Object.keys(channelGroup).map((key, i) => {
    //             return {
    //                 type: 'bar',
    //                 label: key,
    //                 // 有可能没值，则默认是0
    //                 data: labels.map((time) => channelGroup[key][time] || 0),
    //                 backgroundColor: Paired12[i % 12]
    //             }
    //         })

    //         // 计算累计值
    //         const sumAmount = labels.map((time) => {
    //             return Object.keys(channelGroup).reduce((amount, channelName) => {
    //                 return amount + (Number(channelGroup[channelName][time] || 0))
    //             }, 0)
    //         })

    //         datasets.push({
    //             type: 'line',
    //             label: '小时总计营销额',
    //             borderColor: 'rgb(255, 99, 132)',
    //             borderWidth: 2,
    //             fill: false,
    //             data: sumAmount
    //         })

    //         this.setState({
    //             perHourChannelAmountChartData: { labels: labels.map(time => dayjs(time).format("DD日HH时")), datasets }
    //         })
    //     })
    // }

    // mainChartInit() {
    //     return Object.keys(this.state.perHourChannelAmountChartData).map((key) => this.state.perHourChannelAmountChartData[key])
    // }

    render() {
        return this.state.channel && <div style={{ margin: 10 }}>
            <div>
            </div>
            <div>
                <Revenue channel={this.state.channel} />
            </div>
        </div>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);