import React, { useState } from "react";
import { Button, Form, Input, TreeSelect } from 'antd';


function QueryForm(props) {
    const [form] = Form.useForm()

    form.setFieldsValue(props.values)

    return <div>
        <Form form={form} autoComplete="off" layout="inline" onFinish={props.onSearch} initialValues={props.values} style={{ display: "flex", alignItems: "center" }}>
            <Form.Item name="name" label="名称">
                <Input placeholder="模糊查询"></Input>
            </Form.Item>

            <Button.Group>
                <Button type="primary" htmlType="submit">查询</Button>
                <Button onClick={() => props.onSearch({})} htmlType="reset">清空</Button>

            </Button.Group>
            {props.children}
        </Form>
    </div>
}

export default QueryForm