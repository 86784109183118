import { Button, Select, message, Form, Input, InputNumber, TreeSelect } from "antd"
import useLocalStorageState from "use-local-storage-state";

const { TreeNode } = TreeSelect;

function AlbumForm(props) {
    const [formCateID, setFormCateID] = useLocalStorageState('video-course-form-cate_id', null)
    const album = props.album
    const formItemLayout = {
        labelCol: {
            xs: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 4,
                offset: 6,
            },
        },
    };

    const handleFormSubmit = (values) => {
        props.channel.push(props.album?.id ? "edit:album" : "create:album", values)
            .receive("ok", () => {
                message.success("创建成功")
                props.closeModal && props.closeModal()
            })
    }

    // 分类树的选项
    const cateTreeToSelectOption = tree => {
        tree = tree || []

        if (tree.length > 0) {
            return tree.map(({ id, name, children }) => {
                return <TreeNode key={id} value={id} title={name}>{cateTreeToSelectOption(children)}</TreeNode>
            })
        } else {
            return null
        }
    }

    return <>
        <Form {...formItemLayout} onFinish={handleFormSubmit}>
            <Form.Item name="name" initialValue={album?.name} label="标题" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="py" initialValue={album?.py} label="拼音" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="index" initialValue={album?.index} label="序号" help="逆序，值越大越在前。不填写默认为最大值">
                <InputNumber />
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::author"]} initialValue={album?.labels.author} label="作者" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="cate_ids" label="分类" help="多选" initialValue={formCateID} rules={[{ required: true, message: '不能为空' }]}>
                <TreeSelect multiple listHeight="60vh" treeDefaultExpandAll onChange={(v) => setFormCateID(v)}>
                    {cateTreeToSelectOption(props.cateTree)}
                </TreeSelect>
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::lesson_count"]} initialValue={album?.labels.lesson_count || 0} label="课程数量" rules={[{ required: true }]}>
                <InputNumber min={0} size="small" />
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::playback"]} initialValue={album?.labels?.playback} label="播放次数" rules={[{ required: true }]}>
                <InputNumber min={0} size="small" />
            </Form.Item>
            <Form.Item name="permission" initialValue={album?.permission || "vip"} label="权限" rules={[{ required: true }]}>
                <Select >
                    <Select.Option value="free">免费</Select.Option>
                    <Select.Option value="vip">会员</Select.Option>
                    <Select.Option value="purchase">单独购买</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="state" initialValue={album?.state || "draft"} label="状态" rules={[{ required: true }]}>
                <Select >
                    <Select.Option value="draft">草稿</Select.Option>
                    <Select.Option value="publish">发布</Select.Option>
                    <Select.Option value="complain">举报下架</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="app_version_code" initialValue={album?.app_version_code || 56} label="APP最低版本" rules={[{ required: true }]} help="低于此版本的APP，看不到本课程">
                <Select>
                    <Select.Option value={56}>v2.6.4</Select.Option>
                    <Select.Option value={58}>v2.6.6</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::ip_belongs"]} initialValue={album?.labels.ip_belongs} label="IP归属地" rules={[{ required: true }]}>
                <Input size="small" placeholder="比如：海南省、广东省" />
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::summary"]} initialValue={album?.labels.summary} label="简略信息" rules={[{ required: true }]}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" >
                    保存
                </Button>
            </Form.Item>
        </Form>
    </>
}

export default AlbumForm