import React from "react"
import { Badge, Avatar } from "antd"
import { HeartTwoTone } from '@ant-design/icons'
import dayjs from "dayjs"
import Config from "../../../config";
import LocalTimeAgo from "../../../components/local_time_ago";
import { connect } from "react-redux";
import { userInformationModal } from "../../../store/global/actions"

function CommentAncestors({ comments = [], userShow, report }) {
    return comments.map(comment => (
        <div className="comment" key={comment.id} style={{ background: comment.id === report.reference.id ? "#ffffdd" : "transparent" }}>
            <div className="avatar">
                <div className="owner" onClick={() => userShow(comment.owner?.owner_id)}>
                    <Badge className="user-avatar" count={comment.owner?.group === "vip" ? <CrownTwoTone twoToneColor="#eb2f96" rotate={45} /> : null} style={{ fontSize: 16 }}>
                        <Avatar src={Config.AvatarUrl(comment.owner?.avatar)} />
                    </Badge>

                    &nbsp;
                    {comment.owner?.name || "匿名用户"}
                    &nbsp;
                    {comment.reply_to_owner && <span>回复 {comment.reply_to_owner.name || "匿名用户"}</span>}
                </div>
            </div>
            <div style={{ paddingLeft: 36 }} >
                <div style={{ wordWrap: "break-word" }}>{comment.content}</div>
                <div style={{ color: "#afafaf", padding: "10px 0", display: "flex" }}>
                    <span><LocalTimeAgo date={dayjs(comment.inserted_at)} /> {comment.ip_belongs_text || "未知"}</span>
                    &nbsp;
                    <span><HeartTwoTone twoToneColor="red" /> {comment.like_count}</span>
                </div>
            </div>

        </div>
    ))

}

const mapStateToProps = ({ global }) => {
    return {
        onlineUsers: global.onlineUsers
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        userShow: (userId) => {
            dispatch(userInformationModal.show(userId))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentAncestors);