import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Button, Modal, Table, Form, Radio, message } from "antd"
import { Menu as MenuAction } from '../../store/global/actions'
import { Provider } from 'react-redux';

import { PlusSquareTwoTone } from "@ant-design/icons"
import RuntimeSchedulerForm from "./components/RuntimeSchedulerForm";

import CronParser from "cron-parser"
import dayjs from "dayjs";

const { Column } = Table

function RuntimeScheduler(props) {
    let [data, setData] = useState([])
    let [channel, setChannel] = useState()
    let [form] = Form.useForm()

    props?.setActiveKey("SETTING-RUNTIME-SCHEDULER")

    useEffect(() => {
        let _channel = props.socket.channel("ops:runtime_scheduler")
        _channel.join()
        setChannel(_channel)

        _channel.on("data", ({ data }) => { setData(data) })

        return () => {
            if (!!_channel) _channel.leave()
        }
    }, [])

    const handleChangeField = (id, field, value) => {
        channel.push("change-field", { id, field, value })
    }

    const formModal = (scheduler = {}) => {
        if (!!scheduler.id) {
            form.setFieldsValue(scheduler)
        }
        Modal.confirm({
            width: 800,
            title: `表单`,
            content: <Provider store={store}>
                <RuntimeSchedulerForm form={form} channel={channel} scheduler={scheduler} />
            </Provider>,
            cancelText: "取消",
            okText: "提交",
            onCancel: () => {
                form.resetFields();
            },
            onOk: (close) => {
                form
                    .validateFields()
                    .then(values => {
                        if (!scheduler.id) {
                            channel.push("create", values)
                                .receive("ok", (resp) => {
                                    close()
                                    message.success("创建成功")
                                    form.resetFields();
                                })
                        } else {
                            channel.push("update", { ...values, id: scheduler.id })
                                .receive("ok", (resp) => {
                                    close()
                                    message.success("更新成功")
                                    form.resetFields();
                                })
                        }
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    return <div className="page">
        <h1>定时调度</h1>

        <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
            <Button icon={<PlusSquareTwoTone />} type="link" onClick={() => formModal()}>
                新增调度器
            </Button>
        </div>

        <Table dataSource={data} pagination={false} bordered rowKey={item => item.id} style={{overflowY: "auto"}}>
            <Column title="名称" dataIndex="name" key="name" align="center" />
            <Column title="唯一标识符" dataIndex="key" key="key"  />
            <Column title="简介" dataIndex="summary" key="summary" />
            <Column title="时刻表" dataIndex="cron" key="cron" width={120} align="center" />
            <Column title="上次执行时间(估算)" width={180} align="center" render={(_, v) => {
                if (CronParser.parseString(v.cron).expressions.length > 0) {
                    let interval = CronParser.parseExpression(v.cron, { tz: "Asia/Chongqing" })
                    return dayjs(interval.prev()).format("YYYY-MM-DD HH:mm")
                } else {
                    return ""
                }
            }} />
            <Column title="下次执行时间(估算)" width={180} align="center" render={(_, v) => {
                if (CronParser.parseString(v.cron).expressions.length > 0) {
                    let interval = CronParser.parseExpression(v.cron, { tz: "Asia/Chongqing" })
                    return dayjs(interval.next()).format("YYYY-MM-DD HH:mm")
                } else {
                    return ""
                }
            }} />
            <Column title="状态" dataIndex="enable" key="enable" align="center" width={130} render={(value, item) =>
                <>
                    <div>
                        <Radio.Group onChange={(e) => handleChangeField(item.id, "enable", e.target.value)} size="small" value={value} buttonStyle="solid">
                            <Radio.Button value={false}>禁用</Radio.Button>
                            <Radio.Button value={true}>启用</Radio.Button>
                        </Radio.Group>
                    </div>
                </>} />
            <Column title="操作" render={(_, item) => {
                return <div>
                    <Button type="link" onClick={() => formModal(item)}>编辑</Button>
                </div>
            }}></Column>
        </Table>
    </div>
}

const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RuntimeScheduler)