import { connect } from 'react-redux';
import { Input, Button, message } from 'antd'
import React from "react";
import Editor from './components/editor'
import edjsParser from '../components/editjs_html'
import { LeftOutlined, SaveOutlined } from '@ant-design/icons';

import 'react-device-emulator/lib/styles/style.css';
import './ArticleForm.css'

import Mobile from '../images/mobile.svg';
import Tablet from '../images/tablet.svg';
import LocalTimeAgo from "../components/local_time_ago";

const DEFAULT_INITIAL_DATA = (text = "文章标题请修改此处", content = null) => {
    let data = {
        "time": new Date().getTime(),
        "blocks": [
            {
                "type": "header",
                "data": {
                    "text": text,
                    "level": 2
                }
            }
        ]
    }

    if (!!content) data["blocks"].push({
        "type": "raw",
        "data": {
            "html": content
        }
    })

    return data
}

class ArticleForm extends React.Component {
    state = {
        columnTitle: null,
        columnTitleEditable: true,
        // 编辑的时候获取的文章的值
        article: {},
        articleTitle: null,
        articleTitleEditable: true,
        content: null,
        submitting: false,
        type: "mobile"
    }

    static getDerivedStateFromProps(props, state) {
        const columnTitle = props.match?.params?.column_title
        const articleTitle = props.match?.params?.article_title
        // 编辑文章
        if (!!columnTitle && !!articleTitle)
            return { columnTitle, columnTitleEditable: false, articleTitle, articleTitleEditable: false }
        // 栏目新增文章
        else if (!!columnTitle)
            return { columnTitle, columnTitleEditable: false }
        else
            return null
    }

    componentDidMount() {
    }

    loadArticle(articleTitle) {
        this.props.jsonRequest.get(`/api/cms/operation/${this.state.columnTitle}/${articleTitle}`)
            .then(response => response.json())
            .then(({ data }) => {
                let editorJSContent = data?.data?.editorjs

                if (!data.data?.editorjs) {
                    editorJSContent = DEFAULT_INITIAL_DATA(articleTitle, data.content)
                    message.error("此文章不能使用此编辑器，请重新编辑")
                }

                this.setState({ content: editorJSContent, article: data })
                this.editorInstance.render(editorJSContent)
            })
    }

    handleSetEditor(instance) {
        this.editorInstance = instance

        if (!!this.state.articleTitle) this.loadArticle(this.state.articleTitle)
    }

    onFinish() {
        if (!this.state.columnTitle) return message.error("栏目名不能为空")
        if (!this.state.articleTitle) return message.error("文章名不能为空")
        if (!this.state.content) return message.error("文章内容不能为空")

        this.setState({ submitting: true })
        const articleTitle = this.state.articleTitle
        const content = this.state.content

        // 原始文章, 编辑时使用
        const originalArticle = this.state.article || {}
        
        this.props.jsonRequest.post(`/api/cms/operation/${this.state.columnTitle}/${articleTitle}`, {
            ...originalArticle,
            content: edjsParser.parse(content).join(""),
            type: "blocks",
            data: { ...originalArticle.data, editorjs: content }
        })
            .then(response => {
                this.setState({ submitting: false })
                return response.json()
            })
            .then(({ data, message: msg }) => {
                if (!!msg) return message.warn(msg)

                if (!!this.state.articleTitle)
                    message.success("修改文章成功")
                else {
                    message.success("创建文章成功")
                    this.editorInstance.render(DEFAULT_INITIAL_DATA("再来一篇"))
                }
            })
    }

    onContentChange(newData) {
        this.setState({ content: newData })
    }

    render() {
        return <div className="page cms-article-new">
            <div className="editor-review">
                <div className="editor">
                    <div className="actions">
                        <Button onClick={() => this.props.history.goBack()} type="link" icon={<LeftOutlined />}
                            size={"large"}>返回</Button>
                        <Button loading={this.state.submitting} type="link" size={"large"}
                            onClick={this.onFinish.bind(this)}>保存 <SaveOutlined /></Button>
                    </div>
                    <Input className="column-title" placeholder="栏目名" readOnly={!this.state.columnTitleEditable}
                        bordered={false} onChange={e => this.setState({ columnTitle: e.target.value })}
                        value={this.state.columnTitle} size="middle" />
                    <Input className="article-title" placeholder="文章名" readOnly={!this.state.articleTitleEditable}
                        bordered={false} onChange={e => this.setState({ articleTitle: e.target.value })}
                        value={this.state.articleTitle} size="middle" />
                    <Editor instanceRef={instance => this.handleSetEditor(instance)}
                        onChange={this.onContentChange.bind(this)} />
                </div>
                {this.state.content &&
                    <div className="review">
                        <div className="device-emulator-container">
                            <div className="button-group">
                                <button data-testid="mobile" className="icon-button"
                                    onClick={() => this.setState({ type: "mobile" })}>
                                    <img alt="mobile" src={Mobile} className="" /></button>
                                <button data-testid="tab" className="icon-button"
                                    onClick={() => this.setState({ type: "tab" })}>
                                    <img alt="tab" src={Tablet} className="" /></button>
                            </div>
                            <div className={`${this.state.type}-chrome`}>
                                <div className={`frame ${this.state.type}-frame`}>
                                    <div className="title">{this.state.articleTitle || "文章标题"}</div>
                                    <div dangerouslySetInnerHTML={{ __html: edjsParser.parse(this.state.content).join("") }} />
                                    <div className="meta"
                                        style={{ color: "#888", fontSize: 12, textAlign: "right" }}>最近更新：<LocalTimeAgo
                                            date={new Date(this.state.content.time)} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(ArticleForm)

