import React from "react";
import {
    MainContainer, ChatContainer, ConversationHeader, MessageSeparator, ExpansionPanel,
    MessageList, Message, MessageInput, ConversationList, Conversation, Sidebar, Avatar
} from '@chatscope/chat-ui-kit-react';
import { Tag, Modal, Button, Form, Switch } from "antd"
import { connect } from "react-redux";
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import LocalTimeAgo from '../components/local_time_ago'
import { CrownTwoTone, UserOutlined, CloseCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { userInformationModal } from "../store/global/actions"
import config from '../config'
import dayjs from 'dayjs'
import './CustomerService.css'

import { CustomerOperationChannel as COAction } from "../store/global/actions"
import { Input } from "antd";

const imageBase64Upload = (base64Str, token) => {
    const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` }, body: JSON.stringify({ file: base64Str }) };
    return fetch(config.HttpEndpoint("/api/attach/base64/image"), requestOptions)
}

const simplifyMessageContent = (message) => {
    let info = ""
    switch (message.content.type) {
        case "image":
            info = "[图片]"
            break;
        case "text":
            info = message.content.content
            break;
        default:
            break;
    }
    return info
}

// const snapReply = [
//     "您好，有什么可以帮助您的吗？",
//     "感谢您的支持，祝您使用愉快！",
//     "您的建议已收到，感谢您的支持！",
//     "节假日期间客服可能不在线，回复不及时，请您谅解！",
//     "客服工作时间是工作日的9:00至21:00，其余时间无法及时回复请您谅解",
//     "谢谢您，您的反馈建议我们已收到，同时会纳入我们后续的工作计划中进行综合评估，再次感谢您的支持，祝您生活愉快！"
// ]

// const snapReplyQuestion = [
//     "客服只负责回答使用APP时遇到的功能性问题，没有医师和教师执照，无法回答学习和治疗上的问题哦，也无法提供在线教学或看诊，技术咨询，请您谅解～",
//     "开通会员后，可在会员的有效期内使用目前APP所有功能，但受极少数合作方要求，此类用户上传提供的课程可能要额外付费（会员享受优惠价），AI诊断（如手诊、舌诊、面诊等）限定为每日使用3次，除此之外目前无其他限制。",
//     "目前支持设备包含安卓、鸿蒙、苹果系统，手机与平板皆兼容，但是同类型（手机、平板）设备只能同时有一台设备在线，望知悉～",
//     "为了满足多种学习需要，男性模型的经络循行是按照《黄帝内经》中的描述制作的；女性模型的经络循行是按照现代经络图的标准制作的。"
// ]

// const snapReplyPay = [
//     "目前有活动，购买永久会员后在手机应用市场提交5星好评（不少于10个字）并截图发给我们，可以给您返现15元。",
//     "请在 ‘我的-设置-关于’ 查看用户协议，里面有退款的相关规定哦，请您确认下是否符合。",
//     "请在微信或支付宝中将您的支付记录截图，并发给我们，我们帮您查找。",
//     "苹果用户请这样操作：打开Appstore -> 右上角点自己的头像 -> 继续点击自己的头像 ->  滑到下面点击“购买记录” ->  找到我们的APP支付记录 -> 点开截图 -> 发给我们",
//     "因苹果系统征收30%的费用，因此比安卓系统要高一些，建议您使用安卓手机购买，然后您再换回苹果手机。给您带来的不便，敬请谅解～",
//     "在登录时您已经接受了《用户协议》（可继续在“设置”->“关于”最下方查看），根据您的使用时长已满1小时且不足24小时，只能给您退支付款的一半。",
//     "在登录时您已经接受了《用户协议》（可继续在“设置”->“关于”最下方查看），根据您的使用时长不满足1小时，可以给您退全款，但要收10%的手续费。",
//     "在登录时您已经接受了《用户协议》（可继续在“设置”->“关于”最下方查看），根据您的使用时长已超过24小时，无法给您退款哦。",
//     "您的退款或返现已提交财务申请，会在48小时内到账。款项会按订单原路返回，请在微信或支付宝中查看～",
// ]

// const snapReplyAI = [
//     "为了防止商业使用，智能AI（手诊、舌诊、穴位识别等）诊断目前限制每日3次，请您谅解～",
// ]

// const snapReplyAccount = [
//     "使用购买时绑定的账号（微信 或 手机号+验证码）登录即可。",
//     "您之前是使用当前登录账号（微信/手机验证码）购买的吗？如不是，请在“个人中心” 中点击退出登录后再使用微信/手机验证码登录尝试，如仍有问题，再与我们联系～",
//     "亲，您现在是匿名用户状态，建议您绑定微信或手机号哦，以免更换手机或重装app后无法使用付费权限。",
//     "因为之前，App提醒您绑定微信或者手机号码， 您拒绝了。一旦您卸载app，换手机，长时间未使用，都可能会导致VIP丢失。所以绑定微信或者手机就能防止以上情况",
//     "现在已经为您处理好了，您只需要退出登录，然后使用手机短信或者微信重新登录就好"
// ]

// const snapReplyVideoCourse = [
//     "部分课程视频是用户自己上传的并设置了收费，开放上传是为了让更多的用户互相分享资源有助于学习。但上传视频课程目前是内测功能，还未开放哦～",
// ]

class CustomerService extends React.Component {

    state = {
        onlineUsers: [],
        conversations: {},
        // 当前会话频道实例， 网络通信
        currentConversationChannel: null,
        // 当前会话历史消息列表
        currentConversationHistoryMessages: [],
        // 当前会话消息列表
        currentConversationMessages: [],
        // 当前会话拥有者消息，展示在消息框头部
        currentConversationOwner: null,
        // 当前会话已无更多的消息
        currentNoMoreMessage: false,
        // 监听conversation-update的事件， 每次析构需要停止监听，以防多次监听
        onConversationUpdateRef: null,
        // 快捷回复的列表
        quickReplies: [],
        // GPT客服是否打开
        gptCustomerServiceEnable: false
    }

    componentDidMount() {
        const channel = this.props.customerOperationChannel
        // 在线用户更新
        // channel.push("online-user", {}).receive("ok", (data) => this.setState({ onlineUsers: data.online_user_list }))
        // 会话列表更新
        channel.push("conversation-list", {}).receive("ok", ({ conversations }) => this.setState({ conversations }))
        // 获取快捷回复
        channel.push("quick-reply-fetch", {}).receive("ok", ({ replies }) => {
            this.setState({ quickReplies: replies })
        });
        // gpt的客服服务改变
        channel.on("gpt-customer-service-changed", ({ enable }) => this.setState({ gptCustomerServiceEnable: enable }))

        // 获取gpt是否开启
        channel.push("get-gpt-customer-service", {})
            .receive("ok", ({ enable }) => {
                this.setState({ gptCustomerServiceEnable: enable })
            });

        // 会话更新
        let onConversationUpdateRef = channel.on("conversation-update", (conversation) => {
            let conversations = { ...this.state.conversations }
            // 如果当前会话不是房主
            if (this.props.currentConversationCustomerID?.toString() !== conversation.owner?.id?.toString())
                conversation["unread"] = true

            conversations[conversation.channel] = conversation
            this.setState({ conversations })
        })

        const customer_id = this.props.match?.params?.customer_id || this.props.customerId

        if (!!customer_id)
            this.conversationJoin(customer_id)

        this.setState({ onConversationUpdateRef })
    }

    componentWillUnmount() {
        // 注销监听会话更新
        !!this.state.onConversationUpdateRef && this.props.customerOperationChannel.off("conversation-update", this.state.onConversationUpdateRef)
        // 离开当前会话的频道
        !!this.state.currentConversationChannel && this.state.currentConversationChannel.leave()
        // 清空当前激活的会话
        this.props.conversationActive(null)
    }

    UNSAFE_componentWillReceiveProps(nextProp) {
        const nextCustomerId = nextProp.match?.params?.customer_id || nextProp.customerId

        if (!!nextCustomerId && nextProp.currentConversationCustomerID !== nextCustomerId)
            this.conversationJoin(nextCustomerId)
    }

    // 进入某个特定的用户频道
    conversationJoin(customer_id) {
        if (customer_id === this.props.currentConversationCustomerID) return
        this.props.conversationActive(customer_id)
        // 清空会话数据
        this.setState({ currentConversationMessages: [], currentConversationHistoryMessages: [], currentNoMoreMessage: false })
        // 如果存在之前的频道，则离开后重新创建
        !!this.state.currentConversationChannel && this.state.currentConversationChannel.leave()
        const channel = this.props.socket.channel("ops:online:customer-service:customer:" + customer_id)
        // 被推送历史消息
        channel.on("history-init", (data) => {
            this.setState({ currentConversationHistoryMessages: data.messages.reverse() }, () => {
                data.messages.length > 0 && this.handleReadMessage(data.messages[data.messages.length - 1].id)
            })
        })
        // 记录当前频道的拥有人信息
        channel.on("channel-owner", ({ owner }) => this.setState({ currentConversationOwner: owner }))
        // 被推送信息
        channel.on("message", (message) => {
            let messages = this.state.currentConversationMessages.slice();
            messages.push(message)
            this.setState({ currentConversationMessages: messages }, () => this.handleReadMessage(message.id))
        })
        // 记录当前频道的拥有人信息
        channel.on("owner-read", ({ message_id }) => {
            let messages = this.state.currentConversationMessages.slice();
            let msg = messages.filter(item => item.id === message_id)[0]
            if (!!msg) msg.state = "read"
            this.setState({ currentConversationMessages: messages })
        })
        channel.join()

        // 设置当前会话为已读
        this.handleCurrentConversationSetRead(customer_id)
        this.setState({ currentConversationChannel: channel })
    }

    // 点击加入
    handleClickConversationJoin(id) {
        this.props.history.push("/online/customer-service/" + id)
    }

    // 通用的推送消息给服务端
    handlePush(event, payload) {
        this.state.currentConversationChannel.push(event, payload)
    }

    // 设置当前会话为已读
    handleCurrentConversationSetRead(customer_id) {
        const conversations_with_key_item = Object.entries(this.state.conversations).filter(([channel_name, item]) => item.owner.id.toString() === customer_id.toString())
        // 如果没有会话则返回
        if (conversations_with_key_item.length === 0) return;
        const [key, item] = conversations_with_key_item[0]
        let conversations = { ...this.state.conversations }
        conversations[key] = { ...item, unread: false }

        this.setState({ conversations })
    }

    // 加载更多的历史
    handleLoadMoreHistory() {
        // 如果没有更多的消息则不加载
        if (this.state.currentNoMoreMessage || !this.state.currentConversationChannel) return;

        if (this.historyLoading) return;

        this.historyLoading = true;
        const loadCount = 10
        this.state.currentConversationChannel.push("history", { before_message_id: this.historyMessages[0]?.id, count: loadCount })
            .receive("ok", ({ data }) => {
                let noMoreMessage = data.length < loadCount;

                this.setState({
                    currentConversationHistoryMessages: data.reverse().concat((this.state.currentConversationHistoryMessages || []).slice()),
                    currentNoMoreMessage: noMoreMessage
                }, () => this.historyLoading = false)
            })
    }

    // 帮助消息进消息列表
    handleHelpMessage(message) {
        return <MessageSeparator content={message} />
    }

    // 发送消息
    handleMessageSend(_innerHtml, _textContent, innerText) {
        this.state.currentConversationChannel.push("message", { type: "text", content: innerText })
    }

    // 发送消息
    handleImageSend(url) {
        this.state.currentConversationChannel.push("message", { type: "image", url: url })
    }

    // 处理粘贴图片
    handlePaste(e) {
        // 如果没选中会话，则返回
        if (!this.state.currentConversationOwner) return;
        if (e.clipboardData.files.length) {
            const fileObject = e.clipboardData.files[0];

            var URLObj = window.URL || window.webkitURL;
            var dataUrl = URLObj.createObjectURL(fileObject)

            const handleImageSend = this.handleImageSend.bind(this)

            const token = this.props.user.token

            Modal.confirm({
                title: `确认发送给${this.state.currentConversationOwner.nickname}吗`,
                width: 800,
                content: (
                    <div style={{ background: `url(${dataUrl}) center no-repeat`, backgroundSize: "contain", width: "100%", height: 600 }}></div>
                ),
                okText: "发送",
                onOk() {
                    var reader = new FileReader();
                    reader.readAsDataURL(fileObject);
                    reader.onloadend = () => {
                        var base64data = reader.result;

                        imageBase64Upload(base64data, token)
                            .then(response => {
                                return response.json()
                            })
                            .then(({ data }) => {
                                handleImageSend(config.HttpEndpoint(`/api/attach/${data.path}`))
                            });
                    }
                },
                cancelText: "取消",
                onCancel() {
                }
            });
        } else {
        }
    };

    // 提交已读
    handleReadMessage(message_id) {
        this.handlePush("read", { message_id })
    }

    // 是否在线
    isOnline(owner_id) {
        return this.state.onlineUsers.filter(item => item?.user?.id?.toString() === owner_id.toString()).length > 0
    }

    // 会话列表视图
    get ConversationListView() {
        return Object.entries(this.state.conversations).sort(([_pcn, pre_item], [_ncn, next_item]) => {
            return dayjs(next_item.message.inserted_at) - dayjs(pre_item.message.inserted_at)
        }).map(([channel_name, item]) => {
            const owner = item.owner || {}
            let info = simplifyMessageContent(item.message);

            const ownerIsVip = dayjs(owner.vip_ended_at) > dayjs()

            const ownerName = !!owner ? <span>
                {ownerIsVip && <CrownTwoTone twoToneColor="#eb2f96" size="large" style={{ fontSize: 16 }} />}
                &nbsp;
                {owner.nickname}
                <span style={{ color: "#bbb" }}>@{owner.id}</span>
            </span> : ""

            return <Conversation key={channel_name} lastActivityTime={<LocalTimeAgo date={item.message.inserted_at} />}
                active={this.props.currentConversationCustomerID?.toString() === owner?.id?.toString()}
                onClick={() => this.handleClickConversationJoin(owner.id)} name={ownerName}
                lastSenderName={item?.sender?.nickname} info={info} unreadDot={item.unread}>
                <Avatar src={config.AvatarUrl(owner.avatar)} name={owner.nickname} status={this.isOnline(owner?.id) ? "available" : "invisible"} />
            </Conversation>
        })
    }

    // 实时消息
    get messages() {
        return (this.state.currentConversationMessages || [])
    }

    // 历史消息
    get historyMessages() {
        return (this.state.currentConversationHistoryMessages || [])
    }

    // 消息列表视图
    MessageView(messages) {
        return messages.map(item => {
            const props = this.props;
            const current_user_id = props.user.id.toString()
            let content = null;
            switch (item.content.type) {
                case "text":
                    content = <Message.TextContent text={item.content.content} />
                    break;
                case "image":
                    content = <Message.ImageContent className="message-image" src={item.content.url} />
                    break;
                default:
                    content = <Message.TextContent text="未识别的消息" />
                    break;
            }

            // 本消息是否是自己的消息。 控制消息的方向， 以及判别消息的已读是针对自己的消息是否被频道用有人已读
            const isMyMessage = current_user_id === item.sender?.id?.toString()
            // 判断是不是房主的消息
            const isOwnerMessage = item.sender?.id?.toString() === this.state.currentConversationOwner?.id?.toString()

            // 我的消息，频道主是否已读
            let myMessageStateForChannelOwner = ""
            switch (item.state) {
                case "read": myMessageStateForChannelOwner = "已读"; break;
                case "received": myMessageStateForChannelOwner = "已送达"; break;
                default: myMessageStateForChannelOwner = "已留言"; break;
            }

            return <Message key={`${item.event}-${item.id}`} model={{
                sentTime: item.inserted_at,
                sender: item.sender.nickname,
                avatarPosition: "tl",
                avatarSpacer: true,
                direction: isMyMessage ? "outgoing" : "incoming"
            }} className={`${isOwnerMessage ? "" : "has-footer"}`}>
                <Message.Header sender={item?.sender?.nickname} sentTime={item.inserted_at} />
                <Avatar src={config.AvatarUrl(item.sender.avatar)} name={item?.sender?.nickname} />
                {content}
                <Message.Footer sentTime={!isOwnerMessage && myMessageStateForChannelOwner} />
            </Message>
        })
    }

    // 在线列表视图
    // get OnlineListView() {
    //     return this.state.onlineUsers
    //         .sort((pre, next) => (JSON.parse(pre.key)["user_id"] || 0) - (JSON.parse(next.key)["user_id"] || 0))
    //         .reverse()
    //         .map(item => {
    //             const user = item.user || {}

    //             const ownerIsVip = dayjs(user.vip_ended_at) > dayjs()

    //             return <Conversation key={item.key} className={`customer-service-online-list ${!user.id ? "anonymous" : ""}`}
    //                 active={this.props.currentConversationCustomerID === user.id}
    //                 onClick={() => !!user.id && this.conversationJoin(user.id)}
    //                 name={<Badge count={ownerIsVip ? <CrownTwoTone twoToneColor="#eb2f96" rotate={45} size="large" style={{ fontSize: 16 }} /> : null}>{user.nickname || "未登录用户"}</Badge>}
    //                 info={<span><Tag>{item.app_info.version}</Tag><Tag>{item.app_info.device_brand}</Tag></span>}>
    //                 {!!user.avatar && <Avatar src={user.avatar} />}
    //             </Conversation>
    //         })
    // }

    showUser(userId) {
        this.props.show(userId)
    }

    handleSnapReplyClick(message) {
        document.querySelector(".cs-message-input__content-editor").innerHTML = message
    }

    // 删除快捷回复
    handleSnapReplyDelete(key) {
        this.props.customerOperationChannel.push("quick-reply-drop", { key }).receive("ok", ({ replies }) => {
            this.setState({ quickReplies: replies });
        });
    }


    snapAddForm = React.createRef();
    // 添加快捷回复
    handleSnapReplyAdd(groupKey) {
        Modal.confirm({
            width: 400,
            title: '添加快捷回复',
            content: <Form ref={this.snapAddForm} labelCol={{ xs: { span: 6 } }} wrapperCol={{ xs: { span: 24 } }}>
                <Form.Item label="文本" onfini name="content" rules={[{ required: true, message: '不能为空' }]}>
                    <Input.TextArea autoSize />
                </Form.Item>
            </Form>,
            onOk: (close) => {
                this.snapAddForm.current
                    .validateFields()
                    .then(({ content }) => {
                        this.props.customerOperationChannel.push("quick-reply-save", { "value": content, "parent_key": groupKey })
                            .receive("ok",
                                ({ replies }) => {
                                    this.setState({ quickReplies: replies });
                                    close()
                                });
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    handleClickGPTCustomerService(checked) {
        // 发送关闭或者打开指令
        this.props.customerOperationChannel.push("set-gpt-customer-service", { enable: checked })

    }

    render() {
        // 用户信息
        const owner = this.state.currentConversationOwner
        // 用户的app信息
        const owner_app_info = (this.state.currentConversationOwner?.login_app_info || {})

        const ownerName = !!owner ? <span>
            {owner.nickname}<span style={{ color: "#bbb" }}>@{owner.id}</span>
        </span> : ""

        const ownerIsVip = !!owner ? dayjs(owner.vip_ended_at) > dayjs() : ""

        const ownerConversationDetail = !!owner ? <span>
            {ownerIsVip && <Tag icon={<CrownTwoTone twoToneColor="#eb2f96" size="large" style={{ fontSize: 16 }} />}><LocalTimeAgo date={owner.vip_ended_at} />到期</Tag>}
            <Tag><LocalTimeAgo date={owner.active_at} />激活</Tag>
            <Tag>{owner_app_info.device_brand}</Tag>
            <Tag>{owner_app_info.version}</Tag>
        </span> : null

        return <div className="page online-customer-service" onPaste={this.handlePaste.bind(this)}>
            <MainContainer style={{ height: 700, borderRadius: 10, flex: "1 1 auto" }}>
                <ChatContainer >
                    {!!owner && <ConversationHeader>
                        <Avatar src={config.AvatarUrl(owner.avatar)} name={owner.nickname} status={this.isOnline(owner?.id) ? "available" : "invisible"} />
                        <ConversationHeader.Content userName={ownerName} info={ownerConversationDetail}></ConversationHeader.Content>
                        {!this.props.hideAction &&
                            <ConversationHeader.Actions>
                                <Button type="link" onClick={() => this.showUser(owner.id)}><UserOutlined style={{ fontSize: 24 }} /></Button>
                            </ConversationHeader.Actions>
                        }
                    </ConversationHeader>}
                    <MessageList onYReachStart={this.handleLoadMoreHistory.bind(this)} disableOnYReachWhenNoScroll>
                        {this.state.currentNoMoreMessage && <MessageSeparator content="已无更多历史消息" />}
                        {this.MessageView(this.historyMessages)}
                        <MessageSeparator content="以上为历史消息" />
                        {this.MessageView(this.messages)}
                    </MessageList>
                    <MessageInput placeholder="" onPaste={() => null} autoFocus onSend={this.handleMessageSend.bind(this)} attachButton={false} disabled={!this.state.currentConversationChannel} />
                </ChatContainer>

                {!this.props.hideConversation &&
                    <ConversationList>
                        {this.ConversationListView}
                    </ConversationList>}
                {!this.props.hideSidebar && <Sidebar style={{ width: 500 }}>
                    <ExpansionPanel open title="打开智能客服" className="gpt-customer-service group">
                        <Switch checkedChildren="已打开智能客服" unCheckedChildren="已关闭智能客服" onChange={(checked) => this.handleClickGPTCustomerService(checked)} checked={this.state.gptCustomerServiceEnable}></Switch>
                    </ExpansionPanel>
                    {
                        this.state.quickReplies.map(({ name, key: groupKey, children }) => {
                            return <ExpansionPanel open title={name} key={groupKey} className="quick-reply group">
                                <div>
                                    {
                                        children.map(({ deletable, key, value }) =>
                                            <div className="quick-reply message">
                                                <Message key={key} model={{ message: value, position: "single" }} onClick={() => this.handleSnapReplyClick(value)} />
                                                {
                                                    deletable && <Button onClick={() => this.handleSnapReplyDelete(key)} className="quick-reply btn-rm" size="small" style={{ margin: 5 }} icon={<CloseCircleTwoTone twoToneColor="red" />} type="link"  ></Button>
                                                }
                                            </div>)
                                    }
                                    <div style={{ margin: "5px 0" }}>
                                        <Button size="small" onClick={() => this.handleSnapReplyAdd(groupKey)} className="quick-reply btn-add" icon={<PlusCircleTwoTone />} type="primary" ghost block>添加</Button>
                                    </div>
                                </div>
                            </ExpansionPanel>
                        })
                    }
                </Sidebar>}
            </MainContainer>
        </div>
    }
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        user: global.user,
        customerOperationChannel: global.customerOperationChannel,
        currentConversationCustomerID: global.currentConversationCustomerID
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        conversationActive: (customer_id) => {
            dispatch(COAction.Conversation.active(customer_id))
        },

        show: (userId) => {
            dispatch(userInformationModal.show(userId))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerService)