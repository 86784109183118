import { useState, useEffect } from "react";
import { Upload } from "antd";

import { connect } from "react-redux";
import Sha256Encode from "../components/sha256";

function UploadComponent({ value, bucket, onChange, path, jsonRequest, children, ...props }) {
    const [OSSData, setOSSData] = useState();

    const init = async () => {
        const result = await jsonRequest.get("/api/attach/sign/policy", { bucket }).then(response => response.json())
        setOSSData(result.data)
    };

    useEffect(() => {
        init();
    }, []);

    const handleChange = ({ fileList }) => {
        console.log('Aliyun OSS:', fileList);
        onChange?.([...fileList]);
    };

    const onRemove = (file) => {
        const files = (value || []).filter((v) => v.url !== file.url);

        // if (onChange) {
        //     onChange(files);
        // }
    };

    const getExtraData = (file) => ({
        key: file.url,
        OSSAccessKeyId: OSSData?.accessid,
        policy: OSSData?.policy,
        Signature: OSSData?.signature,
    });

    const beforeUpload = async (file) => {
        if (!OSSData) return false;

        const expire = Number(OSSData.expire) * 1000;

        if (expire < Date.now()) {
            await init();
        }
        
        const file_content = await file.arrayBuffer()
        const file_name = await Sha256Encode(file_content)

        file.url = [path, file_name].join("/")

        return file;
    };

    const uploadProps = {
        name: 'file',
        fileList: value,
        action: OSSData?.host,
        onChange: handleChange,
        onRemove,
        data: getExtraData,
        beforeUpload,
    };

    return (
        <Upload {...props} {...uploadProps} >
            {children}
        </Upload>
    );
};


const mapStateToProps = ({ global }) => {
    return {
        jsonRequest: global.jsonRequest,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UploadComponent)