import React, { useState } from "react";
import { Form, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import UploadComponent from "../../components/UploadComponent";
import { connect } from "react-redux";
import configFunc from "../../config";
import { Switch } from "antd";

function TopicForm({ jsonRequest, initial, ...props }) {
    const [avatar, setAvatar] = useState(null)
    const [topicName, setTopicName] = useState(initial?.title)
    const [form] = Form.useForm();

    props.setForm(form);

    // 表格的值变化，自动填写拼音
    const handleFormValuesChange = (changedValues, allValues) => {
        setTopicName(allValues.title)
    }

    const handleUploadSuccess = ([file]) => {
        form.setFieldsValue({ avatar: configFunc.ResourceUrl(file.url) })
        setAvatar(configFunc.ResourceUrl(file.url))
    }

    return <Form form={form} name="basic" onValuesChange={handleFormValuesChange} initialValues={initial} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
        <Form.Item label="名称" name="title" rules={[{ required: true, message: '不能为空，也不能包含特殊字符' }]} >
            <Input disabled={!!initial} />
        </Form.Item>
        <Form.Item label="简介" name="summary" >
            <Input.TextArea autoSize />
        </Form.Item>
        <Form.Item label="推荐" name="recommend" help="由于话题可以被用户添加，只有推荐的话题会被显示在APP发帖中可供点选" valuePropName="checked" >
            <Switch />
        </Form.Item>
        {!props.disableIcon && !!topicName && <>
            <Form.Item label="封面图" rules={[{ required: true, message: '不能为空' }]}>
                <UploadComponent path={["community", "topic"].join("/")} bucket="acupoint-resource" maxCount={1}
                    listType="picture-card" showUploadList={false}
                    onChange={(fileList) => handleUploadSuccess(fileList)}>
                    {avatar ? <img src={avatar} alt="avatar" style={{ width: '100%' }} /> : <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>拖到这里</div>
                    </div>}
                </UploadComponent>
            </Form.Item>
            <Form.Item label="图标地址" name="avatar">
                <Input disabled />
            </Form.Item>
            {/* <Form.Item label="是否免费" name="free" initialValue={true} valuePropName="checked">
                <Switch checkedChildren="免费" unCheckedChildren="需要收费" />
            </Form.Item> */}
        </>}

    </Form>
}


const mapStateToProps = ({ global }) => {
    return {
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(TopicForm)