// 依赖
import { HashRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { Socket } from "phoenix"
import { ConfigProvider, message } from "antd"

// UI
import Nav from './Nav'
import Breadcrumb from './Breadcrumb'
import Dashboard from "./Dashboard/index"
import CustomerService from "./Operation/CustomerService"
import Online from "./Online"
import SQEC from './SQEC'
import CMS from './CMS'
import Statistics from './Statistics'
import SignIn from './SignIn'
import UserInformationModal from "./components/user/UserInformationModal";
import AuthRoute from './components/auth_route'
import Finance from './Finance'
// import Push from './Push'
import Marketing from "./Marketing";
import Tech from './Tech';
import Operation from "./Operation";
import UserInformation from "./components/user/UserInformation";

// websocket
import NeedSocket from './components/need_socket'
import { Socket as OperationSocket } from './store/global/actions'
import CustomerOperationChannel from "./channel/customer_operation_channel"
import NotificationChannel from "./channel/notification_channel"
import AppOnlineUserChannel from "./channel/app_online_user_channel"
import UserOpsChannel from "./channel/user_ops_channel"
import PushChannel from "./channel/push_channel"
import GlobalChannel from "./channel/global_channel";

import RequireRolesOr from './components/require_roles_or'

import { connect } from "react-redux"
import zhCN from 'antd/lib/locale/zh_CN';

// 工具配置
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from "dayjs/plugin/utc"
import duration from 'dayjs/plugin/duration'
import 'dayjs/locale/zh-cn'
import dayjs from 'dayjs'

import 'moment/locale/zh-cn';
import config from './config'
import "./Home.css"
import Community from "./Community";

dayjs.locale('zh-cn');
dayjs.extend(relativeTime)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(updateLocale)
dayjs.extend(duration)
dayjs.extend(customParseFormat)
dayjs.extend(utc)

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        user: global.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSocket: (token) => {
            const socket = new Socket(config.SocketEndpoint("/ops-socket"), { params: { token: token }, reconnectAfterMs: () => 500, heartbeatIntervalMs: 5000 })
            socket.connect()
            socket.onError(error => {
                console.log(error)
            })
            socket.onClose(error => {
                message.error({ content: "与服务器丢失连接", key: "socket-close" })
            })
            socket.onOpen(() => {
                message.success({ content: "与服务器建立了连接", key: "socket-close", duration: 1 })
            })

            socket.onMessage((msg) => {
                switch (msg?.payload?.status) {
                    case "error":
                        const exception_message = msg?.payload?.response?.message
                        if (!!exception_message) {
                            message.destroy()
                            message.error(exception_message)
                        }
                        break;

                    default:
                        break;
                }
            })

            dispatch(OperationSocket.set(socket))
            return socket;
        }
    }
}

function Home(props) {
    // 启动socket
    if (!!props.user && !props.socket)
        props.setSocket(props.user.token)

    return <ConfigProvider locale={zhCN}>
        <Router>
            <Switch>
                <Route path="/sign_in" exact component={SignIn} ></Route>
                {!props.user && <Redirect to="/sign_in" />}
                <NeedSocket>
                    <GlobalChannel />
                    <RequireRolesOr require={["acupoint:role::customer-service", "acupoint:role::operator"]}>
                        <CustomerOperationChannel />
                        <UserOpsChannel />
                    </RequireRolesOr>
                    <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::finance"]}>
                        <NotificationChannel />
                    </RequireRolesOr>
                    <AppOnlineUserChannel />
                    <RequireRolesOr require={["acupoint:role::operator"]}>
                        <PushChannel />
                    </RequireRolesOr>
                    <div className="App">
                        <main role="main" className="container">
                            <div style={{ padding: "5px" }}>
                                <Nav />
                            </div>
                            <div style={{ padding: "5px 20px" }}>
                                <Breadcrumb />
                            </div>

                            <RequireRolesOr require={["acupoint:role::operator"]}>
                                <AuthRoute path="/dashboard" component={Dashboard} />
                            </RequireRolesOr>
                            <RequireRolesOr require={["acupoint:role::customer-service", "acupoint:role::operator"]}>
                                <AuthRoute path="/operation" component={CustomerService} />
                            </RequireRolesOr>
                            <RequireRolesOr require={["acupoint:role::customer-service", "acupoint:role::operator"]}>
                                <AuthRoute path="/ops" component={Operation} />
                            </RequireRolesOr>
                            <RequireRolesOr require={["acupoint:role::customer-service", "acupoint:role::operator"]}>
                                <AuthRoute path="/online" component={Online} />
                            </RequireRolesOr>
                            <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::editor"]}>
                                <AuthRoute path="/sqec" component={SQEC} />
                            </RequireRolesOr>
                            <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::editor"]}>
                                <AuthRoute path="/cms" component={CMS} />
                            </RequireRolesOr>
                            <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::customer-service", "acupoint:role::finance"]}>
                                <AuthRoute path="/statistics" component={Statistics} />
                            </RequireRolesOr>
                            <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::finance"]}>
                                <AuthRoute path="/finance" component={Finance} />
                            </RequireRolesOr>
                            {/* <AuthRoute path="/resource" component={Resource} /> */}
                            {/* <RequireRolesOr require={["acupoint:role::operator"]}>
                                <AuthRoute path="/tech/push" component={Push} />
                            </RequireRolesOr> */}
                            <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::marketing"]}>
                                <AuthRoute path="/marketing" component={Marketing} />
                            </RequireRolesOr>
                            <RequireRolesOr require={["acupoint:role::operator"]}>
                                <AuthRoute path="/tech" component={Tech} />
                            </RequireRolesOr>
                            <RequireRolesOr require={["acupoint:role::customer-service"]}>
                                <AuthRoute path="/community" component={Community} />
                            </RequireRolesOr>
                        </main>
                    </div>
                </NeedSocket>
            </Switch>
            <UserInformationModal />
        </Router>
    </ConfigProvider>
};

export default connect(mapStateToProps, mapDispatchToProps)(Home)