import { Alert, Form, Modal, Input } from 'antd';

function PasswordConfirm({ title, message, type = "warning", externalItems = <></>, ref, onOk }) {
    const currentEvidence = JSON.parse(localStorage.getItem('current-evidence') || 'null')
    Modal.confirm({
        title: title,
        content: <>
            <Alert message={message} banner type={type} showIcon />
            <p></p>
            <Form ref={ref} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off">
                <Form.Item label="账号">
                    <span className="ant-form-text">{currentEvidence.account}</span>
                </Form.Item>
                <Form.Item label="确认密码" name="password" rules={[{ required: true, message: '密码不能为空' }]}>
                    <Input.Password />
                </Form.Item>
                {externalItems}
            </Form>
        </>,
        onOk: (close) => {
            const form = ref.current

            form
                .validateFields()
                .then(values => {
                    form.resetFields();
                    onOk(values, close)
                })
                .catch(info => {
                });
            return false
        }
    });
}

export default PasswordConfirm;