import { message } from "antd"
function iconSettle(file, cb = () => null) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
        message.error('只能上传 JPG/PNG 文件!');
        return false
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('文件不能超过2MB!');
        return false
    }

    const reader = new FileReader();
    reader.addEventListener('load', () => {
        cb(reader.result)
    });
    reader.readAsDataURL(file);

    return false
}

export default iconSettle