const Sha256Encode = val =>
    crypto.subtle
        .digest('SHA-256', val)
        .then(h => {
            let hexes = [],
                view = new DataView(h);
            for (let i = 0; i < view.byteLength; i += 4)
                hexes.push(('00000000' + view.getUint32(i).toString(16)).slice(-8));
            return hexes.join('');
        });

export default Sha256Encode