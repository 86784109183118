import React, { useEffect, useState } from "react"
import { Button, Input, Form, Tabs, Popover, message } from 'antd'
import Group from "./group";
import { PlusSquareTwoTone, EditTwoTone, BoxPlotTwoTone } from '@ant-design/icons';

import './index.css'
import { useForm } from "antd/lib/form/Form";

const { TabPane } = Tabs;

function MediaViewer({ name: topName, detail: Details, height, editable = false, channel, ownerType, ownerID, hasUpdate }) {
    const [groupFormRef] = useForm()
    const [propDetails, setPropDetails] = useState([...Details])
    const [currentTabID, setCurrentTabID] = useState(propDetails[0]?.id)
    const [groupAddFormVisible, setGroupAddFormVisible] = useState(false)
    const [groupEditFormVisible, setGroupEditFormVisible] = useState(false)
    const [groupSortVisible, setGroupSortVisible] = useState(false)

    useEffect(() => {
        setPropDetails(Details)
        return () => { }
    }, [Details])

    const handleFormSubmit = (values) => {
        channel.push("add-or-edit-group", values).receive("ok", () => {
            setGroupAddFormVisible(false)
            setGroupEditFormVisible(false)
            message.success("操作成功")
        })
    }

    const groupForm = <Form form={groupFormRef} onFinish={handleFormSubmit} name="basic" autoComplete="off" style={{ display: "flex" }}>
        <Form.Item label={false} hidden name="id"><Input /></Form.Item>
        <Form.Item label={false} hidden name="owner_id"><Input /></Form.Item>

        <Form.Item label={false} name="name" rules={[{ required: true, message: '不能为空' }]}>
            <Input placeholder="组名" />
        </Form.Item>
        <Button htmlType="submit" type="primary">提交</Button>
    </Form>

    // 点击组添加按钮
    const handleClickAdd = () => {
        groupFormRef.setFieldsValue({ name: "", id: null, owner_id: ownerID })
        setGroupAddFormVisible(true)
    }

    // 点击组编辑按钮
    const handleClickEdit = (tabID) => {
        const currentTab = propDetails.find(item => item.id.toString() === tabID.toString())

        groupFormRef.setFieldsValue({ name: currentTab.name, id: currentTab.id, owner_id: ownerID })
        setGroupEditFormVisible(true)
    }

    // 处理点击group的顺序
    const handleClickSaveTabsOrder = () => {
        let data = propDetails.reduce((map, { id }, index) => { return { ...map, [id]: index } }, {})

        channel.push("resort-groups", data).receive("ok", () => {
            message.success("排序成功")
            setGroupSortVisible(false)
        })
    }

    // 组排序往左移动
    const handleClickMoveLeft = (currentIndex) => {
        if (currentIndex < 1) return;
        const new_children = [...propDetails]
        const element = new_children[currentIndex];
        new_children.splice(currentIndex, 1);
        new_children.splice(currentIndex - 1, 0, element);
        setPropDetails(new_children)
        setGroupSortVisible(true)
    }

    // 组排序往右移动
    const handleClickMoveRight = (currentIndex) => {
        const new_children = [...propDetails]
        const element = new_children[currentIndex];
        new_children.splice(currentIndex, 1);
        new_children.splice(currentIndex + 1, 0, element);
        setPropDetails(new_children)
        setGroupSortVisible(true)
    }

    const groups = propDetails.map(({ id: groupID, type, name: tabName, medicineInfoList }, index) => {
        return <TabPane key={groupID} tab={tabName}>
            <Group groupID={groupID} tabName={tabName}
                ownerType={ownerType} ownerID={ownerID}
                channel={channel} name={topName} groupChildren={medicineInfoList}
                height={height} editable={editable}
                onClickMoveLeft={() => handleClickMoveLeft(index)}
                onClickMoveRight={() => handleClickMoveRight(index)}
            />
        </TabPane>
    })

    return <div className={`component media-viewer ${hasUpdate && "has-update"}`}>
        <Tabs type="line" onChange={(key) => setCurrentTabID(key)} tabBarExtraContent={editable && <>
            <Popover content={groupForm} title="添加栏目" trigger="click" open={groupAddFormVisible} onOpenChange={(visibale) => setGroupAddFormVisible(visibale)}>
                <Button type="link" onClick={handleClickAdd} icon={<PlusSquareTwoTone twoToneColor="#e3a338" />}></Button>
            </Popover>
            <Popover content={groupForm} title="编辑栏目" trigger="click" open={groupEditFormVisible} onOpenChange={(visibale) => setGroupEditFormVisible(visibale)}>
                <Button type="link" onClick={() => handleClickEdit(currentTabID)} icon={<EditTwoTone twoToneColor="#e3a338" />}></Button>
            </Popover>
            {groupSortVisible &&
                <Button type="link" onClick={() => handleClickSaveTabsOrder()} icon={<BoxPlotTwoTone twoToneColor="#e3a338" />}></Button>}
        </>}>
            {groups}
        </Tabs>
    </div>
}

export default MediaViewer