
import React, { useEffect } from "react";
import { Button, Table } from 'antd';
import { connect } from "react-redux";
import { useState } from "react";
import dayjs from "dayjs";
import QueryForm from "./components/query_form";
import UserAvatar from "../../components/user/UserAvatar";

const { Column } = Table

function NotificationLogs(props) {
    const [appleNotificationChannel, setAppleNotificationChannel] = useState(null)
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        const channel = props.socket.channel("statistics:apple:notification")
        channel.on("data", ({ data }) => {
            setData(data)
            setLoading(false)
        })
        channel.join().receive("ok", () => { })
        setAppleNotificationChannel(channel)

        return () => {
            if (!!appleNotificationChannel)
                appleNotificationChannel.leave()

        }
    }, [])

    const loadData = (page, size) => {
        appleNotificationChannel.push("page-to", { page_size: size, page: page })
    }

    const notificationTypeText = (text) => {
        switch (text) {
            case "REFUND_DECLINED":
                return "退款已拒绝"
            case "CONSUMPTION_REQUEST":
                return "退款申请"
            case "EXPIRED":
                return "订阅已过期"
            case "PRICE_INCREASE":
                return "自动续订价格上涨"
            case "REFUND":
                return "已退款"
            case "RENEWAL_EXTENDED":
                return ""
            case "SUBSCRIBED":
                return "用户发起订阅"
            case "DID_RENEW":
                return "用户成功续订"
            case "DID_FAIL_TO_RENEW":
                return "用户续订失败"
            case "GRACE_PERIOD_EXPIRED":
                return "计费宽限期结束"
            case "DID_CHANGE_RENEWAL_STATUS":
                return "订阅状态变更"

            default:
                return text
        }
    }

    const subTypeText = (text) => {
        switch (text) {
            case "INITIAL_BUY":
                return "购买"
            case "AUTO_RENEW_DISABLED":
                return "取消自动续费"

            default:
                break;
        }
    }

    return <div className="page apple-logs">
        <h1>苹果回调日志</h1>
        <div>
            <QueryForm onSearch={(values) => appleNotificationChannel.push("query", values)}></QueryForm>
        </div>
        <br />
        {
            data && <Table dataSource={data.entries} rowKey={item => item.id}
                pagination={{
                    onChange: loadData,
                    total: data.total_entries,
                    current: data.page_number,
                    pageSize: data.page_size,
                    showQuickJumper: true
                }}>
                <Column title="ID" dataIndex="id" key="id" width={50} />
                <Column title="时间" dataIndex="inserted_at" key="inserted_at" width={90} render={(text) => dayjs(`${text}Z`).format("YYYY-MM-DD HH:mm:ss")} />
                <Column title="用户" width={60} align="center" dataIndex={["user", "avatar"]} key={["user", "avatar"]} render={(_text, obj) =>
                    <UserAvatar id={obj.user?.id} nickname={obj.user?.nickname} size={40} avatar={obj.user?.avatar} vip={obj.user?.group === "vip"} />} />
                <Column title="涉及金额" width={80} align="center" dataIndex={["order", "amount"]} key={["order", "amount"]} render={(v, obj) => (v / 100)} />
                <Column title="域" dataIndex="host" key="host" width={90} />
                <Column title="路径" dataIndex="path" key="path" width={100} />
                <Column title="通知类型" dataIndex={["data", "unsignedPayload", "notificationType"]} key={["data", "unsignedPayload", "notificationType"]} width={80} ellipsis render={(v) => <div>{notificationTypeText(v)}</div>} />
                <Column title="子类型" dataIndex={["data", "unsignedPayload", "subtype"]} key={["data", "unsignedPayload", "subtype"]} width={80} ellipsis render={(v) => <div>{subTypeText(v)}</div>} />
                <Column title="相关订单业务" dataIndex={["data", "unsignedPayload", "data", "transactionInfo", "originalTransactionId"]} key={["data", "unsignedPayload", "data", "transactionInfo", "originalTransactionId"]} width={80} ellipsis />

            </Table>
        }
    </div>
}



const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NotificationLogs)