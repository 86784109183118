import React, { useState } from "react";
import { Form, Input, Switch, Select } from 'antd';


function DraftForm(props) {
    const [form] = Form.useForm();

    props.setForm(form);


    // 表格的值变化，自动填写拼音
    const handleFormValuesChange = (changedValues, allValues) => {
        if (!!changedValues.name) {
            props.channel.push("py", { name: changedValues.name })
                .receive("ok", ({ search_py, display_py }) => {
                    form.setFieldsValue({ py: display_py, search_py: search_py })
                })
        }
    }

    return <Form form={form} name="basic" onValuesChange={handleFormValuesChange} initialValues={props.initial} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
        <Form.Item label="名称" name="name" rules={[{ required: true, message: '不能为空' }]}>
            <Input />
        </Form.Item>
        <Form.Item label="别名" name="alias" help="输入一个别名后按回车，可以有多个别名">
            <Select mode="tags" style={{ width: '100%' }} placeholder="[可选] 昵称，学名，小名，关联名" />
        </Form.Item>
        {!props.disalbePY && <>
            <Form.Item label="拼音" name="py" rules={[{ required: true, message: '必填' }]}>
                <Input placeholder="会自动填，请检测，不匹配请修改" />
            </Form.Item>
            <Form.Item label="搜索拼音" name="search_py" rules={[{ required: true, message: '必填' }]}>
                <Input placeholder="会自动填，请检测，不匹配请修改" />
            </Form.Item>
        </>}
        <Form.Item label="是否免费" name="free" initialValue={true} valuePropName="checked">
            <Switch checkedChildren="免费" unCheckedChildren="需要收费" />
        </Form.Item>

    </Form>
}

export default DraftForm;