import React from "react";
import { List, Modal, Button, Switch } from 'antd';
import 'jsoneditor-react/es/editor.min.css';
import PlanItem from "./components/plan_item";
import PlanForm from "./components/plan_form";
import { PlusSquareTwoTone } from '@ant-design/icons';

import { connect } from "react-redux";

import "./Plan.css"

class Plan extends React.Component {
    state = {
        channel: null,
        data: {},
        showCreateForm: false,
        editable: false
    }

    componentDidMount() {
        const channel = this.props.socket.channel("marketing:plan")
        channel.on("data", ({ data }) => this.setState({ data }))
        channel.join()
        this.setState({ channel })
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    pushEvent(event, params, opts = null) {
        return this.state.channel.push(event, params)
    }

    tableChange = (page, size) => {
        this.pushEvent("page-to", { page_size: size, page: page })
    }

    onFinish = (values) => {
        this.pushEvent("query", values)
    }

    onReset = () => {
        this.pushEvent("query", {})
    }

    render() {
        const data = this.state.data;

        return <div className="page marketing plan">
            <h1>营销计划 <Switch onChange={checked => this.setState({ editable: checked })} checkedChildren="开启编辑" unCheckedChildren="只读" /></h1>
            <div className="actions" style={{ display: "flex" }}>
                <Button icon={<PlusSquareTwoTone />} type="link" onClick={() => this.setState({ showCreateForm: true })}>
                    新增计划
                </Button>
            </div>

            {
                !!data.entries &&
                <List
                    grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 3, }}
                    dataSource={data.entries}
                    pagination={{
                        onChange: (page, size) => { this.tableChange(page, size) },
                        total: data.total_count,
                        current: data.page_number,
                        pageSize: data.page_size,
                        showQuickJumper: true
                    }}
                    renderItem={item => <PlanItem editable={this.state.editable} channel={this.state.channel} item={item} />}
                />}

            {this.state.channel &&
                <Modal title="新增计划" width={1400} open={this.state.showCreateForm} footer={false} onCancel={() => this.setState({ showCreateForm: false })}>
                    <PlanForm channel={this.state.channel} closeModal={() => this.setState({ showCreateForm: false })} />
                </Modal>
            }
        </div>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Plan)