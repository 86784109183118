import { useEffect, useState } from "react"
import AuditForm from "./AuditForm";
import React from "react"
import { Menu as MenuAction } from '../../store/global/actions'
import { connect } from "react-redux"
import { Button, message, Empty } from "antd";

function Audit(props) {
    const referenceType = props.match.params.reference_type
    const [channel, setChannel] = useState(null)
    const [simulatorChannel, setSimulatorChannel] = useState(null)
    let [data, setData] = useState({})
    let formRef = React.createRef()

    useEffect(() => {
        props?.setActiveKey("COMMUNITY")

        let _channel = props.socket.channel("community:reports", { status: "pending", reference_type: referenceType, type: null, no_list: true })
        let _simulatorChannel = props.socket.channel("community:simulator")
        _simulatorChannel.join()
        _channel.join()
        setChannel(_channel)
        setSimulatorChannel(_simulatorChannel)

        // 刷新一条数据
        _channel.push("random-report", {})
            .receive("ok", (data) => {
                setData(data)
            })

        return () => {
            if (!!_channel) _channel.leave()
            if (!!_simulatorChannel) _simulatorChannel.leave()
        }
    }, [referenceType])

    const refresh = (_channel) => {
        setData({})
        _channel = channel || _channel
        _channel.push("random-report", {})
            .receive("ok", (data) => {
                setData(data)
            })
    }

    const handleSubmit = () => {
        const form = formRef.current
        form
            .validateFields()
            .then(values => {
                // form.resetFields();
                channel.push("audit-submit", { ...values, id: data?.id })
                    .receive("ok", () => {
                        refresh()
                        message.success("提交成功")
                    })
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    let modalTitle = null;
    switch (data?.reference?.type) {
        case "answer":
            modalTitle = "回答详情"
            break;
        case "question":
            modalTitle = "提问详情"
            break;

        default:
            break;
    }
    return <div>
        <div className="page operation community audit">
            <h1>审核台</h1>
            <div style={{ marginBottom: 20, display: "flex" }}>
                <div style={{ width: 600 }}>
                    <span style={{ fontSize: 24 }}>{modalTitle}</span>
                </div>
                <Button.Group>
                    <Button onClick={refresh}>跳过</Button>
                    <Button onClick={handleSubmit} type="primary">提交</Button>
                </Button.Group>
            </div>
            <div>
                <div>
                    {!!data?.id ? <AuditForm formRef={formRef} report={data} simulatorChannel={simulatorChannel} postType={data?.reference?.type} referenceID={data?.reference_id} /> :
                        <div style={{ width: 700, height: 400, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>}
                </div>
            </div>
        </div>
    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Audit)