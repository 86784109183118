import React, { useState, useEffect } from "react"
import { Radio, Pagination, Timeline, Tag } from 'antd'
import useLocalStorageState from 'use-local-storage-state'
import LocalTimeAgo from "../../../components/local_time_ago";
import dayjs from 'dayjs'
import { WechatOutlined, KeyOutlined, AppleFilled, BarcodeOutlined, FieldStringOutlined, FrownOutlined, MessageOutlined } from '@ant-design/icons';


function authenticateBy(by) {
    switch (by) {
        case "wechat":
            return "微信"
        case "appleid":
            return "苹果"
        case "token":
            return "令牌"
        case "device":
            return "设备"
        case "browser":
            return "浏览器"
        case "disposable":
            return "匿名"
        case "sms":
            return "短信"
        default:
            return by
    }
}

// 当评价页数变更时执行
function handleAuthenticateLogPageChange(props, setAuthenticateLogPagedData, setfilterAuthenticateBy, page, pageSize = 10, by) {
    props.pushEvent("authenticate-logs", { page, page_size: pageSize, authenticate_by: by || null })
        .receive("ok", (data) => {
            setAuthenticateLogPagedData(data)
            setfilterAuthenticateBy(by)
        })
}

function timelineDot(log) {
    switch (log.authenticate_by) {
        case "wechat": return <WechatOutlined className="timeline-clock-icon" style={{ color: "green" }} />
        case "appleid": return <AppleFilled className="timeline-clock-icon" style={{ color: "#444" }} />
        case "token": return <KeyOutlined className="timeline-clock-icon" style={{ color: "silver" }} />
        case "device": return <BarcodeOutlined className="timeline-clock-icon" style={{ color: "silver" }} />
        case "browser": return <FieldStringOutlined className="timeline-clock-icon" style={{ color: "silver" }} />
        case "disposable": return <FrownOutlined className="timeline-clock-icon" style={{ color: "silver" }} />
        case "sms": return <MessageOutlined className="timeline-clock-icon" style={{ color: "orange" }} />
        default: return <></>
    }
}

function AuthenticateLogs(props) {
    // 标签记录
    const [filterAuthenticateBy, setfilterAuthenticateBy] = useLocalStorageState('filterAuthenticateBy', undefined)
    // 校验日志数据
    const [authenticateLogPagedData, setAuthenticateLogPagedData] = useState({})


    useEffect(() => {
        handleAuthenticateLogPageChange(props, setAuthenticateLogPagedData, setfilterAuthenticateBy, 1, 10, filterAuthenticateBy)
    }, [props.userId])

    return <>
        <Radio.Group value={filterAuthenticateBy} onChange={(e) => handleAuthenticateLogPageChange(props, setAuthenticateLogPagedData, setfilterAuthenticateBy, authenticateLogPagedData.page_number, authenticateLogPagedData.page_size, e.target.value)} size="small" style={{ margin: 20 }}>
            <Radio.Button value={undefined}>全部</Radio.Button>
            <Radio.Button value="wechat">微信</Radio.Button>
            <Radio.Button value="appleid">Apple ID</Radio.Button>
            <Radio.Button value="token">Token</Radio.Button>
            <Radio.Button value="sms">短信</Radio.Button>
        </Radio.Group>
        <Pagination current={authenticateLogPagedData?.page_number} showQuickJumper size="small" total={authenticateLogPagedData?.total_count} pageSize={authenticateLogPagedData?.page_size || 10} onChange={(page, size) => handleAuthenticateLogPageChange(props, setAuthenticateLogPagedData, setfilterAuthenticateBy, page, size, filterAuthenticateBy)} />
        <br></br>
        <Timeline>
            {!!authenticateLogPagedData.entries && authenticateLogPagedData.entries.map((log) => {
                return <Timeline.Item key={log.id} dot={timelineDot(log)}>
                    <p style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>{<LocalTimeAgo date={dayjs(log.inserted_at).add(8, 'hours').format("YYYY-MM-DD HH:mm")} />} </span>
                        {authenticateBy(log.authenticate_by)}

                    </p>
                    <span style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>
                            <Tag>{log.request_device_data.version}</Tag>
                            {log.request_device_data?.device_brand && <Tag> {log.request_device_data?.device_brand}</Tag>}
                            <Tag> {log.request_ip}</Tag>
                            <Tag> {log.data?.province}</Tag>
                            <Tag> {log.data?.city}</Tag>
                            <Tag> {log.data?.ip_addr || log.data?.isp}</Tag>
                        </span>
                    </span>
                </Timeline.Item>
            })}
        </Timeline>
        <Pagination current={authenticateLogPagedData?.page_number} showQuickJumper size="small" total={authenticateLogPagedData?.total_count} pageSize={authenticateLogPagedData?.page_size || 10} onChange={(page, size) => handleAuthenticateLogPageChange(props, setAuthenticateLogPagedData, setfilterAuthenticateBy, page, size, filterAuthenticateBy)} />
    </>
}

export default AuthenticateLogs;