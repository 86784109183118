import React from "react";
import { Table } from 'antd';
import { connect } from 'react-redux';
import dayjs from "dayjs"
import './FinanceOrderRefund.css'
import { WechatOutlined, AlipayOutlined, AppleFilled } from '@ant-design/icons';
import UserAvatar from "../components/user/UserAvatar";


const { Column } = Table;
class FinanceOrderRefund extends React.Component {

    state = {
        channel: null,
        data: {},
        loading: false,
        page: 1,
        size: 10,
        query: {},
        channelCount: {}
    }

    loadData(page, size) {
        page = page || this.state.data.page_number
        size = size || this.state.data.page_size
        this.state.channel.push("page-to", { page, size })
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    componentDidMount() {
        const channel = this.props.socket.channel("statistics:finance_order:refund")
        channel.join()
        channel.on("data", ({ data }) => {
            this.setState({ data })
        })

        this.setState({ channel })
    }

    handleOnTableChange(_pagination, _filters, sorter) {
        console.log(sorter)
    }

    tradeOrderIcon(text) {
        switch (text) {
            case "acupoint:trade:channel::wechat":
                return <span className="icon"><WechatOutlined style={{ color: "green" }} /></span>

            case "acupoint:trade:channel::alipay":
                return <span className="icon"><AlipayOutlined style={{ color: "#027AFF" }} /></span>

            default:
                return <span className="icon"><AppleFilled style={{ color: "black" }} /></span>
        }
    }


    render() {
        let total = 0
        for (let channel in this.state.channelCount)
            total = total + this.state.channelCount[channel]

        total = Math.round(total)
        return <div className="page trade-order-refund">
            <h1>订单退款</h1>

            {
                this.state.data.entries && <Table dataSource={this.state.data.entries} rowKey={item => item.id}
                    scroll={{ y: document.documentElement.clientHeight - 300 }}
                    size="middle"
                    onChange={this.handleOnTableChange.bind(this)}
                    rowClassName={obj => `status-${obj.status}`}
                    pagination={{
                        onChange: (page, size) => { this.loadData(page, size) },
                        total: this.state.data.total_entries,
                        current: this.state.data.page_number,
                        pageSize: this.state.data.page_size,
                        showQuickJumper: true
                    }}>
                    <Column title="退款时间" align="center" dataIndex="refund_at" width={180} key="refund_at" render={(text) => {
                        const refund_at = dayjs(`${text}`)
                        return <div>
                            <div>{refund_at.add(8, 'hour').format("YYYY-MM-DD HH:mm:ss")}</div>
                        </div>
                    }} />
                    <Column title="订单编号" align="center" dataIndex="no" width={250} key="no" />
                    <Column title="用户" width={80} align="center" dataIndex={["user", "avatar"]} key={["user", "avatar"]} render={(_text, obj) =>
                        <UserAvatar id={obj.user.id} nickname={obj.user.nickname} size={40} avatar={obj.user.avatar} vip={obj.user.group === "vip"} />} />
                    <Column title="购买项目" align="center" dataIndex={["pricing", "name"]} width={100} key={["pricing", "name"]} />
                    <Column title="支付价格" align="center" dataIndex="payer_amount" width={100} key="payer_amount" render={(text, obj) => text && (text / 100)} />
                    <Column title="本次退款" className="refund" align="center" dataIndex="refund_amount" width={100} key="refund_amount" render={(text, obj) => text && (text / 100)} />
                    <Column title="总计退款" className="total_refund" align="center" dataIndex="total_refund_amount" width={100} key="total_refund_amount" render={(text, obj) => text && (text / 100)} />
                    <Column title="支付方式" align="center" dataIndex="trade_channel_meta" width={100} key="trade_channel_meta" render={this.tradeOrderIcon.bind(this)} />
                    <Column title="渠道" align="center" dataIndex="channel" width={120} key="channel" />
                    <Column title="操作人" dataIndex={["operator", "nickname"]} key={["operator", "nickname"]} width={100} />
                    <Column title="退款备注" dataIndex="remark" key="remark" />
                </Table>
            }
        </div >
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FinanceOrderRefund);