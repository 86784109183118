import React, { useState, useRef, useEffect } from "react"
import { Form, Input, ConfigProvider, Select, message, Button } from "antd"
import { PlusOutlined } from '@ant-design/icons'
import UploadComponent from "../../../components/UploadComponent";
import configFunc from "../../../config";
import zhCN from 'antd/lib/locale/zh_CN';
import { Radio } from "antd";
import imageUploader from "quill-image-uploader";
import uploadFile from "../../../components/upload_file";
import ReactQuill, { Quill } from "react-quill"
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import Ajv from 'ajv';
import { Divider } from "antd";
import { Alert } from "antd";
import Sha256Encode from "../../../components/sha256";

import "./SceneForm.css"

const ajv = new Ajv({ allErrors: true, verbose: true });
Quill.register("modules/imageUploader", imageUploader);

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color', 'background'
]


const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
    ],
    imageUploader: {
        upload: (file) => {
            return new Promise(async (resolve, reject) => {
                const file_content = await file.arrayBuffer()
                console.log(file_content)
                const file_name = await Sha256Encode(file_content)

                uploadFile(window.store.getState().global.jsonRequest, `notification/scene/${file_name}`, "acupoint-attach", file, (path) => {
                    resolve(configFunc.AttachUrl(path));
                }, () => message.error("上传失败"))
            });
        },
    }
}

function NotificationSceneForm({ form, data, channels, hasDefaultContent, ...props }) {
    const [formData, setFormData] = useState(data)
    const [defaultAvatar, setDefaultAvatar] = useState(data?.default_avatar)
    const [contentType, setContentType] = useState(data?.default_content?.type || "text")
    const [content, setContent] = useState(null)
    // json编辑器的关联
    const jsonEditorRef = useRef()

    const handleUploadSuccess = ([file]) => {
        form.setFieldsValue({ defaultAvatar: configFunc.ResourceUrl(file.url) })
        setDefaultAvatar(configFunc.ResourceUrl(file.url))
    }


    const contentTypeComponent = (_contentType) => {
        switch (_contentType) {
            case "text":
                return <Input.TextArea style={{ height: 200 }} />
            case "quill":
                return <ReactQuill ref={props.quillRef} placeholder="请填写回答详情" theme="snow" style={{ height: 200, marginBottom: 70 }}
                    className="editor" value={content} modules={modules} onChange={setContent} formats={formats} />

            default:
                break;
        }
    }

    const formDataChanged = (changeValue) => {
        const default_content = { ...formData.default_content, ...changeValue["default_content"] }
        const default_external = [{ ...formData.default_external[0], ...changeValue["default_external"]?.[0] }]
        const default_push = { ...formData.default_push, ...changeValue["default_push"] }
        setFormData({ ...formData, ...changeValue, default_content, default_external, default_push })
    }

    const defaultContentRender = () => {
        switch (formData.default_content?.type) {
            case "text":
                return formData.default_content?.content_text
            case "quill":
                return <div dangerouslySetInnerHTML={{ __html: formData.default_content?.content_text }}></div>

            case "community_post":
                return <div style={{ padding: "5px 10px", background: "#fff", margin: 5 }}>
                    <div><b>中医应该怎么学？</b></div>
                    <div style={{ fontSize: 11 }}>阅读 12123 · 赞同 321</div>
                </div>
            default:
                break;
        }
    }

    return <ConfigProvider locale={zhCN}>
        <Form className="notification scene form" form={form} name="basic" labelCol={{ span: 5 }} wrapperCol={{ span: 17 }} autoComplete="off" style={{ display: "flex", margin: "0 20px" }} onValuesChange={formDataChanged}>
            <div style={{ width: "38%" }}>
                <Divider style={{ color: "#666" }}>频道设置</Divider>
                <Form.Item label="场景名" name="name" rules={[{ required: true, message: '必填' }]}>
                    <Input placeholder="如: 系统 会员 社区 点赞" disabled={!!formData?.id && !formData.can_manual_push} />
                </Form.Item>
                <Form.Item label="唯一键" name="key" rules={[{ required: true, message: '必填' }]}>
                    <Input placeholder="如：acupoint:notification:zhongjixuetang" disabled={!!formData?.id} />
                </Form.Item>
                <Form.Item label="场景分类" name="category" rules={[{ required: true, message: '必填' }]} help="仅用于管理时放便筛选">
                    <Input placeholder="如：社区、会员、财务等" />
                </Form.Item>
                <Form.Item label="所属频道" name="channel_key" rules={[{ required: true, message: '必填' }]} help="本场景会在此频道里发送消息">
                    <Select>
                        {channels.map((channel) => {
                            return <Select.Option key={channel.key} value={channel.key}>{channel.name}</Select.Option>
                        })}
                    </Select>
                </Form.Item>


                <Form.Item label="默认头像" help="可不填">
                    <UploadComponent path={["notification", "scene"].join("/")} bucket="acupoint-resource" maxCount={1}
                        listType="picture-card" showUploadList={false}
                        onChange={(fileList) => handleUploadSuccess(fileList)}>
                        {defaultAvatar ? <img src={defaultAvatar} alt="avatar" style={{ width: '100%' }} /> : <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>拖到这里</div>
                        </div>}
                    </UploadComponent>
                </Form.Item>

                <Form.Item label="图标地址" name="defaultAvatar" initialValue={defaultAvatar}>
                    <Input disabled />
                </Form.Item>

                <Divider style={{ color: "#666" }}>消息内容设置</Divider>

                <Form.Item label="内容类型" name={["default_content", "type"]} initialValue={contentType} rules={[{ required: true, message: '必填' }]}>
                    <Radio.Group buttonStyle="solid" size="small" onChange={(e) => setContentType(e.target.value)} value={contentType} disabled={!hasDefaultContent}>
                        <Radio.Button value="text">文本</Radio.Button>
                        <Radio.Button value="quill">富文本</Radio.Button>
                        <Radio.Button value="community_post" disabled>社区问答</Radio.Button>
                        <Radio.Button value="community_comment" disabled>社区评论</Radio.Button>
                        <Radio.Button value="community_comment_like" disabled>社区评论点赞</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label="标题" name={["default_content", "title"]} rules={[{ required: true, message: '必填' }]} help="消息列表展示">
                    <Input placeholder="如：您的发帖被举报下架" />
                </Form.Item>

                <Form.Item label="简介" name="default_summary" rules={[{ required: true, message: '必填' }]} help="在频道列表时展示">
                    <Input.TextArea autoSize style={{ minHeight: "4em" }} maxLength={99} />
                </Form.Item>
                {["community_post", "community_comment", "community_comment_like"].indexOf(contentType) === -1 &&
                    <Form.Item label="内容" name={["default_content", "content_text"]} rules={[{ required: true, message: '必填' }]}>
                        {contentTypeComponent(contentType)}
                    </Form.Item>
                }
            </div>
            <div style={{ width: "38%", margin: "0 20px" }}>

                <Divider style={{ color: "#666" }}>额外操作</Divider>
                <Form.Item label="类型" name={["default_external", 0, "type"]} initialValue={`undefined`}>
                    <Radio.Group buttonStyle="solid" size="small">
                        <Radio.Button value="button">按钮</Radio.Button>
                        <Radio.Button value={`undefined`}>无</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                {formData.default_external[0]?.type === "button" && <>
                    <Form.Item label="按钮文本" name={["default_external", 0, "text"]} help="此处可以留空，若留空，在消息列表中将不会展示。点击消息后直接执行操作。">
                        <Input placeholder="如：去处理 去申诉 去查看" />
                    </Form.Item>

                    <Form.Item label="按钮URL" name={["default_external", 0, "url"]} >
                        <Input placeholder="可以是外部网址或者APP的内部页面" />
                    </Form.Item>

                    <Form.Item label="附加参数" name={["default_external", 0, "params"]} initialValue={{}}>
                        <Editor ref={jsonEditorRef} ace={ace} mode="code" ajv={ajv} language="zh-CN" />
                    </Form.Item>
                </>}

                <Divider style={{ color: "#666" }}>推送配置</Divider>
                <Form.Item label="推送标题" name={["default_push", "title"]} >
                    <Input />
                </Form.Item>

                <Form.Item label="推送内容" name={["default_push", "content"]} >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item label="点击打开URL" name={["default_push", "url"]} >
                    <Input placeholder="APP的内部页面" />
                </Form.Item>

                <Form.Item label="附加参数" name={["default_push", "params"]} initialValue={{}}>
                    <Editor ref={jsonEditorRef} ace={ace} mode="code" ajv={ajv} language="zh-CN" />
                </Form.Item>

            </div>
            <div style={{ width: "24%" }}>
                <Divider style={{ color: "#666" }}>频道列表预览</Divider>
                <div style={{ background: "#f8f8f8", borderRadius: "5px", padding: 10, display: "flex" }}>
                    <div style={{ width: 55, padding: 5 }}>
                        <img src={formData.channel_icon} style={{ width: '100%' }}></img>
                    </div>
                    <div>
                        <div>{formData?.channel_name}</div>
                        <div style={{ color: "#aaa" }}>{formData.default_summary}</div>
                        <div style={{ color: "#aaa" }}>01-01 00:00:00</div>
                    </div>
                </div>

                <Divider style={{ color: "#666" }}>频道内消息列表预览</Divider>
                <div className="review in-channel" style={{ background: "#f8f8f8", borderRadius: "5px", padding: 10 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {defaultAvatar && <img width={16} src={defaultAvatar}></img>} &nbsp; {formData?.default_content?.title || "请在左边设置标题"}</div>
                    <div style={{ color: "#888" }} className="content">{defaultContentRender()}</div>
                    {formData.default_external.length > 0 && <div>
                        {formData.default_external.map(item => <Button size="small" type="link" key={item.text}>{item.text}</Button>)}
                    </div>}
                    <div style={{ color: "#aaa" }}>01-01 00:00:00</div>
                </div>

                <Divider style={{ color: "#666" }}>推送预览</Divider>
                <div style={{ background: "#f8f8f8", borderRadius: "5px", padding: 10, display: "flex" }}>
                    <div>
                        <div>{formData?.default_push?.title}</div>
                        <div style={{ color: "#aaa" }}>{formData?.default_push?.content}</div>
                    </div>
                </div>

                <Divider style={{ color: "#666" }}>附录</Divider>
                <Alert type="warning" closable={false} message="此处为变量，可以在'简介'、'标题'、'内容'、'URL'、'附加参数'里使用" />
                <div style={{ background: "#f8f8f8", borderRadius: "5px", padding: 10, marginTop: 20 }}>
                    {Object.keys(formData?.variables || [])?.map(key => {
                        return <div key={key}><span style={{ color: "orange" }}>{"{"}{key}{"}"}</span>: {formData.variables[key]}</div>
                    })}
                </div>
            </div>
        </Form>
    </ConfigProvider>
}

export default NotificationSceneForm;