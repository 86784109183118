import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import React from 'react';
import { connect } from "react-redux";

// type=> acupoint, illness, meridian
function IAMRemoteSelect({ debounceTimeout = 500, type, params = {}, ...props }) {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchRef = React.useRef(0);
    let channel = props.channel

    function fetchUserList(name) {
        return channel.push(`search-${type}`, { ...params, name })
    }

    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchUserList(value).receive("ok", ({ data }) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                data = data.map((item) => {
                    return {
                        id: item.id,
                        key: item.id,
                        value: item.id,
                        label: item.name
                    }
                })


                setOptions(data);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchUserList, debounceTimeout]);

    return (
        <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
        />
    );
}

const mapStateToProps = ({ global }) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(IAMRemoteSelect)

