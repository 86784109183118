import { useState, useEffect } from "react"

import { Menu as MenuAction } from '../store/global/actions'
import { connect } from "react-redux"

import UserAvatar from "../components/user/UserAvatar";
import { Tooltip, Table, Form, Button, Input, Select } from 'antd';
import 'jsoneditor-react/es/editor.min.css';
import { QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';
import UTCTimeAgo from "../components/utc_time_ago"
import LocalTimeAgo from "../components/local_time_ago"

function InviteLog(props) {
    const [channel, setChannel] = useState(null)
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const [params, setParams] = useState({})

    useEffect(() => {
        props?.setActiveKey("COMMUNITY")

        let _channel = props.socket.channel("marketing:invite_code:log", {})
        _channel.join()
        setChannel(_channel)

        _channel.on("data", ({ data }) => {
            setLoading(false)
            setData(data)
        })

        return () => {
            if (!!_channel) _channel.leave()
        }
    }, [])


    const handlePageTo = (page, size) => {
        setLoading(true)
        channel.push("page-to", { page, size })
            .receive("ok", () => setLoading(false))
    }

    const handleSearch = (values) => {
        setParams(values)

        channel.push("query", values)
            .receive("ok", () => setLoading(false))
    }

    return <div className="page marketing invite-code">
        <h1>邀请历史 </h1>
        <div className="actions" style={{ display: "flex", marginBottom: 10 }}>
            <Form autoComplete="off" layout="inline" onFinish={handleSearch} initialValues={params} style={{ display: "flex", alignItems: "center" }}>
                <Form.Item name="code" label="邀请码">
                    <Input placeholder="精确匹配"></Input>
                </Form.Item>
                <Form.Item name="status" label="状态" initialValue={null}>
                    <Select style={{ minWidth: 120 }}>
                        <Select.Option value={null}>全部</Select.Option>
                        <Select.Option value="pending">等待结算</Select.Option>
                        <Select.Option value="success">结算完成</Select.Option>
                        <Select.Option value="failed">结算失败</Select.Option>
                    </Select>
                </Form.Item>
                <Button type="primary" htmlType="submit">查询</Button>
                <Button onClick={() => handleSearch({})} htmlType="reset">清空</Button>
            </Form>
        </div>
        <div>
            <Table dataSource={data?.entries || []} loading={loading} pagination={{
                onChange: (page, size) => { handlePageTo(page, size) },
                total: data?.total_entries,
                current: data?.page_number,
                pageSize: data.page_size,
                showQuickJumper: true
            }} rowKey={d => d.id} style={{ flexGrow: 1 }}>

                <Table.Column align="center" title="销售时间" dataIndex="inserted_at" key="inserted_at" render={text => <LocalTimeAgo date={text} />} />
                <Table.Column align="center" title="结算时间" dataIndex="settle_at" key="settle_at" render={text => <LocalTimeAgo date={text}></LocalTimeAgo>} />
                <Table.Column align="center" title="邀请码" width={100} dataIndex="code" key="code" render={text => <Button icon={<SearchOutlined />} type="link" onClick={() => handleSearch({ code: text })}>{text}</Button>} />
                <Table.Column align="center" title="邀请用户" dataIndex={["reference_data", "user"]} key={["reference_data", "user"]} render={(user, item) => <UserAvatar nickname={item?.user?.nickname} size={40} avatar={item?.user?.avatar} id={item?.user?.id} vip={item?.user?.group === "vip"} />} />
                <Table.Column align="center" title="被邀请用户" dataIndex={["reference_data", "user"]} key={["reference_data", "user"]} render={user => <UserAvatar nickname={user?.nickname} size={40} avatar={user?.avatar} id={user?.id} vip={user?.group === "vip"} />} />
                <Table.Column align="center" title="订单号" dataIndex="reference_id" key="reference_id" />
                <Table.Column align="center" title="销售价格" dataIndex="price" key="price" />
                <Table.Column align="center" title="预计获得积分" dataIndex="adding_num" key="adding_num" />
                <Table.Column align="center" title="获得积分" dataIndex="coin_num" key="coin_num" />
                <Table.Column align="center" title="状态" dataIndex="status" key="status" render={(text, item) => {
                    switch (text) {
                        case "pending":
                            return "等待结算";
                        case "success":
                            return "结算完成";
                        case 'failed':
                            return <Tooltip title={"用户已退款"}>结算失败 <QuestionCircleOutlined /></Tooltip>;
                    }
                }} />
            </Table>
        </div>
    </div>
}

const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteLog)