import { useState } from "react";
import { DeleteOutlined, EditOutlined, ArrowUpOutlined, ArrowDownOutlined, FieldTimeOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Image, Slider, Tag, Carousel, Tooltip, Form, Popover, Select, Input } from "antd"
import Config from '../../../config'
import MediumForm from "./medium_form";
import { Link } from "react-router-dom";

import ContentType from "./content_type";

import debounce from "debounce"
import { message } from "antd";

// editing: 是否编辑模式
function Medium({ channel, ...props }) {
    const { medium, editable = false, onDelete = () => null, onSave = () => null, onMoveUp = () => null, onMoveDown = () => null } = props
    const name = medium?.name

    // 展示类型，展示或编辑
    const [mode, setMode] = useState(medium === null ? 'edit' : 'view')
    const [sliderValue, setSliderValue] = useState(50)
    const [appLinkForm] = Form.useForm();

    // 应用内内容链接
    const [appLinkSearchResult, setAppLinkSearchResult] = useState({ participle: [] })
    const [appLinkSearching, setAppLinkSearching] = useState(false)
    const [appLinkFormPopShow, setAppLinkFormPopShow] = useState(false)

    let content = ""

    const handleEditClick = () => {
        setMode("edit")
    }

    const handleOnSave = (values) => {
        setMode("view")
        onSave(values)
    }

    const handleOnCancel = () => {
        setMode("view")
        if (medium === null) {
            onDelete()
        }
    }

    // 处理应用内链接的搜索
    const handleAppLinkSearch = (text) => {
        if (text.length < 1) return;
        setAppLinkSearching(true)
        channel.push("search-content", { text })
            .receive("ok", (result) => {
                setAppLinkSearchResult(result)
                setAppLinkSearching(false)
            })
    }


    // 提交app链接
    let handleAppLinkSubmit = ({ label, content_type_and_id }) => {
        // sub_id 次级内容的id，比如视频课程中的课时id
        let [content_type, content_id, content_name, sub_id] = content_type_and_id.split(":")
        let data = { media_id: medium.id, label, content_type, content_id, content_name: content_name, sub_id }
        channel.push("save-app-link-to", data)
            .receive("ok", () => {
                message.success("添加成功")
                setAppLinkFormPopShow(false)
            })
    }

    // 取消绑定app链接
    let handleAppLinkRevoke = () => {
        channel.push("revoke-app-link-to", { media_id: medium.id })
            .receive("ok", () => {
                message.success("取消注销成功")
            })
    }

    const blockTypeEditable = () => {
        return ["song"].indexOf(medium?.type) < 0
    }

    // 搜索结果的选项
    const searchResultOptions = () => {
        return appLinkSearchResult?.similar?.map((item) => {
            return {
                label: item.label,
                options: item.data?.map(subItem => {
                    let label = subItem.name
                    let value = `${subItem.content_type}:${subItem.content_id}:${subItem.name}`
                    switch (item.type) {
                        case "video_course":
                            label = `${subItem.name}-${subItem.lesson?.name}`

                            // 第4个，视频课程中的课时id，对应数据中的subid
                            value = `${subItem.content_type}:${subItem.content_id}:${subItem.name}:${subItem.lesson?.id}`
                            break;

                        default:
                            break;
                    }


                    return { label: label, key: value, value }
                })
            }
        }) || [];
    }


    switch (medium?.type) {
        case "title":
            break;
        case "sub_title":
            break;
        case "str":
            var description = (medium?.description || medium?.value || "").replaceAll("\n", "<br />")
            content = <div className="content" dangerouslySetInnerHTML={{ __html: description }}></div>;
            break;

        case "song":
            content = <audio src={Config.ResourceUrl(medium.path)} style={{ width: "100%", maxHeight: "100%" }} />
            break;
        case "rhyme":
            var description = (medium?.description || medium?.value || "").replaceAll("\n", "<br />")
            content = <div>
                <div className="content" dangerouslySetInnerHTML={{ __html: description }}></div>
                <audio src={Config.ResourceUrl(medium.path)} style={{ width: "100%", maxHeight: "100%" }} controls />
                <div className="content">
                    <p>相关歌赋</p>
                    {
                        medium?.term_poetics?.map(({ id, name }) => <Tag key={`${name}-term_poetics-${id}-${name}`}><Link to={`/cms/term/Poetics/${name}`}>{name}</Link></Tag>)
                    }
                </div>
            </div>;
            break;

        case "picture":
            content = <div className="content" ><Image src={Config.ResourceUrl(medium.path)} alt="avatar" style={{ maxWidth: '100%', maxHeight: "100%" }} /></div>
            break;
        case "video":
            content = <div className="content">
                <video controls preload="none" poster={Config.ResourceUrl(medium.thumbnailPath)}>
                    <source src={Config.ResourceUrl(medium.path)} type="video/mp4"></source>
                </video>
            </div>
            break;
        case "acupoint":
            content = <div className="content">
                {
                    (medium?.medicineIllnessList || medium?.value).map(({ id, name }) => <Tag key={`${name}-acupoint-${id}-${name}`}><Link to={`/cms/acupoint/ancestor/${name}`}>{name}</Link></Tag>)
                }
            </div>
            break;
        case "illness":
            content = <div className="content">
                {
                    (medium?.medicineIllnessList || medium?.value).map(({ id, name }) => <Tag key={`${name}-illness-${id}-${name}`}><Link to={`/cms/illness/${name}`}>{name}</Link></Tag>)
                }
            </div>
            break;
        case "term_poetics":
            content = <div className="content">
                {
                    medium?.value.map(({ id, name }) => <Tag key={`${name}-term_poetics-${id}-${name}`}><Link to={`/cms/term/Poetics/${name}`}>{name}</Link></Tag>)
                }
            </div>
            break;
        case "position":
            content = <div className="content" style={{ position: "relative", height: 240 }}>
                {medium.path.split("；").map((p, i) => {
                    const sliverValuePercent = (sliderValue / 100)
                    let opacity = 0;
                    switch (i) {
                        case 0:
                            opacity = 1 - sliverValuePercent * 2
                            break;
                        case 1:
                            opacity = -0.5 + sliverValuePercent * 3
                            break;
                        case 2:
                            opacity = -1 + sliverValuePercent * 2
                            break;
                    }

                    return <img key={`${medium?.name}-position-${p}-${i}`} style={{ position: "absolute", top: 5, left: 0, maxWidth: '100%', maxHeight: "100%", opacity: opacity }} src={Config.ResourceUrl(p)} alt="avatar" />
                })}
                <Slider defaultValue={sliderValue} style={{ marginTop: 200 }} onChange={(value) => setSliderValue(value)} />
            </div>;
            break;

        case "carousel":
            content = <div className="content" style={{ position: "relative", height: 240 }}>
                <p style={{ textAlign: "center" }}><FieldTimeOutlined /> {medium.duration} </p>
                <Carousel autoplay className="carousel">
                    {medium.paths.map(path => {
                        return <img key={`${medium?.name}-carousel-${path}`} src={Config.ResourceUrl(path)} />
                    })}
                </Carousel>
            </div>;
            break;
        case "internal_link":
            content = <div className="content">
                {
                    (medium?.value || []).map(({ id, name }) => <Tag key={`${name}-internal_link-${id}-${name}`}>{name}</Tag>)
                }
            </div>
            break;


        default:
            content = <div className={`block ${medium?.type}`} key={name}>
                {name}
            </div>
            break;
    }

    return mode === "view" ? <div className={`block type-${medium?.type}`}>
        <div className={`title ${!!name ? "" : "blank"}`}>
            <span className="name">{name}</span>
            <span className="edit">
                {
                    !editable && medium.data?.audio?.url &&
                    <audio controls preload="meta">
                        <source src={medium.data?.audio?.url} type="audio/mpeg" />
                    </audio>
                }
                {
                    editable ? <>
                        {
                            blockTypeEditable() && <>
                                <Tooltip title="编辑"><Button size="small" type="link" icon={<EditOutlined />} onClick={handleEditClick}> </Button></Tooltip>
                                <Tooltip title="删除"><Button size="small" type="link" icon={<DeleteOutlined />} onClick={onDelete}> </Button></Tooltip>
                            </>
                        }
                        <Tooltip title="上移"><Button size="small" type="link" icon={<ArrowUpOutlined />} onClick={onMoveUp}> </Button></Tooltip>
                        <Tooltip title="下移"><Button size="small" type="link" icon={<ArrowDownOutlined />} onClick={onMoveDown}> </Button></Tooltip>
                        {!!medium?.id && <Tooltip title="搜索并链接到其他资源">
                            <Popover title="搜索并关联的资源" open={appLinkFormPopShow} onOpenChange={open => setAppLinkFormPopShow(open)} width={250} content={
                                <Form style={{ width: 300 }} form={appLinkForm} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off" onFinish={handleAppLinkSubmit}>
                                    <Form.Item label="展示的文字" initialValue={medium.app_router?.label} name="label" rules={[{ required: true, message: '不能为空' }]}>
                                        <Input placeholder="App中显示的链接文字" />
                                    </Form.Item>
                                    {medium.app_router && <Form.Item label="绑定的资源">
                                        <span className="ant-form-text">{ContentType.toText(medium.app_router.arguments?.type)} - {medium.app_router?.arguments?.name}</span>
                                    </Form.Item>}
                                    <Form.Item label="关联资源" name="content_type_and_id" rules={[{ required: true, message: '不能为空，修改的时候请重新搜索' }]} help={!!medium.app_router && "修改时需要重新搜索选择"}>
                                        <Select showSearch placeholder="搜索后选择" optionLabelProp="label" filterOption={false} loading={appLinkSearching} onSearch={debounce((text) => handleAppLinkSearch(text), 500)} options={searchResultOptions()}>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item wrapperCol={{ xs: { span: 8, offset: 8, } }}>
                                        <Button.Group>
                                            <Button size="small" type="primary" htmlType="submit">保存</Button>
                                            {!!medium.app_router && <Button size="small" type="primary" danger onClick={() => handleAppLinkRevoke()}>取消绑定</Button>}
                                        </Button.Group>
                                    </Form.Item>
                                </Form>
                            } trigger="click">
                                {!!medium.app_router ? <a style={{ fontSize: 14, fontWeight: "normal", color: "#dcb677", maxWidth: 30 }}><LinkOutlined /> {medium.app_router?.label}</a> : <Button size="small" type="link" disabled={!medium?.id} icon={<LinkOutlined />}></Button>}
                            </Popover>
                        </Tooltip>}
                    </> :
                        <>
                            {!!medium.app_router && <a style={{ fontSize: 14, fontWeight: "normal", color: "#dcb677", maxWidth: 30 }}>{medium.app_router?.label}({medium.app_router?.arguments?.name})</a>}
                        </>
                }
            </span>
        </div>

        {content}
    </div> : <MediumForm {...props} channel={channel} onSave={handleOnSave} onCancel={handleOnCancel} />
}

export default Medium;