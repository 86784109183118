
import React from "react"
import { Menu as MenuAction } from '../store/global/actions'
import { Table, message, Button, Input, Switch, Tag, Tabs, Empty, Divider, Pagination } from "antd"
import { CloseOutlined } from '@ant-design/icons'
import Highlighter from "react-highlight-words";
import { connect } from "react-redux"
import { Link } from "react-router-dom";
import UTCTimeAgo from "../components/utc_time_ago"

const { Column } = Table;
class HotSearch extends React.Component {

    state = {
        channel: null,
        data: { hot_searchs: [], ban_searchs: [] },
        loading: true,
        clickToBan: false,
        // 搜索结果
        searchResult: { participle: [] },
        // 正在搜索的词
        searchText: "",
        // 当前搜索的分类
        ownerType: "null",
        searchLogs: {}
    }

    componentDidMount() {
        this.props.setActiveKey("HOT-SEARCH")

        let channel = this.props.socket.channel("operation:hot-search")
        channel.on("data", (data) => {
            this.setState({ data: data, loading: false })
        })
        channel.join()
            .receive("ok", () => {
                this.handleSearchLogPageChange(null, null)
            })

        this.setState({ channel })
    }

    componentWillUnmount() {
        if (this.state.channel)
            this.state.channel.leave()
    }

    handleSearch(text) {
        this.setState({ searchText: text, ownerType: "null" })
        this.state.channel.push("search", { text })
            .receive("ok", (result) => {
                this.setState({ searchResult: result })
            })
    }

    handleClickToBan(text) {
        this.state.channel.push("ban", { text })
            .receive("ok", () => {
                message.success(`已屏蔽: ${text}`, 1)
            })
    }

    handleClickToUnBank(text) {
        this.state.channel.push("unban", { text })
            .receive("ok", () => {
                message.success(`已恢复: ${text}`, 1)
            })
    }

    handleOwnerTypeChange(type) {
        if (type === "null") {
            this.setState({ ownerType: "null" })
            return this.handleSearch(this.state.searchText)
        }
        this.setState({ ownerType: type })
        this.state.channel.push("search", { text: this.state.searchText, owner_type: type, limit_count: 20 })
            .receive("ok", (result) => {
                this.setState({ searchResult: result })
            })
    }

    handleSearchLogPageChange(page, size) {
        this.state.channel.push("search-log", { page: page || 1, size: size || 100 })
            .receive("ok", data => this.setState({ searchLogs: data }))
    }

    onwerView(item) {
        let name = ""
        switch (item.type) {
            case "Illness":
            case "illness":
                name = <Link key={item.name} target="_blank" to={`/cms/illness/${item.name}`}>{item.name}</Link>
                break;
            case "Acupoint":
            case "acupoint":
                name = <Link key={item.name} target="_blank" to={`/cms/acupoint/ancestor/${item.name}`}>{item.name}</Link>
                break;
            case "Herb":
            case "herb":
                name = <Link key={item.name} target="_blank" to={`/cms/herb/${item.name}`}>{item.name}</Link>
                break;
            case "Food":
            case "food":
                name = <Link key={item.name} target="_blank" to={`/cms/food/${item.name}`}>{item.name}</Link>
                break;

            default:
                name = item.name
                break;
        }

        return <div key={item.name}>
            <p style={{ fontWeight: 600 }}>{name} </p>
            <Highlighter style={{ maxHeight: "3em", overflow: "hidden", color: "#666", display: "block", marginBottom: 10 }}
                searchWords={[this.state.searchResult?.participle]}
                textToHighlight={item.description}></Highlighter>
        </div>
    }

    render() {
        return <div className="page pricing" style={{ margin: 20 }}>
            <h1>热搜词 </h1>
            <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}>
                    <h1>搜索</h1>
                    <div>
                        <Input size="large" style={{ width: "40vw" }} onChange={(e) => this.handleSearch(e.target.value)} value={this.state.searchText} />
                    </div>
                    <br />
                    {
                        this.state.searchResult?.participle?.length > 0 && <p>
                            分词：
                            {this.state.searchResult?.participle?.map(word => <Tag>{word}</Tag>)}
                        </p>
                    }
                    <Tabs activeKey={this.state.ownerType} onChange={(type) => this.handleOwnerTypeChange(type)} items={[
                        { key: "null", label: "全部" },
                        { key: "Acupoint", label: "穴位" },
                        { key: "Meridian", label: "经络" },
                        { key: "Illness", label: "疾病" },
                        { key: "Herb", label: "中草药" },
                        { key: "Food", label: "食物" },
                        { key: "null", label: "全部" },
                    ]}>
                    </Tabs>

                    {this.state.searchResult?.exact?.map((item) => {
                        return <div key={item.name}>
                            <h3 className="group-name">{item.name}</h3>
                            <div style={{ padding: 10, background: '#fafafa' }}>
                                {item.medicineInfoList?.map(item => {
                                    return <p key={item.name}>
                                        <span>{item.title} ：</span>
                                        <Highlighter style={{ maxHeight: "3em" }} searchWords={[this.state.searchResult?.participle]} textToHighlight={item.description}></Highlighter>
                                    </p>
                                })}
                            </div>
                        </div>
                    })}
                    {this.state.searchResult?.data?.map(this.onwerView.bind(this))}

                    {this.state.searchResult?.similar?.map((item) => {
                        if (item.type === "video_course") {
                            return <div key={item.name} style={{ background: "#fefefe" }}>
                                <h3 className="group-name">{item.label}</h3>

                                <div style={{ padding: 10, background: '#fafafa' }}>
                                    {item.data?.map(album => {
                                        return <div key={`${album.name}-${album.lesson.name}`} style={{ display: "flex" }}>
                                            <p style={{ fontWeight: 600, marginRight: 10 }}><img src={album.icon} height={100}></img> </p>
                                            <p style={{ color: "#666" }}>
                                                <p> <Link target="_blank" to={`/cms/video-course-list/${album.id}/editor`}>{album.name}</Link></p>
                                                <p>课时：{album.lesson.name}</p>
                                                <p>{album.playback} 人学过</p>
                                            </p>
                                        </div>
                                    })}
                                </div>
                            </div>
                        } else {
                            return <div>
                                <h3 className="group-name">{item.label}</h3>
                                <div style={{ padding: 10, background: '#fafafa' }}>
                                    {item.data?.map(this.onwerView.bind(this))}
                                </div>
                            </div>
                        }
                    })}
                    {!this.state.searchResult.participle?.length > 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </div>
                <div style={{ width: "30vw", marginLeft: 20 }}>
                    <div>
                        <h3>
                            热搜词 <Switch checkedChildren="点词屏蔽" unCheckedChildren="点词搜索" checked={this.state.clickToBan} onChange={(checked) => this.setState({ clickToBan: checked })}></Switch>
                        </h3>
                        {this.state.data.hot_searchs.map((text) => <Button onClick={() => this.state.clickToBan ? this.handleClickToBan(text.text) : this.handleSearch(text.text)} key={text.text} size="small" style={{ margin: 3 }}>{text.text} </Button>)}
                    </div>
                    <br />
                    <div>
                        <h3>
                            屏蔽词
                        </h3>
                        {this.state.data.ban_searchs.map((text) => <Button onClick={() => this.handleClickToUnBank(text.text)} icon={<CloseOutlined />} key={text.text} size="small" style={{ margin: 3 }}>{text.text}</Button>)}

                        {this.state.data.ban_searchs.length < 1 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    </div>
                    <div>
                        <h3>
                            搜索历史
                        </h3>
                        <Pagination current={this.state.searchLogs?.page_number} size="small" total={this.state.searchLogs?.total_entries} pageSize={this.state.searchLogs?.page_size || 10} onChange={(page, size) => this.handleSearchLogPageChange(page, size)} />

                        {
                            this.state.searchLogs?.entries?.map(item => <Tag onClick={() => this.handleSearch(item.text)}>{item.text} <Divider type="vertical" /> <span style={{ color: "#a8a8a8" }}><UTCTimeAgo date={item.inserted_at}></UTCTimeAgo></span></Tag>)
                        }
                        {!this.state.searchLogs?.entries?.length > 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        <Pagination current={this.state.searchLogs?.page_number} size="small" total={this.state.searchLogs?.total_entries} pageSize={this.state.searchLogs?.page_size || 10} onChange={(page, size) => this.handleSearchLogPageChange(page, size)} />
                    </div>
                </div>
            </div>
        </div >
    }
}
const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(HotSearch)