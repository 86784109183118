const contentLinkSrc = (contentType, name) => {
    switch (contentType) {
        case "acupoint":
        case "acupoint_ancestor":
            return `/cms/acupoint/ancestor/${name}`
        case "acupoint_baby":
            return `/cms/acupoint/baby/${name}`
        case "acupoint_dongs":
            return `/cms/acupoint/dongs/${name}`
        case "meridian":
        case "meridian_ancestor":
            return `/cms/meridian/ancestor/${name}`
        case "meridiant_baby":
            return `/cms/meridian/baby/${name}`
        case "meridian_dongs":
            return `/cms/meridian/dongs/${name}`
        case "illness":
            return `/cms/illness/${name}`
        case "food":
            return `/cms/food/${name}`
        case "herb":
            return `/cms/herb/${name}`
        case "term_basic_theory":
            return `/cms/term/BasicTheory/${name}`
        case "term_terminology":
            return `/cms/term/terminology/${name}`
        case "term_doctor":
            return `/cms/term/doctor/${name}`
        case "term_ancient_book":
            return `/cms/term/AncientBook/${name}`
        case "term_diet_therapy":
            return `/cms/term/DietTherapy/${name}`
        case "term_regimen":
            return `/cms/term/regimen/${name}`
        case "term_poetics":
            return `/cms/term/poetics/${name}`
        default:
            return "";
    }
}

export default contentLinkSrc;