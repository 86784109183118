import { message } from "antd"

const uploadFile = (jsonRequest, path, bucket, file, uploadSuccess = () => null, uploadFailed = () => null) => {
    message.loading({ content: "上传中，请稍后...", key: "handleUploadFile", duration: 0 })
    jsonRequest.get("/api/attach/sign/policy", { bucket })
        .then(response => response.json())
        .then(({ data }) => {
            var formData = new FormData()
            const ossPath = path
            formData.append('key', ossPath)
            formData.append('policy', data.policy)
            formData.append('success_action_status', 200)
            formData.append('OSSAccessKeyId', data.accessid)
            formData.append('Signature', data.signature)
            formData.append('file', file)

            fetch(data.host, { method: "POST", body: formData, mode: "cors" })
                .then(() => {
                    message.success({ content: "上传成功", key: "handleUploadFile", duration: 2 })
                    uploadSuccess(ossPath)
                })
                .catch((e) => {
                    message.warning({ content: "上传失败", key: "handleUploadFile", duration: 2 })
                    uploadFailed(e)
                })
        })
        .catch((e) =>
            message.warning({ content: "请求签名失败", key: "handleUploadFile", duration: 2 }))
}

export default uploadFile