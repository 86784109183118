import React from "react";
import { Modal, Button, Switch, Tooltip, Divider, Pagination, Input, Radio, DatePicker, Select } from 'antd';
import 'jsoneditor-react/es/editor.min.css';
import { PlusSquareTwoTone, BarcodeOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import UserAvatar from "../components/user/UserAvatar";
import InviteCodeSIM from "./components/invite_code_sim";
import InviteCodeForm from "./components/invite_code_form";
import InviteCodeDetailModel from "./components/invite_code_detail_modal"
import InviteCodeItem from "./components/invite_code_item";
import Config from "../config";

import { connect } from "react-redux";
import debounce from "debounce"
import "./InviteCode.css"

const { RangePicker } = DatePicker;
class InviteCode extends React.Component {
    state = {
        channel: null,
        data: {},
        showCreateForm: false,
        showDetailCode: null,
        editable: false,
        ownerType: null,
        showCodeSIM: null,
        // 筛选想
        filter: {}
    }

    componentDidMount() {
        this.channelJoin(this.props.match.params.ownerType)
        this.setState({ ownerType: this.props.match.params.ownerType })
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.match.params.ownerType !== this.state.ownerType) {
            this.setState({ ownerType: props.match.params.ownerType })
            this.channelJoin(props.match.params.ownerType)
        }
    }

    channelJoin(bearerOwnerType) {
        if (!!this.state.channel) {
            this.state.channel.off("data")
            this.state.channel.leave()
        }

        const channel = this.props.socket.channel(`marketing:invite_code:list:${bearerOwnerType}`)
        channel.on("data", ({ data }) => this.setState({ data }))
        channel.join()
        this.setState({ channel })
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    pushEvent(event, params, opts = null) {
        return this.state.channel.push(event, params)
    }

    tableChange = (page, size) => {
        this.pushEvent("page-to", { page_size: size, page: page })
    }

    onFinish = (values) => {
        this.pushEvent("query", values)
    }

    onReset = () => {
        this.pushEvent("query", {})
    }


    // 结算类型翻译
    settleTypeLabel = (value) => {
        switch (value) {
            case "cycle":
                return "循环"

            case "single":
                return "单次"
            default:
                break;
        }
    }

    // 结算单位翻译
    settleUnitLabel = (value) => {
        switch (value) {
            case "money":
                return "金额:分"

            case "coin":
                return "积分"
            default:
                break;
        }
    }

    // 周期元数据翻译
    statusLabel = (value) => {
        switch (value) {
            case "enable":
                return "有效"

            case "disable":
                return "无效"

            case "finish":
                return "已完成"

            default:
                break;
        }
    }


    handleClickDetail(code) {
        this.setState({ showDetailCode: code })
    }


    handleFilterChange(field, value) {
        this.setState({ filter: { ...this.state.filter, [field]: value } }, () => {
            this.pushEvent("query", this.state.filter)
                .receive("ok", () => {
                })
        })
    }

    render() {
        const data = this.state.data;
        const filter = this.state.filter;

        return <div className="page marketing invite-code">
            <h1>邀请码 <Switch onChange={checked => this.setState({ editable: checked })} checkedChildren="开启编辑" unCheckedChildren="只读" /></h1>
            <div className="actions" style={{ display: "flex" }}>
                <Button icon={<PlusSquareTwoTone />} type="link" onClick={() => this.setState({ showCreateForm: this.state.ownerType })}>
                    新增邀请码
                </Button>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", maxWidth: 1600 }}>
                {data?.entries && data.entries.map(inviteCode => {
                    return <div style={{ margin: 20 }} key={`invite-code-${inviteCode.id}`}>
                        <InviteCodeItem inviteCode={inviteCode} onHandleClickDetail={(code) => this.handleClickDetail(code)} onHandClickSim={(code) => this.setState({ showCodeSIM: code })} />
                    </div>
                })}
            </div>
            <Pagination showQuickJumper total={data?.total_entries || 0} current={data?.page_number || 1} pageSize={data?.page_size || 12} onChange={(page, size) => { this.tableChange(page, size) }} />

            {
                this.state.channel &&
                <Modal title="新增邀请码" width={800} open={!!this.state.showCreateForm} footer={false} onCancel={() => this.setState({ showCreateForm: null })}>
                    <InviteCodeForm channel={this.state.channel} type={this.state.showCreateForm} closeModal={() => this.setState({ showCreateForm: null })} />
                </Modal>
            }

            {

                <Modal title="邀请码模拟器" width={1100} open={!!this.state.showCodeSIM} footer={false} onCancel={() => this.setState({ showCodeSIM: null })}>
                    <InviteCodeSIM code={this.state.showCodeSIM} channel={this.state.channel} closeModal={() => this.setState({ showCodeSIM: null })} />
                </Modal>
            }

            <InviteCodeDetailModel code={this.state.showDetailCode} channel={this.state.channel} close={() => this.setState({ showDetailCode: null })} />
        </div>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(InviteCode)