import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Quill } from "react-quill"
import { Button, Badge, Dropdown, Skeleton, message, Modal, Form, Segmented, Input } from "antd"
import { HeartTwoTone, StarOutlined, LeftOutlined, ShareAltOutlined, EditOutlined, MoreOutlined, CrownTwoTone } from "@ant-design/icons"
import Report from "../actions/Report"
import Config from "../../config"
import { Avatar } from "antd"
import { connect } from "react-redux";
import { userInformationModal } from "../../store/global/actions"
import NotificationPushForm from "./components/NotificationPushForm";
import Comments from "./components/Comments"

import { Provider } from 'react-redux';
import { legacy_createStore as createStore } from "redux"
import reducer from '../../store';
import dayjs from "dayjs"

const store = createStore(reducer)

// inReview 是否在审查中，隐藏一些操作和功能
function AnswerDetail({ channel, inReview, maskUser, userShow, ...props }) {
    let [answer, setAnswer] = useState(null)
    let [commentText, setCommentText] = useState("")
    let [pushForm] = Form.useForm()
    let answer_id = props.match?.params?.answer_id || props.answer_id
    let history = useHistory()

    useEffect(() => {
        setAnswer(null)
        refresh()
        let refreshRef = channel.on("refresh", () => refresh())

        return () => {
            channel.off("refresh", refreshRef)
        }
    }, [answer_id])

    const refresh = () => {
        channel.push("answer-detail", { id: answer_id })
            .receive("ok", (data) => {
                setAnswer(data)
            })
    }

    const handleClickLike = () => {
        channel.push("like-a-post", { id: answer_id })
            .receive("ok", () => { })
    }

    const handleMenuClick = ({ key }) => {
        switch (key) {
            case "report":
                Report({
                    onSubmit: (values) => {
                        channel.push("report", { ...values, id: answer_id, reference_type: "post" }).receive("ok", () => {
                            message.success("举报成功！")
                        })
                        return true
                    },
                    initial: { type: "违法违规" }
                })
                break;

            default:
                break;
        }
    }

    const handleCommentAdd = () => {
        channel.push("comment-add", { reference_type: "post", reference_id: answer_id, content: commentText }).receive("ok", () => {
            message.success("评论成功！")
            setCommentText("")
        })
    }

    // 添加版本
    const pushFormModal = () => {
        Modal.confirm({
            width: 1100,
            title: `精彩内容手动推送`,
            content: <Provider store={store}>
                <NotificationPushForm form={pushForm} postTitle={answer.title} postLikeCount={answer.like_count} postViewCount={answer.view_count} channel={channel} />
            </Provider>,
            cancelText: "取消",
            okText: "提交",
            onCancel: () => {
                pushForm.resetFields();
            },
            onOk: (close) => {
                const form = pushForm
                form
                    .validateFields()
                    .then(values => {
                        channel.push("push-notification-post-recommand", { ...values, post_id: answer_id })
                            .receive("ok", (resp) => {
                                close()
                                message.success("推送成功")
                                form.resetFields();
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    const tempCont = document.createElement("div");
    (new Quill(tempCont)).setContents(answer?.content?.delta);
    const contentHtml = tempCont.getElementsByClassName("ql-editor")[0].innerHTML.replaceAll(/src="(?!(http\:\/\/|https\:\/\/))(.+)"/g, `src="${Config.AttachUrl("")}/$2"`);

    return <div className={`page community simulator answer-detail ${inReview && "in-review"}`}>
        <div className="header">
            <span>
                <Button icon={<LeftOutlined />} size="small" type="link" onClick={() => history.push(`/community/simulator/question/${answer.parent_id}`)}></Button>
            </span>
            <span>
                <Dropdown menu={{ items: [{ key: "report", label: "举报" }], onClick: handleMenuClick }} trigger={['click']}>
                    <Button type="link" icon={<MoreOutlined />}></Button>
                </Dropdown>
            </span>
        </div>
        <div className="answer">
            <div className="title">{answer?.title || <Skeleton paragraph={{ rows: 0 }} active />}</div>
            <div className="header-info"><span>{answer?.view_count} 阅读</span></div>
            <div className="owner" onClick={() => userShow(answer?.owner_id)}>
                <Badge className="user-avatar" count={answer?.owner?.group === "vip" ? <CrownTwoTone twoToneColor="#eb2f96" rotate={45} /> : null} style={{ fontSize: 16 }}>
                    <Avatar size="large" src={Config.AvatarUrl(answer?.owner?.avatar)} />
                </Badge>

                &nbsp;
                {answer?.owner_name || "匿名用户"}
            </div>

            {answer ? <>
                <div className={`post-content content`} dangerouslySetInnerHTML={{ __html: contentHtml }}></div>
                <div className="footer-info">
                    {answer?.edit_at && <div style={{ fontStyle: "italic" }}> 本回答最后修改于 {dayjs(answer.edit_at).format("YYYY-MM-DD HH:mm")}</div>}
                    <div>
                        <span>发布于 {dayjs(answer?.inserted_at).format("YYYY-MM-DD HH:mm")}</span>
                        <span>IP属地 {answer?.ip_belongs_text || "未知"}</span>
                        <span>{answer?.view_count} 阅读</span>
                    </div>
                </div>
            </> : <Skeleton paragraph={{ rows: 4 }} active></Skeleton>}

            {!inReview &&
                <div className="comments">
                    <div className="input">
                        <Input size="small" value={commentText} onChange={(e) => setCommentText(e.target.value)} />
                        <Button size="small" type="link" onClick={() => handleCommentAdd()}>提交</Button>
                    </div>
                    <div className="summary" >
                        <span>评论 {answer?.comments?.total}</span>
                        <span>
                            <Segmented size="small" value={answer?.comments?.sort_type}
                                options={answer?.comments?.sort_types}
                            />
                        </span>
                    </div>
                    {!!channel && <Comments referenceType="post" referenceID={answer?.id} comments={answer?.comments} channel={channel} />}
                </div>
            }
        </div>
        <div className="actions">
            <Button disabled={inReview} size="small" type={answer?.like ? "primary" : "dashed"} icon={<HeartTwoTone />} onClick={handleClickLike}>赞同 {answer?.like_count}</Button>
            <div>
                <Button disabled={inReview} size="small" type="link" icon={<StarOutlined />}> {answer?.favorite_count}</Button>
                <Button disabled={inReview} icon={<ShareAltOutlined />} size="small" type="link" onClick={pushFormModal}>精彩推荐</Button>
                <Button disabled={inReview || maskUser?.id != answer?.owner_id} size="small" type="link" icon={<EditOutlined />} onClick={() => history.push(`/community/simulator/question/${answer?.parent_id}/answer/${answer?.id}/edit`)}> 修改</Button>
            </div>
        </div>
    </div>
}

const mapStateToProps = ({ global }) => {
    return {
        onlineUsers: global.onlineUsers
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        userShow: (userId) => {
            dispatch(userInformationModal.show(userId))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnswerDetail);