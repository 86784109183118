
import { Form, Input, InputNumber, Switch, message } from "antd"
import { useEffect, useState } from "react"
import { Button } from "antd"
import { connect } from "react-redux"
import { Menu as MenuAction } from "../../store/global/actions"
import { PlusOutlined } from '@ant-design/icons';
import "./PhoneSimulator.css"
import UploadComponent from "../../components/UploadComponent";
import configFunc from "../../config";


function HealthGuidance(props) {
    let [data, setData] = useState(null)
    let [channel, setCannel] = useState(null)
    const [diagnoseEntryUrl, setDiagnoseEntryUrl] = useState(null)
    const [dialogTopBgUrl, setDialogTopBgUrl] = useState(null)
    const [qrCode, setQrCode] = useState(null)

    const [form] = Form.useForm()

    props.setActiveKey("SETTING-HEALTH-GUIDANCE")

    const handleUploadSuccess = (field, setFunc, [file]) => {
        form.setFieldsValue({ [field]: configFunc.AttachUrl(file.url) })
        setFunc(configFunc.AttachUrl(file.url))
    }

    useEffect(
        () => {
            const _channel = props.socket.channel(`operation:settings:healthGuidance`)
            _channel.join()
            _channel.on("data", ({ data }) => {
                const jsonedData = JSON.parse((data[0] || { content: "{}" }).content)
                setData(jsonedData)
                setDiagnoseEntryUrl(jsonedData.diagnose_entry_url)
                setDialogTopBgUrl(jsonedData.dialog_top_bg_url)
                setQrCode(jsonedData.qr_code)
            })
            setCannel(_channel)

            return () => {
                !!_channel && _channel.leave()
            }
        }, [])

    const cleanField = (field, setFunc) => {
        form.setFieldsValue({ [field]: null })
        setFunc(null)
    }

    const onSave = (values) => {
        channel.push("set", { healthGuidance: JSON.stringify(values) })
            .receive("ok", () => { message.success({ content: "保存成功" }) })
    }

    return <div className="page settings" style={{ flexDirection: "column", display: "flex", height: "100%" }}>
        <h1>健康咨询设置</h1>

        <div style={{ display: "flex", flexGrow: 1 }}>
            {!!data &&
                <Form form={form} style={{ width: 500 }} initialValues={data} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off" onFinish={onSave}>
                    <Form.Item label="是否开启" name="enable" valuePropName="checked" rules={[{ required: true, message: '不能为空' }]}>
                        <Switch />
                    </Form.Item>
                    <Form.Item label="微信号" name="wechat_id" rules={[{ required: true, message: '不能为空' }]} >
                        <Input />
                    </Form.Item>
                    <Form.Item label="浮窗关闭沉默时长" name="silence" rules={[{ required: true, message: '不能为空' }]} >
                        <InputNumber addonAfter="小时" />
                    </Form.Item>

                    <Form.Item label="二维码" rules={[{ required: true, message: '不能为空' }]}>
                        <UploadComponent path={["information", "HealthGuidance"].join("/")} bucket="acupoint-attach" maxCount={1}
                            listType="picture-card" showUploadList={false}
                            onChange={(fileList) => handleUploadSuccess("qr_code", setQrCode, fileList)}>
                            {qrCode ? <img src={qrCode} alt="avatar" style={{ width: '100%' }} /> : <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>拖到这里</div>
                            </div>}
                        </UploadComponent>
                    </Form.Item>
                    <Form.Item label="浮窗URL" name="qr_code">
                        <Input disabled addonAfter={<Button type="link" size="small" onClick={() => cleanField("qr_code", setQrCode)}>清空</Button>} />
                    </Form.Item>

                    <Form.Item label="浮窗图片" rules={[{ required: true, message: '不能为空' }]}>
                        <UploadComponent path={["information", "HealthGuidance"].join("/")} bucket="acupoint-attach" maxCount={1}
                            listType="picture-card" showUploadList={false}
                            onChange={(fileList) => handleUploadSuccess("diagnose_entry_url", setDiagnoseEntryUrl, fileList)}>
                            {diagnoseEntryUrl ? <img src={diagnoseEntryUrl} alt="avatar" style={{ width: '100%' }} /> : <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>拖到这里</div>
                            </div>}
                        </UploadComponent>
                    </Form.Item>
                    <Form.Item label="浮窗URL" name="diagnose_entry_url" rules={[{ required: true, message: '不能为空' }]}>
                        <Input disabled />
                    </Form.Item>

                    <Form.Item label="弹窗背景" rules={[{ required: true, message: '不能为空' }]}>
                        <UploadComponent path={["information", "HealthGuidance"].join("/")} bucket="acupoint-attach" maxCount={1}
                            listType="picture-card" showUploadList={false}
                            onChange={(fileList) => handleUploadSuccess("dialog_top_bg_url", setDialogTopBgUrl, fileList)}>
                            {dialogTopBgUrl ? <img src={dialogTopBgUrl} alt="avatar" style={{ width: '100%' }} /> : <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>拖到这里</div>
                            </div>}
                        </UploadComponent>
                    </Form.Item>
                    <Form.Item label="弹窗背景URL" name="dialog_top_bg_url" rules={[{ required: true, message: '不能为空' }]}>
                        <Input disabled />
                    </Form.Item>

                    <Form.Item wrapperCol={{ xs: { span: 8, offset: 8, } }}>
                        <Button.Group>
                            <Button size="small" type="primary" htmlType="submit">保存</Button>
                        </Button.Group>
                    </Form.Item>
                </Form>
            }
        </div>
    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HealthGuidance)