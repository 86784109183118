import { Input } from "antd"
import { Radio } from "antd";
import { Form } from "antd"
import UploadComponent from "../../components/UploadComponent";
import configFunc from "../../config";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons"

function UserForm({ formRef, ...props }) {
    const [avatar, setAvatar] = useState(null)

    const handleUploadSuccess = ([file]) => {
        formRef.current.setFieldsValue({ avatar: configFunc.AttachUrl(file.url) })
        setAvatar(configFunc.AttachUrl(file.url))
    }

    return <div>
        <Form ref={formRef} initialValues={{ group: "mask" }} labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}>
            <Form.Item label="昵称" name="nickname" rules={[{ required: true, message: '不能为空，只后台可见，方便管理' }]} >
                <Input />
            </Form.Item>
            <Form.Item label="手机号码" name="phone" rules={[{ required: true, message: '不能为空, 11位' }]} >
                <Input maxLength={11} minLength={11} />
            </Form.Item>
            <Form.Item label="头像" rules={[{ required: true, message: '不能为空' }]}>
                <UploadComponent path={["user", "avatar"].join("/")} bucket="acupoint-attach" maxCount={1}
                    listType="picture-card" showUploadList={false}
                    onChange={(fileList) => handleUploadSuccess(fileList)}>
                    {avatar ? <img src={avatar} alt="avatar" style={{ width: '100%' }} /> : <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>拖到这里</div>
                    </div>}
                </UploadComponent>
            </Form.Item>
            <Form.Item label="头像地址" name="avatar">
                <Input disabled />
            </Form.Item>
            <Form.Item label="用户类别" name="group" rules={[{ required: true, message: '不能为空' }]} help="马甲仅供社区使用">
                <Radio.Group>
                    <Radio value="potential" disabled>普通会员</Radio>
                    <Radio value="mask">马甲</Radio>
                </Radio.Group>
            </Form.Item>
        </Form>
    </div>
}

export default UserForm