import React from "react";
import { List, Switch, Button, Popover, message, Form, Select, Input, Modal, Radio, Divider, Table, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { Menu as MenuAction } from '../../store/global/actions'

import { RocketOutlined, PlusSquareTwoTone, DeploymentUnitOutlined, CheckCircleTwoTone } from '@ant-design/icons';

import MediaViewer from "../components/media_editor";
import PasswordConfirm from "../../components/passwordConfirm";
import { useState } from "react";
import useLocalStorageState from "use-local-storage-state";
import ListGrid from "../components/ListGrid";

const { Column } = Table

function AcupointForm({ form, initial, meridianData }) {
    const [formInitialValues, setFormInitialValues] = useLocalStorageState("/cms/acupoint", { type: "dongs", meridian_id: null })
    const [meridians, setMeridians] = useState(meridianData[formInitialValues.type] || [])

    const handleFormChange = (_, values) => {
        setMeridians(meridianData[values.type] || [])
        setFormInitialValues({ type: values.type, free: values.free, meridian_id: values.meridian_id })
    }

    return <Form ref={form} name="basic" initialValues={initial} onValuesChange={handleFormChange} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
        <Form.Item label="穴位名称" name="name" rules={[{ required: true, message: '不能为空' }]}>
            <Input />
        </Form.Item>
        <Form.Item name="type" label="体系" initialValue={formInitialValues.type} rules={[{ required: true, message: '不能为空' }]}>
            <Radio.Group>
                <Radio value="ancestor">成人</Radio>
                <Radio value="baby">小儿</Radio>
                <Radio value="dongs">董氏奇穴</Radio>
            </Radio.Group>
        </Form.Item>
        <Form.Item name="meridian_id" label="所属分类" initialValue={formInitialValues.meridian_id} rules={[{ required: true, message: '不能为空' }]}>
            <Select>
                {meridians.map(m => <Select.Option key={m.index} value={m.index} >
                    {m.name}
                </Select.Option>)}
            </Select>
        </Form.Item>
        <Form.Item name="free" label="是否免费" initialValue={formInitialValues.free} valuePropName="checked">
            <Switch checkedChildren="是" unCheckedChildren="否" />
        </Form.Item>
    </Form>
}

class AcupointList extends React.Component {
    // 表格引用
    formRef = React.createRef()
    state = {
        channel: null,
        data: {},
        editable: false,
        loading: false,
        query: { name: this.props.match?.params?.name },
        classType: this.props.match?.params?.type,
        meridianData: {},
        editorHeight: 500,
    }

    joinChannel(props) {
        this.setState({ loading: true })
        console.log(this.state.classType)
        const channel = this.props.socket.channel("ops:resource:acupoint", { type: this.state.classType, page_size: 4, ...this.state.query })
        channel.on("data", ({ data }) => this.setState({ data, loading: false }))
        channel.on("meridian-data", ({ data }) => this.setState({ meridianData: data }))
        channel.join().receive("ok", () => { })
        this.setState({ channel })
    }

    componentDidMount() {
        this.props.setActiveKey("Acupoint")
        this.joinChannel(this.props)
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        !!this.state.channel && this.state.channel.off("data")
        !!this.state.channel && this.state.channel.leave()
        this.setState({ query: { ...this.state.query, name: nextProps.match?.params?.name }, classType: nextProps.match?.params?.type }, () => this.joinChannel(nextProps))
    }

    // 处理点击是否发布
    handleClickPublish(acupointID) {
        this.state.channel.push("publish!", { id: acupointID }).receive("ok", () => message.success("发布成功"))
    }

    // 处理点击是否删除
    handleClickDrop(acupointID) {
        this.state.channel.push("drop!", { id: acupointID }).receive("ok", () => message.success("删除成功"))
    }

    handlePageTo(page, size) {
        this.setState({ loading: true })
        this.state.channel.push("page-to", { page, size })
            .receive("ok", () =>
                this.setState({ loading: false }))
    }

    handleSearch(values) {
        this.setState({ loading: true, query: values })
        this.state.channel.push("search", { ...values })
            .receive("ok", () =>
                this.setState({ loading: false }))
    }

    handleAddDraft = () => {
        const channel = this.state.channel

        Modal.confirm({
            width: 500,
            title: '添加穴位',
            // content: AcupointForm({ ref: this.formRef, initial: {} }),
            content: <AcupointForm form={this.formRef} initial={{}} meridianData={this.state.meridianData} />,
            onOk: (close) => {
                const form = this.formRef.current
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        channel.push("add-draft", { ...values })
                            .receive("ok", (resp) => {
                                close()
                                message.success("创建成功，在列表中编辑")
                                this.handleSearch({ name: resp.name })
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    handleClickAddToGraphSuggest(id, name) {
        this.state.channel.push("add-to-graph-suggest", { content_type: `acupoint_${this.state.classType}`, content_id: id, content_name: name })
            .receive("ok", () => message.success("添加到图谱推荐列表成功"))
    }

    exportPasswordConfirmRef = React.createRef()
    handleClickExport() {
        PasswordConfirm({
            ref: this.exportPasswordConfirmRef,
            title: '密码确认',
            message: "导出数据会被记录在操作日志里，请谨慎操作。",
            onOk: ({ password }, close) => {
                message.loading({ content: '请求数据中...', key: "exporting", duration: 0 })
                this.state.channel.push("export-from-filter", { password }, 100000)
                    .receive("ok", ({ encrypt_str }) => {
                        navigator.clipboard.writeText(encrypt_str)
                            .then(() => {
                                message.success({ content: '已复制到剪切板中', key: "exporting", duration: 3 })
                                close()
                            })
                            .catch(() => {
                                message.error({ content: '写入剪切板错误', key: "exporting", duration: 3 })
                                navigator.clipboard.writeText("")
                            })

                    })
            }
        })
    }

    handleClickImport() {
        PasswordConfirm({
            ref: this.exportPasswordConfirmRef,
            title: '密码确认',
            message: "导入数据会被记录在操作日志里，请谨慎操作。",
            onOk: ({ password }, close) => {
                message.loading({ content: '导入中...', key: "importing", duration: 3 })
                navigator.permissions.query({ name: 'clipboard-read' })
                    .then(result => {
                        if (result.state === "granted" || result.state === "prompt") {
                            navigator.clipboard.readText()
                                .then(text => {
                                    this.state.channel.push("import-from-encrypt-str", { str: text, password: password }, 100000)
                                        .receive("ok", ({ review_data }) => {
                                            close()
                                            this.handleClickImportConfirm(review_data)
                                            message.warn({ content: '确认导入数据', key: "importing", duration: 3 })

                                            navigator.clipboard.writeText("")
                                        })
                                })
                                .catch(err => {
                                    console.log(err)
                                    message.error({ content: '读入剪切板错误', key: "importing", duration: 3 })
                                    navigator.clipboard.writeText("")
                                });
                        }
                    })
            }
        })
    }

    handleClickImportConfirm(reviewData) {
        Modal.confirm({
            title: '预览导入数据',
            width: 600,
            content: <Table dataSource={reviewData}>
                <Column title="名称" dataIndex="name" key="name"></Column>
                <Column title="数据库中存在(将忽略)" align="center" dataIndex="exists" key="exists" render={(value) => value && <CheckCircleTwoTone />}></Column>
            </Table>,
            onOk: (close) => {
                this.state.channel.push("import-confirm", {}, 100000)
                    .receive("ok", () => {
                        message.success({ content: '已导入', key: "importing", duration: 3 })
                        close()
                    })
                return false
            },
            okText: "确认导入"
        });
    }

    render() {
        return <div className="page resource-acupoint">

            {this.state?.channel &&
                <div className="actions" style={{ display: "flex" }}>
                    <Button icon={<PlusSquareTwoTone />} type="link" onClick={() => this.handleAddDraft()}>新增穴位</Button>
                </div>
            }

            <h1>穴位列表
                <Switch onChange={checked => this.setState({ editable: checked })} checkedChildren="开启编辑" unCheckedChildren="只读" />
            </h1>
            <div >
                <Form autoComplete="off" layout="inline" onFinish={this.handleSearch.bind(this)} initialValues={this.state.query} style={{ display: "flex", alignItems: "center" }}>
                    <Form.Item name="name" label="穴位名">
                        <Input placeholder="模糊查询"></Input>
                    </Form.Item>
                    <Form.Item name="meridian_id" label="经络" initialValue={null}>
                        <Select style={{ minWidth: 120 }}>
                            <Select.Option value={null}>全部</Select.Option>
                            {
                                this.state.meridianData && this.state.meridianData[this.props.match?.params?.type]?.map(m => <Select.Option key={m.index} value={m.index} >
                                    {m.name}
                                </Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Button type="primary" htmlType="submit">查询</Button>
                    <Button onClick={() => this.handleSearch({})} htmlType="reset">清空</Button>
                    {this.state.editable && <>
                        <Divider type="vertical" />
                        <Button.Group>
                            <Button onClick={this.handleClickImport.bind(this)}  >导入</Button>
                            <Button onClick={this.handleClickExport.bind(this)} danger>导出筛选结果</Button>
                        </Button.Group>
                    </>}
                </Form>
            </div>

            {this.state.data.entries &&
                <ListGrid
                    dataSource={this.state.data.entries}
                    loading={this.state.loading}
                    pagination={{
                        onChange: (page, size) => { this.handlePageTo(page, size) },
                        total: this.state.data.total_entries,
                        current: this.state.data.page_number,
                        pageSize: this.state.data.page_size,
                        showQuickJumper: true
                    }}
                    onHeightEdit={(height) => this.setState({ editorHeight: height })}
                    renderItem={item => (
                        <List.Item className="item-acupoint">
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <span style={{ fontSize: 20, fontWeight: "bold" }}>{item.name}</span>
                                <span>
                                    <Tooltip title="添加到图谱推荐表">
                                        <Button type="link" icon={<DeploymentUnitOutlined />} onClick={() => this.handleClickAddToGraphSuggest(item.id, item.name)} ></Button>
                                    </Tooltip>

                                    {this.state.editable && <span>{item.has_update &&
                                        <>
                                            <Divider type="vertical" />
                                            <Popover content={<Button onClick={() => this.handleClickPublish(item.id)} block type="primary" size="small">确认</Button>} title="确认发布？" trigger="click">
                                                <Button type="link" icon={<RocketOutlined />}></Button>
                                            </Popover>
                                        </>}
                                    </span>}
                                </span>
                            </div>
                            <div>
                                <MediaViewer ownerType="Acupoint" hasUpdate={item.has_update} ownerID={item.id} channel={this.state.channel} name={item.name} detail={item.detail} type="acupoint" editable={this.state.editable} height={this.state.editorHeight} />
                                {this.state.editable && <Popover trigger="click" placement="top" content={<Button onClick={() => this.handleClickDrop(item.id)} danger size="small" type="primary">确认删除</Button>} ><Button block type="primary" size="small">删除</Button></Popover>}
                            </div>
                        </List.Item>
                    )
                    } />
            }
        </div >
    }
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AcupointList);