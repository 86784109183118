import React from "react";
import { List, Tag, Button, Tabs, Modal, Divider, Input, Radio } from 'antd';
import { CheckCircleOutlined, IssuesCloseOutlined, PlusSquareTwoTone } from '@ant-design/icons';
import { Menu as MenuAction } from '../../store/global/actions'

import { connect } from "react-redux";
import EntryForm from "./form";
import "./index.css"
import ButtonGroup from "antd/lib/button/button-group";

const { TabPane } = Tabs;

class EntryList extends React.Component {
    state = {
        channel: null,
        data: {},
        loading: true,
        showCreateForm: false,
        referenceAcupoint: {},
        referenceIllness: {},
        referenceMeridian: {},
        referenceFood: {},
        referenceHerb: {},
        referenceTerminology: {},
        referenceDoctor: {},
        referenceAncientBook: {},
        referenceDietTherapy: {},
        referenceBasicTheory: {},
        referenceRegimen: {},
        referenceDescription: {},
        query: { permission: "all", state: "all", q: "" }
    }

    componentDidMount() {
        this.props.setActiveKey("ENTRY")
        const channel = this.props.socket.channel("ops:resource:entry")
        channel.on("data", (data) => this.setState({ data, loading: false }))
        channel.join()
        this.setState({ channel })
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    pushEvent(event, params, opts = null) {
        return this.state.channel.push(event, params)
    }

    tableChange = (page, size) => {
        this.pushEvent("page-to", { page_size: size, page: page })
    }

    stateTranslate(state) {
        switch (state) {
            case "publish": return <span style={{ color: "green" }}><IssuesCloseOutlined /> <span >发布</span></span>
            case "draft": return <span style={{ color: "orange" }}><IssuesCloseOutlined /> <span >草稿</span></span>
            case "complain": return <span style={{ color: "silver" }}><IssuesCloseOutlined /> <span >举报下架</span></span>

            default:
                break;
        }
    }

    handlePageTo(page, size) {
        this.setState({ loading: true })
        this.state.channel.push("page-to", { page, size })
            .receive("ok", () =>
                this.setState({ loading: false }))
    }

    handleEntryTabChange(key, id, name) {
        if (key === "ref") {
            this.pushEvent("match", { type: "Acupoint", entry_name: name, entry_id: id })
                .receive("ok", ({ data, bound_ids }) => {
                    let clone_references = { ...this.state.referenceAcupoint, [id]: { matches: data, bound_ids } }
                    this.setState({ referenceAcupoint: clone_references })
                })

            this.pushEvent("match", { type: "Meridian", entry_name: name, entry_id: id })
                .receive("ok", ({ data, bound_ids }) => {
                    let clone_references = { ...this.state.referenceMeridian, [id]: { matches: data, bound_ids } }
                    this.setState({ referenceMeridian: clone_references })
                })

            this.pushEvent("match", { type: "Illness", entry_name: name, entry_id: id })
                .receive("ok", ({ data, bound_ids }) => {
                    let clone_references = { ...this.state.referenceIllness, [id]: { matches: data, bound_ids } }
                    this.setState({ referenceIllness: clone_references })
                })

            this.pushEvent("match", { type: "Food", entry_name: name, entry_id: id })
                .receive("ok", ({ data, bound_ids }) => {
                    let clone_references = { ...this.state.referenceFood, [id]: { matches: data, bound_ids } }
                    this.setState({ referenceFood: clone_references })
                })
            this.pushEvent("match", { type: "Herb", entry_name: name, entry_id: id })
                .receive("ok", ({ data, bound_ids }) => {
                    let clone_references = { ...this.state.referenceHerb, [id]: { matches: data, bound_ids } }
                    this.setState({ referenceHerb: clone_references })
                })
            this.pushEvent("match", { type: "Elixir.Acupoint.Model.STD.Term.Terminology", entry_name: name, entry_id: id })
                .receive("ok", ({ data, bound_ids }) => {
                    let clone_references = { ...this.state.referenceTerminology, [id]: { matches: data, bound_ids } }
                    this.setState({ referenceTerminology: clone_references })
                })
            this.pushEvent("match", { type: "Elixir.Acupoint.Model.STD.Term.Doctor", entry_name: name, entry_id: id })
                .receive("ok", ({ data, bound_ids }) => {
                    let clone_references = { ...this.state.referenceDoctor, [id]: { matches: data, bound_ids } }
                    this.setState({ referenceDoctor: clone_references })
                })
            this.pushEvent("match", { type: "Elixir.Acupoint.Model.STD.Term.AncientBook", entry_name: name, entry_id: id })
                .receive("ok", ({ data, bound_ids }) => {
                    let clone_references = { ...this.state.referenceAncientBook, [id]: { matches: data, bound_ids } }
                    this.setState({ referenceAncientBook: clone_references })
                })
            this.pushEvent("match", { type: "Elixir.Acupoint.Model.STD.Term.DietTherapy", entry_name: name, entry_id: id })
                .receive("ok", ({ data, bound_ids }) => {
                    let clone_references = { ...this.state.referenceDietTherapy, [id]: { matches: data, bound_ids } }
                    this.setState({ referenceDietTherapy: clone_references })
                })
            this.pushEvent("match", { type: "Elixir.Acupoint.Model.STD.Term.BasicTheory", entry_name: name, entry_id: id })
                .receive("ok", ({ data, bound_ids }) => {
                    let clone_references = { ...this.state.referenceBasicTheory, [id]: { matches: data, bound_ids } }
                    this.setState({ referenceBasicTheory: clone_references })
                })
            this.pushEvent("match", { type: "Elixir.Acupoint.Model.STD.Term.Regimen", entry_name: name, entry_id: id })
                .receive("ok", ({ data, bound_ids }) => {
                    let clone_references = { ...this.state.referenceRegimen, [id]: { matches: data, bound_ids } }
                    this.setState({ referenceRegimen: clone_references })
                })
        } else if (key === "descs") {
            this.state.channel.push("ref-descriptions", { resource_id: id })
                .receive("ok", ({ data }) => {
                    let clone_references = { ...this.state.referenceDescription, [id]: data }
                    this.setState({ referenceDescription: clone_references })
                })
        }
    }

    // 关联所有类型
    handleClickBoundAllReferenceType(entry_name, entry_id) {
        this.pushEvent("bound-to-references", { entry_name, entry_id })
            .receive("ok", () => {
                this.handleEntryTabChange("ref", entry_id, entry_name)
            })
    }

    // 取消关联所有类型
    handleClickCancelBoundAllReferenceType(entry_name, entry_id) {
        this.pushEvent("cancel-bound-to-references", { entry_id })
            .receive("ok", () => {
                this.handleEntryTabChange("ref", entry_id, entry_name)
            })
    }

    // 搜索项变更
    handleQueryChange(field, value) {
        let clone = { ...this.state.query, [field]: value }
        this.setState({ query: clone })
        this.pushEvent("data", { query: clone })
    }

    render() {
        const data = this.state.data;

        return <div className="page resource-entry-list">

            <div className="actions" style={{ display: "flex" }}>
                <Button icon={<PlusSquareTwoTone />} type="link" onClick={() => this.setState({ showCreateForm: true })}>
                    新增词条
                </Button>
            </div>
            <br />
            <div style={{ padding: "0 30px" }}>
                <span className="item" style={{ display: "inline-flex", alignItems: "center" }}>
                    <span className="label" style={{ whiteSpace: "nowrap" }}>搜索：</span>
                    <Input placeholder="名称、拼音模糊搜索" size="small" allowClear value={this.state.query.q} onChange={e => this.handleQueryChange("q", e.target.value)} />
                </span>
                <Divider type="vertical" />
                <span className="item" style={{ display: "inline-flex", alignItems: "center" }}>
                    <span className="label" style={{ whiteSpace: "nowrap" }}>权限：</span>
                    <Radio.Group buttonStyle="solid" size="small" value={this.state.query.permission} onChange={(e) => this.handleQueryChange("permission", e.target.value)}>
                        <Radio.Button value="all">全部</Radio.Button>
                        <Radio.Button value="free">免费</Radio.Button>
                        <Radio.Button value="vip">会员</Radio.Button>
                    </Radio.Group>
                </span>
                <Divider type="vertical" />
                <span className="item" style={{ display: "inline-flex", alignItems: "center" }} onChange={(e) => this.handleQueryChange("state", e.target.value)}>
                    <span className="label" style={{ whiteSpace: "nowrap" }}>状态：</span>
                    <Radio.Group buttonStyle="solid" size="small" value={this.state.query.state}>
                        <Radio.Button value="all">全部</Radio.Button>
                        <Radio.Button value="draft">草稿</Radio.Button>
                        <Radio.Button value="publish">发布</Radio.Button>
                        <Radio.Button value="delete">已删除</Radio.Button>
                    </Radio.Group>
                </span>
            </div>
            <br />

            {!!data.entries && <>
                <List dataSource={data.entries}
                    loading={this.state.loading}
                    pagination={{
                        onChange: (page, size) => { this.handlePageTo(page, size) },
                        total: data.total_entries,
                        current: data.page_number,
                        pageSize: data.page_size,
                        showQuickJumper: true
                    }}
                    grid={{ gutter: 16, column: 3 }}
                    itemLayout="vertical"
                    size="large"
                    renderItem={item => (
                        <List.Item key={item.id} className="item-article">
                            <Tabs defaultActiveKey="form" className="entry-form-tab" onChange={(key) => this.handleEntryTabChange(key, item.id, item.name)}>
                                <TabPane tab={item.name} key="form">
                                    <div className="content">
                                        <EntryForm entry={item} channel={this.state.channel} />
                                    </div>
                                </TabPane>
                                <TabPane tab="关联" key="ref">
                                    <ButtonGroup block>
                                        <Button size="small" type="primary" block onClick={() => this.handleClickBoundAllReferenceType(item.name, item.id)}>重新关联</Button>

                                        <Button size="small" type="primary" danger block onClick={() => this.handleClickCancelBoundAllReferenceType(item.name, item.id)}>取消所有关联</Button>
                                    </ButtonGroup>
                                    <Divider orientation="left" orientationMargin="0">穴位</Divider>
                                    <div>
                                        {this.state.referenceAcupoint[item.id]?.matches?.map(({ id, name }) => {
                                            let status = this.state.referenceAcupoint[item.id]?.bound_ids.indexOf(id) > -1 && <><Divider type="vertical" /> <CheckCircleOutlined /></>
                                            return <Tag key={`${item.name}-${id}`}>{name} {status}</Tag>
                                        })}
                                    </div>
                                    <Divider orientation="left" orientationMargin="0">经络</Divider>
                                    <div>
                                        {this.state.referenceMeridian[item.id]?.matches?.map(({ id, name }) => {
                                            let status = this.state.referenceMeridian[item.id]?.bound_ids.indexOf(id) > -1 && <><Divider type="vertical" /> <CheckCircleOutlined /></>

                                            return <Tag key={`${item.name}-${id}`}>{name}{status}</Tag>
                                        })}
                                    </div>
                                    <Divider orientation="left" orientationMargin="0">疾病</Divider>
                                    <div>
                                        {this.state.referenceIllness[item.id]?.matches?.map(({ id, name }) => {
                                            let status = this.state.referenceIllness[item.id]?.bound_ids.indexOf(id) > -1 && <><Divider type="vertical" /> <CheckCircleOutlined /></>

                                            return <Tag key={`${item.name}-${id}`}>{name} {status}</Tag>
                                        })}
                                    </div>
                                    <Divider orientation="left" orientationMargin="0">食物</Divider>
                                    <div>
                                        {this.state.referenceFood[item.id]?.matches?.map(({ id, name }) => {
                                            let status = this.state.referenceFood[item.id]?.bound_ids.indexOf(id) > -1 && <><Divider type="vertical" /> <CheckCircleOutlined /></>

                                            return <Tag key={`${item.name}-${id}`}>{name} {status}</Tag>
                                        })}
                                    </div>
                                    <Divider orientation="left" orientationMargin="0">中草药</Divider>
                                    <div>
                                        {this.state.referenceHerb[item.id]?.matches?.map(({ id, name }) => {
                                            let status = this.state.referenceHerb[item.id]?.bound_ids.indexOf(id) > -1 && <><Divider type="vertical" /> <CheckCircleOutlined /></>

                                            return <Tag key={`${item.name}-${id}`}>{name} {status}</Tag>
                                        })}
                                    </div>
                                    <Divider orientation="left" orientationMargin="0">医家</Divider>
                                    <div>
                                        {this.state.referenceDoctor[item.id]?.matches?.map(({ id, name }) => {
                                            let status = this.state.referenceDoctor[item.id]?.bound_ids.indexOf(id) > -1 && <><Divider type="vertical" /> <CheckCircleOutlined /></>

                                            return <Tag key={`${item.name}-${id}`}>{name} {status}</Tag>
                                        })}
                                    </div>
                                    <Divider orientation="left" orientationMargin="0">典籍</Divider>
                                    <div>
                                        {this.state.referenceAncientBook[item.id]?.matches?.map(({ id, name }) => {
                                            let status = this.state.referenceAncientBook[item.id]?.bound_ids.indexOf(id) > -1 && <><Divider type="vertical" /> <CheckCircleOutlined /></>

                                            return <Tag key={`${item.name}-${id}`}>{name} {status}</Tag>
                                        })}
                                    </div>
                                    <Divider orientation="left" orientationMargin="0">药膳</Divider>
                                    <div>
                                        {this.state.referenceDietTherapy[item.id]?.matches?.map(({ id, name }) => {
                                            let status = this.state.referenceDietTherapy[item.id]?.bound_ids.indexOf(id) > -1 && <><Divider type="vertical" /> <CheckCircleOutlined /></>

                                            return <Tag key={`${item.name}-${id}`}>{name} {status}</Tag>
                                        })}
                                    </div>
                                    <Divider orientation="left" orientationMargin="0">基础理论</Divider>
                                    <div>
                                        {this.state.referenceBasicTheory[item.id]?.matches?.map(({ id, name }) => {
                                            let status = this.state.referenceBasicTheory[item.id]?.bound_ids.indexOf(id) > -1 && <><Divider type="vertical" /> <CheckCircleOutlined /></>

                                            return <Tag key={`${item.name}-${id}`}>{name} {status}</Tag>
                                        })}
                                    </div>
                                    <Divider orientation="left" orientationMargin="0">养生</Divider>
                                    <div>
                                        {this.state.referenceRegimen[item.id]?.matches?.map(({ id, name }) => {
                                            let status = this.state.referenceRegimen[item.id]?.bound_ids.indexOf(id) > -1 && <><Divider type="vertical" /> <CheckCircleOutlined /></>

                                            return <Tag key={`${item.name}-${id}`}>{name} {status}</Tag>
                                        })}
                                    </div>
                                </TabPane>
                                <TabPane tab="语料" key="descs">
                                    <List
                                        dataSource={this.state.referenceDescription[item.id]}
                                        renderItem={(item) => (
                                            <List.Item>
                                                {item}
                                            </List.Item>
                                        )}
                                    />
                                </TabPane>
                            </Tabs>
                        </List.Item>
                    )}
                />
            </>}

            <Modal title="新增词条" open={this.state.showCreateForm} footer={false} onCancel={() => this.setState({ showCreateForm: false })}>
                <EntryForm channel={this.state.channel} closeModal={() => this.setState({ showCreateForm: false })} />
            </Modal>
        </div>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(EntryList)