import React from "react";
import { Button, Form, Select } from 'antd';


function QueryForm(props) {
    const [form] = Form.useForm()

    form.setFieldsValue(props.values)

    const typeHash = {
        "REFUND_DECLINED": "退款已拒绝",
        "CONSUMPTION_REQUEST": "退款申请",
        "EXPIRED": "订阅已过期",
        "PRICE_INCREASE": "自动续订价格上涨",
        "REFUND": "已退款",
        "RENEWAL_EXTENDED": "",
        "SUBSCRIBED": "用户发起订阅",
        "DID_RENEW": "用户成功续订",
        "DID_FAIL_TO_RENEW": "用户续订失败",
        "GRACE_PERIOD_EXPIRED": "计费宽限期结束",
        "DID_CHANGE_RENEWAL_STATUS": "订阅状态变更"
    }

    return <div>
        <Form form={form} autoComplete="off" layout="inline" onFinish={props.onSearch} initialValues={props.values} style={{ display: "flex", alignItems: "center" }}>
            <Form.Item name="notificationType" label="回调类型">
                <Select style={{ minWidth: 200 }}>
                    {Object.keys(typeHash).map(key =>
                        <Select.Option key={key} value={key} label={typeHash[key]}>{typeHash[key]}</Select.Option>
                    )}
                </Select>
            </Form.Item>

            <Button.Group>
                <Button type="primary" htmlType="submit">查询</Button>
                <Button onClick={() => props.onSearch({})} htmlType="reset">清空</Button>

            </Button.Group>
            {props.children}
        </Form>
    </div>
}

export default QueryForm