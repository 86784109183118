import { HashRouter as Router, Route } from "react-router-dom";
import { connect } from 'react-redux';

import SideMenu from "./components/side_menu"
import { Menu as MenuAction } from '../store/global/actions'
import React, { useEffect, useState } from "react";

import Simulator from "./simulator";
import Topic from "./Topic";
import Report from "./reports"
import Audit from "./reports/Audit";
import "./index.css"
import { Switch } from "react-router-dom";
import Guidelines from "./guidelines";
import Post from "./post"

function Community(props) {
    const [channel, setChannel] = useState(null)
    const [data, setDate] = useState({})

    useEffect(() => {
        props.setActiveKey("COMMUNITY")
        const _channel = props.socket.channel("community:summary")
        _channel.on("data", ({ data }) => {
            setDate(data)
        })
        _channel.join()
        setChannel(_channel)

        return () => {
            if (!!_channel) _channel.leave();
        }
    }, [])

    return <Router>
        <div className="module module-community">
            <div className="side-menu">
                <SideMenu
                    reportPostPendingCount={data?.report_post_pending_count}
                    reportCommentPendingCount={data?.report_comment_pending_count}
                    answerPublishCount={data?.answer_publish_count}
                    questionPublishCount={data?.question_publish_count}
                />
            </div>
            <div className="content">
                <Switch>
                    <Route path="/community/simulator" component={Simulator}></Route>
                    <Route path="/community/topic" component={Topic}></Route>
                    <Route path="/community/report/audit/:reference_type" component={Audit}></Route>
                    <Route path="/community/report/:reference_type/:status" component={Report}></Route>
                    <Route path="/community/guidelines" component={Guidelines} ></Route>
                    <Route path="/community/post/:type" component={Post} ></Route>
                </Switch>
            </div>
        </div>
    </Router>
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Community)