import { HashRouter as Router, Route } from "react-router-dom";
import { Menu as MenuAction } from '../store/global/actions'
import { connect } from 'react-redux';

import SideMenu from "./components/side_menu"
import React from "react";

import TongueRecords from "./records/TongueRecords";
import ActiveRecords from "./records/ActiveRecords";
import HandAnalysis from "./records/HandAnalysis";
import FaceAnalysis from "./records/FaceAnalysis";
import CorporeityAnalysis from "./records/CorporeityAnalysis";
import NotificationLogs from "./apple/NotificationLogs";
import GraphStatistic from "./graph"
import NinetonLog from './nineton/log'
import AcupointDetect from "./records/AcupointDetect"

import RequireRolesOr from '../components/require_roles_or'

class Statistics extends React.Component {

    componentDidMount() {
        this.props.setActiveKey("Statistics")
    }

    render() {
        return <Router>
            <div className="module module-statistics">
                <div className="side-menu">
                    <SideMenu />
                </div>
                <div className="content">
                    <RequireRolesOr require={["acupoint:role::operator"]}>
                        <Route path="/statistics/nineton-logs" exact component={NinetonLog}></Route>
                        <Route path="/statistics/register-records" exact component={ActiveRecords}></Route>
                        <Route path="/statistics/apple-notify-logs" exact component={NotificationLogs}></Route>
                        <Route path="/statistics/graph-logs" exact component={GraphStatistic}></Route>
                    </RequireRolesOr>
                    <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::customer-service"]}>
                        <Route path="/statistics/tongue-analysis" exact component={TongueRecords}></Route>
                        <Route path="/statistics/acupoint-detect" exact component={AcupointDetect}></Route>
                        <Route path="/statistics/hand-analysis" exact component={HandAnalysis}></Route>
                        <Route path="/statistics/face-analysis" exact component={FaceAnalysis}></Route>
                        <Route path="/statistics/corporeity-analysis" exact component={CorporeityAnalysis}></Route>
                    </RequireRolesOr>
                </div>
            </div>
        </Router>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Statistics)