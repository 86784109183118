import React from "react";
import { Menu, Tag } from 'antd';
import { MoneyCollectOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";
import { connect } from 'react-redux';
import RequireRolesOr from '../../components/require_roles_or'

function SideMenu() {
    const location = useLocation()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")
    const fullPathName = location.pathname

    return <Menu style={{ width: 256 }} defaultSelectedKeys={[pathName, fullPathName]} mode="inline" >
        <Menu.ItemGroup key="pricing" title="价格">
            <Menu.Item key="/finance/pricing/acupoint:shelve::vip" icon={<MoneyCollectOutlined />}>
                <Link to="/finance/pricing/acupoint:shelve::vip">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>完整版VIP价格表</span>
                        <span><Tag color="blue">实时</Tag></span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/finance/pricing/acupoint:shelve::video_course" icon={<MoneyCollectOutlined />}>
                <Link to="/finance/pricing/acupoint:shelve::video_course">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>视频课程价格表</span>
                        <span><Tag color="blue">实时</Tag></span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
        <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::finance"]}>
            <Menu.ItemGroup key="order" title="订单">
                <Menu.Item key="/finance/trade-order" icon={<UnorderedListOutlined />}>
                    <Link to="/finance/trade-order">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>订单</span>
                            <span><Tag color="blue">实时</Tag></span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/finance/refund" icon={<UnorderedListOutlined />}>
                    <Link to="/finance/refund">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>退款记录</span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/finance/alipay/agreement" icon={<UnorderedListOutlined />}>
                    <Link to="/finance/alipay/agreement">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>支付宝合约</span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/finance/apple/subscription" icon={<UnorderedListOutlined />} disabled>
                    <Link to="/finance/apple/subscription">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>苹果订阅</span>
                        </div>
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>
        </RequireRolesOr>

        <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::finance"]}>
            <Menu.ItemGroup key="order" title="积分">
                <Menu.Item key="/finance/coin/code-table" icon={<UnorderedListOutlined />}>
                    <Link to="/finance/coin/code-table">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>积分配置表</span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/finance/coin/pool" icon={<UnorderedListOutlined />}>
                    <Link to="/finance/coin/pool">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>积分池</span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/finance/coin/unachieved/cloak-in" icon={<UnorderedListOutlined />}>
                    <Link to="/finance/coin/unachieved/cloak-in">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>当期未签到用户</span>
                        </div>
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>
        </RequireRolesOr>

        <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::finance"]}>
            <Menu.ItemGroup key="order" title="分布式财务（积分）系统">
                <Menu.Item key="/finance/coin/distributed/finance/account-flow" icon={<UnorderedListOutlined />}>
                    <Link to="/finance/coin/distributed/finance/account-flow">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>账户流水</span>
                        </div>
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>
        </RequireRolesOr>
    </Menu >
}


const mapStateToProps = ({ global }) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);