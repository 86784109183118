import { connect } from "react-redux";
import { UserOpsChannel as UserOpsChannelAction } from "../store/global/actions"

function UserOpsChannel(props) {
    let channel = props.channel

    if (!channel) {
        channel = props.socket.channel("ops:user_ops")
        channel.join()
        props.join(channel)
    }

    return <></>
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        channel: global.userOpsChannel
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        join: (channel) => {
            dispatch(UserOpsChannelAction.join(channel))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserOpsChannel)