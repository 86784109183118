import { Modal } from "antd";
import UserInformation from "./UserInformation";
import { connect } from 'react-redux';
import { userInformationModal } from "../../store/global/actions"
import React from "react"

class UserInformationModal extends React.Component {

    state = {
        // isModalVisible: false,
        userId: null,
        tabKey: null
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.showingUserId !== this.state.userId)
            this.setState({ userId: nextProps.showingUserId })
    }

    handleCancel() {
        this.props.close()
        // this.setState({ isModalVisible: false })
    }

    show(userId) {
        this.setState({ tabKey: null, userId })
    }

    showCustomerService(userId) {
        this.setState({ tabKey: "customer-service", userId })
    }

    render() {
        return <Modal title={false} width={1440} destroyOnClose centered footer={null} open={!!this.props.showingUserId} onOk={false} onCancel={this.handleCancel.bind(this)}>
            {this.state.userId && <UserInformation tabKey={this.state.tabKey} id={this.state.userId} inModal></UserInformation>}
        </Modal>
    }
}



const mapStateToProps = ({ global }) => {
    return {
        showingUserId: global.userInformationShowingUserID
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        close: () => {
            dispatch(userInformationModal.close())
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserInformationModal)