import { connect } from "react-redux"
import "./index.css"
import { useEffect, useState } from "react"
import { Col, Row, Button, Segmented, Avatar } from "antd"
import MakeQuestion from './MakeQuestion'
import Portal from './Portal'
import MakeAnswer from "./MakeAnswer"
import QuestionDetail from "./QuestionDetail"
import AnswerDetail from "./AnswerDetail"
import { Route, HashRouter as Router, Switch } from "react-router-dom"
import defaultAvatar from "../../images/avatar.png"
import Config from "../../config"

function Simulator({ socket, ...props }) {
    let [channel, setChannel] = useState(null)
    // 用户马甲，用于扮演
    const [questUser, setQuestUser] = useState(null);
    // 用户马甲，用于扮演
    const [answerUser, setAnswerUser] = useState(null);
    // 用户马甲，用于扮演
    const [questUsers, setQuestUsers] = useState([]);
    // 用户马甲，用于扮演
    const [answerUsers, setAnswerUsers] = useState([]);
    const [loadingMaskUser, setLoadingMaskUser] = useState(false)

    useEffect(() => {
        let _channel = socket.channel("community:simulator")
        _channel.join()
        _channel.on("quest_user", ({ quest_user, quest_users }) => {
            setQuestUsers(quest_users)
            setQuestUser(quest_user)
            setLoadingMaskUser(false)
        })
        _channel.on("answer_user", ({ answer_user, answer_users }) => {
            setAnswerUsers(answer_users)
            setAnswerUser(answer_user)
            setLoadingMaskUser(false)
        })
        setChannel(_channel)
        return () => {
            if (!!_channel) _channel.leave()
        }
    }, [])

    const selectQuestUser = (userID) => {
        channel.push("change-quest-user", { user_id: userID })
            .receive("ok", (user) => setQuestUser(user))
    }

    const selectAnswerUser = (userID) => {
        channel.push("change-answer-user", { user_id: userID })
            .receive("ok", (user) => setAnswerUser(user))
    }


    const randomUser = (type) => {
        setLoadingMaskUser(true)
        channel.push("random-user", { type })
    }

    return <div className="page community router">
        <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ margin: 10, display: "flex", alignItems: "center" }}>
                <Segmented size="small" value={questUser?.id} onChange={(value) => selectQuestUser(value)}
                    options={
                        questUsers.map(item => ({
                            label: <div style={{ padding: 2, maxWidth: 50 }}>
                                <Avatar src={Config.AvatarUrl(item.avatar)} width={20} height={20} />
                                <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{item.nickname || "[匿名]"}</div>
                            </div>,
                            value: item.id
                        }))
                    }
                />
                <Button style={{ height: 57 }} onClick={() => randomUser("quest_user")} type="primary" size="small" loading={loadingMaskUser}>刷新提问用户</Button>
            </div>
            <div style={{ margin: 10, display: "flex", alignItems: "center" }}>
                <Segmented size="small" value={answerUser?.id} onChange={(value) => selectAnswerUser(value)}
                    options={
                        answerUsers.map(item => ({
                            label: <div style={{ padding: 2, maxWidth: 50 }}>
                                <Avatar src={Config.AvatarUrl(item.avatar)} width={20} height={20} />
                                <div style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{item.nickname || "[匿名]"}</div>
                            </div>,
                            value: item.id
                        }))
                    }
                />
                <Button style={{ height: 57 }} onClick={() => randomUser("answer_user")} type="primary" size="small" loading={loadingMaskUser}>刷新回答用户</Button>
            </div>
        </div>
        {!!channel &&
            <Router>
                <Row>
                    {/* <Col span={2}>
                        <div className="page community simulator portal">
                            <div><Button loading={loadingMaskUser} type="link" size="small" onClick={() => randomUser()} icon={<ReloadOutlined />}>扮演用户</Button></div>
                            <UserAvatar nickname={maskUser?.nickname} size={50} avatar={maskUser?.avatar} id={maskUser?.id} vip={maskUser?.group === "vip"} />
                        </div>
                    </Col> */}
                    <Col span={6}>
                        <Route path="/community/simulator" render={routeProps => <Portal channel={channel} {...routeProps} maskUser={answerUser} />}></Route>
                    </Col>
                    <Col span={6}>
                        <Switch>
                            <Route path="/community/simulator/new" render={routeProps => <MakeQuestion channel={channel} {...routeProps} maskUser={questUser} />}></Route>
                            <Route path="/community/simulator/question/:id/edit" render={routeProps => <MakeQuestion channel={channel} {...routeProps} maskUser={questUser} />}></Route>
                            <Route path="/community/simulator/question/:id" render={routeProps => <QuestionDetail channel={channel} {...routeProps} maskUser={answerUser} />}></Route>
                        </Switch>
                    </Col>
                    <Col span={6}>
                        <Switch>
                            <Route path="/community/simulator/question/:id/answer/:answer_id/edit" render={routeProps => <MakeAnswer channel={channel} {...routeProps} maskUser={answerUser} />}></Route>
                            <Route path="/community/simulator/question/:id/answer/:answer_id" render={routeProps => <AnswerDetail channel={channel} {...routeProps} maskUser={answerUser} />}></Route>
                            <Route path="/community/simulator/question/:id/answer" render={routeProps => <MakeAnswer channel={channel} {...routeProps} maskUser={answerUser} />}></Route>
                        </Switch>
                        {/* <Route path="/community/question/:id/report" exact component={Simulator}></Route> */}
                    </Col>
                    <Col span={6}>
                        <Switch>
                            <Route path="/community/simulator/question/:id/answer/:answer_id/comment/:commend_id" render={routeProps => <AnswerDetail channel={channel} {...routeProps} maskUser={answerUser} />}></Route>
                        </Switch>
                        {/* <Route path="/community/question/:id/report" exact component={Simulator}></Route> */}
                    </Col>
                </Row>
            </Router>
        }
    </div >
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        user: global.user
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Simulator)
