import React from "react";
import { Table, Space, Tag, Form, Input, Button } from 'antd';
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import { Link } from "react-router-dom";


export default function QuestionList(props) {
    const columns = [
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
            ellipsis: true,
            render: text => <Link>{text}</Link>,
            width: 400
        },
        {
            title: '选项',
            dataIndex: 'options',
            key: 'options',
            ellipsis: true,
            width: 300,
            render: (text, i) => {
                const options = JSON.parse(text);
                const corrects = JSON.parse(i.corrects)

                return options.map((option, index) => {
                    let correct_icon = <CloseCircleTwoTone twoToneColor="#eb2f96" />
                    if (corrects[index] === 1) correct_icon = <CheckCircleTwoTone twoToneColor="#52c41a" />

                    return <p key={option[0].name}>{correct_icon} {index + 1}. {option[0].name}</p>
                })

            }
        },
        {
            title: '题型',
            dataIndex: 'type',
            align: 'center',
            key: 'type',
            render: text => {
                switch (text) {
                    case 0: return "单选题";
                    case 1: return "多选题";
                    case 2: return "判断题";
                    default: return ""
                }
            },
        },
        {
            title: '最低适配版本',
            align: 'center',
            dataIndex: 'min_version_code',
            key: 'min_version_code'
        },
        // {
        //     title: '分类',
        //     dataIndex: 'category',
        //     key: 'category',
        //     render: category => <a>{category}</a>,
        // },
        {
            title: '标签',
            key: 'tags',
            dataIndex: 'tags',
            align: 'center',
            width: 200,
            render: tags => (
                <>
                    {tags.tags.map(tag => {
                        let color = tag.length > 5 ? 'geekblue' : 'green';
                        if (tag === 'loser') {
                            color = 'volcano';
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: "绑定资源",
            key: "data",
            align: "center",
            render: (text, i) => {
                switch (i.data.type) {
                    case "acupoint": return "穴位";
                    case "meridian": return "经络"
                    default: return ""
                }
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                </Space>
            ),
        }
    ]

    const tableChange = (pagination, filters, sorter) => {
        props.pushEvent("page-to", { page_size: pagination.pageSize, page: pagination.current })
    }

    const onFinish = (values) => {
        props.pushEvent("query", values)
    }

    const onReset = () => {
        props.pushEvent("query-reset", {})
    }

    const data = props.data;

    return <>
        <Form name="query" layout="inline" onFinish={onFinish} initialValues={{ q: props.query }}   >
            <Form.Item name="q" >
                <Input placeholder="搜题名" />
            </Form.Item>
            <Form.Item>
                <Button.Group>
                    <Button type="primary" htmlType="submit">
                        搜索
                    </Button>
                    <Button htmlType="button" onClick={onReset}>
                        重置
                    </Button>
                </Button.Group>
            </Form.Item>
        </Form>
        <br />

        <Table columns={columns} rowKey="id" dataSource={data.entries} onChange={tableChange}
            pagination={{
                pageSize: data.page_size, current: data.page_number, total: data.total_count, showSizeChanger: false,
                showQuickJumper: true
            }} />
    </>
}