
import { Form, Input, InputNumber, Select, Switch, message } from "antd"
import { useEffect, useState, useRef } from "react"
import { Button } from "antd"
import { connect } from "react-redux"
import { Menu as MenuAction } from "../../store/global/actions"
import UploadComponent from "../../components/UploadComponent";
import configFunc from "../../config";
import { PlusOutlined } from '@ant-design/icons';
import Ajv from 'ajv';
import { JsonEditor as Editor } from 'jsoneditor-react';
import "./PhoneSimulator.css"
const ajv = new Ajv({ allErrors: true, verbose: true });


function HomeBannerAD(props) {
    let [data, setData] = useState(null)
    let [channel, setCannel] = useState(null)
    const [pic, setPic] = useState(null)

    const [form] = Form.useForm()
    // json编辑器的关联
    const jsonEditorRef = useRef()

    const handleUploadSuccess = ([file]) => {
        form.setFieldsValue({ url: configFunc.AttachUrl(file.url) })
        setPic(configFunc.AttachUrl(file.url))
    }

    props.setActiveKey("SETTING-HOME-BANNER-AD")

    useEffect(
        () => {
            const _channel = props.socket.channel(`operation:settings:homeBannerAD`)
            _channel.join()
            _channel.on("data", ({ data }) => {
                const jsonedData = JSON.parse((data[0] || { content: "{}" }).content)
                console.log((data[0] || { content: "{}" }).content)
                setData(jsonedData)
                setPic(jsonedData.url)
            })
            setCannel(_channel)

            return () => {
                !!_channel && _channel.leave()
            }
        }, [])

    const onSave = (values) => {
        values = { ...values, url: pic }
        channel.push("set", { homeBannerAD: JSON.stringify(values) })
            .receive("ok", () => { message.success({ content: "保存成功" }) })
    }

    return <div className="page settings" style={{ flexDirection: "column", display: "flex", height: "100%" }}>
        <h1>首页横幅广告设置</h1>

        <div style={{ display: "flex", flexGrow: 1 }}>
            {!!data &&
                <Form form={form} style={{ width: 500 }} initialValues={data} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off" onFinish={onSave}>
                    <Form.Item label="是否开启" name="enable" valuePropName="checked" rules={[{ required: true, message: '不能为空' }]}>
                        <Switch />
                    </Form.Item>
                    <Form.Item label="允许关闭" valuePropName="checked" name="closable" rules={[{ required: true, message: '不能为空' }]} >
                        <Switch checkedChildren="允许" unCheckedChildren="禁止" />
                    </Form.Item>
                    <Form.Item label="关闭重新打开时间" name="silence" rules={[{ required: true, message: '不能为空' }]} >
                        <InputNumber min={1} step={1} addonAfter="小时" />
                    </Form.Item>
                    <Form.Item label="图片" rules={[{ required: true, message: '不能为空' }]}>
                        <UploadComponent path={["information", "homeBannerAD"].join("/")} bucket="acupoint-attach" maxCount={1}
                            listType="picture-card" showUploadList={false}
                            onChange={(fileList) => handleUploadSuccess(fileList)}>
                            {pic ? <img src={pic} alt="avatar" style={{ width: '100%' }} /> : <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>拖到这里</div>
                            </div>}
                        </UploadComponent>
                    </Form.Item>
                    <Form.Item label="图片URL" name="url" rules={[{ required: true, message: '不能为空' }]}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item label="行为类型" name={["action", "type"]} rules={[{ required: true, message: '必填' }]} >
                        <Select >
                            <Select.Option value="app_router">APP路由</Select.Option>
                            <Select.Option value="url">URL</Select.Option>
                            <Select.Option value="wechat_miniprogram">微信小程序</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="行为参数" name={["action", "data"]} rules={[{ required: true, message: '必填' }]} help="由于每个APP适配的参数不一样，所以这里是自定义的格式">
                        <Editor ref={jsonEditorRef} ace={ace} mode="code" ajv={ajv} language="zh-CN" />
                    </Form.Item>
                    <Form.Item wrapperCol={{ xs: { span: 8, offset: 8, } }}>
                        <Button.Group>
                            <Button size="small" type="primary" htmlType="submit">保存</Button>
                        </Button.Group>
                    </Form.Item>
                </Form>
            }
        </div>
    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeBannerAD)