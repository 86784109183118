import React from "react";
import { Table, Button, Form, Radio, DatePicker } from 'antd';
import ButtonGroup from "antd/lib/button/button-group";
import { connect } from 'react-redux';
import dayjs from "dayjs"
import locale from 'antd/es/date-picker/locale/zh_CN';
import './CorporeityAnalysis.css'
import { CheckOutlined } from '@ant-design/icons';
import UserAvatar from "../../components/user/UserAvatar";


const { Column } = Table;
const { RangePicker } = DatePicker
class CorporeityAnalysis extends React.Component {
    state = {
        channel: null,
        data: {},
        loading: false,
        page: 1,
        size: 10,
        query: {}
    }

    loadData(page, size) {
        page = page || this.state.data.page_number
        size = size || this.state.data.page_size
        this.state.channel.push("corporeity-analysis-paged-data", { page, size, query: this.state.query })
            .receive("ok", (data) => this.setState({ data }))
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    componentDidMount() {
        const channel = this.props.socket.channel("statistics:xun-ai")
        channel.join()

        this.setState({ channel }, this.loadData)
    }

    // 搜索
    handleOnSearch(values) {
        let params = {}

        if (values.status !== 'all') params["status"] = values.status
        if (!!values.inserted_at) params["inserted_at"] = [
            values.inserted_at[0].set({ hour: 0, minute: 0, second: 0 }),
            values.inserted_at[1].set({ hour: 0, minute: 0, second: 0 })
        ]

        this.setState({ query: params }, () => { this.loadData() })
    }

    // 重置搜索
    handleSearchReset() {
        this.setState({ query: {} }, () => { this.loadData() })
    }

    statusSpan(text) {
        switch (text) {
            case "success":
                return <span style={{ color: "green" }}>已出报告</span>
            case "fail":
                return <span style={{ color: "red" }}>检测失败</span>
            case "set_up":
                return <span style={{ color: "grey" }}>等待提交</span>
            case "continuous":
                return <span style={{ color: "orange" }}>资料不全</span>
            case "wait":
                return <span style={{ color: "orange" }}>等待报告中</span>

            default:
                break;
        }
    }

    render() {
        return <div className="page xun-ai-hand-analysis">
            <h1>体质分析</h1>
            <div className="filter">
                <Form name="basic" layout="inline" onReset={this.handleSearchReset.bind(this)}
                    initialValues={{ status: "all", path: "all" }}
                    onFinish={this.handleOnSearch.bind(this)}>
                    <Form.Item name="status" label={false}>
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="all">全部</Radio.Button>
                            <Radio.Button value="success">已出报告</Radio.Button>
                            <Radio.Button value="continuous">资料不全</Radio.Button>
                            <Radio.Button value="fail">检测失败</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="inserted_at" label={false}>
                        <RangePicker format="YYYY-MM-DD" locale={locale} />
                    </Form.Item>
                    <Form.Item>
                        <ButtonGroup>
                            <Button type="primary" htmlType="submit" >查询</Button>
                            <Button type="ghost" htmlType="reset">重置</Button>
                        </ButtonGroup>
                    </Form.Item>
                </Form>
            </div>

            {
                this.state.data.entries && <Table dataSource={this.state.data.entries} rowKey={item => item.id}
                    pagination={{
                        onChange: (page, size) => { this.loadData(page, size) },
                        total: this.state.data.total_entries,
                        current: this.state.data.page_number,
                        pageSize: this.state.data.page_size,
                        showQuickJumper: true
                    }}>
                    <Column title="用户" align="center" width={120} dataIndex={["user", "avatar"]} key={["user", "avatar"]} render={(_text, obj) =>
                        <UserAvatar id={obj.user?.id} nickname={obj.user?.nickname} size={60} avatar={obj.user?.avatar} vip={obj.user?.group === "vip"} />} />
                    <Column title="状态" align="center" dataIndex="status" key="status" width={120} render={this.statusSpan.bind(this)} />
                    <Column title="已读" align="center" dataIndex="read" key="read" width={100} render={(read) => read && <span style={{ color: "green" }}><CheckOutlined /></span>} />
                    <Column title="概要" dataIndex="summary" key="summary" />
                    <Column title="提交时间" dataIndex="inserted_at" width={150} key="inserted_at" render={text => dayjs(text).format("YY-MM-DD HH:mm:ss")} />
                </Table>
            }
        </div >
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CorporeityAnalysis);