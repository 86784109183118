import { useEffect, useState } from "react"
import { Alert, Card, Divider, List } from "antd"
import { JsonEditor as Editor } from 'jsoneditor-react';
import LocalTimeAgo from "../components/local_time_ago"
import { connect } from "react-redux"
import UserAvatar from "../components/user/UserAvatar";
import { Menu as MenuAction } from '../store/global/actions'
import "./Exception.css"

function Exception(props) {
    let [data, setData] = useState({})
    let [channel, setCannel] = useState(null)

    props.setActiveKey("EXCEPTION")

    useEffect(
        () => {
            const _channel = props.socket.channel("tech:exception", { size: 18 })
            _channel.join()
            _channel.on("data", (data) => {
                console.log(data)
                setData(data)
            })
            setCannel(_channel)

            return () => {
                !!_channel && _channel.leave()
            }
        }, []
    )

    const handlePageTo = (page, size) => {
        channel.push("page-to", { page, size })
    }


    return <div className="page tech-exception">
        <h1>异常日志</h1>
        <List dataSource={data.entries}
            grid={{ gutter: 16, column: 1 }}
            itemLayout="horizontal"
            pagination={{
                onChange: (page, size) => { handlePageTo(page, size) },
                total: data.total_entries,
                current: data.page_number,
                pageSize: data.page_size || 0,
                showQuickJumper: true
            }}
            renderItem={item => (
                <List.Item key={`report-${item.id}`} className="item">
                    <Card title={<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span style={{ display: "flex", alignItems: "center" }}>{item.user && <> <UserAvatar nickname={item.user.nickname} size={50} avatar={item.user.avatar} id={item.user.id} vip={item.user.group === "vip"} /> <Divider type="vertical" /></>} {item.method} {item.path}</span><span><LocalTimeAgo date={item.created_at} /></span></div>}>
                        <Editor value={{ 请求头: item.headers, 参数: item.params, 堆栈信息: item.stack.split("\n") }} mode="view" language="zh-CN"></Editor>
                        <br />
                        <Alert style={{width: 1530}} message={item.message} type="warning"></Alert>
                        {/* <p></p> */}
                        {/*  <pre>
                            {item.stack}
                        </pre> */}
                    </Card>
                </List.Item>
            )}
        />
    </div>
}



const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        user: global.user
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Exception)
