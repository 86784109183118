import { useEffect, useState } from "react"
import { Table, Timeline, Steps } from "antd"
import LocalTimeAgo from "../../components/local_time_ago"
import { connect } from "react-redux"
import UserAvatar from "../../components/user/UserAvatar";
import { Menu as MenuAction } from '../../store/global/actions'
import dayjs from "dayjs"

const { Column } = Table;

function GraphStatistic(props) {
    let [data, setData] = useState({})
    let [channel, setCannel] = useState(null)

    // props.setActiveKey("EXCEPTION")

    useEffect(
        () => {
            const _channel = props.socket.channel("statistics:graph", { size: 20 })
            _channel.join()
            _channel.on("data", (data) => {
                setData(data)
            })
            setCannel(_channel)

            return () => {
                !!_channel && _channel.leave()
            }
        }, []
    )

    const handlePageTo = (page, size) => {
        channel.push("page-to", { page, size })
    }


    return <div className="page graph-logs">
        <h1>图谱使用日志</h1>

        <Table dataSource={data.entries} rowKey={item => item.id} size="middle"

            scroll={{ y: document.documentElement.clientHeight - 350 }}
            expandable={{
                rowExpandable: (item) => (item?.data?.trace || []).length > 0,
                expandedRowRender: (item) => {
                    let trace = (item?.data?.trace || [])
                    return <div>
                        <Timeline mode="left">
                            {trace.map((t, i) => {
                                const diffSec = !!trace[i + 1] && dayjs(trace[i + 1]?.datetime).diff(t.datetime, "second")
                                const chainStepData = (t?.data?.chain || []).map(title => { return { title } })
                                let color = "blue";
                                switch (t.event) {
                                    case "初始化节点":
                                        color = "green";
                                        break;
                                    case "初始化节点":
                                        break;
                                    case "点击展开":
                                        color = "red"
                                        break;

                                    default:
                                        break;
                                }
                                return <Timeline.Item color={color} key={`graph-${t.id}-${i}`} label={t.data?.name}>
                                    <p>{t.event} <span>{diffSec !== false && i + 1 !== trace.length && `${diffSec}秒`}</span></p>
                                    <Steps current={(chainStepData || []).length} items={chainStepData} size="small" />

                                </Timeline.Item>

                            })}
                        </Timeline>
                    </div>
                }
            }}
            pagination={{
                onChange: (page, size) => { handlePageTo(page, size) },
                total: data.total_entries,
                current: data.page_number,
                pageSize: data.page_size || 0,
                showQuickJumper: true
            }}>
            <Column title="打开时间" dataIndex="join_at" key="join_at" width={250} render={(text) => dayjs(`${text}`).format("YYYY-MM-DD HH:mm:ss")} />
            <Column title="离开时间" dataIndex="leave_at" key="leave_at" width={250} render={(text) => dayjs(`${text}`).format("YYYY-MM-DD HH:mm:ss")} />
            <Column title="使用时长（秒）" dataIndex="seconds" key="seconds" width={250} />
            <Column title="IP" dataIndex="ip" key="ip" />
            <Column title="操作步数" dataIndex={["data", "trace"]} key="ip" render={(trace) => (trace || []).length} />
            <Column title="用户" dataIndex="user" key="user" width={80} render={(user) => !!user && <UserAvatar nickname={user.nickname} size={50} avatar={user.avatar} id={user.id} vip={user.group === "vip"} />} />
        </Table>
    </div>
}



const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        user: global.user
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(GraphStatistic)
