import { Button, Input, message } from "antd"
import { useRef, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import ReactQuill, { Quill } from "react-quill"
import 'react-quill/dist/quill.snow.css';
import imageUploader from "quill-image-uploader";
import uploadFile from "../../components/upload_file";
import config from "../../config"
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Sha256Encode from "../../components/sha256";

Quill.register("modules/imageUploader", imageUploader);

const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
    ],
    imageUploader: {
        upload: (file) => {
            return new Promise(async (resolve, reject) => {
                const file_content = await file.arrayBuffer()
                const file_name = await Sha256Encode(file_content)

                uploadFile(window.store.getState().global.jsonRequest, `community/post/${file_name}`, "acupoint-attach", file, (path) => {
                    resolve(config.AttachUrl(path));
                }, () => message.error("上传失败"))
            });
        },
    }
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color', 'background'
]

function Answer({ channel, maskUser, ...props }) {
    let [question, setQuestion] = useState([])
    const [value, setValue] = useState('');
    const { id, answer_id } = useParams()
    const editorRef = useRef()
    const history = useHistory()

    useEffect(() => {
        if (!!answer_id) {
            channel.push("answer-detail", { id: answer_id })
                .receive("ok", (data) => {
                    setQuestion(data)
                    setValue(data.content["delta"])
                })

        } else {
            channel.push("question-detail", { id })
                .receive("ok", (data) => {
                    setQuestion(data)
                })
        }
    }, [id])

    const handlePost = () => {
        const data = {
            parent_id: id,
            content_text: editorRef.current.getEditor().getText(),
            content: {
                delta: editorRef.current.getEditor().getContents().ops
            }, topics: [],
        }

        // 有answer_id表示修改
        if (!!answer_id) {
            channel.push("update-content", { ...data, id: answer_id })
                .receive("ok", () => {
                    message.success("修改回答成功")
                    history.push(`/community/simulator/question/${id}/answer/${answer_id}`)
                })

        } else {
            channel.push("add-answer", data)
                .receive("ok", (answer_id) => {
                    message.success("创建回答成功")
                    history.push(`/community/simulator/question/${id}/answer/${answer_id}`)
                })
        }
    }

    return <div className="page community simulator answer">
        <div className="header">
            <div><Link to={`/community/simulator/question/${question.id}`} style={{ padding: "4px 15px" }}>取消</Link></div>
            <div><h2>写回答</h2></div>
            <div>
                <Button type="link" size="small" onClick={() => handlePost()} disabled={value?.length < 20}>发布</Button>
            </div>
        </div>
        <Input.TextArea autoSize placeholder="输入问题并以问号结尾" className="title" readOnly value={question.title}></Input.TextArea>
        <ReactQuill ref={editorRef} placeholder="请填写回答详情" theme="snow"
            className="editor" value={value} modules={modules} onChange={setValue} formats={formats} />
        {/* <div className="editor">
            
        </div> */}
    </div>
}

export default Answer