import React from "react";
import { Table, Space, Tooltip, Button, Form, Radio, DatePicker, Divider } from 'antd';
import ButtonGroup from "antd/lib/button/button-group";
// import { ProjectTwoTone } from '@ant-design/icons';
// import { useLocation, Link } from "react-router-dom";
// import { useState } from 'react';
import { connect } from 'react-redux';
import dayjs from "dayjs"
import locale from 'antd/es/date-picker/locale/zh_CN';
import './log.css'

const { Column } = Table;
const { RangePicker } = DatePicker
class Log extends React.Component {

    state = {
        channel: null,
        data: {},
        loading: false,
        page: 1,
        size: 10,
        query: {}
    }

    loadData(page, size) {
        page = page || this.state.data.page_number
        size = size || this.state.data.page_size
        this.state.channel.push("data", { page, size, ...this.state.query })
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    componentDidMount() {
        const channel = this.props.socket.channel("ops:nineton:log")
        channel.on("data", (data) => this.setState({ data }))
        channel.join()

        this.setState({ channel }, this.loadData)
    }

    // 搜索
    handleOnSearch(values) {
        let params = {}
        if (values.group !== 'all') params["group"] = values.group
        if (values.path !== 'all') params["path"] = values.path
        if (!!values.inserted_at) params["inserted_at"] = [
            values.inserted_at[0].set({ hour: 0, minute: 0, second: 0 }),
            values.inserted_at[1].set({ hour: 0, minute: 0, second: 0 })
        ]

        this.setState({ query: params }, () => { this.loadData() })
    }

    // 重置搜索
    handleSearchReset() {
        this.setState({ query: {} }, () => { this.loadData() })
    }

    // 单条日志重放
    handleReplay({ id, params }) {
        this.state.channel.push("replay", { id, platform: params.appid === 135 ? "android" : "ios" })
            .receive("ok", this.loadData.bind(this))
    }

    // 重新推送核算
    handleReCheck(values) {
        this.state.channel.push("check-push", values)
            .receive("ok", this.loadData.bind(this))
    }

    // 重新推送订单数据
    handleReOrder(values) {
        this.state.channel.push("order-push", values)
            .receive("ok", this.loadData.bind(this))
    }

    render() {
        return <div className="page nineton-logs">
            <div className="operation">
                <div className="repush-orders">
                    <h2>订单推送</h2>
                    <Form name="reorder_action" layout="inline"
                        initialValues={{ group: "all", path: "all" }}
                        onFinish={this.handleReOrder.bind(this)}>
                        <Form.Item name="range" label={false}>
                            <RangePicker format="YYYY-MM-DD" locale={locale} />
                        </Form.Item>
                        <Form.Item>
                            <ButtonGroup>
                                <Button type="primary" htmlType="submit" >重新推送</Button>
                            </ButtonGroup>
                        </Form.Item>
                    </Form>
                </div>
                <div className="check-action">
                    <h2>核算推送</h2>
                    <Form name="check-action" layout="inline"
                        initialValues={{ group: "all", path: "all" }}
                        onFinish={this.handleReCheck.bind(this)}>
                        <Form.Item name="range" label={false}>
                            <RangePicker format="YYYY-MM-DD" locale={locale} />
                        </Form.Item>
                        <Form.Item>
                            <ButtonGroup>
                                <Button type="primary" htmlType="submit" >发送核算</Button>
                            </ButtonGroup>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <Divider />
            <h1>日志列表</h1>
            <div className="filter">
                <Form name="basic" layout="inline" onReset={this.handleSearchReset.bind(this)}
                    initialValues={{ group: "all", path: "all" }}
                    onFinish={this.handleOnSearch.bind(this)}>
                    <Form.Item name="group" label={false}>
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="all">全部</Radio.Button>
                            <Radio.Button value="ios">IOS</Radio.Button>
                            <Radio.Button value="android">安卓</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="path" label={false}>
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="all">全部</Radio.Button>
                            <Radio.Button value="/user/reg">用户注册</Radio.Button>
                            <Radio.Button value="/user/vip">用户VIP</Radio.Button>
                            <Radio.Button value="/order/create">创建订单</Radio.Button>
                            <Radio.Button value="/order/pay">订单支付</Radio.Button>
                            <Radio.Button value="/Admin/Api_Check/collate">核算</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="inserted_at" label={false}>
                        <RangePicker format="YYYY-MM-DD" locale={locale} />
                    </Form.Item>
                    <Form.Item>
                        <ButtonGroup>
                            <Button type="primary" htmlType="submit" >查询</Button>
                            <Button type="ghost" htmlType="reset">重置</Button>
                        </ButtonGroup>
                    </Form.Item>
                </Form>
            </div>

            {
                this.state.data.entries && <Table dataSource={this.state.data.entries} rowKey={item => item.id}
                    pagination={{
                        onChange: (page, size) => { this.loadData(page, size) },
                        total: this.state.data.total_entries,
                        current: this.state.data.page_number,
                        pageSize: this.state.data.page_size,
                        showQuickJumper: true
                    }}>
                    <Column title="时间" dataIndex="inserted_at" key="inserted_at" width={140} render={(text) => dayjs(`${text}Z`).format("MM-DD HH:mm:ss")} />
                    <Column title="分组" dataIndex="group" key="group" width={100} />
                    <Column title="路径" dataIndex="path" key="path" width={150} ellipsis render={(text) => <Tooltip placement="topLeft" title={text}>{text}</Tooltip>} />
                    <Column title="方法" dataIndex="method" key="method" width={80} />
                    <Column title="请求头" dataIndex="headers" key="headers" ellipsis render={(text) => <Tooltip placement="topLeft" title={JSON.stringify(text)}>{JSON.stringify(text)}</Tooltip>} />
                    <Column title="参数" dataIndex="params" key="params" ellipsis render={(text) => <Tooltip placement="topLeft" title={JSON.stringify(text)}>{JSON.stringify(text)}</Tooltip>} />
                    <Column title="响应" dataIndex="response" key="response" ellipsis render={(text) => JSON.stringify(text)} />
                    <Column title="STATUS" dataIndex="http_status" key="http_status" width={90} />
                    <Column title="耗时" dataIndex="spend_time" key="spend_time" width={80} />
                    <Column width={80}
                        title="操作"
                        key="操作"
                        render={(text, record) => (
                            <Space size="middle">
                                <Button type="link" onClick={() => this.handleReplay(record)}>重放</Button>
                            </Space>
                        )}
                    />
                </Table>
            }
        </div>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Log);