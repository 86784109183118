import { Select, Spin, Avatar } from 'antd';
import debounce from 'lodash/debounce';
import React from 'react';
import { CustomerOperationChannel as COAction } from "../store/global/actions"
import { connect } from "react-redux";
import { SmileTwoTone, CrownTwoTone } from '@ant-design/icons';
import Config from "../config"

function UserSearchSelect({ debounceTimeout = 500, join: _join, leave: _leave, ...props }) {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchRef = React.useRef(0);
    let channel = props.channel

    function fetchUserList(query) {
        return channel.push("suggest", { q: query })
    }

    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchUserList(value).receive("ok", ({ suggest_users: suggestUsers }) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                suggestUsers = suggestUsers.map((user) => {
                    return {
                        key: user.id,
                        value: user.id,
                        label: (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <span> <span style={{ color: "#aaa" }}>{user.id}</span> {user.group !== "vip" ? <SmileTwoTone /> : <CrownTwoTone twoToneColor="#eb2f96" />}  {user.nickname || user.id} </span>
                                <Avatar src={Config.AvatarUrl(user.avatar)} size={18} />
                            </div>
                        ),
                    }
                })


                setOptions(suggestUsers);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchUserList, debounceTimeout]);

    return (
        <Select
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
        />
    );
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        channel: global.userOpsChannel
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        join: (channel) => {
            dispatch(COAction.join(channel))
        },
        leave: () => {
            dispatch(COAction.leave())
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserSearchSelect)

