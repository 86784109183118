import React from "react";
import { Button, message, Table, Tree } from 'antd';
import { Menu as MenuAction } from '../store/global/actions'
import { connect } from 'react-redux';
import dayjs from "dayjs"


const { Column } = Table;
class Cache extends React.Component {

    state = {
        channel: null,
        cacheModules: [],
        query: {},
        currentSelectModule: null,
        currentSelectModuleKeys: []
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    componentDidMount() {
        this.props.setActiveKey("CACHE")
        this.joinChannel(this.props)
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        this.joinChannel(nextProps)
    }

    joinChannel(props) {
        if (!!this.state.channel && this.state.channel.state !== "closed") {
            // channel.off("cache-modules")
            this.state.channel.leave()
        }

        const cacheName = props.match?.param?.name || "Acupoint.Cache"
        const channel = props.socket.channel("cache:manage", { cache_name: cacheName })
        channel.join()
        channel.on("cache-modules", ({ data }) => {
            this.setState({ cacheModules: data })
        })

        this.setState({ channel })
    }

    sumSize(currentSelectModuleKeys) {
        return currentSelectModuleKeys.reduce((prev, current, _i) => {
            return current.size + prev
        }, 0) / 1000
    }

    handleSelect([select_key]) {
        this.state.channel.push("load-detail", { key: select_key })
            .receive("ok", ({ data }) => {
                this.setState({ currentSelectModule: select_key, currentSelectModuleKeys: data })
            })
    }

    handleClickClear() {
        this.state.channel.push("clear-by-keys", { key: this.state.currentSelectModule })
            .receive("ok", ({ data }) => {
                message.success("已清空")
                this.setState({ currentSelectModule: null, currentSelectModuleKeys: null })
            })
    }

    render() {
        const treeData = this.state.cacheModules.map(([module, funcs]) => {

            const chldren = funcs.map(({ func, desc, count }) => {
                return { title: `${desc} ${count}`, key: [module, func] }
            })

            return { title: module, key: module, children: chldren }

        })


        return <div className="page database-cache">
            <h1>缓存</h1>

            <div style={{ display: "flex" }}>
                {this.state.cacheModules.length > 0 &&
                    <Tree
                        showIcon
                        defaultExpandAll
                        treeData={treeData}
                        onSelect={this.handleSelect.bind(this)}
                    />

                }

                <div style={{ flexGrow: 1 }}>

                    {this.state.currentSelectModuleKeys &&
                        <>
                            <p style={{ display: "flex", justifyContent: "space-between" }}>
                                <Button size="small" onClick={this.handleClickClear.bind(this)}>清空表格内缓存</Button>
                                <div>
                                    <b>{this.sumSize(this.state.currentSelectModuleKeys)} KB</b>
                                </div>
                            </p>
                            <Table dataSource={this.state.currentSelectModuleKeys} rowKey={d => `${d.key.module}-${d.key.func}-${JSON.stringify(d.key.args)}`} pagination={false}>
                                <Column title="模块" width={100} dataIndex={["key", "module"]} key="key" render={(text, item) => <>
                                    {item.key?.desc}
                                    <br />
                                    {text}
                                </>} />
                                <Column title="函数" dataIndex={["key", "func"]} key="func" />
                                <Column title="参数" dataIndex={["key", "args"]} key="args" render={(value) => JSON.stringify(value)} />
                                <Column title="体积" align="right" width={150} dataIndex="size" key="size" render={(value) => `${value / 1000} KB`} />
                                <Column title="更新时间" width={180} dataIndex="touched_at" key="touched_at" render={text => dayjs(text).format("YYYY-MM-DD HH:mm")} />
                                <Column title="失效时间" width={180} dataIndex="ttl" key="ttl" render={(value, item) => {
                                    if (value === "infinity")
                                        return "永久"
                                    else
                                        return dayjs(item.touched_at).add(value / 1000, "second").format("YYYY-MM-DD HH:mm")
                                }} />
                            </Table>
                        </>
                    }
                </div>
            </div>
        </div >
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Cache);