import React, { useState, useEffect } from "react";
import { Switch, Button, message, Tooltip, Divider, Form, Input, Modal, TreeSelect, Tag, Badge, Radio } from 'antd';
import { connect } from 'react-redux';
import { Menu as MenuAction } from '../../store/global/actions'
import InfiniteScroll from 'react-infinite-scroll-component';

import { LoadingOutlined, StarOutlined, StarFilled, EyeTwoTone, PayCircleFilled } from '@ant-design/icons';

import "./book.css"

import { BookDetail } from "./components/BookDetail";

var topData = []
var pageSize = 12
function Book(props) {
    const [channel, setChannel] = useState(null)
    const [data, setData] = useState([])
    const [cateTree, setCateTree] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const [bookCount, setBookCount] = useState({})
    const [query, setQuery] = useState({ status: null })

    useEffect(() => {
        props.setActiveKey("CMS")

        let _channel = props.socket.channel("ops:resource:book", { size: pageSize })
        _channel.join()

        _channel.on("data", ({ data: respData, refresh }) => {
            setHasMore(respData?.length >= pageSize)
            if (refresh) {
                topData = respData
            } else {
                topData = topData.concat(respData)
            }
            setData(topData)
        })
        _channel.on("cate-data", ({ data }) => {
            setCateTree(data)
        })

        _channel.on("book-count", ({ data }) => {
            setBookCount(data)
        })

        setChannel(_channel)
        return () => {
            !!_channel && _channel.leave()
        }
    }, [])

    // 搜索
    const handleSearch = (values) => {
        setData(topData)
        channel.push("search", { ...values })
            .receive("ok", () =>
                setLoading(false))
    }

    // 设置分类
    const handleSetCategory = (international_code, categories) => {
        channel.push("set-categories", { international_code, categories })
            .receive("ok", () =>
                message.success("已设置分类"))
    }

    // 
    const handleSetRecommend = (international_code, recommend) => {
        channel.push("set-recommend", { international_code, recommend })
            .receive("ok", () =>
                message.success("已设置推荐"))
    }

    // 搜索
    const loadMore = (after) => {
        setLoading(loading)
        channel.push("load-more", { after })
            .receive("ok", () =>
                setLoading(false))
    }

    const handleClickModal = (item) => {
        const price = (item.price / 100).toFixed(2)
        Modal.info({
            width: 1000,
            title: `${item.title} - ${item.author} - ¥${price}`,
            content: <BookDetail internationalCode={item.international_code} cateTree={cateTree} user={props.user} channel={channel} />,
            okText: "关闭"
        })
    }


    return <div className="page cms-book">
        <h1>书籍列表 <Badge count={`${bookCount.on_shelf} / ${bookCount.all}`} overflowCount={9999999} color="blue"></Badge></h1>
        <div >
            <Form autoComplete="off" layout="inline" size="small" onFinish={handleSearch} initialValues={query} style={{ display: "flex", alignItems: "center" }}>
                <Form.Item name="title" label="书籍名">
                    <Input placeholder="模糊查询"></Input>
                </Form.Item>
                <Form.Item name="category" label="分类">
                    <TreeSelect placeholder="点击筛选分类" listHeight="60vh" treeCheckable treeCheckStrictly treeDefaultExpandAll showCheckedStrategy={TreeSelect.SHOW_ALL} allowClear style={{ minWidth: 300, maxWidth: 600 }} treeData={cateTree?.map((item) => ({
                        title: item.name,
                        value: item.name,
                        key: item.key,
                        children: item.children?.map((item) => ({
                            title: item.name,
                            value: item.name,
                            key: item.key,
                        }))
                    }))}>

                    </TreeSelect>
                </Form.Item>
                <Form.Item name="no_category" label="无分类" valuePropName="checked">
                    <Switch></Switch>
                </Form.Item>
                <Form.Item name="recommend" label="推荐" valuePropName="checked">
                    <Switch></Switch>
                </Form.Item>
                <Form.Item name="status" label="状态">
                    <Radio.Group optionType="button" buttonStyle="solid" size="small">
                        <Radio value={null}>全部</Radio>
                        <Radio value="on_shelf">上架</Radio>
                        <Radio value="off_shelf">下架</Radio>
                    </Radio.Group>
                </Form.Item>
                <Button.Group>
                    <Button type="primary" htmlType="submit">查询</Button>
                    <Button onClick={() => handleSearch({})} htmlType="reset">清空</Button>
                </Button.Group>
            </Form>
        </div>

        <div id="books" className="books" style={{
            display: "flex",
            flexDirection: 'column',
            paddingBottom: 100
        }}>
            <InfiniteScroll dataLength={topData?.length || 0} next={() => loadMore(topData?.[topData?.length - 1]?.international_code, topData)} hasMore={hasMore}
                loader={<div style={{ textAlign: "center" }}><LoadingOutlined /></div>}
                scrollableTarget="books" endMessage={<Divider plain style={{ color: "#aaa" }}>没有更多书了...</Divider>}
            >
                {
                    data.map(item => {
                        return <div key={item.international_code} className="book-item" style={{ opacity: item.status === "on_shelf" ? 1 : 0.3 }} >
                            <div className="cover" onClick={() => handleClickModal(item)} style={{ background: `url(${item.cover_url}) center no-repeat`, backgroundSize: "cover" }}>
                                <div className="price">
                                    <PayCircleFilled /> {(item.price / 100)?.toFixed(2)}
                                </div>
                                <div className="view-count">
                                    <EyeTwoTone twoToneColor="#aaa" /> {item.view_count || 0}
                                </div>
                                <div className="categories">
                                    {item.categories.map(cate => <Tag key={cate}>{cate}</Tag>)}
                                </div>
                                {/* <img className="img" src={item.cover_url} width={200} height={300}></img> */}
                            </div>
                            <div className="title">
                                {item.recommend ? <Tooltip title="点击取消推荐"><StarFilled onClick={() => handleSetRecommend(item.international_code, false)} className="recommend" style={{ color: "orange" }} /></Tooltip> : <Tooltip title="点击设置推荐"> <StarOutlined onClick={() => handleSetRecommend(item.international_code, true)} className="no-recommend" /></Tooltip>} <span onClick={() => handleClickModal(item)}>{item.title} <Divider type="vertical"></Divider> <span style={{ color: "#aaa" }}>{item.author}</span> </span>
                            </div>
                        </div>
                    })
                }
            </InfiniteScroll>
        </div>
    </div>
}



const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        user: global.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Book);