
import { Badge, Avatar } from "antd"
import { CrownTwoTone } from '@ant-design/icons';
import { connect } from "react-redux";
import { userInformationModal } from "../../store/global/actions"
import Config from "../../config"
import "./UserAvatar.css"

function UserAvatar(props) {
    const { onlineUsers, id = null, vip = false, size = 40, avatar = "", onlyAvatar = false, disabled = false } = props
    const onlineUserList = onlineUsers.online_user_list || []
    const online = !!onlineUserList.find((online) => online?.user?.id?.toString() === id?.toString())

    return <div className="user-avatar-wrap" onClick={() => !disabled && id && props.show(id)} {...props}>
        <Badge className="user-avatar" count={vip ? <CrownTwoTone twoToneColor="#eb2f96" rotate={45} /> : null} style={{ fontSize: size * 0.4 }}>
            <Avatar src={Config.AvatarUrl(avatar)} className={`${online ? "online" : ""}`} size={size} />
        </Badge>
        {!onlyAvatar && <span className="nickname" style={{ maxWidth: size + 4 }}>{props.nickname || id}</span>}
    </div>
}

const mapStateToProps = ({ global }) => {
    return {
        onlineUsers: global.onlineUsers
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        show: (userId) => {
            dispatch(userInformationModal.show(userId))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAvatar);