import React from "react";
import { List, Tag, Button, message, Modal, Divider, TreeSelect, Pagination } from 'antd';
import { Link } from "react-router-dom";
import { FormOutlined, CalendarOutlined, EyeOutlined, UserOutlined, IssuesCloseOutlined, DeleteOutlined, ReloadOutlined, PlusSquareTwoTone, SafetyCertificateOutlined, PlaySquareOutlined } from '@ant-design/icons';

import { Menu as MenuAction } from '../../store/global/actions'

import ButtonGroup from "antd/lib/button/button-group";

import "./List.css"

import LocalTimeAgo from "../../components/local_time_ago";
import { connect } from "react-redux";
import AlbumForm from "./AlbumForm";

const { TreeNode } = TreeSelect;
class VideoCourseList extends React.Component {
    state = {
        channel: null,
        data: {},
        filterTag: null,
        status: null,
        cate_id: null,
        cateTreeData: []
    }

    componentDidMount() {
        this.props.setActiveKey("VIDEO-COURSE")
        const channel = this.props.socket.channel("ops:resource:video:course")
        channel.on("data", (data) => this.setState({ data }))
        // 分类树
        channel.on("cate-tree", ({ data: cateTreeData }) => this.setState({ cateTreeData }))
        channel.join()
        this.setState({ channel })
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    pushEvent(event, params, opts = null) {
        return this.state.channel.push(event, params)
    }

    tableChange = (page, size) => {
        this.pushEvent("page-to", { page_size: size, page: page })
    }

    onFinish = (values) => {
        this.pushEvent("query", values)
    }

    onReset = () => {
        this.pushEvent("query-reset", {})
    }

    metaTranslate(label) {
        let text = ""
        switch (label.meta) {
            case "acupoint:resource:label::cover":
                text = "封面"
                break;
            case "acupoint:resource:label::duration":
                text = "时长"
                break;
            case "acupoint:resource:label::oss":
                text = "OSS"
                break;
            case "acupoint:resource:label::playback":
                text = "播放次数"
                break;
            case "acupoint:resource:label::uploader":
                text = "上传者"
                break;
            case "acupoint:resource:label::m3u8":
                text = "流视频"
                break;
            default:
                text = "暂未定义"
                break;
        }
        return <Tag style={{ margin: 3 }}><span style={{ color: "#aaa" }}>{text}</span>: {this.mediaTranslate(label.value)}</Tag>
    }

    permissionTranslate(state) {
        switch (state) {
            case "purchase": return <span style={{ color: "orange" }}><SafetyCertificateOutlined /> <span >需购买</span></span>
            case "free": return <span style={{ color: "silver" }}><SafetyCertificateOutlined /> <span >免费</span></span>
            case "vip": return <span style={{ color: "#1890ff" }}><SafetyCertificateOutlined /> <span >需VIP</span></span>

            default:
                break;
        }
    }

    stateTranslate(state) {
        switch (state) {
            case "publish": return <span style={{ color: "green" }}><IssuesCloseOutlined /> <span >发布</span></span>
            case "draft": return <span style={{ color: "orange" }}><IssuesCloseOutlined /> <span >草稿</span></span>
            case "complain": return <span style={{ color: "silver" }}><IssuesCloseOutlined /> <span >举报下架</span></span>
            case "delete": return <span style={{ color: "red" }}><DeleteOutlined /> <span >被删除</span></span>

            default:
                break;
        }
    }

    mediaTranslate(media) {
        switch (media.type) {
            case "acupoint:media:type::text":
            case "acupoint:media:type::integer":
                return media.value
            case "acupoint:media:type::oss":
                return <a rel="noreferrer" target="_blank" href={media.path}>查看</a>

            default:
                break;
        }
    }

    handleStateChange(id, value) {
        this.pushEvent("edit:album:state", { state: value, id: id }).receive("ok", () => message.success("设置成功"))

    }

    handleLabelTagClick(id, tagName) {
        this.pushEvent("edit:album:tag", { id, tag_name: tagName })
    }

    handleLabelTagFilter(tagName) {
        this.setState({ filterTag: tagName })
        this.pushEvent("filter-by-tag", { tag_name: tagName })
    }

    handleStatusFilter(status) {
        this.setState({ status: status })
        this.pushEvent("filter-by-status", { status: status })
    }

    handleCateIDFilter(cate_id) {
        this.setState({ cate_id: cate_id })
        this.pushEvent("filter-by-cate_id", { cate_id: cate_id })
    }

    handleBadgeClick(id, badgeName, color) {
        this.pushEvent("edit:album:badge", { id, badge_name: badgeName, color })
    }

    handleDeleteClick(id) {
        this.pushEvent("delete:at:status", { id })
    }

    handleToDraftClick(id) {
        this.pushEvent("draft:at:status", { id })
    }

    // 分类树的选项
    cateTreeToSelectOption = tree => {
        tree = tree || []

        if (tree.length > 0) {
            return tree.map(({ id, name, children }) => {
                return <TreeNode key={id} value={id} title={name}>{this.cateTreeToSelectOption(children)}</TreeNode>
            })
        } else {
            return null
        }
    }

    render() {
        const data = this.state.data;

        return <div className="page resource-video-course-list">

            <div className="actions" style={{ display: "flex" }}>
                <p>
                    <Button icon={<PlusSquareTwoTone />} type="link" onClick={() => this.setState({ showCreateAlbum: true })}>
                        新增课程
                    </Button>
                </p>
            </div>
            <p style={{ paddingLeft: 20 }}>
                <ButtonGroup size="small">
                    <Button type={this.state.filterTag === null && "primary"} size="small" onClick={() => this.handleLabelTagFilter(null)}>全部</Button>
                    <Button type={this.state.filterTag === "精品" && "primary"} size="small" onClick={() => this.handleLabelTagFilter("精品")}>精品</Button>
                    <Button type={this.state.filterTag === "入门" && "primary"} size="small" onClick={() => this.handleLabelTagFilter("入门")}>入门</Button>
                    <Button type={this.state.filterTag === "基础" && "primary"} size="small" onClick={() => this.handleLabelTagFilter("基础")}>基础</Button>
                    <Button type={this.state.filterTag === "临床" && "primary"} size="small" onClick={() => this.handleLabelTagFilter("临床")}>临床</Button>
                    <Button type={this.state.filterTag === "保健养生" && "primary"} size="small" onClick={() => this.handleLabelTagFilter("保健养生")}>保健养生</Button>
                </ButtonGroup>
                <ButtonGroup size="small">
                    <Button type={this.state.status === null && "primary"} size="small" onClick={() => this.handleStatusFilter(null)}>全部</Button>
                    <Button type={this.state.status === 1 && "primary"} size="small" onClick={() => this.handleStatusFilter(1)}>已发布</Button>
                    <Button type={this.state.status === 0 && "primary"} size="small" onClick={() => this.handleStatusFilter(0)}>草稿</Button>
                    <Button type={this.state.status === 2 && "primary"} size="small" onClick={() => this.handleStatusFilter(2)}>举报下架</Button>
                    <Button type={this.state.status === 3 && "primary"} size="small" onClick={() => this.handleStatusFilter(3)}>已删除</Button>
                </ButtonGroup>

                <Divider type="vertical" />
                <TreeSelect listHeight="60vh" placeholder="筛选分类" treeDefaultExpandAll style={{ minWidth: 200 }} allowClear onChange={v => this.handleCateIDFilter(v)}>
                    {this.cateTreeToSelectOption(this.state.cateTreeData)}
                </TreeSelect>
            </p>

            {!!data.entries && <>
                <List dataSource={data.entries}
                    itemLayout="vertical"
                    size="large"
                    style={{ flex: "1 1 auto", overflowY: "auto" }}
                    pagination={false}
                    renderItem={item => (
                        <List.Item key={item.id} className="item-article" actions={
                            [
                                <Link to={`/cms/video-course-list/${item.id}/editor`}><FormOutlined /> 查看及编辑</Link>,
                                item.state === "delete" ?
                                    <Button onClick={() => this.handleToDraftClick(item.id)} type="link" size="small" icon={<ReloadOutlined />}>恢复成草稿</Button> :
                                    <Button onClick={() => this.handleDeleteClick(item.id)} danger type="link" size="small" icon={<DeleteOutlined />} >删除</Button>,
                                <ButtonGroup size="small">
                                    <Button type={item.labels?.tag?.indexOf("精品") > -1 && "primary"} size="small" onClick={() => this.handleLabelTagClick(item.id, "精品")}>精品</Button>
                                    <Button type={item.labels?.tag?.indexOf("入门") > -1 && "primary"} size="small" onClick={() => this.handleLabelTagClick(item.id, "入门")}>入门</Button>
                                    <Button type={item.labels?.tag?.indexOf("基础") > -1 && "primary"} size="small" onClick={() => this.handleLabelTagClick(item.id, "基础")}>基础</Button>
                                    <Button type={item.labels?.tag?.indexOf("临床") > -1 && "primary"} size="small" onClick={() => this.handleLabelTagClick(item.id, "临床")}>临床</Button>
                                    <Button type={item.labels?.tag?.indexOf("保健养生") > -1 && "primary"} size="small" onClick={() => this.handleLabelTagClick(item.id, "保健养生")}>保健养生</Button>
                                </ButtonGroup>,
                                <ButtonGroup size="small" className="cover-badge-setting">
                                    <Button type="link" onClick={() => this.handleBadgeClick(item.id, "名师", "ff5568ff")} className={item.labels?.badge?.name === "名师" ? "active ming-shi" : "ming-shi"} size="small">
                                        名师
                                    </Button>
                                    <Button type="link" onClick={() => this.handleBadgeClick(item.id, "推荐", "ffff6161")} className={item.labels?.badge?.name === "推荐" ? "active tui-jian" : "tui-jian"} size="small">
                                        推荐
                                    </Button>
                                </ButtonGroup>
                                // <Select defaultValue={item.state} size="small" onChange={(value) => this.handleStateChange(item.id, value)}>
                                //     <Select.Option value="draft">草稿</Select.Option>
                                //     <Select.Option value="publish">发布</Select.Option>
                                //     <Select.Option value="complain">举报下架</Select.Option>
                                // </Select>
                            ]}
                            extra={
                                <img
                                    width={300}
                                    height={150}
                                    alt="logo"
                                    src={item.labels.cover}
                                    className="cover"
                                />
                            }>

                            <div className="title-line">
                                <span className="title"><span style={{ borderRadius: 3, display: "inline-block", padding: "1px 3px", fontFamily: "monospace", background: "#dedede", color: "#fff" }}>{item.index}</span> {item.name}</span>
                            </div>

                            <div className="meta-line">
                                <span className="permission">
                                    <EyeOutlined /> {item.labels.playback} </span>
                                {/* <span className="status"> {item.status === "active" ? "激活" : "禁用"} </span> */}
                                <span className="publishAt">
                                    <CalendarOutlined /> <LocalTimeAgo date={item.inserted_at} /> </span>
                                <span className="view-sum">
                                    <PlaySquareOutlined /> {item.labels.lesson_count} 节 </span>
                                <span className="view-sum">
                                    <UserOutlined /> {item.labels.author} </span>
                                <span className="state"> {this.stateTranslate(item.state)} </span>
                                <span className="permission"> {this.permissionTranslate(item.permission)} </span>
                                {
                                    item.permission === "purchase" && <>
                                        <span className="purchased_count">销售数量 {item.purchased_count || 0} </span>
                                        <span className="purchased_amount">销售金额 {(item.purchased_amount / 100) || 0} </span>
                                    </>
                                }
                            </div>
                            <div className="content"> {item.labels.summary}</div>
                        </List.Item>
                    )}
                />
                <Pagination onChange={(page, size) => { this.tableChange(page, size) }} total={data.total_count} current={data.page_number} pageSize={data.page_size} showQuickJumpe />

            </>}
            {this.state.channel && this.state.cateTreeData.length > 0 &&
                <Modal title="新增课程" open={this.state.showCreateAlbum} footer={false} onCancel={() => this.setState({ showCreateAlbum: false })}>
                    <AlbumForm channel={this.state.channel} cateTree={this.state.cateTreeData} closeModal={() => this.setState({ showCreateAlbum: false })} />
                </Modal>
            }
        </div>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VideoCourseList)