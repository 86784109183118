import React from "react";
import dayjs from 'dayjs';
import { List, Tag, Tooltip, Badge, Switch } from 'antd';
import LocalTimeAgo from "../components/local_time_ago";

import { WechatOutlined, AlipayOutlined, AppleFilled, ShoppingCartOutlined, MoneyCollectTwoTone, HarmonyOSOutlined } from '@ant-design/icons';

import { connect } from 'react-redux'
import UserAvatar from "../components/user/UserAvatar";
// import coinSvg from "../images/coin.svg"

const coinSvg = <svg t="1645085489125" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12329" width="18" height="18"><path d="M739.1744 155.1872a425.02144 425.02144 0 0 1 65.4848 227.1744c0 235.8272-191.1808 427.0592-427.0592 427.0592-84.224 0-162.6624-24.4736-228.8128-66.56 75.5712 120.064 209.2032 199.8848 361.5744 199.8848 235.8272 0 427.0592-191.1808 427.0592-427.0592 0-151.6544-79.1552-284.7232-198.2464-360.4992z" fill="#ffa115" p-id="12330"></path><path d="M510.3616 973.4656c-252.416 0-457.7792-205.3632-457.7792-457.7792s205.3632-457.7792 457.7792-457.7792 457.7792 205.3632 457.7792 457.7792-205.3632 457.7792-457.7792 457.7792z m0-854.1184c-218.5216 0-396.3392 177.7664-396.3392 396.3392S291.84 912.0256 510.3616 912.0256s396.3392-177.7664 396.3392-396.3392-177.8176-396.3392-396.3392-396.3392z" fill="#474A54" p-id="12331"></path><path d="M655.6672 585.216h-116.6336v-49.9712h116.6336c16.9472 0 30.72-13.7728 30.72-30.72s-13.7728-30.72-30.72-30.72h-80.3328l102.3488-104.96c11.8272-12.1344 11.6224-31.5904-0.5632-43.4176-12.1344-11.8272-31.5904-11.6224-43.4176 0.5632L508.3136 454.5536 382.9248 325.9392a30.72 30.72 0 0 0-43.4176-0.5632 30.72 30.72 0 0 0-0.5632 43.4176l102.3488 104.96H360.96c-16.9472 0-30.72 13.7728-30.72 30.72s13.7728 30.72 30.72 30.72h116.6336v49.9712H360.96c-16.9472 0-30.72 13.7728-30.72 30.72s13.7728 30.72 30.72 30.72h116.6336v84.6336c0 16.9472 13.7728 30.72 30.72 30.72s30.72-13.7728 30.72-30.72V646.656h116.6336c16.9472 0 30.72-13.7728 30.72-30.72s-13.7728-30.72-30.72-30.72z" fill="#474A54" p-id="12332"></path></svg>

class BuyingList extends React.Component {

    state = {
        data: [],
        today_amount: 0,
        channel: null,
        // 最近订单的范围
        scope: localStorage.getItem("BuyingList.state.scope") || "all"
    }

    componentDidMount() {
        const channel = this.props.socket.channel("ops:buying_list", { scope: this.state.scope })
        channel.on("latest_10_orders", ({ data, today_amount }) => this.setState({ data, today_amount }))
        channel.join()
        this.setState({ channel })
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    handleSelectUser(user_id) {
        this.props.history.push("/operation/" + user_id);
    }

    handleScopeChanged(purchased) {
        const scope = purchased ? "purchased" : "all"
        this.setState({ scope: scope })
        this.state.channel.push("reload", { scope })
        localStorage.setItem("BuyingList.state.scope", scope)
    }

    renderItem(item) {
        return <List.Item onClick={() => this.handleSelectUser(item.user_id)} style={{ cursor: "pointer", opacity: item.status === "finished" ? 1 : 0.5 }}>
            <List.Item.Meta
                avatar={<UserAvatar avatar={item.avatar} id={item.user_id} onlyAvatar disabled />}
                title={<span style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>{(item.nickname || item.user_id.toString()).substr(0, 12)}</span>
                    <span>
                        <Tooltip title={dayjs(item.created_at).format("YYYY-MM-DD HH:mm:ss")}>
                            <LocalTimeAgo date={item.created_at} />
                        </Tooltip>
                    </span>
                </span>}
                description={<>
                    <div style={{ marginBottom: 5 }}>{this.financeOrderIcon(item)} <Tag>{item.app_info?.channel?.replace("ReleaseChannel.", "")}</Tag> <Tag>{item.app_info?.version}</Tag><Tag>{item.summary}</Tag></div>
                    <div>{item.tags.map(tag => <Tag size="small" key={tag}>{tag}</Tag>)}</div>
                    {item.data?.pricing?.message && <div>"{item.data?.pricing?.message}"</div>}
                </>}
            />
        </List.Item>
    }

    render() {
        return <List
            header={<div >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>最近订单</span>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span><Switch checkedChildren="购买" unCheckedChildren="全部" size="small" onChange={this.handleScopeChanged.bind(this)} checked={this.state.scope === 'purchased'} />  </span>
                    <span style={{ color: '#1890ff' }}>
                        <MoneyCollectTwoTone /> 今日 {this.state.today_amount / 100}
                    </span>
                </div>
            </div>}
            bordered
            dataSource={this.state.data}
            renderItem={item => (
                item.status === "finished" ?
                    <Badge.Ribbon color="#f4f4f4" style={{ padding: "2px 4px 0 4px" }} text={coinSvg} >
                        {this.renderItem(item)}
                    </Badge.Ribbon> : this.renderItem(item)
            )}
        />
    }

    financeOrderIcon(order) {
        switch (order.trade_channel_meta) {
            case "acupoint:trade:channel::wechat":
                return <Tag icon={<WechatOutlined style={{ color: "green" }} />} color="green">{Number(order.amount) / 100}</Tag>;

            case "acupoint:trade:channel::alipay":
                return <Tag icon={<AlipayOutlined style={{ color: "#027AFF" }} />} color="blue">{Number(order.amount) / 100}</Tag>;

            case "zy:trade::balance":
                return <Tag icon={<ShoppingCartOutlined style={{ color: "orange" }} />} color="orange">{Number(order.amount)}</Tag>;

            case "acupoint:trade:channel::huawei":
                return <Tag icon={<HarmonyOSOutlined style={{ color: "black" }} />} color="orange">{Number(order.amount) / 100}</Tag>;

            default:
                return <Tag icon={<AppleFilled style={{ color: "black" }} />} color="default">{Number(order.amount) / 100}</Tag>;
        }
    }
}

const mapStateToProps = () => {
    return {
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(BuyingList);