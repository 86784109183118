import { HashRouter as Router, Route } from "react-router-dom";
import { Menu as MenuAction } from '../store/global/actions'
import { connect } from 'react-redux';
import Plan from "./Plan";
import InviteCode from "./InviteCode";
import RewardConvert from "./RewardConvert";
import Contract from "./Contract"
import SideMenu from "./components/side_menu"
import React from "react";
import InviteLog from "./InviteLog";

import RequireRolesOr from '../components/require_roles_or'
import { Switch } from "react-router-dom";

class Marketing extends React.Component {

    componentDidMount() {
        this.props.setActiveKey("MARKETING")
    }

    render() {
        return <Router>
            <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::marketing"]}>
                <div className="module module-statistics">
                    <div className="side-menu">
                        <SideMenu />
                    </div>
                    <div className="content">
                        <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::marketing"]}>
                            <Route path="/marketing/contract" exact component={Contract}></Route>
                            <Route path="/marketing/plan" exact component={Plan}></Route>
                            <Switch>
                                <Route path="/marketing/invite-code/log" exact component={InviteLog}></Route>
                                <Route path="/marketing/invite-code/:ownerType" exact component={InviteCode}></Route>
                            </Switch>
                            <Route path="/marketing/invite-reward/convert" exact component={RewardConvert}></Route>
                            {/* <Route path="/marketing/target" exact component={NinetonLog}></Route>
                        <Route path="/marketing/plan" exact component={ActiveRecords}></Route> */}
                        </RequireRolesOr>
                    </div>
                </div>
            </RequireRolesOr>
        </Router>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Marketing)