import { Form, Input, Button, Modal, Upload, message, Image } from "antd"
import React from 'react'
import { useState } from "react"
import { connect } from "react-redux";

import RewardConvertItem from "../components/reward_convert_item"
import { UploadOutlined } from '@ant-design/icons';
import Config from "../../config";
import Sha256Encode from "../../components/sha256";


class RewardConvertReleaseFormModal extends React.Component {

    state = {
        rewardConvert: this.props.rewardConvert,
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.rewardConvert !== this.state.rewardConvert)
            this.setState({ rewardConvert: nextProps.rewardConvert })
    }

    handleCancel() {
        this.props.close()
    }

    show(rewardConvert) {
        this.setState({ tabKey: null, rewardConvert })
    }

    render() {
        return <Modal title="提交发放凭据" width={600} destroyOnClose centered footer={null} open={!!this.state.rewardConvert} onOk={false} onCancel={this.handleCancel.bind(this)}>
            {this.state.rewardConvert && <RewardConvertReleaseFormWrap rewardConvert={this.state.rewardConvert} inModal {...this.props} onFinish={this.handleCancel.bind(this)}></RewardConvertReleaseFormWrap>}
        </Modal>
    }
}


const formItemLayout = {
    labelCol: {
        xs: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 17 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 4,
            offset: 6,
        },
    },
};

function RewardConvertReleaseForm({ channel, rewardConvert, onRefresh, onFinish, jsonRequest, ...props }) {
    const [rewardReleadImg, setRewardReleadImg] = useState(null)

    // 处理提取审核表单
    const handleRewardConvertAuditFormSubmit = (invite_reward_convert_id, values) => {
        channel.push("release:convert", { ...values, invite_reward_convert_id, reward_release_img: rewardReleadImg })
            .receive("ok", () => {
                if (!!onRefresh)
                    onRefresh()
                message.success("提交了发放");
                if (!!onFinish)
                    onFinish()
            })
    }

    const handleUploadFile = (obj) => {
        const file = obj.file
        message.loading({ content: "上传中，请稍后...", key: "handleUploadFile", duration: 0 })
        file.arrayBuffer().then(file_content => {
            Sha256Encode(file_content).then(file_name => {
                var data = new FormData()
                data.append('file', file)
                data.append('filename', file_name)
                data.append('owner_type', "RewardConvert")
                data.append('owner_id', rewardConvert.id)

                jsonRequest.file(`/api/operation/media/upload`, data)
                    .then(resp => resp.json())
                    .then(({ data }) => {
                        const { path } = data
                        message.success({ content: "上传成功", key: "handleUploadFile", duration: 2 })
                        setRewardReleadImg(path)
                    })
            })
        })
    }

    return <div>
        <Form {...formItemLayout} onFinish={(values) => handleRewardConvertAuditFormSubmit(rewardConvert.id, values)}>
            <Form.Item label="申请">
                <RewardConvertItem reward={rewardConvert} />
            </Form.Item>
            <Form.Item label="发放凭据" rules={[{ required: true }]}>
                <Upload.Dragger
                    showUploadList={false}
                    customRequest={(obj) => handleUploadFile(obj)}
                >
                    {rewardReleadImg ? <Image src={Config.ResourceUrl(rewardReleadImg)} /> : <> <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                    </p>
                        <p className="ant-upload-text">点击或拖拽上传</p></>}
                </Upload.Dragger>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" disabled={!rewardReleadImg}>
                    提交
                </Button>
            </Form.Item>
        </Form>
    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}

const RewardConvertReleaseFormWrap = connect(mapStateToProps, mapDispatchToProps)(RewardConvertReleaseForm)

export { RewardConvertReleaseFormWrap, RewardConvertReleaseFormModal }