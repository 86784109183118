import { connect } from "react-redux";
import { NotificationChannel as NotiAction } from "../store/global/actions"

function NotificationChannel(props) {
    let channel = props.channel

    if (!channel) {
        channel = props.socket.channel("ops:notify")
        channel.join()
            .receive("ok", resp =>  Notification.requestPermission())
        props.join(channel)
    }

    // 设置事件监听的ref，以免波及整个event的监听
    !!window.notificationChannelNewPurchase && channel.off("new_purchase", window.notificationChannelNewPurchase)

    // 会话更新
    window.notificationChannelNewPurchase = channel.on("new_purchase", ({ order, user }) => {
        Notification.requestPermission().then(function (permission) {
            if (permission === 'granted') {
                var ops_notification = new Notification(`${user.nickname || "匿名用户"} ${order.amount / 100}元`, {
                    body: `${order.data.channel.replace("ReleaseChannel.", "")}\n${order.trade_channel_meta}`,
                    icon: user.avatar
                });
                ops_notification.onclick = () => ops_notification.close();
            }
        });
    });


    return <></>
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        channel: global.notificationChannel
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        join: (channel) => {
            dispatch(NotiAction.join(channel))
        },
        leave: () => {
            dispatch(NotiAction.leave())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationChannel)