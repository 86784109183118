import React from "react";
import { List, Switch, Button, Popover, message, Form, Modal, Input, TreeSelect, Radio, Divider, Checkbox, Table, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { Menu as MenuAction } from '../../store/global/actions'

import { RocketOutlined, PlusSquareTwoTone, CheckCircleTwoTone, EditTwoTone, DeploymentUnitOutlined } from '@ant-design/icons';

import MediaViewer from "../components/media_editor";
import Column from "antd/lib/table/Column";
import PasswordConfirm from "../../components/passwordConfirm";
import ListGrid from "../components/ListGrid";

const { TreeNode } = TreeSelect;


function IllnessForm({ ref, initial, cateTree }) {
    return <Form ref={ref} name="basic" initialValues={initial} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
        <Form.Item label="疾病名称" name="name" rules={[{ required: true, message: '不能为空' }]}>
            <Input />
        </Form.Item>
        <Form.Item name="type" label="类别" initialValue="general" rules={[{ required: true, message: '不能为空' }]}>
            <Radio.Group>
                <Radio value="general">常见疾病</Radio>
                <Radio value="advance">其他</Radio>
            </Radio.Group>
        </Form.Item>

        <Form.Item name="cate_id" label="分类" rules={[{ required: true, message: '不能为空' }]}>
            <TreeSelect showSearch treeDefaultExpandAll>
                {cateTree}
            </TreeSelect>
        </Form.Item>
    </Form>
}

function QueryForm(props) {
    const [form] = Form.useForm()

    form.setFieldsValue(props.values)

    return <div>
        <Form form={form} autoComplete="off" layout="inline" onFinish={props.onSearch} initialValues={props.values}>
            <Form.Item name="name" label="疾病名">
                <Input placeholder="模糊查询"></Input>
            </Form.Item>
            <Form.Item name="cate_id" label="分类">
                <TreeSelect showSearch treeDefaultExpandAll style={{ minWidth: 200 }} allowClear>
                    {props.cateTree}
                </TreeSelect>
            </Form.Item>
            <Button.Group>
                <Button type="primary" htmlType="submit">查询</Button>
                <Button onClick={() => props.onSearch({})} htmlType="reset">清空</Button>
            </Button.Group>
        </Form>
    </div>
}

class IllnessList extends React.Component {
    formRef = React.createRef();

    state = {
        channel: null,
        data: {},
        editable: false,
        loading: false,
        query: { name: this.props.match?.params?.name },
        cateTreeData: [],
        cateTree: [],
        editorHeight: 500,
    }

    joinChannel(props) {
        this.setState({ loading: true })
        const channel = props.socket.channel("ops:resource:illness", { page_size: 4, ...this.state.query })
        channel.on("data", ({ data }) => this.setState({ data, loading: false }))
        channel.join()
        channel.push("cate-tree", {})
            .receive("ok", (tree) => this.setState({ cateTreeData: tree, cateTree: this.cateTreeToSelectOption(tree) }))
        this.setState({ channel })
    }

    componentDidMount() {
        this.props.setActiveKey("Illness")
        this.joinChannel(this.props)
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        !!this.state.channel && this.state.channel.off("data")
        !!this.state.channel && this.state.channel.leave()

        this.setState({ query: { ...this.state.query, name: nextProps.match?.params?.name } }, () => this.joinChannel(nextProps))

    }

    // 处理点击是否发布
    handleClickPublish(illnessID) {
        this.state.channel.push("publish!", { id: illnessID }).receive("ok", () => message.success("发布成功"))
    }

    // 处理点击是否删除
    handleClickDrop(illnessID) {
        this.state.channel.push("drop!", { id: illnessID }).receive("ok", () => message.success("删除成功"))
    }


    handlePageTo(page, size) {
        this.setState({ loading: true })
        this.state.channel.push("page-to", { page, size })
            .receive("ok", () =>
                this.setState({ loading: false }))
    }

    handleSearch(values) {
        this.setState({ loading: true, query: values })
        this.state.channel.push("search", values)
            .receive("ok", () =>
                this.setState({ loading: false }))
    }

    // 处理点击添加图标
    handleClickToggleBadge(illnessID, badge_name, value) {
        this.state.channel.push("set-badge", { id: illnessID, badge_name, value }).receive("ok", () => message.success("发布成功"))
    }


    cateTreeToSelectOption(tree) {
        tree = tree || []

        if (tree.length > 0) {
            return tree.map(({ id, name, children }) => {
                return <TreeNode key={id} value={id} title={name}>{this.cateTreeToSelectOption(children)}</TreeNode>
            })
        } else {
            return null
        }
    }

    handleAddDraft = () => {
        const channel = this.state.channel

        Modal.confirm({
            title: '添加疾病',
            content: IllnessForm({ ref: this.formRef, cateTree: this.state.cateTree, initial: {} }),
            onOk: (close) => {
                const form = this.formRef.current
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        channel.push("add-draft", { ...values })
                            .receive("ok", (resp) => {
                                close()
                                message.success("创建成功，在列表中编辑")
                                this.handleSearch({ name: resp.name })
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    exportPasswordConfirmRef = React.createRef()
    handleClickExport() {
        PasswordConfirm({
            ref: this.exportPasswordConfirmRef,
            title: '密码确认',
            message: "导出数据会被记录在操作日志里，请谨慎操作。",
            onOk: ({ password }, close) => {
                message.loading({ content: '请求数据中...', key: "exporting", duration: 0 })
                this.state.channel.push("export-from-filter", { password }, 100000)
                    .receive("ok", ({ encrypt_str }) => {
                        navigator.clipboard.writeText(encrypt_str)
                            .then(() => {
                                message.success({ content: '已复制到剪切板中', key: "exporting", duration: 3 })
                                close()
                            })
                            .catch(() => {
                                message.error({ content: '写入剪切板错误', key: "exporting", duration: 3 })
                                navigator.clipboard.writeText("")
                            })

                    })
            }
        })
    }

    handleClickImport() {
        PasswordConfirm({
            ref: this.exportPasswordConfirmRef,
            title: '密码确认',
            message: "导入数据会被记录在操作日志里，请谨慎操作。",
            onOk: ({ password }, close) => {
                message.loading({ content: '导入中...', key: "importing", duration: 3 })
                navigator.permissions.query({ name: 'clipboard-read' })
                    .then(result => {
                        if (result.state === "granted" || result.state === "prompt") {
                            navigator.clipboard.readText()
                                .then(text => {
                                    this.state.channel.push("import-from-encrypt-str", { str: text, password: password }, 100000)
                                        .receive("ok", ({ review_data }) => {
                                            close()
                                            this.handleClickImportConfirm(review_data)
                                            message.warn({ content: '确认导入数据', key: "importing", duration: 3 })

                                            navigator.clipboard.writeText("")
                                        })
                                })
                                .catch(err => {
                                    console.log(err)
                                    message.error({ content: '读入剪切板错误', key: "importing", duration: 3 })
                                    navigator.clipboard.writeText("")
                                });
                        }
                    })
            }
        })
    }

    handleClickImportConfirm(reviewData) {
        Modal.confirm({
            title: '预览导入数据',
            width: 600,
            content: <Table dataSource={reviewData}>
                <Column title="名称" dataIndex="name" key="name"></Column>
                <Column title="数据库中存在(将忽略)" align="center" dataIndex="exists" key="exists" render={(value) => value && <CheckCircleTwoTone />}></Column>
            </Table>,
            onOk: (close) => {
                this.state.channel.push("import-confirm", {}, 100000)
                    .receive("ok", () => {
                        message.success({ content: '已导入', key: "importing", duration: 3 })
                        close()
                    })
                return false
            },
            okText: "确认导入"
        });
    }

    // 添加到图谱推荐表
    handleClickAddToGraphSuggest(id, name) {
        this.state.channel.push("add-to-graph-suggest", { content_type: `illness`, content_id: id, content_name: name })
            .receive("ok", () => message.success("添加到图谱推荐列表成功"))
    }

    editBasicInfo(initial) {
        const channel = this.state.channel
        Modal.confirm({
            title: '修改基础信息',
            content: IllnessForm({ ref: this.formRef, cateTree: this.state.cateTree, initial: initial }),
            onOk: (close) => {
                const form = this.formRef.current
                form
                    .validateFields()
                    .then(values => {
                        channel.push("edit", { id: initial.id, ...values })
                            .receive("ok", (resp) => {
                                close()
                                message.success("修改成功")
                                form.resetFields();
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    // 类别展示
    typeRender(type) {
        switch (type) {
            case "general":
                return <span style={{ color: "#aaa" }}>常见疾病</span>

            default:
                break;
        }
    }

    getCateNameById(cate_id) {
        return this.state.cateTreeData.find(({ id }) => id === cate_id)
    }

    render() {
        return <div className="page resource-illness">
            {this.state?.channel &&
                <div className="actions" style={{ display: "flex" }}>
                    <Button icon={<PlusSquareTwoTone />} type="link" onClick={() => this.handleAddDraft()}>新增疾病</Button>
                    {/* <IllnessDraftForm onSuccess={(illness) => this.handleSearch({ name: illness.name })} cateTree={this.state.cateTree} pushEvent={(event, params) => this.state?.channel?.push(event, params)} /> */}
                </div>
            }
            <h1>疾病列表
                <Divider type="vertical" />
                <Switch onChange={checked => this.setState({ editable: checked })} checkedChildren="开启编辑" unCheckedChildren="只读" />
            </h1>

            <div style={{ display: "flex", alignItems: "center" }}>
                <QueryForm cateTree={this.state.cateTree} onSearch={this.handleSearch.bind(this)} values={this.state.query} />
                {this.state.editable && <>
                    <Divider type="vertical" />
                    <Button.Group>
                        <Button onClick={this.handleClickImport.bind(this)}  >导入</Button>
                        <Button onClick={this.handleClickExport.bind(this)} danger>导出筛选结果</Button>
                    </Button.Group>
                </>}
            </div>

            <br />
            {
                this.state.data.entries &&
                <ListGrid
                    dataSource={this.state.data.entries}
                    loading={this.state.loading}
                    rowKey={d => d.name}
                    pagination={{
                        onChange: (page, size) => { this.handlePageTo(page, size) },
                        total: this.state.data.total_entries,
                        current: this.state.data.page_number,
                        pageSize: this.state.data.page_size,
                        showQuickJumper: true
                    }}
                    onHeightEdit={(height) => this.setState({ editorHeight: height })}
                    renderItem={item => (
                        <List.Item className="item-illness">
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <small style={{ fontSize: 12, color: "#888" }}>{this.getCateNameById(item.cate_id)?.name}</small>
                                    <span style={{ fontSize: 20, fontWeight: "bold" }}>
                                        {item.name}
                                    </span>
                                </div>

                                <div>
                                    <span>{this.typeRender(item.type)}</span>
                                    <Divider type="vertical" />
                                    <Tooltip title="添加到图谱推荐表">
                                        <Button type="link" icon={<DeploymentUnitOutlined />} onClick={() => this.handleClickAddToGraphSuggest(item.id, item.name)} ></Button>
                                    </Tooltip>

                                    {this.state.editable ?
                                        <>
                                            <Divider type="vertical" />
                                            <Popover placement="top" content={<img src="https://acupoint-resource.oss-cn-shenzhen.aliyuncs.com/Illness/88241661248359_.pic.jpg"></img>}>
                                                <Checkbox checked={!!item?.data?.badge?.图} onChange={(e) => this.handleClickToggleBadge(item.id, "图", e.target.checked)}>图</Checkbox>
                                            </Popover>
                                            {/* <Divider type="vertical" />
                                            <Popover placement="top" content={<Button onClick={() => this.handleClickDrop(item.id)} danger size="small" type="primary">确认删除</Button>}>
                                                <Button type="link" size="small" danger icon={<DeleteTwoTone twoToneColor="red" />}></Button>
                                            </Popover> */}

                                            <Divider type="vertical" />
                                            <Button type="link" icon={<EditTwoTone />} onClick={() => this.editBasicInfo(item)}></Button>
                                            <span>{item.has_update &&
                                                <>
                                                    <Divider type="vertical" />
                                                    <Popover content={<Button onClick={() => this.handleClickPublish(item.id)} block type="primary" size="small">确认发布</Button>} trigger="click">
                                                        <Button type="link" icon={<RocketOutlined />}></Button>
                                                    </Popover>
                                                </>}
                                            </span>
                                        </> : <>
                                            <Divider type="vertical" />
                                            <Popover placement="top" content={<img src="https://acupoint-resource.oss-cn-shenzhen.aliyuncs.com/Illness/88241661248359_.pic.jpg"></img>}>
                                                <Checkbox checked={!!item?.data?.badge?.图} disabled>图</Checkbox>
                                            </Popover>
                                        </>}
                                </div>
                            </div>
                            <div>
                                <MediaViewer ownerType="Illness" hasUpdate={item.has_update} ownerID={item.id} channel={this.state.channel} name={item.name} detail={item.detail} type="illness" editable={this.state.editable} height={this.state.editorHeight} />
                                {this.state.editable && <Popover trigger="click" placement="top" content={<Button onClick={() => this.handleClickDrop(item.id)} danger size="small" type="primary">确认删除</Button>} ><Button block type="primary" size="small">删除</Button></Popover>}
                            </div>
                        </List.Item>
                    )
                    } />
            }
        </div >
    }
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IllnessList);