import { useEffect, useState } from "react"
import { Menu as MenuAction } from '../../store/global/actions'
import { connect } from "react-redux"
import { Quill } from "react-quill"
import { List, Button } from "antd"
import UserAvatar from "../../components/user/UserAvatar";
import Config from "../../config"
import dayjs from "dayjs"
import { FormModal } from "./FormModal"
import { Divider } from "antd"

function Guidelines({ socket, ...props }) {
    let [channel, setChannel] = useState(null)
    let [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let _channel = socket.channel("community:guidelines")
        _channel.on("data", ({ data }) => {
            setLoading(false)
            setData(data)
        })
        _channel.join()
        setChannel(_channel)
        return () => {
            if (!!_channel) _channel.leave()
        }
    }, [])

    const statusText = (statusEnum) => {
        switch (statusEnum) {
            case "publish":
                return <span style={{ color: "green" }}>已发布</span>
            case "draft":
                return <span>草稿</span>
        }
    }

    return <div className="page operation version">
        <h1>社区公约</h1>

        <p>
            <Button onClick={() => FormModal({ channel })} type="primary">新增版本</Button>
        </p>

        <List loading={loading} dataSource={data} itemLayout="vertical" key="versions-table" rowKey={item => item.id} size="middle"
            grid={{ gutter: 16, column: 2 }}
            renderItem={(item) => {
                const tempCont = document.createElement("div");
                (new Quill(tempCont)).setContents(item?.content?.delta);
                const contentHtml = tempCont.getElementsByClassName("ql-editor")[0].innerHTML.replaceAll(/src="(?!(http\:\/\/|https\:\/\/))(.+)"/g, `src="${Config.AttachUrl("")}/$2"`);

                return <List.Item style={{ borderBottom: "1px solid #eee", paddingBottom: 10 }} key={item.id} actions={[
                    <Button type="link" onClick={() => FormModal({ channel, id: item.id, status: item.status, commitReason: item.commit_reason, content: item.content?.delta })}>编辑内容</Button>
                ]}>
                    <List.Item.Meta avatar={<UserAvatar nickname={item.bearer?.name} size={40} avatar={item.bearer?.avatar} id={item.bearer?.owner_id} />}
                        title={<span><span style={{ color: "orange" }}>[版本{item.version}]</span> <span>{item.commit_reason}</span></span>}
                        description={<span>
                            <span>编辑时间：{dayjs(item.upadted_at).format("YYYY-MM-DD HH:mm")}</span>
                            <Divider type="vertical" />
                            <span>状态：{statusText(item.status)}</span>
                        </span>} />
                    <div style={{ background: "#fafafa", padding: "10px 10px 20px 10px", maxHeight: 400, overflowY: "auto", whiteSpace: "break-spaces" }}>
                        <div dangerouslySetInnerHTML={{ __html: contentHtml }}></div>
                    </div>
                </List.Item>
            }} />
    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Guidelines)