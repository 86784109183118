import { useState, useEffect } from "react";
import { Table } from "antd";
const { Column } = Table

function Pricings({ meta, group, pushEvent, ...props }) {
    const [data, setData] = useState([])

    useEffect(() => {
        pushEvent("channel-pricings", { meta, group })
            .receive("ok", ({ data }) => {
                setData(data)
            });

        return () => {
        }
    }, [props.userId, meta, group])

    return <div>
        <Table dataSource={data} size="middle" rowKey={d => d.id}>
            <Column title="展示名" dataIndex="display_name" key="display_name" />
            <Column title="售价" dataIndex="amount" key="amount" width={100} render={text => <span style={{ color: "green" }}>{text / 100} 元</span>} />
            <Column title="原价" dataIndex="originAmount" key="originAmount" render={text => <span style={{ color: "#aaa" }}>{text / 100} 元</span>} />
            <Column title="小标题" dataIndex="slogan" key="slogan" />
        </Table>
    </div>
}

export default Pricings;