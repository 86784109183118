import React, { useEffect, useState } from "react"
import { Form, ConfigProvider, Button, Input } from "antd"
import zhCN from 'antd/lib/locale/zh_CN';
import UserAdvanceFilter from "../../../components/user_advance_filter";
import useLocalStorageState from "use-local-storage-state";
import "./NotificationPushForm.css"

function NotificationPushForm({ form, channel, ...props }) {
    // 用户高阶筛选器的筛选结果容量
    const [userAdvanceFilterResult, setUserAdvanceFilterResult] = useState(null)
    const [releaseChannelsAndVersions, setReleaseChannelsAndVersions] = useState({})
    const [summary, setSummary] = useLocalStorageState("/community/simulator/notification_push_form/summary", null)
    const [contentTitle, setContentTitle] = useLocalStorageState("/community/simulator/notification_push_form/title", null)

    useEffect(() => {
        channel.push("get-release-channels-and-versions", {})
            .receive("ok", (data) => {
                setReleaseChannelsAndVersions(data)
                if (!summary)
                    setSummary(data.scene.default_summary)
                if (!contentTitle)
                    setContentTitle(data.scene.default_content?.title)
            })
    }, [])

    // 用户筛选器提交获取数量
    const handleUserAvdvanceFilterSubmit = (value) => {
        channel.push("get-user-count-by-filters", { filters: value })
            .receive("ok", (count) => {
                setUserAdvanceFilterResult(count)
                form.setFieldsValue({ filters: value })
            })
    }

    const defaultContentRender = () => {
        switch (releaseChannelsAndVersions.scene?.default_content?.type) {
            case "text":
                return releaseChannelsAndVersions.scene?.default_content?.content_text
            case "quill":
                return <div style={{ maxHeight: 300, overflowY: "auto" }} dangerouslySetInnerHTML={{ __html: releaseChannelsAndVersions.scene?.default_content?.content_text }}></div>

            case "community_post":
                return <div style={{ padding: "5px 10px", background: "#fff", margin: 5 }}>
                    <div><b>{props.postTitle}</b></div>
                    <div style={{ fontSize: 11 }}>阅读 {props.postViewCount} · 赞同 {props.postLikeCount}</div>
                </div>
            default:
                break;
        }
    }

    return <ConfigProvider locale={zhCN}>
        <Form className="notification push-form" form={form} name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 24 }} autoComplete="off" style={{ display: "flex" }}>
            <div style={{ width: "60%" }}>
                <Form.Item name="filters" rules={[{ required: true, message: '消息内容不能为空' }]}>
                    <div>
                        <UserAdvanceFilter channelData={releaseChannelsAndVersions.channel_data || []} versionData={releaseChannelsAndVersions.version_data || []} className="form" onChange={handleUserAvdvanceFilterSubmit} />
                        {userAdvanceFilterResult !== 0 && userAdvanceFilterResult ? <p>
                            筛选结果用户数：{userAdvanceFilterResult}
                        </p> : <span>无会员</span>}
                    </div>
                </Form.Item>
            </div>
            <div style={{ width: "40%" }}>
                <Form.Item name="summary" initialValue={summary} help="这里可以改简介">
                    <Input onChange={(e) => setSummary(e.target.value)} />
                </Form.Item>
                <Form.Item  >
                    <div style={{ background: "#f8f8f8", borderRadius: "5px", padding: 10, display: "flex" }}>
                        <div style={{ width: 55, padding: 5 }}>
                            <img src={releaseChannelsAndVersions.scene?.channel_icon} style={{ width: '100%' }}></img>
                        </div>
                        <div>
                            <div>{releaseChannelsAndVersions.scene?.channel_name}</div>
                            <div style={{ color: "#aaa" }}>{summary}</div>
                            <div style={{ color: "#aaa" }}>01-01 00:00:00</div>
                        </div>
                    </div>
                </Form.Item>
                <Form.Item name="contnet_title" initialValue={contentTitle} help="这里可以改内容的标题">
                    <Input onChange={(e) => setContentTitle(e.target.value)} />
                </Form.Item>
                <Form.Item>
                    <div className="review in-channel" style={{ background: "#f8f8f8", borderRadius: "5px", padding: 10 }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {releaseChannelsAndVersions.scene?.channel_icon && <img width={16} src={releaseChannelsAndVersions.scene?.channel_icon}></img>} &nbsp; {contentTitle}</div>
                        <div style={{ color: "#888" }} className="content">{defaultContentRender()}</div>
                        {releaseChannelsAndVersions.scene?.default_external.length > 0 && <div>
                            {releaseChannelsAndVersions.scene?.default_external.map(item => <Button size="small" key={item.text} type="link">{item.text}</Button>)}
                        </div>}
                        <div style={{ color: "#aaa" }}>01-01 00:00:00</div>
                    </div>
                </Form.Item>
            </div>
        </Form>
    </ConfigProvider>
}

export default NotificationPushForm;