import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { Button, Form, Modal, message, Avatar, List } from "antd"
import { Menu as MenuAction } from '../../store/global/actions'

import { Provider } from 'react-redux';
import { legacy_createStore as createStore } from "redux"
import reducer from '../../store';

import SceneForm from "./components/SceneForm"
import PushForm from "./components/PushForm";

import { Radio } from "antd"
import { Tooltip } from "antd";
import { Switch } from "antd";

import { Divider } from "antd";
import { Checkbox } from "antd";
import { PlusSquareTwoTone } from "@ant-design/icons"

const store = createStore(reducer)


function NotificationScene(props) {
    const [channel, setChannel] = useState(null)
    let [data, setData] = useState([])
    let [filterData, setFilterData] = useState({ channel_key: "all", category: "all" })
    let [channelData, setChannelData] = useState([])
    let [versionData, setVersionData] = useState({ entries: [] })
    let [releaseChannelData, setReleaseChannelData] = useState([])
    let [channelForm] = Form.useForm()
    let [pushForm] = Form.useForm()
    const quillRef = useRef()

    props?.setActiveKey("NOTIFICATION-SCENE")

    useEffect(() => {
        let _channel = props.socket.channel("notification:scene")
        _channel.join()
        setChannel(_channel)

        _channel.on("data", ({ data }) => { setData(data) })
        _channel.on("channel_data", ({ data }) => setChannelData(data))
        _channel.on("version_data", ({ data }) => setVersionData(data))
        _channel.on("release_channel_data", ({ data }) => setReleaseChannelData(data))


        return () => {
            if (!!_channel) _channel.leave()
        }
    }, [])

    // 添加版本
    const handleForm = (data) => {
        channelForm.setFieldsValue(data)

        Modal.confirm({
            width: 1500,
            title: '场景设置',
            content: <Provider store={store}>
                <SceneForm form={channelForm} data={data} hasDefaultContent={data.has_default_content} channels={channelData} quillRef={quillRef} />
            </Provider>,
            cancelText: "取消",
            okText: "提交",
            onCancel: () => {
                channelForm.resetFields();
            },
            onOk: (close) => {
                const form = channelForm
                form
                    .validateFields()
                    .then(values => {
                        if (!!data.id) {
                            channel.push("update", { quill: quillRef.current?.getEditor()?.getContents()?.ops, ...values, default_avatar: values.defaultAvatar })
                                .receive("ok", (resp) => {
                                    close()
                                    message.success("修改成功")
                                    form.resetFields();
                                })
                        }
                        else {
                            channel.push("create", { quill: quillRef.current?.getEditor()?.getContents()?.ops, ...values, default_avatar: values.defaultAvatar })
                                .receive("ok", (resp) => {
                                    close()
                                    message.success("创建成功")
                                    form.resetFields();
                                })
                        }
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    // 添加版本
    const pushFormModal = (scene) => {
        Modal.confirm({
            width: 1100,
            title: `场景[${scene.name}]手动推送`,
            content: <Provider store={store}>
                <PushForm form={pushForm} channel={channel} scene={scene} channelData={releaseChannelData} versionData={versionData.entries} />
            </Provider>,
            cancelText: "取消",
            okText: "提交",
            onCancel: () => {
                pushForm.resetFields();
            },
            onOk: (close) => {
                const form = pushForm
                form
                    .validateFields()
                    .then(values => {
                        channel.push("manual-push-by-filter", { ...values, scene_key: scene.key })
                            .receive("ok", (resp) => {
                                close()
                                message.success("推送成功")
                                form.resetFields();
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    // 筛选想设置并搜索
    const filterSetAndSearch = (name, value) => {
        let newFilterData = { ...filterData, [name]: value }
        setFilterData(newFilterData)

        let requestData = { ...newFilterData }
        if (requestData.channel_key === "all")
            delete requestData.channel_key

        if (requestData.category === "all")
            delete requestData.category

        if (requestData.can_manual_push === null)
            delete requestData.can_manual_push

        channel.push("query", requestData)
    }

    // 修改单个属性
    const updateAttr = (key, field, value) => {
        channel.push("update-field", { key, field, value })
    }

    return <div className="page operation version">
        <h1>
            通知场景管理
        </h1>
        <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
            <Button icon={<PlusSquareTwoTone />} type="link" onClick={() => handleForm({ default_external: [], default_content: { type: "text" }, has_default_content: true })}>
                新增自定义场景
            </Button>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>

            场景分类：
            <Radio.Group value={filterData?.category} size="small" onChange={(e) => filterSetAndSearch("category", e.target.value)}>
                <Radio.Button value="all">全部</Radio.Button>
                <Radio.Button value="社区">社区</Radio.Button>
                <Radio.Button value="会员">会员</Radio.Button>
                <Radio.Button value="财务">财务</Radio.Button>
                <Radio.Button value="积分">积分</Radio.Button>
            </Radio.Group>

            <Divider type="vertical" />

            频道：
            <Radio.Group value={filterData?.channel_key} size="small" onChange={(e) => filterSetAndSearch("channel_key", e.target.value)}>
                <Radio.Button value="all">全部</Radio.Button>
                {channelData.map((channel) => <Radio.Button key={channel.key} value={channel.key}>{channel.name}</Radio.Button>)}
            </Radio.Group>

            <Divider type="vertical" />

            <Checkbox onChange={(e) => filterSetAndSearch("can_manual_push", e.target.checked ? true : null)}>可手动推送</Checkbox>
        </div>
        <br />
        <div style={{overflowY: "auto", overflowX: "hidden"}}>
            <List dataSource={data} itemLayout="vertical" grid={{ gutter: 16, column: 4 }}
                renderItem={(item) => (
                    <List.Item actions={[
                        <Button type="link" size="small" onClick={() => handleForm(item)}>详情 / 修改</Button>,
                        <Switch size="small" checkedChildren="启用" onChange={(value) => updateAttr(item.key, "enable", value)} checked={item.enable} unCheckedChildren="禁用"></Switch>,
                        <Button type="link" onClick={() => pushFormModal(item)} disabled={!item.can_manual_push}>手动推送{item.can_manual_push}</Button>
                    ]} style={{ background: "#fafafa", padding: 10, borderRadius: 10 }}>
                        <List.Item.Meta
                            avatar={item.default_avatar && <Avatar size="large" src={item.default_avatar} />}
                            title={<div><span style={{ color: "orange" }}>[{item.channel_name || <Button type="link" size="small" onClick={() => handleForm(item)}>设置频道</Button>}]</span><span>{item.name}</span></div>}
                            description={<div>
                                <div style={{ height: 100, overflowY: "auto" }}>
                                    <p>{item.default_summary}</p>
                                    {item.default_external.length > 0 && <div>
                                        额外操作：
                                        <Button.Group>
                                            {item.default_external.map((external) =>
                                                <Tooltip title={external.url} key={external.type}>
                                                    <Button type="dashed" danger size="small">{external.text || "\"列表中点击直接执行\""}</Button>
                                                </Tooltip>
                                            )}
                                        </Button.Group>
                                    </div>}
                                </div>
                                <div>01-01 00:00:00</div>
                            </div>}
                        />
                    </List.Item>
                )}>

            </List>
        </div>
    </div >
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NotificationScene)