import React, { useState } from "react";
import { List, Switch, Button, Popover, message, Modal, Upload, TreeSelect, Divider, Table, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { Menu as MenuAction } from '../../../store/global/actions'

import { RocketOutlined, PlusSquareTwoTone, UploadOutlined, EditTwoTone, FileImageTwoTone, CheckCircleTwoTone, DeploymentUnitOutlined } from '@ant-design/icons';

import MediaViewer from "../../components/media_editor";
import QueryForm from "./query_form";
import iconSettle from "../../components/media_editor/icon_settle";

import DraftForm from "./form";
import PasswordConfirm from "../../../components/passwordConfirm";
import ListGrid from "../../components/ListGrid";

const { TreeNode } = TreeSelect;
const { Column } = Table;


class Index extends React.Component {

    formRef = React.createRef();

    state = {
        channel: null,
        data: {},
        editable: false,
        loading: false,
        query: { name: this.props.match?.params?.name },
        cateTree: [],
        editorHeight: 500,
    }

    joinChannel(props) {
        this.setState({ loading: true })
        const channel = props.socket.channel("ops:resource:term:divergence", { page_size: 12, ...this.state.query })
        channel.on("data", ({ data }) => this.setState({ data, loading: false }))

        channel.join()

        // channel.push("cate-tree", {})
        //     .receive("ok", (tree) => this.setState({ cateTree: tree }))
        this.setState({ channel })
    }

    componentDidMount() {
        this.props.setActiveKey("TERM-Divergence")
        this.joinChannel(this.props)
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        !!this.state.channel && this.state.channel.off("data")
        !!this.state.channel && this.state.channel.leave()

        this.setState({ query: { ...this.state.query, name: nextProps.match?.params?.name } }, () => this.joinChannel(nextProps))
    }

    cateTreeToSelectOption(tree) {
        tree = tree || []

        if (tree.length > 0) {
            return tree.map(({ id, name, children }) => {
                return <TreeNode key={id} value={id} title={name}>{this.cateTreeToSelectOption(children)}</TreeNode>
            })
        } else {
            return null
        }
    }

    // 处理点击是否发布
    handleClickPublish(acupointID) {
        this.state.channel.push("publish!", { id: acupointID }).receive("ok", () => message.success("发布成功"))
    }

    editBasicInfo(initial) {
        const channel = this.state.channel
        let formInstance = null
        Modal.confirm({
            width: 600,
            title: '修改基础信息',
            content: <DraftForm channel={this.state.channel} disableIcon initial={initial} setForm={(form) => formInstance = form} cateTree={this.cateTreeToSelectOption(this.state.cateTree)} />,
            onOk: (close) => {
                formInstance
                    .validateFields()
                    .then(values => {
                        channel.push("edit", { id: initial.id, ...values })
                            .receive("ok", (resp) => {
                                close()
                                message.success("修改成功")
                                this.handleSearch({ name: resp.name })
                                formInstance.resetFields();
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    handlePageTo(page, size) {
        this.setState({ loading: true })
        this.state.channel.push("page-to", { page, size })
            .receive("ok", () =>
                this.setState({ loading: false }))
    }

    handleSearch(values) {
        this.setState({ loading: true, query: values })
        this.state.channel.push("search", values)
            .receive("ok", () =>
                this.setState({ loading: false }))
    }

    render() {
        const cateTree = this.cateTreeToSelectOption(this.state.cateTree)
        return <div className="page resource-herb">
            <h1>十二经别
                <Divider type="vertical" />
                <Switch onChange={checked => this.setState({ editable: checked })} checkedChildren="开启编辑" unCheckedChildren="只读" />
            </h1>

            <div>
                <QueryForm cateTree={cateTree} onSearch={this.handleSearch.bind(this)} values={this.state.query} >
                </QueryForm>
            </div>

            <br />
            {
                this.state.data.entries &&
                <ListGrid
                    dataSource={this.state.data.entries}
                    loading={this.state.loading}
                    rowKey={d => d.name}
                    onHeightEdit={(height) => this.setState({ editorHeight: height })}
                    pagination={{
                        onChange: (page, size) => { this.handlePageTo(page, size) },
                        total: this.state.data.total_entries,
                        current: this.state.data.page_number,
                        pageSize: this.state.data.page_size,
                        showQuickJumper: true
                    }}
                    renderItem={item => (
                        <List.Item className="item-herb">
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <small style={{ fontSize: 12, color: "#888" }}>{item.py}</small>
                                    <span style={{ fontSize: 20, fontWeight: "bold" }}>
                                        {item.name}
                                    </span>
                                </div>

                                <span>
                                    {this.state.editable && <span>
                                        <Divider type="vertical" />
                                        <Button type="link" icon={<EditTwoTone />} onClick={() => this.editBasicInfo(item)}></Button>
                                        {item.has_update && <>
                                            <Divider type="vertical" />
                                            <Popover content={<Button onClick={() => this.handleClickPublish(item.id)} block type="primary" size="small">确认</Button>} title="确认发布？" trigger="click">
                                                <Button type="link" icon={<RocketOutlined />}></Button>
                                            </Popover></>}
                                    </span>}
                                </span>
                            </div>
                            <div>
                                <MediaViewer ownerType="Elixir.Acupoint.Model.STD.Term.Divergence" hasUpdate={item.has_update} ownerID={item.id} channel={this.state.channel} name={item.name} detail={item.detail} type="herb" editable={this.state.editable} height={this.state.editorHeight} />
                            </div>
                        </List.Item>
                    )
                    } />
            }
        </div >
    }
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);