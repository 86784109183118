import React, { useEffect, useRef, useState } from "react"
import { Form, ConfigProvider, Button, Input } from "antd"
import zhCN from 'antd/lib/locale/zh_CN';
import { Radio } from "antd";
import { JsonEditor } from 'jsoneditor-react';
import Ajv from 'ajv';
import { Select } from "antd";
import { Switch } from "antd";
import CronParser from "cron-parser"
import dayjs from "dayjs";


const ajv = new Ajv({ allErrors: true, verbose: true });

function RuntimeSchedulerForm({ form, channel, scheduler, ...props }) {
    const [formData, setFormData] = useState({ type: "elixir", perform: { args: [], func: "call" } })
    const [nextTriggerAt, setNextTriggerAt] = useState(null)

    useEffect(() => {
        calNextTriggerAt(form.getFieldsValue())
    }, [])

    // json编辑器的关联
    const jsonEditorRef = useRef()

    const calNextTriggerAt = (values) => {
        if (!!values?.cron && CronParser.parseString(values?.cron).expressions.length > 0) {
            let interval = CronParser.parseExpression(values.cron, { tz: "Asia/Chongqing" })
            setNextTriggerAt(interval.next())
        } else {
            setNextTriggerAt(null)
        }
    }

    const handleFormDataChanged = (values) => {
        setFormData(values)

        calNextTriggerAt(values)
    }

    return <ConfigProvider locale={zhCN}>
        <Form className="runtime-scheduler push-form" initialValues={formData} onValuesChange={(_, values) => handleFormDataChanged(values)} form={form} name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
            <Form.Item label="名称" name="name" rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="标志符" name="key" rules={[{ required: true, message: '必填' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="简介" name="summary">
                <Input.TextArea placeholder="描述用途" autoSize />
            </Form.Item>
            <Form.Item label="Linux Cron时刻表" name="cron" rules={[{
                required: true, message: '时刻表不合法', validator: (rule, value) => {
                    if (!!value && CronParser.parseString(value).expressions.length > 0)
                        return Promise.resolve()
                    else
                        return Promise.reject("时刻表不合法")
                }
            }]}>
                <Input placeholder="" />
            </Form.Item>
            {!!nextTriggerAt &&
                <Form.Item label="下次执行时间">
                    <span>{dayjs(nextTriggerAt).format("YYYY-MM-DD HH:mm")}</span>
                </Form.Item>
            }
            <Form.Item label="类型" name="type" rules={[{ required: true, message: '必填' }]}>
                <Radio.Group >
                    <Radio value="elixir">程序</Radio>
                    <Radio value="http" disabled>HTTP</Radio>
                </Radio.Group>
            </Form.Item>
            {formData?.type === "elixir" && <>
                <Form.Item label="模块名" name={["perform", "module"]} rules={[{ required: true, message: '必填' }]} help="如消息通知：Acupoint.Business.Notification.PushByUserFilter">
                    <Input placeholder="" />
                </Form.Item>
                <Form.Item label="函数名" name={["perform", "func"]} rules={[{ required: true, message: '必填' }]}>
                    <Input placeholder="" />
                </Form.Item>
                <Form.Item label="参数" name={["perform", "args"]} >
                    <JsonEditor ref={jsonEditorRef} ace={ace} mode="code" ajv={ajv} language="zh-CN" />
                </Form.Item>
            </>}
            <Form.Item label="启用?" name="enable" valuePropName="checked">
                <Switch />
            </Form.Item>
        </Form>
    </ConfigProvider>
}

export default RuntimeSchedulerForm;