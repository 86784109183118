import { Table, List, Button, Form, Input, message } from "antd"
import React, { useEffect, useState } from "react"
import dayjs from "dayjs"
import { CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined, FormOutlined } from "@ant-design/icons"
import PasswordConfirm from "../../passwordConfirm";
import { Alert } from "antd"

const { Column } = Table

function AlipayAgreement({ pushEvent, userId }) {
    const [data, setData] = useState([])
    let revokePasswordConfirmRef = React.createRef()

    useEffect(() => {
        pushEvent("ali-agreements", {})
            .receive("ok", ({ data }) => { setData(data) })
        return () => {

        }
    }, [userId])


    const handleRequestCost = (agreementNo) => {
        pushEvent("cost-ali-agreement", { agreement_no: agreementNo })
            .receive("ok", () => {
                pushEvent("ali-agreements", {})
                    .receive("ok", ({ data }) => { setData(data) })
                message.success("扣款成功")
            })
        // .receive("error", ({ message: msg }) => message.error(msg))
    }

    const handleRefreshFromAlipay = (agreementNo) => {
        pushEvent("refresh-ali-agreement", { agreement_no: agreementNo })
            .receive("ok", () => {
                pushEvent("ali-agreements", {})
                    .receive("ok", ({ data }) => { setData(data) })
                message.success("刷新成功")
            })
        // .receive("error", ({ message: msg }) => message.error(msg))
    }

    // 解约
    const handleRevokeAgreement = (agreementNo, userID) => {
        PasswordConfirm({
            ref: revokePasswordConfirmRef,
            title: '解约密码确认',
            message: "请确认，操作会被记录",
            externalItems: <>
                <Form.Item label="短信" name="sms" initialValue="会员自动续费服务已取消，即日起您的账户将不再自动扣款。为了减少不必要的费用，平台主动为您解约。期待我们下次重逢，为您提供更优质的服务！" rules={[{ required: true, message: '短信内容不能为空' }]}>
                    <Input.TextArea size="large" showCount autoSize />
                </Form.Item>
            </>,
            onOk: ({ password, sms }, close) => {
                message.loading({ content: '解约中...', key: "revoking", duration: 3 })
                pushEvent("revoke-ali-agreement", { agreement_no: agreementNo, password: password, sms, user_id: userID }, 100000)
                    .receive("ok", () => {
                        close()

                        pushEvent("ali-agreements", {})
                            .receive("ok", ({ data }) => { setData(data) })

                        message.success({ content: '解约成功！', key: "revoking", duration: 3 })
                    })
            }
        })
    }


    return <List dataSource={data} renderItem={(item) => {
        const diffDays = dayjs(item.execute_time).diff(dayjs(), "day")
        let execute_time;
        if (item.status !== "NORMAL")
            execute_time = ""
        else if (diffDays > 0 && diffDays < 5)
            execute_time = <span style={{ color: "green" }}>{dayjs(item.execute_time).format("YYYY-MM-DD HH:mm")}</span>
        else if (diffDays < 0 && item.status === "NORMAL")
            execute_time = <span style={{ color: "red" }}>{dayjs(item.execute_time).format("YYYY-MM-DD HH:mm")}</span>
        else
            execute_time = <span >{dayjs(item.execute_time).format("YYYY-MM-DD HH:mm")}</span>

        let status = "";
        switch (item.status) {
            case "NORMAL":
                status = <span style={{ color: "green" }}><CheckCircleOutlined /> 正常</span>
                break
            case "UNSIGN":
                status = <span style={{ color: "red" }}><CloseCircleOutlined /> 解约</span>
                break
            case null:
                status = <span style={{ color: "orange" }}><ClockCircleOutlined /> 未签约</span>
                break

            default:
                break;
        }

        return <List.Item>
            <div style={{ width: "100%" }}>
                {item.recent_message && <Alert message={item.recent_message} type="warning" banner />}
                <div style={{ fontSize: 24 }}>
                    {item.pricing?.name} ¥{item.single_amount}
                </div>
                <div>合同号: {item.agreement_no} {status}</div>
                <div>内部合同号: {item.external_agreement_no}</div>
                <div><span>生成时间: {item.inserted_at ? dayjs(item.inserted_at).add(8, "hour").format("YYYY-MM-DD HH:mm") : ""}</span></div>
                <div><span>签约时间: {item.valid_time ? dayjs(item.valid_time).format("YYYY-MM-DD HH:mm") : ""}</span></div>
                <div><span>下次扣费时间：{execute_time}</span></div>
                <div>
                    {item.status === "NORMAL" && <Button type="link" onClick={() => handleRequestCost(item.agreement_no)}>手动扣款</Button>}
                    {item.status !== "UNSIGN" && <><Button type="link" onClick={() => handleRefreshFromAlipay(item.agreement_no)}>刷新状态</Button></>}
                    {item.status !== "UNSIGN" && <><Button danger type="link" disabled={!item.agreement_no || item.status != "NORMAL"} onClick={() => handleRevokeAgreement(item.agreement_no, item.user?.id)}>解约 <FormOutlined /></Button></>}
                </div>
            </div>
        </List.Item>
    }}>

    </List>

    // return <Table dataSource={data} size="middle">
    //     <Column title="支付宝合约号" width={210} dataIndex="agreement_no" key="agreement_no" />
    //     <Column title="周期类型" width={100} dataIndex="period_type" key="period_type" render={(_, { period, period_type }) => `${period} ${period_type}`} />
    //     <Column title="预计下次执行时间" width={160} dataIndex="execute_time" key="execute_time" render={(text, item) => {
    //         const diffDays = dayjs(text).diff(dayjs(), "day")

    //         if (item.status !== "NORMAL")
    //             return ""
    //         else if (diffDays > 0 && diffDays < 5)
    //             return <span style={{ color: "green" }}>{dayjs(text).format("YYYY-MM-DD HH:mm")}</span>
    //         else if (diffDays < 0 && item.status === "NORMAL")
    //             return <span style={{ color: "red" }}>{dayjs(text).format("YYYY-MM-DD HH:mm")}</span>
    //         else
    //             return <span >{dayjs(text).format("YYYY-MM-DD HH:mm")}</span>
    //     }} /><Column title="对应产品" width={100} dataIndex="owner_id" key="owner_id" render={(text, { pricing }) => {
    //         return <div>
    //             {pricing.name}
    //         </div>
    //     }} />
    //     <Column title="单次扣款" width={100} align="center" dataIndex="single_amount" key="single_amount" render={text => `${text} 元`} />
    //     <Column title="状态" width={100} align="center" dataIndex="status" key="status" render={text => {
    //         switch (text) {
    //             case "NORMAL":
    //                 return <span style={{ color: "green" }}><CheckCircleOutlined /> 正常</span>
    //             case "UNSIGN":
    //                 return <span style={{ color: "red" }}><CloseCircleOutlined /> 解约</span>
    //             case null:
    //                 return <span style={{ color: "orange" }}><ClockCircleOutlined /> 未签约</span>

    //             default:
    //                 break;
    //         }
    //     }} />
    //     <Column title="签约时间" width={160} align="center" dataIndex="valid_time" key="valid_time" render={text => {
    //         if (text === null)
    //             return ""
    //         else
    //             return dayjs(text).format("YYYY-MM-DD HH:mm")
    //     }} />
    //     <Column title="最近消息" dataIndex="recent_message" key="recent_message" />
    //     {/* <Column title="操作" width={300} render={(_, item) => {
    //         // const diffDays = dayjs(item.execute_time).diff(dayjs(), "day")
    //         // console.log(dayjs(item.execute_time), Math.abs(diffDays % item.period))
    //         return <div>
    //             {item.status === "NORMAL" && <Button type="link" onClick={() => this.handleRequestCost(item.agreement_no)}>手动扣款</Button>}
    //             {item.status !== "UNSIGN" && <><Button type="link" onClick={() => this.handleRefreshFromAlipay(item.agreement_no)}>刷新状态</Button></>}
    //             {item.status !== "UNSIGN" && <><Button danger type="link" onClick={() => this.handleRevokeAgreement(item.agreement_no, item.user?.id)}>解约 <FormOutlined /></Button></>}

    //         </div>
    //     }}></Column> */}

    // </Table>
}

export default AlipayAgreement