import { Modal } from "antd";
import { connect } from 'react-redux';
import React from "react"
import InviteCodeDetail from "./invite_code_detail";

class InviteCodeDetailModel extends React.Component {

    state = {
        // isModalVisible: false,
        code: null,
        tabKey: null
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.code !== this.state.code)
            this.setState({ code: nextProps.code })
    }

    handleCancel() {
        this.props.close()
    }

    show(code) {
        this.setState({ tabKey: null, code })
    }

    render() {
        return <Modal title={false} width={1000} destroyOnClose centered footer={null} open={!!this.state.code} onOk={false} onCancel={this.handleCancel.bind(this)}>
            {this.state.code && <InviteCodeDetail id={this.state.code} inModal {...this.props}></InviteCodeDetail>}
        </Modal>
    }
}



const mapStateToProps = ({ global }) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(InviteCodeDetailModel)