import React from "react"
import { Input, AutoComplete, Avatar } from 'antd'
import { UserOutlined, WechatOutlined, AlipayOutlined, AppleFilled, SmileTwoTone, CrownTwoTone } from '@ant-design/icons';
import { connect } from 'react-redux'
import Config from "../config"
// const { Search } = Input;

class Dashboard extends React.Component {
    state = {
        suggest_users: [],
        suggest_orders: []
    }

    pushEvent(event, params) {
        return this.props.channel.push(event, params)
    }


    handleSearch(value) {
        this.pushEvent("suggest", { q: value })
            .receive("ok", payload => this.setState({ suggest_users: payload["suggest_users"], suggest_orders: payload["suggest_orders"] }))
    }

    handleSelectUser(user_id) {
        this.props.history.push("/operation/" + user_id);
    }

    renderTitle(title) {
        return <span>
            {title}
        </span>
    };

    renderItem(title, count) {
        return {
            value: title,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    {title}
                    <span>
                        <UserOutlined /> {count}
                    </span>
                </div>
            ),
        }
    }

    optionsGenerate() {
        const suggest_users = this.state.suggest_users;
        const suggest_orders = this.state.suggest_orders;
        return [
            {
                label: this.renderTitle('用户'),
                options: suggest_users.map((user) => {
                    return {
                        key: user.id,
                        value: user.nickname,
                        label: (
                            <div
                                onClick={() => this.handleSelectUser(user.id)}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <span> <span style={{ color: "#aaa" }}>{user.id}</span> {user.group !== "vip" ? <SmileTwoTone /> : <CrownTwoTone twoToneColor="#eb2f96" />}  {user.nickname || user.id}  {user.vip_ended_at ? <span style={{ color: "#aaa" }}>@ {user.vip_ended_at}</span> : ""} </span>
                                <Avatar src={Config.AvatarUrl(user.avatar)} />
                            </div>
                        ),
                    }
                })
            },
            {
                label: this.renderTitle('订单'),
                options: suggest_orders.map((order) => {
                    const show_no = order.wechat_id || order.alipay_id || order.apple_id || order.no
                    return {
                        value: show_no,
                        label: (
                            <div
                                onClick={() => this.handleSelectUser(order.owner_id)}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <span>
                                    {this.financeOrderIcon(order.trade_channel_meta)} {show_no}
                                </span>
                                <span>
                                    {order.amount / 100}
                                </span>
                            </div>
                        ),
                    }
                })
            }
        ];
    }

    financeOrderIcon(trade_channel_meta) {
        switch (trade_channel_meta) {
            case "acupoint:trade:channel::wechat":
                return <WechatOutlined style={{ color: "green" }} />;

            case "acupoint:trade:channel::alipay":
                return <AlipayOutlined style={{ color: "#027AFF" }} />;

            default:
                return <AppleFilled style={{ color: "black" }} />;
        }
    }

    render() {
        return <>
            <AutoComplete
                popupClassName="certain-category-search-dropdown"
                // dropdownMatchSelectWidth={1000}
                style={{ width: 500 }}
                options={this.optionsGenerate()}
                onSearch={this.handleSearch.bind(this)}
                allowClear
            >
                <Input.Search size="large" placeholder="昵称 订单号 商家订单号 用户ID" />
            </AutoComplete>
        </>
    }
}

const mapStateToProps = ({ global }) => {
    return {
        channel: global.userOpsChannel
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);