
import {    MainContainer, ChatContainer, MessageList, Message, MessageInput} from '@chatscope/chat-ui-kit-react';
import { useEffect, useState, useRef } from 'react';
import { connect } from "react-redux"

function ChatGPT(props) {
    const [channel, setChannel] = useState(null)
    const [completions, setCompletions] = useState({})
    const [inputDisable, setInputDisable] = useState(false)

    const completionsRef = useRef(completions); // define mutable ref

    useEffect(() => { completionsRef.current = completions })

    useEffect(() => {
        let _channel = props.socket.channel("ops:openai:davinci-003")
        _channel.join()
        setChannel(_channel)

        _channel.on("history", ({ data }) => {
            let _completions = {}
            data.forEach(item => {
                _completions[item.remote_id] = item
            });
            setCompletions(data)
        })

        _channel.on("answer", (item) => {
            let _completions = { ...completionsRef.current, [item.remote_id]: item }
            setCompletions(_completions)
        })
        _channel.on("answer-error", ({ message }) => {
            setInputDisable(false)
        })

        _channel.on("answer-done", () => {
            setInputDisable(false)
        })

        return () => {
            if (!!_channel) _channel.leave()
        }
    }, [])

    const handleMessageSend = (_innerHtml, _textContent, innerText) => {
        setInputDisable(true)
        channel.push("message", { text: innerText })
    }

    return <div className="page online-customer-service">
        <MainContainer style={{ height: 700, width: 600 }}>
            <ChatContainer >
                <MessageList disableOnYReachWhenNoScroll>
                    {
                        Object.values(completions).map((item) => {
                            return <>
                                <Message key={`request-${item.remote_id}`} model={{
                                    sentTime: item.created_at,
                                    sender: "我",
                                    avatarPosition: "tl",
                                    avatarSpacer: true,
                                    direction: "outgoing"
                                }}>
                                    <Message.Header sender={"我"} sentTime={item.inserted_at} />
                                    {/* <Avatar src={item.sender.avatar} name={item.sender.nickname} /> */}
                                    <Message.TextContent text={item.params.prompt} />
                                    {/* <Message.Footer sentTime={!isOwnerMessage && myMessageStateForChannelOwner} /> */}
                                </Message>
                                <Message key={`response-${item.remote_id}`} model={{
                                    sentTime: item.created_at,
                                    sender: "ChatGPT",
                                    avatarPosition: "tl",
                                    avatarSpacer: true,
                                    direction: "incoming"
                                }}>
                                    <Message.Header sender="ChatGPT" sentTime={item.inserted_at} />
                                    {/* <Avatar src={item.sender.avatar} name={item.sender.nickname} /> */}

                                    <Message.TextContent text={item.response.map(({ text }) => text).join("").trim()} />

                                    {/* <Message.Footer sentTime={!isOwnerMessage && myMessageStateForChannelOwner} /> */}
                                </Message>
                            </>
                        })
                    }
                </MessageList>
                <MessageInput placeholder="" onPaste={() => null} autoFocus onSend={handleMessageSend} attachButton={false} disabled={inputDisable} />
            </ChatContainer>

            {/* {!this.props.hideConversation &&
                <ConversationList>
                    <Conversation lastActivityTime={<LocalTimeAgo date={item.message.inserted_at} />}
                        active onClick={() => this.handleClickConversationJoin(owner.id)} name={ownerName}
                        lastSenderName={item.sender.nickname} info={info}>
                        <Avatar src={owner.avatar} name={owner.nickname} status={this.isOnline(owner?.id) ? "available" : "invisible"} />
                    </Conversation>
                </ConversationList>} */}
        </MainContainer>
    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ChatGPT)