import { Button, Select, message, Form, Input, InputNumber, Upload, Image } from "antd"
import { UploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import Config from "../../config";
import { SketchPicker } from 'react-color';
import Sha256Encode from "../../components/sha256";

function PlanForm({ jsonRequest, ...props }) {
    const plan = props.plan

    const [contrats, setContracts] = useState([])
    const [appBannerBGPath, setAppBannerBGPath] = useState(plan?.data?.app_banner_bg_path)
    const [coinLogo, setCoinLogo] = useState(plan?.coin_logo)
    const [coinConvert, setCoinConvert] = useState(plan?.data?.coin_convert)
    const [formRef] = Form.useForm();

    useEffect(() => {
        props.channel.push("get:contracts", {})
            .receive("ok", setContracts)
        return () => {

        }
    }, [])

    const formItemLayout = {
        labelCol: {
            xs: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 16 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 4,
                offset: 8,
            },
        },
    };

    const handleFormSubmit = (values) => {
        props.channel.push(props.plan?.id ? "edit:plan" : "create:plan", { key: plan?.key, ...values, app_banner_bg_path: appBannerBGPath, coin_logo: coinLogo, coin_convert: coinConvert })
            .receive("ok", () => {
                message.success("提交成功")
                props.closeModal && props.closeModal()
            })
    }

    const handleUploadFile = (obj, field) => {
        const file = obj.file
        message.loading({ content: "上传中，请稍后...", key: "handleUploadFile", duration: 0 })
        file.arrayBuffer().then(file_content => {
            Sha256Encode(file_content).then(file_name => {
                var data = new FormData()
                data.append('file', file)
                data.append('filename', file_name)
                data.append('owner_type', "Marketing")
                data.append('owner_id', "Plan")

                jsonRequest.file(`/api/operation/media/upload`, data)
                    .then(resp => resp.json())
                    .then(({ data }) => {
                        const { path } = data
                        message.success({ content: "上传成功", key: "handleUploadFile", duration: 2 })
                        switch (field) {
                            case "coin_logo":
                                setCoinLogo(path)
                                break;
                            case "app_banner_bg_path":
                                setAppBannerBGPath(path)
                                break;
                            case "coin_convert":
                                setCoinConvert(path)
                                break;

                            default:
                                break;
                        }
                    })
            })
        })
    }


    return <Form {...formItemLayout} form={formRef} onFinish={handleFormSubmit} style={{ display: "flex" }}>
        <div style={{ width: "33%" }}>
            <Form.Item name="name" initialValue={plan?.name} label="计划名" rules={[{ required: true }]} >
                <Input />
            </Form.Item>
            <Form.Item name="display_name" initialValue={plan?.display_name} label="展示名" rules={[{ required: true }]} help="给用户看的名称">
                <Input />
            </Form.Item>
            <Form.Item name="slogan" initialValue={plan?.slogan} label="宣传语" rules={[{ required: true }]} help="给推广人看的简介">
                <Input />
            </Form.Item>
            <Form.Item name="key" initialValue={plan?.key} label="计划KEY" rules={[{ required: true }]} help="软主键">
                <Input disabled={!!plan?.id} />
            </Form.Item>
            <Form.Item name="contract_key" initialValue={plan?.contract_key} label="使用合同" rules={[{ required: true }]} help="完成一次结算后结束，还是可持续结算">
                <Select>
                    {contrats.map((c) =>
                        <Select.Option key={c.key} value={c.key}>{c.name}</Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item name="coin_unit" initialValue={plan?.coin_unit} label="积分单位名" rules={[{ required: true }]} help="不用同一名称，防止用户认为不同计划的积分相同">
                <Input placeholder="宝石、水晶" />
            </Form.Item>
            <Form.Item name="settle_type" initialValue={plan?.settle_type || "cycle"} label="可结算次数" rules={[{ required: true }]} help="邀请码生效之前必须接受合同">
                <Select>
                    <Select.Option value="cycle">不限制</Select.Option>
                    <Select.Option value="single">单次</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item name="duration_meta" initialValue={plan?.duration_meta || "acupoint:duration::day"} label="有效时长单位" rules={[{ required: true }]}>
                <Select>
                    <Select.Option value="acupoint:duration::hour">时</Select.Option>
                    <Select.Option value="acupoint:duration::day">天</Select.Option>
                    <Select.Option value="acupoint:duration::month">月</Select.Option>
                    <Select.Option value="acupoint:duration::year">年</Select.Option>
                    <Select.Option value="acupoint:duration::lifelong">终身</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="duration" initialValue={plan?.duration || 1} label="有效时长量" rules={[{ required: true }]}>
                <InputNumber min={1} size="small" />
            </Form.Item>
            {/* <Form.Item name="rule_text" initialValue={plan?.rule_text} label="规则文本" rules={[{ required: true }]}>
                <Input.TextArea autoSize cols={3} ></Input.TextArea>
            </Form.Item> */}
            <Form.Item name="remark" initialValue={plan?.remark} label="备注">
                <Input.TextArea autoSize ></Input.TextArea>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" >
                    保存
                </Button>
            </Form.Item>
        </div>
        <div style={{ width: "33%" }}>
            <Form.Item name={['data', 'wechat_share_title']} initialValue={plan?.data?.wechat_share_title} label="微信分享标题" rules={[{ required: true }]} help="{nickname}可用于替换邀请人的昵称">
                <Input></Input>
            </Form.Item>
            <Form.Item name={['data', 'wechat_share_summary']} initialValue={plan?.data?.wechat_share_summary} label="微信分享简介" rules={[{ required: true }]} help="{nickname}可用于替换邀请人的昵称">
                <Input></Input>
            </Form.Item>
            <Form.Item initialValue={plan?.coin_logo} label="积分图标" rules={[{ required: true }]} help="不用同一名称，防止用户认为不同计划的积分相同">
                <Upload.Dragger
                    showUploadList={false}
                    customRequest={(obj) => handleUploadFile(obj, "coin_logo")}
                >
                    {coinLogo ? <Image src={Config.ResourceUrl(coinLogo)} /> : <> <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                    </p>
                        <p className="ant-upload-text">点击或拖拽上传</p></>}

                </Upload.Dragger>
            </Form.Item>

            <Form.Item label="APP背景图片">
                <Upload.Dragger showUploadList={false} customRequest={(obj) => handleUploadFile(obj, "app_banner_bg_path")}>
                    {
                        appBannerBGPath ? <Image src={Config.ResourceUrl(appBannerBGPath)} /> :
                            <>
                                <p className="ant-upload-drag-icon">
                                    <UploadOutlined />
                                </p>
                                <p className="ant-upload-text">点击或拖拽视频上传</p>
                            </>
                    }
                </Upload.Dragger>
            </Form.Item>
            <Form.Item name={["data", "theme_color"]} valuePropName="color" initialValue={plan?.data?.theme_color} label="主题颜色" rules={[{ required: true }]}>
                <SketchPicker />
            </Form.Item>
        </div>

        <div style={{ width: "33%" }}>
            <Form.Item initialValue={plan?.coin_convert} label="APP邀请换算图" rules={[{ required: true }]} help="不用同一名称，防止用户认为不同计划的积分相同">
                <Upload.Dragger
                    showUploadList={false}
                    customRequest={(obj) => handleUploadFile(obj, "coin_convert")}
                >
                    {coinConvert ? <Image src={Config.ResourceUrl(coinConvert)} /> : <> <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                    </p>
                        <p className="ant-upload-text">点击或拖拽上传</p></>}

                </Upload.Dragger>
            </Form.Item>
        </div>


    </Form >
}

const mapStateToProps = ({ global }) => {
    return {
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(PlanForm)