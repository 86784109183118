import { Button, List, Modal } from "antd";
import { useRef, useState } from "react"
import ContractForm from "./contract_form";

function ContractItem({ item, channel, readonly, ...props }) {
    const [showForm, setShowForm] = useState(false)

    return item && <div {...props}>
        <div style={{ padding: 10 }}>
            <h3>{item.key} {!readonly && <Button type="link" onClick={() => setShowForm(true)}>编辑</Button>}</h3>
            <h3>{item.name}</h3>
            <div style={{ height: 400, overflowY: "auto" }} dangerouslySetInnerHTML={{ __html: item.content }}></div>

        </div>

        {
            channel &&
            <Modal width={800} title="编辑合同" open={showForm} footer={false} onCancel={() => setShowForm(false)}>
                <ContractForm show={showForm} contract={item} channel={channel} closeModal={() => setShowForm(false)} />
            </Modal>
        }
    </div>
}

export default ContractItem