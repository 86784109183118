import React, { useState, useEffect } from "react"
import { Timeline, Pagination, Tooltip, Empty } from 'antd'
import { Comment } from '@ant-design/compatible';
import dayjs from 'dayjs';

// 当评价页数变更时执行
function handleNotePageChange(setNotePagedData, props, page, pageSize) {
    props.pushEvent("note-list", { page, page_size: pageSize })
        .receive("ok", (data) => {
            setNotePagedData(data)
        })
}

function Notes(props) {
    // 记事
    const [notePagedData, setNotePagedData] = useState({})

    useEffect(() => {
        handleNotePageChange(setNotePagedData, props, 1, 20)
    }, [props.userId])


    return !!notePagedData && notePagedData.total_count > 0 ?
        <>
            <Timeline>
                {notePagedData.entries.map((note) => {
                    return <Timeline.Item key={note.id} color={(note.data.color || {}).hex} >
                        <Comment
                            // actions={item.actions}
                            author={"匿名"}
                            // avatar={""}
                            content={note.content}
                            datetime={<Tooltip title={dayjs(note.inserted_at).add(8, 'hours').format("YYYY-MM-DD HH:mm:ss")}>
                                <span>{dayjs(note.inserted_at).add(8, 'hours').fromNow()}</span>
                            </Tooltip>}
                        />
                    </Timeline.Item>
                })}
            </Timeline>
            <Pagination size="small" onChange={handleNotePageChange} showQuickJumper pageSize={notePagedData?.page_size} current={notePagedData?.page_number} total={notePagedData?.total_count} />
        </>
        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
}

export default Notes;