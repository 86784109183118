import React from "react";
import { Menu } from 'antd';
import { MoneyCollectOutlined, OrderedListOutlined, CalendarOutlined, BarcodeOutlined } from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";
import { connect } from 'react-redux';

function SideMenu() {
    const location = useLocation()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")
    const fullPathName = location.pathname

    return <Menu style={{ width: 256 }} defaultSelectedKeys={[pathName, fullPathName]} mode="inline" >
        <Menu.ItemGroup key="config" title="配置">
            <Menu.Item key="/marketing/contract" icon={<MoneyCollectOutlined />}>
                <Link to="/marketing/contract">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>合同</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/marketing/reward" icon={<MoneyCollectOutlined />} disabled>
                <Link to="/marketing/reward">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>报酬</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/marketing/sale" icon={<MoneyCollectOutlined />} disabled>
                <Link to="/marketing/sale">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>营销品</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/marketing/plan" icon={<OrderedListOutlined />} >
                <Link to="/marketing/plan">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>计划</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>

        <Menu.ItemGroup key="working" title="运作">

            {/* <Menu.Item key="/marketing/invite-code/activity" icon={<CalendarOutlined />} >
                <Link to="/marketing/invite-code/activity">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>活动邀请码</span>
                    </div>
                </Link>
            </Menu.Item> */}
            <Menu.Item key="/marketing/invite-code/user" icon={<BarcodeOutlined />} >
                <Link to="/marketing/invite-code/user">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>用户邀请码</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/marketing/invite-code/log" icon={<BarcodeOutlined />} >
                <Link to="/marketing/invite-code/log">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>邀请历史</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/marketing/invite-reward/convert" icon={<BarcodeOutlined />}>
                <Link to="/marketing/invite-reward/convert">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>报酬提取申请</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
    </Menu >
}


const mapStateToProps = ({ global }) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);