import { useEffect, useState } from "react"
import { Button, Badge, Dropdown, message, Input, Tooltip } from "antd"
import { HeartTwoTone, MoreOutlined, CrownTwoTone, CommentOutlined } from "@ant-design/icons"
import Report from "../../actions/Report"
import Config from "../../../config"
import { Avatar } from "antd"
import { connect } from "react-redux";
import { userInformationModal } from "../../../store/global/actions"
import LocalTimeAgo from "../../../components/local_time_ago";

import dayjs from "dayjs"

function Comments({ referenceType, referenceID, comments, channel, userShow }) {
    let [activeCommentID, setActiveCommentID] = useState(null)
    let [commentText, setCommentText] = useState("")

    const handleCommentAdd = (parent_id) => {
        if (!!commentText && commentText.length > 0)
            channel.push("comment-add", { reference_type: referenceType, reference_id: referenceID, content: commentText, parent_id }).receive("ok", () => {
                message.success("评论成功！")
                setCommentText("")
            })
    }

    const handleCommentLike = (commentID) => {
        channel.push("comment-like", { id: commentID }).receive("ok", () => {
        })
    }


    const handleCommentLikeRevoke = (commentID) => {
        channel.push("comment-like-revoke", { id: commentID }).receive("ok", () => {
        })
    }


    const handleCommentRemove = () => {

    }

    const handleMenuClick = (key, comment) => {
        switch (key) {
            case "report":
                Report({
                    onSubmit: (values) => {
                        channel.push("report", { ...values, id: comment.id, reference_type: "comment" }).receive("ok", () => {
                            message.success("举报成功！")
                        })
                        return true
                    },
                    initial: { type: "违法违规" }
                })
                break;

            default:
                break;
        }
    }


    return !!comments && <div className="items">
        {comments?.comments.map(item => (
            <div className="comment" key={item.id}>
                <div className="avatar">
                    <div className="owner" onClick={() => userShow(item.owner?.owner_id)}>
                        <Badge className="user-avatar" count={item.owner?.group === "vip" ? <CrownTwoTone twoToneColor="#eb2f96" rotate={45} /> : null} style={{ fontSize: 16 }}>
                            <Avatar src={Config.AvatarUrl(item.owner?.avatar)} />
                        </Badge>

                        &nbsp;
                        {item.owner?.name || "匿名用户"}
                        &nbsp;
                        {item.reply_to_owner && <span>回复 {item.reply_to_owner.name || "匿名用户"}</span>}
                    </div>
                </div>
                <div style={{ paddingLeft: 36 }} >
                    <div style={{ wordWrap: "break-word" }}>{item.content}</div>
                    <div style={{ color: "#afafaf", padding: "10px 0", display: "flex", justifyContent: "space-between" }}>
                        <span><LocalTimeAgo date={dayjs(item.inserted_at)} /> {item.ip_belongs_text || "未知"}</span>
                        <span>
                            <Tooltip title="点赞"><Button onClick={() => item.like ? handleCommentLikeRevoke(item.id) : handleCommentLike(item.id)} size="small" style={{ color: item.like ? "red" : "silver" }} icon={<HeartTwoTone twoToneColor={item.like ? "red" : "grey"} />} type="link"> {item.like_count}</Button></Tooltip>
                            <Tooltip title="回复他"><Button size="small" onClick={() => setActiveCommentID(item.id)} icon={<CommentOutlined style={{ color: "#1890ff" }} />} type="link"></Button></Tooltip>

                            <Dropdown menu={{ items: [{ key: "report", label: "举报" }], onClick: ({ key }) => handleMenuClick(key, item) }} trigger={['click']}>
                                <Button type="link" icon={<MoreOutlined />}></Button>
                            </Dropdown>
                        </span>
                    </div>

                    <div>
                        {activeCommentID === item.id ?
                            <div className="input">
                                <Input size="small" value={commentText} placeholder={`回复@${item.owner.name || "匿名用户"}`} onChange={(e) => setCommentText(e.target.value)} />
                                <Button size="small" type="link" onClick={() => handleCommentAdd(item.id)}>提交</Button>
                            </div>
                            : null}
                    </div>
                    <Comments referenceType={referenceType} referenceID={referenceID} comments={item.children} channel={channel} userShow={userShow} />
                </div>

            </div>
        ))}
        <div style={{ paddingLeft: 36 }}>
            {comments.total > comments.page_size ? <a>查看更多{comments.total}条评论</a> : null}
        </div>
    </div>
}

const mapStateToProps = ({ global }) => {
    return {
        onlineUsers: global.onlineUsers
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        userShow: (userId) => {
            dispatch(userInformationModal.show(userId))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Comments);