import { Button, Input, message } from "antd"
import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import ReactQuill, { Quill } from "react-quill"
import 'react-quill/dist/quill.snow.css';
import imageUploader from "quill-image-uploader";
import uploadFile from "../../components/upload_file";
import config from "../../config"
import { useHistory } from "react-router-dom";
import { Select } from "antd";
import Sha256Encode from "../../components/sha256";

Quill.register("modules/imageUploader", imageUploader);

const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
    ],
    imageUploader: {
        upload: (file) => {
            return new Promise(async (resolve, reject) => {
                const file_content = await file.arrayBuffer()
                const file_name = await Sha256Encode(file_content)

                uploadFile(window.store.getState().global.jsonRequest, `community/post/${file_name}`, "acupoint-attach", file, (path) => {
                    resolve(config.AttachUrl(path));
                }, () => message.error("上传失败"))
            });
        },
    }
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color', 'background'
]

function MakeQuestion({ channel, ...props }) {
    const [value, setValue] = useState('');
    const [title, setTitle] = useState('');
    const [seletedTopics, setSelectTopics] = useState([]);
    const [topicData, setTopicData] = useState([]);
    const editorRef = useRef()
    const history = useHistory()

    useEffect(() => {
        channel.push("topic-data", {})
            .receive("ok", ({ data }) => {
                setTopicData(data)
            })
    }, [])

    const handlePost = () => {
        const data = {
            title,
            content_text: editorRef.current.getEditor().getText(),
            content: {
                delta: editorRef.current.getEditor().getContents().ops
            }, topics: seletedTopics
        }

        channel.push("add-question", data)
            .receive("ok", (id) => {
                message.success("创建问题成功")
                history.push(`/community/simulator/question/${id}`)
            })
    }

    return <div className="page community simulator make-question">
        <div className="header">
            <div><Link to="/community/simulator" style={{ padding: "4px 15px" }}>取消</Link></div>
            <div><h2>提问</h2></div>
            <div><Button disabled={title?.length < 5} type="link" onClick={() => handlePost()}>发布</Button></div>
        </div>
        <Input.TextArea autoSize placeholder="输入问题并以问号结尾" className="title" onChange={(e) => setTitle(e.target.value)}></Input.TextArea>
        <ReactQuill ref={editorRef} placeholder="请详细介绍问题，认真的提问才能带来优质的回答（选填）" theme="snow"
            className="editor" value={value} modules={modules} onChange={setValue} formats={formats} />
        <div className="footer">
            <Select mode="tags" value={seletedTopics} onChange={(value) => setSelectTopics(value)} placeholder="选择话题.." allowClear options={topicData.map((topic) => ({ label: topic.title, value: topic.title }))}>

            </Select>
        </div>
        {/* <div className="editor">
            
        </div> */}
    </div>
}

export default MakeQuestion