import { Button, Segmented, Tag, Avatar, Dropdown } from "antd"
import { useState, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import defaultAvatar from "../../images/avatar.png"
import { MoreOutlined, ReloadOutlined, CrownTwoTone } from "@ant-design/icons"
import { connect } from "react-redux";
import { userInformationModal } from "../../store/global/actions"
import { Badge } from "antd"
import Config from "../../config"

function Portal({ channel, userShow, ...props }) {
    let [data, setData] = useState([])
    let [portalListType, setPortalListType] = useState("recommend")
    let [topics, setTopics] = useState([])
    let [currentTopic, setCurrentTopic] = useState("全部")
    let history = useHistory();

    useEffect(() => {
        refresh(portalListType, currentTopic)
        let refreshRef = channel.on("refresh", () => refresh(portalListType, currentTopic))

        return () => {
            channel.off("refresh", refreshRef)
        }
    }, [portalListType, currentTopic])

    const handlePortalTypeChange = (value) => {
        setData([])
        setPortalListType(value)
    }

    // 刷新数据
    const refresh = (_portalListType, topic) => {
        topic = topic === "全部" ? null : topic
        channel.push("refresh", { list_type: _portalListType || portalListType, topic })
            .receive("ok", ({ data, topics }) => {
                setData(data)
                setTopics(topics)
            })
    }

    // 话题点击
    const handleTagClick = (title) => {
        setCurrentTopic(title)
    }

    // 提问块
    const questionRender = (item) => {
        // const tempCont = document.createElement("div");
        // (new Quill(tempCont)).setContents(item.content?.delta);
        // const contentHtml = tempCont.getElementsByClassName("ql-editor")[0].innerHTML;

        return <div className="item" key={item.id}>
            <div className="title" onClick={() => history.push(`/community/simulator/question/${item.id}`)}>{item.title}</div>
            <div className="actions">
                <div className="info">
                    <span>{item.answer_count} 回答</span>
                    <span>{item.view_count} 浏览</span>
                </div>
                <div>
                    <Button size="small" type="primary" onClick={() => history.push(`/community/simulator/question/${item.id}/answer`)}>写回答</Button>
                </div>
            </div>
        </div>
    }

    // 回答块
    const answerRender = (item) => {

        return <div className="item" key={item.id}>
            <div className="title" onClick={() => history.push(`/community/simulator/question/${item.parent_id}`)}>{item.title}</div>
            <div className="owner" onClick={() => userShow(item.owner_id)}>
                <Badge className="user-avatar" count={item?.owner?.group === "vip" ? <CrownTwoTone twoToneColor="#eb2f96" rotate={45} /> : null} style={{ fontSize: 12 }}>
                    <Avatar size="small" src={Config.AvatarUrl(item.owner?.avatar)} />
                </Badge>
                &nbsp;{item.owner_name || "匿名用户"}</div>
            <pre className="post-content content" onClick={() => history.push(`/community/simulator/question/${item.parent_id}/answer/${item.id}`)}>{item.content_text}</pre>
            <div className="footer">
                <span>
                    <span>{item.like_count} 赞同</span>
                    <span>{item.view_count} 阅读</span>
                    <span>{item.total_comment_count || 0} 评论</span>
                    <span>{item.favorite_count} 收藏</span>
                </span>
                <span>
                    <Dropdown menu={{ items: [{ key: "1", label: "举报" }] }} trigger={['click']}>
                        <Button type="link" icon={<MoreOutlined />}></Button>
                    </Dropdown>
                </span>
            </div>
        </div>
    }

    return <div className="page community simulator portal">
        <div className="tabs">
            <div>
                <Button type="link" icon={<ReloadOutlined />} onClick={() => refresh()}>刷新</Button>
            </div>
            <div>
                <Segmented onChange={handlePortalTypeChange} value={portalListType}
                    options={[
                        {
                            value: 'recommend',
                            label: "推荐",
                        },
                        {
                            value: 'questions',
                            label: "待回答",
                        },
                    ]}
                />
            </div>
            <div>
                <Link to="/community/simulator/new">提问</Link>
            </div>
        </div>
        {portalListType === "recommend" &&
            <div className="tags">
                <Tag.CheckableTag checked={currentTopic === "全部"} onClick={() => handleTagClick("全部")}>全部</Tag.CheckableTag>
                {
                    topics?.map((topic) => {
                        return <Tag.CheckableTag key={topic.title} checked={currentTopic === topic.title} onClick={() => handleTagClick(topic.title)}>{topic.title}</Tag.CheckableTag>
                    })
                }
            </div>
        }
        <div className="question-answers">
            {
                data.map((item) => {
                    switch (portalListType) {
                        case "recommend": return answerRender(item)
                        case "questions": return questionRender(item)
                        default: return null
                    }
                })
            }

        </div>

    </div>
}

const mapStateToProps = ({ global }) => {
    return {
        onlineUsers: global.onlineUsers
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        userShow: (userId) => {
            dispatch(userInformationModal.show(userId))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Portal);