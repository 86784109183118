import { Button, Select, message, Form, Input, Popover } from "antd"
import ButtonGroup from "antd/lib/button/button-group";
import { useState } from "react";

function EntryForm(props) {
    const entry = props.entry
    // 表单关联
    const [formRef] = Form.useForm();

    const [deletePopoverVisible, setDeletePopoverVisible] = useState(false)

    const [alias, setAlias] = useState([])

    const formItemLayout = {
        labelCol: {
            xs: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 4,
                offset: 6,
            },
        },
    };

    const handleFormSubmit = (values) => {
        props.channel.push(props.entry?.id ? "edit:entry" : "create:entry", { ...values, id: props.entry?.id })
            .receive("ok", () => {
                message.success("保存成功")
                props.closeModal && props.closeModal()
            })
    }

    // 表格的值变化，自动填写拼音
    const handleFormValuesChange = (changedValues, allValues) => {
        if (!!changedValues.name) {
            props.channel.push("py", { name: changedValues.name })
                .receive("ok", ({ search_py, display_py }) => {
                    let labels = { ...allValues.labels, "acupoint:resource:label::py": display_py }
                    formRef.setFieldsValue({ py: search_py, labels: labels })
                })
        }
    }

    // 点击删除
    const handleClickDelete = () => {
        props.channel.push("delete:entry", { id: entry?.id })
            .receive("ok", () => {
                setDeletePopoverVisible(false)
                message.success("删除成功")
            })

    }

    return <>
        <Form form={formRef} {...formItemLayout} onFinish={handleFormSubmit} onValuesChange={handleFormValuesChange}>
            <Form.Item name="name" initialValue={entry?.name} label="标题" rules={[{ required: true }]}>
                {entry ? <span className="ant-form-text">{entry?.name}</span> : <Input />}
            </Form.Item>
            <Form.Item name="alias" initialValue={entry?.alias} label="别名">
                <Select mode="tags" style={{ width: '100%' }} placeholder="[可选] 昵称，学名，小名，关联名" />
            </Form.Item>
            <Form.Item name="py" initialValue={entry?.py} label="搜索拼音" rules={[{ required: true }]}>
                {entry ? <span className="ant-form-text">{entry?.py}</span> : <Input />}
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::py"]} initialValue={entry?.labels.py || ""} label="展示拼音" rules={[{ required: true }]}>
                {entry?.labels ? <span className="ant-form-text">{entry?.labels["py"]}</span> : <Input />}
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::summary"]} initialValue={entry?.labels.summary || ""} label="简介" rules={[{ required: true }]}>
                <Input.TextArea autoSize ></Input.TextArea>
            </Form.Item>
            <Form.Item name="permission" initialValue={entry?.permission || "vip"} label="权限" rules={[{ required: true }]}>
                <Select >
                    <Select.Option value="free">免费</Select.Option>
                    <Select.Option value="vip">会员</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="state" initialValue={entry?.state || "publish"} label="状态" rules={[{ required: true }]}>
                <Select >
                    <Select.Option value="draft">草稿</Select.Option>
                    <Select.Option value="publish">发布</Select.Option>
                </Select>
            </Form.Item>
            {/* <Form.Item name={["labels", "acupoint:resource:label::app-link", "path"]} initialValue={entry?.app_version_code || 56} label="点击打开的页面" help="">
                <Select>
                    <Select.Option value="/wiki/acupoint">经络</Select.Option>
                </Select>
            </Form.Item> */}
            <Form.Item name="app_version_code" initialValue={entry?.app_version_code || 63} label="APP版本" rules={[{ required: true }]} help="低于此版本的APP，看不到本词条">
                <Select>
                    <Select.Option value={63}>v2.7.0</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
                <ButtonGroup>
                    <Button htmlType="submit" type="link">
                        保存
                    </Button>
                    {
                        entry && <Popover open={deletePopoverVisible} onOpenChange={setDeletePopoverVisible} content={<Button onClick={() => handleClickDelete(entry.id)} block type="primary" size="small" danger>确认</Button>} title="确认删除？" trigger="click">
                            <Button danger type="link" >
                                删除
                            </Button>
                        </Popover>
                    }
                </ButtonGroup>
            </Form.Item>
        </Form>
    </>
}

export default EntryForm