import { Button, Select, message, Form, Input, InputNumber, DatePicker, Divider, Table } from "antd"
import { useEffect, useState } from "react";
import UserSearchSelect from "../../components/user_search_select";

const { Column } = Table

function InviteCodeForm({ type, ...props }) {
    const code = props.code
    const [planList, setPlanList] = useState([])
    const [selectPlan, setSelectPlan] = useState(null)
    const [selectPlanRewards, setSelectPlanRewards] = useState([])
    const [selectPlanSales, setSelectPlanSales] = useState([])

    useEffect(() => {
        props.channel.push("get:plans", {})
            .receive("ok", (data) => {
                setPlanList(data)
            })
    }, [])

    const formItemLayout = {
        labelCol: {
            xs: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 16 },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 4,
                offset: 8,
            },
        },
    };

    const handleFormSubmit = (values) => {
        props.channel.push("create:invite_code", values)
            // props.channel.push(props.code?.id ? "edit:code" : "create:code", { ...values, key: code.key })
            .receive("ok", () => {
                message.success("提交成功")
                props.closeModal && props.closeModal()
            })
    }

    const handlePlanSelect = (key) => {
        setSelectPlan(planList.find((p) => p.key === key))

        props.channel.push("get:plan:sales", { key })
            .receive("ok", (data) => setSelectPlanSales(data))

        props.channel.push("get:plan:rewards", { key })
            .receive("ok", (data) => setSelectPlanRewards(data))
    }

    return <Form {...formItemLayout} onFinish={handleFormSubmit} style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}>
            <Form.Item name="plan_key" initialValue={code?.plan_key} label="选择计划" rules={[{ required: true }]} >
                <Select onSelect={handlePlanSelect}>
                    {
                        planList?.map((item) => {
                            return <Select.Option key={item.key} value={item.key}>{item.name}</Select.Option>
                        })
                    }
                </Select>
            </Form.Item>

            {
                type === "user" &&
                <Form.Item name="user_ids" initialValue={code?.user_id} label="用户" rules={[{ required: true }]} help="搜索并选择用户， 可多选">
                    <UserSearchSelect mode="multiple" placeholder="输入ID，名称，手机号" />
                </Form.Item>
            }

            {
                type === "activity" &&
                <Form.Item name="name" initialValue={code?.name} label="活动名" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
            }

            <Form.Item name="active_at" initialValue={code?.active_at} label="生效日" rules={[{ required: true }]} help="过期日会根据有效时长单位和时长量算出来">
                <DatePicker />
            </Form.Item>
            {/* <Form.Item name="limit_count" initialValue={code?.limit_count} label="限制销售次数" help="留空表示不限制">
                <InputNumber min={1} size="small" />
            </Form.Item> */}
            <Form.Item name="status" initialValue={code?.status || "enable"} label="状态" >
                <Select>
                    <Select.Option value="enable">可用</Select.Option>
                    <Select.Option value="disable">不可用</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" >
                    保存
                </Button>
            </Form.Item>
        </div>
        {!!selectPlan && <div style={{ flexGrow: 1 }}>
            <Form.Item label="可销售产品">
                <Table bordered dataSource={selectPlanSales} pagination={false}>
                    <Column align="center" title="商品名" dataIndex="name" key="name" />
                    <Column align="center" title="销售价格(分)" dataIndex="price" key="price" />
                    <Column align="center" title="可得积分" dataIndex="coin_num" key="coin_num" />
                </Table>
            </Form.Item>
            <Form.Item label="可兑换报酬">
                <Table bordered dataSource={selectPlanRewards} pagination={false}>
                    <Column align="center" title="报酬名" dataIndex="name" key="name" />
                    <Column align="center" title="所需积分" dataIndex="coin_num" key="coin_num" />
                </Table>
            </Form.Item>
            <Form.Item label="可结算次数" rules={[{ required: true }]} help="完成一次结算后结束，还是可持续结算">
                <Select readonly value={selectPlan.settle_type}>
                    <Select.Option value="cycle">不限制</Select.Option>
                    <Select.Option value="single">单次</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item label="有效时长单位">
                <Select value={selectPlan?.duration_meta} readonly>
                    <Select.Option value="acupoint:duration::hour">时</Select.Option>
                    <Select.Option value="acupoint:duration::day">天</Select.Option>
                    <Select.Option value="acupoint:duration::month">月</Select.Option>
                    <Select.Option value="acupoint:duration::year">年</Select.Option>
                    <Select.Option value="acupoint:duration::lifelong">终身</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item label="有效时长量" >
                <InputNumber value={selectPlan?.duration} min={1} size="small" readonly />
            </Form.Item>
        </div>}

    </Form>
}


export default InviteCodeForm