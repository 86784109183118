import { Modal, message, Button } from "antd"
import TopicForm from '../components/TopicForm'
import reducer from '../../store';
import { Provider } from 'react-redux';
import { legacy_createStore as createStore } from "redux"
import { EditOutlined } from "@ant-design/icons";
const store = createStore(reducer)

let editTopicModal = (channel, initialValues, onSubmit = () => null) => {
    let formInstance = null
    Modal.confirm({
        width: 600,
        title: '编辑话题',
        content: <Provider store={store}><TopicForm initial={initialValues} setForm={(form) => formInstance = form} /></Provider>,
        onOk: (close) => {
            formInstance
                .validateFields()
                .then(values => {
                    channel.push("edit-topic", { ...values, title: initialValues.title })
                        .receive("ok", () => {
                            message.success("修改成功")
                            onSubmit()
                            close()
                        })
                })
            return false
        }
    });
}

export { editTopicModal }


function EditTopicButton({ channel, initialValues, onSubmit = () => null }) {
    return <Button icon={<EditOutlined />} type="link" onClick={() => editTopicModal(channel, initialValues, onSubmit)}>
        编辑
    </Button>
};

export default EditTopicButton