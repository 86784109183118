import { useState } from "react";
import { PictureOutlined, PlaySquareOutlined } from '@ant-design/icons';
import { Divider, Button, Upload, Input, Select, message, Alert, Carousel, InputNumber, Switch, Checkbox } from "antd"
import ButtonGroup from "antd/lib/button/button-group";
import Config from '../../../config'
import IAMRemoteSelect from "./iam_remote_select";
import { connect } from 'react-redux';
import InternalLinkRemoteSelect from "./internal_link_remote_select"
import Sha256Encode from "../../../components/sha256";


function MediumForm({ medium, onSave = () => null, onCancel = () => null, channel, ownerType, ownerID, jsonRequest }) {
    const [block, setBlock] = useState(medium || { type: "str", name: '编辑标题' })
    const [multipleUploadFiles, setMultipleUploadFiles] = useState([])

    const handleBlockChange = (field, content) => {
        const editedBlock = { ...block }
        editedBlock[field] = content
        setBlock(editedBlock)
    }

    const handleBlockMerge = (data, deleteKeys = []) => {
        const editedBlock = { ...block }
        // 先删除特别的字段
        deleteKeys.forEach(i => delete editedBlock[i])

        // 然后再合并
        setBlock({ ...editedBlock, ...data })
    }

    const iAMValueSave = (field, value) => {
        value = value.map((item) => {
            return { id: item.key, name: item.label }
        })
        handleBlockChange(field, value)
    }

    // 保存内部链接
    const internalLinkSave = (medium_value, field, value) => {
        console.log(medium_value, value)
        value = value.map((item) => {
            let existsItem = medium_value.find(existsItem => item.value === existsItem.id)
            return existsItem || JSON.parse(item.value);
        })
        handleBlockChange(field, value)
    }


    const onSaveClick = () => {
        if (block.type === 'position') {
            const { positionPath1, positionPath2, positionPath3, ...old } = block;
            const mediumPathArray = medium?.path.split('；') || [];
            const path = [positionPath1 || mediumPathArray[0], positionPath2 || mediumPathArray[1], positionPath3 || mediumPathArray[2]].join('；');
            onSave({ ...old, path });
            return;
        }
        onSave(block)
    }

    // 处理类型， 有些类型有附加字段，比如标题在62以后支持
    const handleTypeForBlock = (value) => {
        // 特别的字段
        const specialKeys = ["max_version_code"]
        switch (value) {
            case "title":
                handleBlockMerge({ type: value, max_version_code: 62 }, specialKeys)
                break;
            case "sub_title":
            case "internal_link":
                handleBlockMerge({ type: value, max_version_code: 79 }, specialKeys)
                break;

            default:
                handleBlockMerge({ type: value }, specialKeys)
                break;
        }
    }

    const handleUploadFile = (obj, field = "path") => {
        const file = obj.file
        message.loading({ content: "上传中，请稍后...", key: "handleUploadFile", duration: 0 })
        file.arrayBuffer().then(file_content => {
            Sha256Encode(file_content).then(file_name => {
                var data = new FormData()
                data.append('file', file)
                data.append('filename', file_name)
                data.append('owner_type', ownerType)
                data.append('owner_id', ownerID)

                jsonRequest.file(`/api/operation/media/upload`, data)
                    .then(resp => resp.json())
                    .then(({ data }) => {
                        const { path } = data
                        message.success({ content: "上传成功", key: "handleUploadFile", duration: 2 })
                        handleBlockChange(field, path)
                    })
            })
        })
    }

    const handleUploadMultipleFile = (obj, field = "path") => {
        const file = obj.file
        message.loading({ content: "上传中，请稍后...", key: "handleUploadFile", duration: 0 })
        file.arrayBuffer().then(file_content => {
            Sha256Encode(file_content).then(file_name => {
                var data = new FormData()
                data.append('file', file)
                data.append('filename', file_name)
                data.append('owner_type', ownerType)
                data.append('owner_id', ownerID)

                return jsonRequest.file(`/api/operation/media/upload`, data)
                    .then(resp => resp.json())
                    .then(({ data }) => {
                        const { path } = data
                        message.success({ content: "上传成功", key: "handleUploadFile", duration: 2 })
                        obj.onSuccess({ ...data })
                    })
            })
        })
    }

    const contentEditor = (type) => {
        switch (type) {
            case "str":
                return <div>
                    <Input.TextArea value={block.description} rows={6} autoSize
                        style={{ borderRadius: 5, borderColor: "#dcb677", fontSize: 16, color: "#b67100" }}
                        onChange={(e) => handleBlockChange("description", e.target.value)} />
                </div>

            case "title":
                return <div>
                    <Alert banner message="v2.6.10及以后支持" type="warning" showIcon />
                </div>
            case "sub_title":
                return <div>
                    <Alert banner message="v2.7.16及以后支持" type="warning" showIcon />
                </div>

            case "picture":
                return <div>
                    <Upload.Dragger showUploadList={false} customRequest={(obj) => handleUploadFile(obj, "path")}>
                        {block.path ? <img src={Config.ResourceUrl(block.path)} alt="avatar"
                            style={{ maxWidth: '90%', maxHeight: "100%" }} />
                            : <> <p className="ant-upload-drag-icon">
                                <PictureOutlined />
                            </p>
                                <p className="ant-upload-text">点击或拖拽图片上传</p></>}
                    </Upload.Dragger>
                </div>
            case "rhyme":
                var defaultValue = (medium?.term_poetics || []).map(item => {
                    return { key: item.id, label: item.name }
                })

                return <div>
                    <Input.TextArea value={block.description} rows={6} autoSize
                        style={{ borderRadius: 5, borderColor: "#dcb677", fontSize: 16, color: "#b67100" }}
                        onChange={(e) => handleBlockChange("description", e.target.value)} />
                    <p>音频</p>
                    <Upload.Dragger showUploadList={false} customRequest={(obj) => handleUploadFile(obj, "path")}>
                        {block.path ?
                            <audio src={Config.ResourceUrl(block.path)} style={{ width: "100%", maxHeight: "100%" }}
                                controls />
                            : <> <p className="ant-upload-drag-icon">
                                <PictureOutlined />
                            </p>
                                <p className="ant-upload-text">点击或拖拽音频上传</p></>}
                    </Upload.Dragger>
                    <p>相关歌赋</p>
                    <IAMRemoteSelect style={{ width: "100%" }} mode="multiple" params={{ type: 0 }} defaultValue={defaultValue} channel={channel}
                        onChange={(value) => iAMValueSave("term_poetics", value)} type="term_poetics"
                        placeholder="搜索并选择经络歌赋，可多选" />
                </div>
            case "position":
                const mediumPaths = medium?.path.split('；') || [];
                const pathArray = [block.positionPath1 || mediumPaths[0], block.positionPath2 || mediumPaths[1], block.positionPath3 || mediumPaths[2]];
                return <div>
                    <Upload.Dragger
                        showUploadList={false}
                        customRequest={(obj) => handleUploadFile(obj, "positionPath1")}
                    >
                        {pathArray[0] ? <img src={Config.ResourceUrl(pathArray[0])} alt="avatar"
                            style={{ maxWidth: '90%', maxHeight: "100%" }} />
                            : <> <p className="ant-upload-drag-icon">
                                <PictureOutlined />
                            </p>
                                <p className="ant-upload-text">点击或拖拽预览图上传</p></>}

                    </Upload.Dragger>
                    <br />
                    <Upload.Dragger
                        showUploadList={false}
                        customRequest={(obj) => handleUploadFile(obj, "positionPath2")}
                    >
                        {pathArray[1] ? <img src={Config.ResourceUrl(pathArray[1])} alt="avatar"
                            style={{ maxWidth: '90%', maxHeight: "100%" }} />
                            : <> <p className="ant-upload-drag-icon">
                                <PictureOutlined />
                            </p>
                                <p className="ant-upload-text">点击或拖拽预览图上传</p></>}

                    </Upload.Dragger>
                    <br />
                    <Upload.Dragger
                        showUploadList={false}
                        customRequest={(obj) => handleUploadFile(obj, "positionPath3")}
                    >
                        {pathArray[2] ? <img src={Config.ResourceUrl(pathArray[2])} alt="avatar"
                            style={{ maxWidth: '90%', maxHeight: "100%" }} />
                            : <> <p className="ant-upload-drag-icon">
                                <PictureOutlined />
                            </p>
                                <p className="ant-upload-text">点击或拖拽预览图上传</p></>}

                    </Upload.Dragger>
                </div>
            case "video":
                return <div>
                    <Upload.Dragger
                        showUploadList={false}
                        customRequest={(obj) => handleUploadFile(obj, "thumbnailPath")}
                    >
                        {block.thumbnailPath ? <img src={Config.ResourceUrl(block.thumbnailPath)} alt="avatar"
                            style={{ maxWidth: '90%', maxHeight: "100%" }} />
                            : <> <p className="ant-upload-drag-icon">
                                <PictureOutlined />
                            </p>
                                <p className="ant-upload-text">点击或拖拽预览图上传</p></>}

                    </Upload.Dragger>
                    <br />

                    <Upload.Dragger
                        showUploadList={false}
                        customRequest={(obj) => handleUploadFile(obj, "path")}
                    >
                        {block.path ? <video controls preload="none" width="90%">
                            <source src={Config.ResourceUrl(block.path)} type="video/mp4"></source>
                        </video>
                            : <> <p className="ant-upload-drag-icon">
                                <PlaySquareOutlined />
                            </p>
                                <p className="ant-upload-text">点击或拖拽视频上传</p></>}

                    </Upload.Dragger>
                </div>
            case "illness":
                var defaultValue = (medium?.medicineIllnessList || []).map(item => {
                    return { key: item.id, label: item.name }
                })
                return <IAMRemoteSelect mode="multiple" channel={channel} defaultValue={defaultValue} type="illness"
                    onChange={(value) => iAMValueSave("medicineIllnessList", value)}
                    placeholder="搜索并选择疾病，可多选" />
            case "acupoint":
                var defaultValue = (medium?.value || []).map(item => {
                    return { key: item.id, label: item.name }
                })
                return <IAMRemoteSelect mode="multiple" params={{ type: 0 }} defaultValue={defaultValue} channel={channel}
                    onChange={(value) => iAMValueSave("value", value)} type="acupoint"
                    placeholder="搜索并选择穴位，可多选" />
            case "meridian":
                var defaultValue = (medium?.value || []).map(item => {
                    return { key: item.id, label: item.name }
                })
                return <IAMRemoteSelect mode="multiple" params={{ type: 0 }} defaultValue={defaultValue} channel={channel}
                    onChange={(value) => iAMValueSave("value", value)} type="meridian"
                    placeholder="搜索并选择经络，可多选" />
            case "term_poetics":
                var defaultValue = (medium?.value || []).map(item => {
                    return { key: item.id, label: item.name }
                })
                return <IAMRemoteSelect mode="multiple" params={{ type: 0 }} defaultValue={defaultValue} channel={channel}
                    onChange={(value) => iAMValueSave("value", value)} type="term_poetics"
                    placeholder="搜索并选择经络歌赋，可多选" />
            case 'carousel':
                return <div>
                    <Alert
                        message="请一次性全部上传所有幻灯片图片，编辑操作为覆盖而非增量。单张间隔时间时间只对APP生效，Web不随设置时间变化"></Alert>
                    <br />
                    <Carousel autoplay>
                        {(block.paths || []).map(path => {
                            return <img key={path} src={Config.ResourceUrl(path)} />
                        })}
                    </Carousel>
                    <br />
                    <p><InputNumber style={{ width: "100%" }} addonBefore="间隔时间" addonAfter="毫秒"
                        value={medium?.duration}
                        onChange={(value) => handleBlockChange("duration", value)} /></p>
                    <Upload.Dragger listType="picture" customRequest={(obj) => handleUploadMultipleFile(obj, "paths")}
                        multiple
                        fileList={multipleUploadFiles}
                        onChange={(info) => {
                            let newFileList = [...info.fileList];
                            newFileList = newFileList.map(file => {
                                if (file.response) {
                                    file.url = Config.ResourceUrl(file.response.path);
                                }

                                return file;
                            });

                            handleBlockChange("paths", newFileList.filter(({ status }) => status == "done").map(({ response }) => response.path))
                            setMultipleUploadFiles(newFileList)
                        }}>
                        {block.path ? <img src={Config.ResourceUrl(block.path)} alt="avatar"
                            style={{ maxWidth: '90%', maxHeight: "100%" }} />
                            : <> <p className="ant-upload-drag-icon">
                                <PictureOutlined />
                            </p>
                                <p className="ant-upload-text">点击或拖拽图片上传</p></>}
                    </Upload.Dragger>
                </div>
            case "internal_link":
                var defaultValue = (medium?.value || []).map(item => {
                    return { key: item.id, label: item.name }
                })
                return <InternalLinkRemoteSelect mode="multiple" defaultValue={defaultValue}
                    onChange={(value) => internalLinkSave(medium?.value || [], "value", value)}
                    placeholder="搜索并选择各种内容，可多选"
                    channel={channel}></InternalLinkRemoteSelect>
            default:
                break;
        }
    }

    return <div className={`block type-${block.type}`}>
        <div className="title">
            <Input className="name" value={block.name} placeholder="不显示标题"
                onChange={(e) => handleBlockChange("name", e.target.value)}></Input>

            <span>
                <Select value={block.type} onChange={(value) => handleTypeForBlock(value)} size="small"
                    style={{ width: "7em" }}>
                    <Select.Option value="title">一级标题</Select.Option>
                    <Select.Option value="sub_title">二级标题</Select.Option>
                    <Select.Option value="str">文本</Select.Option>
                    <Select.Option value="picture">图片</Select.Option>
                    <Select.Option value="video">视频</Select.Option>
                    <Select.Option value="illness">疾病</Select.Option>
                    <Select.Option value="term_poetics">经络歌赋</Select.Option>
                    <Select.Option value="acupoint">穴位</Select.Option>
                    <Select.Option value="rhyme">经络音频</Select.Option>
                    <Select.Option value="position">组图渐进</Select.Option>
                    <Select.Option value="carousel">幻灯片</Select.Option>
                    <Select.Option value="internal_link">内部链接</Select.Option>
                </Select>
            </span>
        </div>
        <Divider />
        <div className={`content type-${block.type}`} style={{ marginTop: 5 }}>
            <div style={{ marginBottom: 5 }}>
                {
                    contentEditor(block.type)
                }
            </div>
            <ButtonGroup style={{ width: "100%" }} size="small">
                <Button type="primary" onClick={onSaveClick} style={{ flexGrow: 1 }}>暂存</Button>
                <Button onClick={onCancel}>取消</Button>
            </ButtonGroup>
        </div>
    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(MediumForm)