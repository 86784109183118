import React from "react";
import { Tag, Divider, Switch, Badge } from 'antd';
import LocalTimeAgo from "../components/local_time_ago";
import { connect } from 'react-redux';
import dayjs from 'dayjs'
import useLocalStorageState from 'use-local-storage-state'

import "./AppUser.css"
import UserAvatar from "../components/user/UserAvatar";
import { MobileOutlined, TabletOutlined, CrownTwoTone } from '@ant-design/icons';


function AppUser(props) {
    const [onlyVIP, setOnlyVIP] = useLocalStorageState('AppUser.Filter.OnlyVIP', false)
    const [filterChannel, setfilterChannel] = useLocalStorageState('AppUser.Filter.Channel', null)
    const [filterVersion, setFilterVersion] = useLocalStorageState('AppUser.Filter.Version', null)
    const [filterDevice, setFilterDevice] = useLocalStorageState('AppUser.Filter.Device', null)
    const [filterRoute, setFilterRoute] = useLocalStorageState('AppUser.Filter.Route', null)

    const userItemView = (value) => {
        const user = value["user"] || {}
        const app_info = value["app_info"] || {}

        return <div key={value["key"]} className="app-user-item" >
            <div className="avatar">
                <UserAvatar size={60} nickname={user?.nickname || user?.id || "匿名用户"} avatar={user?.avatar} id={user?.id} vip={user?.group === "vip"} />
            </div>
            <div className="info">
                <div>{(app_info?.shortest || 0) < 600 ? <Tag color={"green"} icon={<MobileOutlined />}>手机</Tag> : <Tag color={"blue"} icon={<TabletOutlined />}>平板</Tag>}{<LocalTimeAgo date={value["online_at"]} />} </div>
                <div>{value["sum_seconds"]} </div>
                <Tag >{value.channel}</Tag>
                <Tag >{value.version}</Tag>
                {
                    value?.route?.current && <div>
                        <Tag>{value?.route?.current_name}</Tag>
                    </div>
                }
            </div>
            <div className="actions">

            </div>
        </div >
    }

    // 筛选渠道
    const handleClickFilterChannel = (clickedChannel) => setfilterChannel(filterChannel === clickedChannel ? null : clickedChannel)
    // 筛选版本
    const handleClickFilterVersion = (clickedVersion) => setFilterVersion(filterVersion === clickedVersion ? null : clickedVersion)
    // 筛选页面
    const handleClickFilterRoute = (clickedRoute) => setFilterRoute(filterRoute === clickedRoute ? null : clickedRoute)
    // 筛选设备
    const handleClickFilterDevice = (clickedDevice) => {
        clickedDevice = clickedDevice.toString()
        setFilterDevice(filterDevice === clickedDevice ? null : clickedDevice)
    }

    let { online_user_list = [] } = props.onlineUsers

    const channelKeys = online_user_list.map(item => item.channel).filter((v, i, s) => s.indexOf(v) === i)
    const versionKeys = online_user_list.map(item => item.version).filter((v, i, s) => s.indexOf(v) === i)
    const routeNames = online_user_list.map(item => item.route?.current_name).filter((v, i, s) => s.indexOf(v) === i)

    // 筛选选择的频道
    if (!!filterChannel) {
        online_user_list = online_user_list.filter((item) => item.channel === filterChannel)
    }

    // 筛选选择的版本
    if (!!filterVersion) {
        online_user_list = online_user_list.filter((item) => item.version === filterVersion)
    }

    // 筛选选择的页面
    if (!!filterRoute) {
        online_user_list = online_user_list.filter((item) => item?.route?.current_name === filterRoute)
    }

    // 筛选选择VIP
    if (!!onlyVIP) {
        online_user_list = online_user_list.filter((item) => item?.user?.group === "vip")
    }

    // 筛选选择的设备
    if (filterDevice === "phone") {
        online_user_list = online_user_list.filter((item) => item?.app_info?.shortest < 600)
    }

    // 筛选选择的设备
    if (filterDevice === "pad") {
        online_user_list = online_user_list.filter((item) => item?.app_info?.shortest >= 600)
    }

    // vip数
    const vipCount = online_user_list.filter(item => item?.user?.group === "vip").length
    // 手机数
    const phoneCount = online_user_list.filter(item => item?.app_info?.shortest < 600).length
    // 平板数
    const padCount = online_user_list.filter(item => item?.app_info?.shortest >= 600).length
    // 按渠道统计用户数
    const userCountByChannel = online_user_list.reduce((acc, item) => { return { ...acc, [item.channel]: (acc[item.channel] || 0) + 1 } }, {})
    // 按渠道统计用户数
    const userCountByVersion = online_user_list.reduce((acc, item) => { return { ...acc, [item.version]: (acc[item.version] || 0) + 1 } }, {})
    // 按页面统计用户数
    const userCountByRoute = online_user_list.reduce((acc, item) => { return { ...acc, [item.route?.current_name]: (acc[item.route?.current_name] || 0) + 1 } }, {})


    return <div className="page online-app-user">
        <Badge style={{ cursor: "pointer" }} overflowCount={9999} count={(props.onlineUsers?.online_user_list || []).length} offset={[20, 10]} onClick={() => {
            setOnlyVIP(false);
            setFilterVersion(null);
            setfilterChannel(null);
            setFilterRoute(null);
        }}>
            <h2 style={{ display: "flex", alignItems: "center" }}>
                在线用户
            </h2>
        </Badge>
        <p>
            <Tag color="magenta" style={{ cursor: "pointer", fontWeight: onlyVIP ? "bold" : "normal" }} onClick={() => setOnlyVIP(!onlyVIP)} ><CrownTwoTone twoToneColor="#eb2f96" /> {vipCount}</Tag>
            <Tag color="green" style={{ cursor: "pointer", fontWeight: filterDevice === "phone" ? "bold" : "normal" }} onClick={() => handleClickFilterDevice("phone")}><MobileOutlined /> {phoneCount}</Tag>
            <Tag color="blue" style={{ cursor: "pointer", fontWeight: filterDevice === "pad" ? "bold" : "normal" }} onClick={() => handleClickFilterDevice("pad")}><TabletOutlined /> {padCount}</Tag>
        </p>
        <p>
            渠道：
            {channelKeys.sort().map(key => {
                return <Tag style={{ cursor: "pointer", fontWeight: key === filterChannel ? "bold" : "normal" }} onClick={() => handleClickFilterChannel(key)} key={key}>{key} <Divider type="vertical" dashed /> {userCountByChannel[key] || 0}</Tag>
            })}
        </p>
        <p>
            版本：
            {versionKeys.sort().map(key => {
                return <Tag style={{ cursor: "pointer", fontWeight: key === filterVersion ? "bold" : "normal" }} onClick={() => handleClickFilterVersion(key)} key={key}>{key} <Divider type="vertical" dashed /> {userCountByVersion[key] || 0}</Tag>
            })}
        </p>
        <p>
            页面：
            {routeNames.sort((k1, k2) => (userCountByRoute[k2] || 0) - (userCountByRoute[k1] || 0)).map(key => {
                return <Tag style={{ cursor: "pointer", fontWeight: key === filterRoute ? "bold" : "normal" }} onClick={() => handleClickFilterRoute(key)} key={key}>{key} <Divider type="vertical" dashed /> {userCountByRoute[key] || 0}</Tag>
            })}
        </p>
        <div className="online-block">
            {online_user_list.sort((prev, next) => dayjs(next["online_at"]) - dayjs(prev["online_at"])).map(value => userItemView(value))}
        </div>
    </div >
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        channel: global.appOnlineChannel,
        onlineUsers: global.onlineUsers
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppUser);