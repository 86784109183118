import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Button, Avatar, List, Select, Divider, Pagination } from "antd"
import { Menu as MenuAction } from '../../store/global/actions'

import LocalTimeAgo from "../../components/local_time_ago";
import { Radio } from "antd"
import { Tooltip } from "antd";
import dayjs from "dayjs";
import { message } from "antd"
import UserAvatars from "../../components/user/UserAvatars"
import { Link } from "react-router-dom/cjs/react-router-dom";
import Config from "../../config"
import "./Message.css"


function NotificationMessage(props) {
    const [channel, setChannel] = useState(null)
    let [data, setData] = useState({})
    let [filterData, setFilterData] = useState({ channel_key: "all", scene_key: "all" })
    let [channelData, setChannelData] = useState([])
    let [sceneData, setScenelData] = useState({})

    props?.setActiveKey("NOTIFICATION-MESSAGE")

    useEffect(() => {
        let _channel = props.socket.channel("notification:message")
        _channel.join()
        setChannel(_channel)

        _channel.on("data", ({ data }) => { setData(data) })
        _channel.on("channel_data", ({ data }) => setChannelData(data))
        _channel.on("scene_data", ({ data }) => setScenelData(data))

        return () => {
            if (!!_channel) _channel.leave()
        }
    }, [])

    // 筛选想设置并搜索
    const filterSetAndSearch = (name, value) => {
        let newFilterData = { ...filterData, [name]: value }
        setFilterData(newFilterData)

        let requestData = { ...newFilterData }
        if (requestData.channel_key === "all")
            delete requestData.channel_key
        if (requestData.scene_key === "all")
            delete requestData.scene_key

        channel.push("query", requestData)
    }

    const handlePageTo = (page, size) => {
        channel.push("page-to", { page, size })
    }

    const sentToApp = (id) => {
        channel.push("send-to-app", { id })
            .receive("ok", () => message.success("已发送"))
    }


    const contentRender = (message) => {
        switch (message.content?.type) {
            case 1:
                return message.content?.content_text
            case 2:
                return <div style={{ maxHeight: 300, overflowY: "auto" }} dangerouslySetInnerHTML={{ __html: message.content?.content_text }}></div>

            case 3:
                var path = ""
                if (!!message.content?.content?.parent_id) {
                    path = `/community/simulator/question/${message.content.content.parent_id}/answer/${message.content.content.id}`
                } else {
                    path = `/community/simulator/question/${message.content.content.parent_id}`
                }

                return <Link target="_blank" to={path} style={{ color: "#888", display: "block", padding: "5px 10px", background: "#fff", margin: 5, cursor: "pointer" }} >
                    <div><b>{message.content?.content?.title}</b></div>
                    <div style={{ fontSize: 11 }}>阅读 {message.content?.content?.read_count} · 赞同 {message.content?.content?.like_count}</div>
                </Link>
            case 4:
                var path = ""
                if (!!message.external[0]?.params.parentId) {
                    path = `/community/simulator/question/${message.external[0].params.parentId}/answer/${message.external[0].params.id}`
                } else {
                    path = `/community/simulator/question/${message.external[0].params.parentId}`
                }

                return <Link target="_blank" to={path} style={{ color: "#888", display: "block", padding: "5px 10px", background: "#fff", margin: 5, cursor: "pointer" }} >
                    <div><b>{message.content?.content?.content}</b></div>
                </Link>
            default:
                break;
        }
    }

    const externalRender = (message) => {
        if (!message.external || message.external.length <= 0) return null

        switch (message.content?.type) {
            default:
                return <Button.Group>
                    {message.external.map((external, i) =>
                        <Tooltip title={<div>
                            <div>{external.url}</div>
                            <div>12</div>
                        </div>} key={`${message.id}-${i}`}>
                            <Button type="dashed" danger size="small">{external.text || "\"列表中点击直接执行\""}</Button>
                        </Tooltip>
                    )}
                </Button.Group>
        }
    }

    return <div className="page operation notification message">
        <h1>
            已发送的消息通知
        </h1>

        <div style={{ display: "flex", alignItems: "center" }}>
            频道：
            <Radio.Group value={filterData?.channel_key} size="small" onChange={(e) => filterSetAndSearch("channel_key", e.target.value)}>
                <Radio.Button value="all">全部</Radio.Button>
                {channelData.map((channel) => <Radio.Button key={channel.key} value={channel.key}>{channel.name}</Radio.Button>)}
            </Radio.Group>
            <Divider type="vertical" />
            场景：
            <Select listHeight="60vh" size="small" value={filterData?.scene_key} onChange={(value) => filterSetAndSearch("scene_key", value)} style={{ width: 200 }}>
                <Select.Option value="all">全部</Select.Option>
                {
                    Object.keys(sceneData).map(key => <Select.OptGroup label={key}>
                        {
                            sceneData[key].map((scene) => <Select.Option key={scene.key} value={scene.key}>{scene.name}</Select.Option>)}
                    </Select.OptGroup>)
                }
            </Select>
        </div>
        <br />
        <div style={{ height: 720, overflowY: "auto" }}>
            <List dataSource={data?.entries} itemLayout="vertical" grid={{ gutter: 0, column: 3 }}
                rowKey={(r) => r.id}
                pagination={false} renderItem={(item) => (
                    <List.Item actions={[
                        <> {dayjs(item.inserted_at).format("YYYY-MM-DD HH:mm")} <LocalTimeAgo date={dayjs(item.inserted_at).format("YYYY-MM-DD HH:mm")} /></>,
                        externalRender(item)
                        // <Button type="link" size="small" icon={<RocketTwoTone />} onClick={() => sentToApp(item.id)} >发送APP弹窗</Button>,
                    ]} style={{ background: "#fafafa", padding: 10, margin: 10, borderRadius: 10 }}>
                        <List.Item.Meta
                            avatar={item.avatar && <Avatar size="large" src={Config.AvatarUrl(item.avatar)} />}
                            title={<div>
                                <div>
                                    {/* <span style={{ color: "#666", fontSize: 9 }}>{sceneData.find((i) => i.key === item.scene_key)?.name}</span> */}
                                </div>
                                <div>
                                    <span style={{ color: "orange" }}>[{item.channel_name}]</span> <span>{item.content?.title}</span>
                                </div></div>}
                            description={<div style={{ color: "#666", display: "flex" }}>
                                <div className="review">
                                    <div className="content">{contentRender(item)}</div>

                                    {/* <div>
                                        {dayjs(item.inserted_at).format("YYYY-MM-DD HH:mm")} <LocalTimeAgo date={dayjs(item.inserted_at).format("YYYY-MM-DD HH:mm")} />
                                    </div> */}
                                </div>
                                {item.target.type === 2 && <Tooltip title="接受用户">
                                    <div style={{ marginLeft: 10, transform: "translate(0px, -30px)" }}>

                                        <UserAvatars maxCount={1} size="large" users={item.owners?.map(user => ({ ...user, size: 42, vip: user?.group === "vip" }))} />

                                    </div>  </Tooltip>}
                                {item.target.type === 1 && <div style={{ marginLeft: 10, transform: "translate(0px, -30px)" }}> <Avatar size="large">全局</Avatar></div>}
                            </div>}
                        />
                    </List.Item>
                )}>

            </List>
        </div>
        <Pagination
            onChange={(page, size) => { handlePageTo(page, size) }}
            total={data?.total_entries}
            current={data?.page_number}
            pageSize={data?.page_size || 1}
        />
    </div >
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NotificationMessage)