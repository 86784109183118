import { Form, Radio, Button, message } from "antd"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Menu as MenuAction } from "../../store/global/actions"

function AISearchSetting(props) {
    let [data, setData] = useState(null)
    let [channel, setChannel] = useState(null)
    const [form] = Form.useForm()

    props.setActiveKey("SETTING-AI-SEARCH")

    useEffect(() => {
        const _channel = props.socket.channel(`operation:settings:aiSearch`)
        _channel.join()
        _channel.on("data", ({ data }) => {
            const jsonedData = JSON.parse((data[0] || { content: "{}" }).content)
            setData(jsonedData)
        })
        setChannel(_channel)

        return () => {
            !!_channel && _channel.leave()
        }
    }, [])

    const onSave = (values) => {
        channel.push("set", { aiSearch: JSON.stringify(values) })
            .receive("ok", () => { message.success({ content: "保存成功" }) })
    }

    return <div className="page settings" style={{ flexDirection: "column", display: "flex", height: "100%" }}>
        <h1>AI搜索设置</h1>

        <div style={{ display: "flex", flexGrow: 1 }}>
            {!!data &&
                <Form form={form} 
                    style={{ width: 600 }} 
                    initialValues={data} 
                    name="basic" 
                    labelCol={{ span: 8 }} 
                    wrapperCol={{ span: 16 }} 
                    autoComplete="off" 
                    onFinish={onSave}>
                    
                    <Form.Item 
                        label="内容搜索AI决策" 
                        name="content_search_ai_policy" 
                        rules={[{ required: true, message: '不能为空' }]}>
                        <Radio.Group>
                            <Radio value="no_result">无准确结果时使用</Radio>
                            <Radio value="vip">会员可用</Radio>
                            <Radio value="free">免费使用</Radio>
                            <Radio value="close">关闭</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item 
                        label="模型搜索AI决策" 
                        name="model_search_ai_policy" 
                        rules={[{ required: true, message: '不能为空' }]}>
                        <Radio.Group>
                            <Radio value="no_result">无准确结果时使用</Radio>
                            <Radio value="vip">会员可用</Radio>
                            <Radio value="free">免费使用</Radio>
                            <Radio value="close">关闭</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item wrapperCol={{ xs: { span: 8, offset: 8, } }}>
                        <Button.Group>
                            <Button size="small" type="primary" htmlType="submit">保存</Button>
                        </Button.Group>
                    </Form.Item>
                </Form>
            }
        </div>
    </div>
}

const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AISearchSetting) 