
import React from "react";
import { connect } from 'react-redux';
import 'jsoneditor-react/es/editor.min.css';

import PushForm from "../components/push_form"
class Customize extends React.Component {

    render() {
        return <div className="page push-customize" >
            <h1>
                自定义推送
            </h1>

            <PushForm templateID={this.props.match.params.template_id} />
        </div>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Customize)