import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

function AuthRoute({ user, component: Component, ...rest }) {
    return <Route
        {...rest}
        render={props => !!user ? <Component {...props} /> : <Redirect to={{ pathname: "/sign-in", state: { from: props.location } }} />}
    />
}

const mapStateToProps = ({ global }) => {
    return {
        user: global.user,
        roles: global.user.roles,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute)