


import {
    HashRouter as Router,
    Route
} from "react-router-dom";
import { Menu as MenuAction } from '../store/global/actions'
import { connect } from 'react-redux';

import CourseList from './course/list'
import QuestionList from './question/list'
import CorrectionList from './correction/list'
import SideMenu from "./component/side_menu"
import Dashboard from "./Dashboard";

function SQEC(props) {
    props.setActiveKey("SQEC")

    return <Router>
        <div className="page-dashboard module module-sqec">
            <div className="side-menu">
                <SideMenu />
            </div>
            <div className="content">
                <Route path="/sqec/dashboard" exact component={Dashboard}></Route>
                <Route path="/sqec/question/list" exact component={QuestionList}></Route>
                <Route path="/sqec/correction/list" exact component={CorrectionList}></Route>
                <Route path="/sqec/course/list" exact component={CourseList}></Route>
            </div>
        </div>
    </Router>

}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SQEC)

