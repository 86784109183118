import React from "react";
import { message, Pagination, Input, Button, DatePicker, Divider, Radio } from 'antd';
import 'jsoneditor-react/es/editor.min.css';
import RewardConvertItem from "./components/reward_convert_item"
import { RewardConvertAuditFormModel } from "./components/reward_convert_audit_form";
import InviteCodeDetailModel from "./components/invite_code_detail_modal"
import { RewardConvertReleaseFormModal } from "./components/reward_convert_release_form";

import debounce from "debounce"
import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
const { RangePicker } = DatePicker;

class RewardConvert extends React.Component {
    state = {
        channel: null,
        data: {},
        ownerType: null,
        // 用于点击显示邀请码的明细，弹出modal
        showDetailCode: null,
        // 用于点击显示审核表单，弹出modal
        showRewardConvertAuditForm: null,
        // 用于点击显示发放表单，弹出modal
        showRewardConvertReleaseForm: null,
        // 筛选器
        filter: { status: "" }
    }

    componentDidMount() {
        this.channelJoin()
        this.setState({ ownerType: this.props.match.params.ownerType })
    }

    channelJoin() {
        if (!!this.state.channel) {
            this.state.channel.off("data")
            this.state.channel.leave()
        }

        const channel = this.props.socket.channel("marketing:invite_reward:convert", { page_size: 12 })
        channel.on("data", ({ data }) => this.setState({ data }))
        channel.join()
        this.setState({ channel })
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    pushEvent(event, params, opts = null) {
        return this.state.channel.push(event, params)
    }

    tableChange = (page, size) => {
        this.pushEvent("page-to", { page_size: size, page: page })
    }

    onFinish = (values) => {
        this.pushEvent("query", values)
    }

    onReset = () => {
        this.pushEvent("query", {})
    }

    onRefresh = () => {
        this.pushEvent("refresh", {})
    }

    // 处理提取确认发放
    handleRewardConvertRelease(invite_reward_convert_id) {
        this.pushEvent("release:convert", { invite_reward_convert_id })
            .receive("ok", () => {
                this.onRefresh()
                message.success("确认了发放")
            })
    }


    handleClickInviteCode(code) {
        this.setState({ showDetailCode: code })
    }


    handleClickQuickMove(unit_num) {
        let filter = this.state.filter
        let [begin, end] = filter.time
        begin = begin.add(unit_num, "day")
        end = end.add(unit_num, "day")
        this.handleFilterChange("time", [begin, end])
    }

    handleFilterChange(field, value) {
        this.setState({ filter: { ...this.state.filter, [field]: value } }, () => {
            this.pushEvent("query", this.state.filter)
                .receive("ok", () => {
                })
        })
    }

    render() {
        const data = this.state.data;
        const filter = this.state.filter;

        return <>
            <h1>报酬兑换申请</h1>
            <p>
                <span>
                    业务编码：
                    <Input allowClear placeholder="模糊搜索" size="small" style={{ width: 200 }} onChange={debounce((e) => this.handleFilterChange("text", e.target.value), 500)}></Input>
                </span>
                <Divider type="vertical" />
                <span>
                    时间：
                    <RangePicker format="YYYY-MM-DD 00:00:00" size='small' value={filter.time} onChange={(value) => {
                        if (!!value) {
                            let [min, max] = value
                            this.handleFilterChange("time", [min?.startOf('day'), max?.startOf('day')])
                        } else {
                            this.handleFilterChange("time", null)
                        }
                    }}
                    />
                </span>
                <Divider type="vertical" />
                <span>
                    状态：
                    <Radio.Group size='small' value={filter.status} onChange={(e) => this.handleFilterChange("status", e.target.value)}>
                        <Radio.Button value="">全部</Radio.Button>
                        <Radio.Button value="pending">等待审核</Radio.Button>
                        <Radio.Button value="failed">审核不通过</Radio.Button>
                        <Radio.Button value="wait_release">等待发放</Radio.Button>
                        <Radio.Button value="finish">已发放</Radio.Button>
                        {/* <Button></Button> */}
                        {/* <Button onClick={() => handleQuickQuery("hours", [dayjs().startOf("day"), endOfToday])}>今天</Button>
                        <Button onClick={() => handleQuickQuery("hours", [dayjs().startOf("week"), endOfToday])}>本周</Button>
                        <Button onClick={() => handleQuickQuery("hours", [dayjs().startOf("month"), endOfToday])}>本月</Button>
                        <Button onClick={() => handleQuickQuery("hours", [dayjs().startOf("year"), endOfToday])}>本年</Button> */}
                    </Radio.Group>
                </span>
            </p>

            <div style={{ display: "flex", flexWrap: "wrap", width: 1600, marginLeft: -10 }}>
                {data?.entries && data.entries.map(reward => {
                    return <RewardConvertItem key={`reward-convert-${reward.id}`} reward={reward}
                        onClickShowAuditForm={(reward) => this.setState({ showRewardConvertAuditForm: reward })}
                        onClickShowInviteCode={(inviteCode) => this.setState({ showDetailCode: inviteCode })}
                        onClickShowReleaseForm={(reward) => this.setState({ showRewardConvertReleaseForm: reward })} />
                })}
            </div>
            <Pagination showQuickJumper total={data?.total_entries || 0} current={data?.page_number || 1} pageSize={data?.page_size || 12} onChange={(page, size) => { this.tableChange(page, size) }} />
            <InviteCodeDetailModel code={this.state.showDetailCode} close={() => this.setState({ showDetailCode: null })} />
            <RewardConvertAuditFormModel channel={this.state.channel}
                rewardConvert={this.state.showRewardConvertAuditForm}
                onRefresh={this.onRefresh.bind(this)}
                close={() => this.setState({ showRewardConvertAuditForm: null })} />
            <RewardConvertReleaseFormModal channel={this.state.channel}
                rewardConvert={this.state.showRewardConvertReleaseForm}
                onRefresh={this.onRefresh.bind(this)}
                close={() => this.setState({ showRewardConvertReleaseForm: null })} />
        </>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RewardConvert)