import React from "react";
import { List, Button, Tag, Upload, Select, message, Tabs, Form, Input, Modal, InputNumber } from 'antd';
import { UploadOutlined, CalendarOutlined, EyeOutlined, UserOutlined, PlusSquareTwoTone, SafetyCertificateOutlined, IssuesCloseOutlined } from '@ant-design/icons';

import "./Editor.css"

import LocalTimeAgo from "../../components/local_time_ago";
import { connect } from "react-redux";
import AlbumForm from './AlbumForm'

import MultiUploadForm from "./MultiUploadForm";
import UserAvatar from "../../components/user/UserAvatar";

import dayjs from "dayjs";
import UploadComponent from "../../components/UploadComponent";

const { TabPane } = Tabs;

class VideoCourseEditor extends React.Component {
    state = {
        channel: null,
        data: {},
        showCreateVideo: false,
        showEditAlbum: false,
        unsubmitFiles: [],
        cateTreeData: []
    }

    joinChannel(props) {
        const channel = this.props.socket.channel("ops:resource:video:course:" + props.match?.params?.id)
        channel.on("data", (data) => this.setState({ data }))
        // 分类树
        channel.on("cate-tree", ({ data: cateTreeData }) => this.setState({ cateTreeData }))
        channel.join()
        this.setState({ channel })
    }

    componentDidMount() {
        this.joinChannel(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        !!this.state.channel && this.state.channel.off("data")
        !!this.state.channel && this.state.channel.leave()

        this.joinChannel(nextProps)
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    pushEvent(event, params, opts = null) {
        return this.state.channel.push(event, params)
    }


    metaTranslate(label) {
        let text = ""
        switch (label.meta) {
            case "acupoint:resource:label::cover":
                text = "封面"
                break;
            case "acupoint:resource:label::duration":
                text = "时长"
                break;
            case "acupoint:resource:label::oss":
                text = "OSS"
                break;
            case "acupoint:resource:label::playback":
                text = "播放次数"
                break;
            case "acupoint:resource:label::uploader":
                text = "上传者"
                break;
            case "acupoint:resource:label::m3u8":
                text = "流视频"
                break;
            default:
                text = "暂未定义"
                break;
        }
        return <Tag style={{ margin: 3 }}><span style={{ color: "#aaa" }}>{text}</span>: {this.mediaTranslate(label.value)}</Tag>
    }

    mediaTranslate(media) {
        switch (media.type) {
            case "acupoint:media:type::text":
            case "acupoint:media:type::integer":
                return media.value
            case "acupoint:media:type::oss":
                return <a rel="noreferrer" target="_blank" href={media.path}>查看</a>

            default:
                break;
        }
    }

    handleAlbumCoverUpload(obj) {
        const file = obj.file
        let reader = new FileReader()
        reader.addEventListener("load", () => {
            let payload = { binary: reader.result.split(",", 2)[1], filename: file.name }

            this.pushEvent("upload:cover", payload)
                .receive(
                    "ok", (reply) => {
                        message.success("封面上传成功")
                        console.log("got reply", reply)
                    }
                )
        }, false)
        reader.readAsDataURL(file)

    }

    handleVideoCreate(values) {
        this.pushEvent("append:video", { ...values }).receive("ok", (data) => {
            message.success("创建成功")
            this.setState({ showCreateVideo: false })
        })
    }

    handleVideoEdit(id, values) {
        this.pushEvent("edit:video", { ...values, id: id }).receive("ok", (data) => message.success("修改成功"))
    }

    permissionTranslate(state) {
        switch (state) {
            case "purchase": return <span style={{ color: "orange" }}><SafetyCertificateOutlined /> <span >需购买</span></span>
            case "free": return <span style={{ color: "silver" }}><SafetyCertificateOutlined /> <span >免费</span></span>
            case "vip": return <span style={{ color: "#1890ff" }}><SafetyCertificateOutlined /> <span >需VIP</span></span>

            default:
                break;
        }
    }

    stateTranslate(state) {
        switch (state) {
            case "publish": return <span style={{ color: "green" }}><IssuesCloseOutlined /> <span >发布</span></span>
            case "draft": return <span style={{ color: "orange" }}><IssuesCloseOutlined /> <span >草稿</span></span>
            case "complain": return <span style={{ color: "silver" }}><IssuesCloseOutlined /> <span >举报下架</span></span>

            default:
                break;
        }
    }

    modalForm() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 20 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 20,
                    offset: 4
                }
            },
        };
        return <Form {...formItemLayout} onFinish={(values) => this.handleVideoCreate(values)}>
            <Form.Item name="name" label="标题" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::uploader"]} label="上传者" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::playback"]} initialValue={0} label="播放次数" rules={[{ required: true }]}>
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::duration"]} label="时长" rules={[{ required: true }]} help="如：10:00">
                <Input />
            </Form.Item>
            <Form.Item name="app_version_code" initialValue={56} label="APP最低版本" rules={[{ required: true }]} help="低于此版本的APP，看不到本视频">
                <Select size="small">
                    <Select.Option value={56}>v2.6.4</Select.Option>
                    <Select.Option value={58}>v2.6.6</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="permission" initialValue="free" label="权限" rules={[{ required: true }]}>
                <Select >
                    <Select.Option value="free">免费</Select.Option>
                    <Select.Option value="vip">会员</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="state" initialValue="draft" label="状态" rules={[{ required: true }]}>
                <Select >
                    <Select.Option value="draft">草稿</Select.Option>
                    <Select.Option value="publish">发布</Select.Option>
                    <Select.Option value="complain">举报下架</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::ip_belongs"]} label="IP归属地" >
                <Input size="small" placeholder="比如：海南省、广东省" />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" >
                    保存
                </Button>
                &nbsp;
                <Button type="ghost" htmlType="reset">重置</Button>
            </Form.Item>
        </Form>
    }

    handleUploadSuccess = (id, [file]) => {
        this.props.jsonRequest.post(`/api/std/video-course/${id}/upload/video`, { path: file.url })
            .then(() => {
                this.pushEvent("data", {})
            })
    }

    viewVideoForm(item, formRef, onFinish = null) {
        const formItemLayout = {
            labelCol: {
                xs: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 4,
                    offset: 0,
                },
                sm: {
                    span: 4,
                    offset: 8,
                },
            },
        };

        return <Form form={formRef} {...formItemLayout} onFinish={onFinish}>
            <Form.Item name="name" initialValue={item.name} label="标题" rules={[{ required: true }]}>
                <Input size="small" />
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::uploader"]} initialValue={item.labels.uploader} label="上传者" rules={[{ required: true }]}>
                <Input size="small" />
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::playback"]} initialValue={item.labels.playback} label="播放次数" rules={[{ required: true }]}>
                <InputNumber min={0} size="small" />
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::duration"]} initialValue={item.labels.duration} label="时长" rules={[{ required: true }]}>
                <Input size="small" />
            </Form.Item>
            <Form.Item name="app_version_code" initialValue={item.app_version_code} label="APP最低版本" rules={[{ required: true }]} help="低于此版本的APP，看不到本视频">
                <Select size="small">
                    <Select.Option value={56}>v2.6.4</Select.Option>
                    <Select.Option value={58}>v2.6.6</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="permission" initialValue={item.permission} label="权限" rules={[{ required: true }]}>
                <Select size="small">
                    <Select.Option value="free">免费</Select.Option>
                    <Select.Option value="vip">会员</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="state" initialValue={item.state} label="状态" rules={[{ required: true }]}>
                <Select size="small" >
                    <Select.Option value="draft">草稿</Select.Option>
                    <Select.Option value="publish">发布</Select.Option>
                    <Select.Option value="complain">举报下架</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::ip_belongs"]} initialValue={item.labels.ip_belongs} label="IP归属地" rules={[{ required: true }]}>
                <Input size="small" placeholder="比如：海南省、广东省" />
            </Form.Item>
            {!!onFinish && <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" size="small">
                    保存
                </Button>
            </Form.Item>}

        </Form>
    }

    render() {
        const data = this.state.data;


        return <div className="page resource-video-course-editor">
            {data.root && <div className="item-article">
                <div className="title-line">
                    <span className="title">{data.root.name}</span>
                    <Button type="link" onClick={() => this.setState({ showEditAlbum: true })}>编辑</Button>
                </div>

                <div className="meta-line">
                    {/* <span className="status"> {item.status === "active" ? "激活" : "禁用"} </span> */}
                    <span className="publishAt">
                        <CalendarOutlined /> <LocalTimeAgo date={data.root.inserted_at} /> </span>
                    <span className="view-sum">
                        <EyeOutlined /> {data.root.labels.lesson_count} 节 </span>
                    <span className="view-sum">
                        <UserOutlined /> {data.root.labels.author} </span>
                    <span className="state"> {this.stateTranslate(data.root.state)} </span>
                    <span className="permission"> {this.permissionTranslate(data.root.permission)} </span>
                    {
                        data.root.permission === "purchase" && <>
                            <span className="purchased_count">销售数量 {data.root.purchased_count || 0} </span>
                            <span className="purchased_amount">销售金额 {(data.root.purchased_amount / 100) || 0} </span>
                        </>
                    }
                </div>

                <div className="content" style={{ display: "flex" }}>
                    <div style={{ flexGrow: 1 }}>
                        {data.root.labels.summary}
                    </div>
                    <div>
                        <Upload
                            name="cover"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={(obj) => this.handleAlbumCoverUpload(obj)}
                        >
                            {data.root.labels.cover ? <img src={data.root.labels.cover} alt="avatar" style={{ maxWidth: '100%', maxHeight: "100%" }} /> : <div>
                                <div style={{ marginTop: 8 }}>上传封面</div>
                            </div>}
                        </Upload>
                    </div>
                </div>
            </div>}
            <Tabs >
                <TabPane tab="视频" key="1">
                    <div className="actions" style={{ display: "flex", marginBottom: 10 }}>
                        <Button icon={<PlusSquareTwoTone />} type="link" onClick={() => this.setState({ showCreateVideo: true })}>
                            新增单个视频
                        </Button>
                    </div>

                    {!!data.children && <>
                        <List dataSource={data.children}
                            grid={{ gutter: 16, column: 2 }}
                            itemLayout="vertical"
                            size="large"
                            renderItem={item => (
                                <List.Item key={item.id} className="item-article"
                                    extra={<div>
                                        <div className="video-upload">
                                            <UploadComponent path={["video", "course", data.root.name].join("/")} bucket="acupoint-resource" maxCount={1} onChange={(fileList) => this.handleUploadSuccess(item.id, fileList)}>
                                                <Button icon={<UploadOutlined />}>单独上传视频</Button>
                                            </UploadComponent>
                                            {/* <Upload {...this.uploadConfig(item.id, data.root.name)}>
                                            </Upload> */}
                                        </div>

                                        {item.labels.mp4 ? <video controls height={180} width={240} preload="none">
                                            <source src={item.labels.mp4.url} type="video/mp4"></source>
                                        </video> : null}
                                    </div>
                                    }>

                                    <div className="title-line">
                                        <span className="title">{item.index} {item.name}</span>
                                    </div>
                                    <div className="content">
                                        {this.viewVideoForm(item, undefined, (values) => this.handleVideoEdit(item.id, values))}
                                    </div>
                                </List.Item>
                            )}
                        />
                    </>}

                    <Modal title="新增视频" open={this.state.showCreateVideo} footer={false} onCancel={() => this.setState({ showCreateVideo: false })}>
                        {this.modalForm()}
                    </Modal>


                    {this.state.cateTreeData.length > 0 &&
                        <Modal title="编辑课程" open={this.state.showEditAlbum} footer={false} onCancel={() => this.setState({ showEditAlbum: false })}>
                            <AlbumForm album={this.state.data.root} cateTree={this.state.cateTreeData} channel={this.state.channel} closeModal={() => this.setState({ showEditAlbum: false })} />
                        </Modal>}
                </TabPane>
                <TabPane tab="批量上传" key="2">
                    {data?.root?.id && <MultiUploadForm albumID={data.root.id} albumName={data.root.name} onUploadFinished={() => this.pushEvent("data", {})} />}
                </TabPane>
                <TabPane tab="反馈" key="3">
                    <List dataSource={data.reports}
                        grid={{ gutter: 16, column: 3 }}
                        itemLayout="horizontal"
                        renderItem={item => (
                            <List.Item key={`report-${item.id}`}>
                                <List.Item.Meta
                                    avatar={<UserAvatar nickname={item.user.nickname} size={40} avatar={item.user.avatar} id={item.user.id} vip={item.user.group === "vip"} />}
                                    title={<span>{dayjs(item.inserted_at).add(8, 'hour').format("YYYY-MM-DD HH:mm")} <LocalTimeAgo date={dayjs(item.inserted_at).add(8, 'hours').format("YYYY-MM-DD HH:mm")} /></span>}
                                    description={item.content}
                                />
                            </List.Item>
                        )}
                    />
                </TabPane>
            </Tabs>

        </div>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        user: global.user,
        jsonRequest: global.jsonRequest,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VideoCourseEditor)