import React, { useEffect, useState } from "react"
import { Button, List } from "antd"
import { } from '@ant-design/icons'
import UserAvatar from "../../../components/user/UserAvatar";
import dayjs from "dayjs"
import { Divider } from "antd"
import { ClockCircleTwoTone, SelectOutlined, LinkOutlined } from "@ant-design/icons"
import { auditModal } from "../AuditModal"
import { Link } from "react-router-dom";

function PostListItem({ item, channel, simulatorChannel }) {
    const viewDetail = (report) => {
        auditModal(channel, simulatorChannel, report)
    }

    let linkPath = "";

    let review_result = null;
    switch (item.review_result) {
        case "compliant":
            review_result = <span style={{ color: "green" }}>符合规定</span>
            break;
        case "violation":
            review_result = <span style={{ color: "red" }}>违规</span>
            break;
        default:
            break;
    }


    switch (item.reference.type) {
        case "answer":
            linkPath = `/community/simulator/question/${item.reference.parent_id}/answer/${item.reference.id}`
            break;
        case "question":
            linkPath = `/community/simulator/question/${item.reference.id}`
            break;

        default:
            break;
    }

    return <List.Item style={{ borderBottom: "1px solid #eee", paddingBottom: 10 }} key={item.id} actions={[<Button onClick={() => viewDetail(item)} type="link" size="small" icon={<SelectOutlined />}>查看并审核</Button>]}>
        <List.Item.Meta avatar={<UserAvatar nickname={item.owner_name} size={40} avatar={item.owner_avatar} id={item.owner_id} />}
            title={<span><span style={{ color: "orange" }}>[{item.type}]</span> <Link target="_blank" to={linkPath}><span>{item.reference?.title}<LinkOutlined /></span></Link></span>}
            description={<span>
                <span>累计被举报数：{item?.reference?.report_count}</span> <Divider type="vertical" />
                <span>阅读数：{item?.reference?.view_count}</span> <Divider type="vertical" />
                <span>赞同数：{item?.reference?.like_count}</span>
            </span>} />
        <div style={{ background: "#fafafa", padding: "10px 10px 20px 10px" }}>
            <p>
                <span style={{ color: "#1890ff" }}><ClockCircleTwoTone /> {dayjs(item.inserted_at).format("YYYY-MM-DD HH:mm")}</span> 举报
            </p>
            <div style={{ textIndent: "2em" }}>
                <div>{item.content || "[无内容]"}</div>
            </div>

            {item.status == "settle" && <>
                <p >
                    <span style={{ color: "#1890ff" }}><ClockCircleTwoTone /> {dayjs(item.upadted_at).format("YYYY-MM-DD HH:mm")}</span> 审核结果：{review_result}
                </p>
                <div style={{ textIndent: "2em" }}>
                    <div>{item.review_content || "[无内容]"}</div>
                </div>
            </>}
        </div>
    </List.Item>
}


export default PostListItem;