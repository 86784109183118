import { Select } from "antd";
import { Form, Button, Radio } from "antd"
import { useEffect } from "react";


function QueryForm(props) {
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue(props.values)
    }, [])

    return <div>
        <Form form={form} autoComplete="off" layout="inline" onFinish={props.onSearch} initialValues={props.values}>
            <Form.Item name="type" label="举报类型">
                <Select style={{ minWidth: 150 }}>
                    <Select.Option value="">全部</Select.Option>
                    <Select.Option value="违法违规">违法违规</Select.Option>
                    <Select.Option value="垃圾广告">垃圾广告</Select.Option>
                    <Select.Option value="虚假不实">虚假不实</Select.Option>
                    <Select.Option value="侵权投诉">侵权投诉</Select.Option>
                    <Select.Option value="引人不适">引人不适</Select.Option>
                    <Select.Option value="谩骂攻击">谩骂攻击</Select.Option>
                    <Select.Option value="扰乱社区秩序">扰乱社区秩序</Select.Option>
                    <Select.Option value="其他">其他</Select.Option>
                </Select>
            </Form.Item>
            {/* <Form.Item name="status" label="状态">
                <Radio.Group buttonStyle="solid">
                    <Radio.Button value="pending">待审核</Radio.Button>
                    <Radio.Button value="settle">已审核</Radio.Button>
                </Radio.Group>
            </Form.Item> */}
            <Button.Group>
                <Button type="primary" htmlType="submit">查询</Button>
            </Button.Group>
        </Form>
    </div>
}

export default QueryForm;