import React from "react";
import { List, Modal, message } from 'antd';
import 'jsoneditor-react/es/editor.min.css';
import HistoryItem from "../components/history_item"

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Menu as MenuAction } from '../../store/global/actions'
// import LocalTimeAgo from "../../components/local_time_ago";
import { connect } from "react-redux";

class History extends React.Component {
    state = {
        channel: null,
        data: {}
    }

    componentDidMount() {
        this.props.setActiveKey("PUSH")
        const channel = this.props.socket.channel("jpush:history")
        channel.on("history-data", ({ data }) => this.setState({ data }))
        channel.join()
        this.setState({ channel })
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    pushEvent(event, params, opts = null) {
        return this.state.channel.push(event, params)
    }

    tableChange = (page, size) => {
        this.pushEvent("page-to", { page_size: size, page: page })
    }

    onFinish = (values) => {
        this.pushEvent("query", values)
    }

    onReset = () => {
        this.pushEvent("query-reset", {})
    }

    // 加载推送送达的详情
    handleReportReload(transaction_id) {
        this.pushEvent("report-reload", { transaction_id: transaction_id }).receive("ok", () => message.success("已刷新"))
    }

    // 重新推送一次
    handleReplay(transaction_id) {
        Modal.confirm({
            title: '确定重新推送一次？',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                this.pushEvent("replay", { transaction_id: transaction_id })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    // 存成模板
    handleSaveTemplate(transaction_id, name) {
        this.pushEvent("save-template", { transaction_id: transaction_id })
    }

    render() {
        const data = this.state.data;

        return <>
            <h1>推送历史</h1>
            {
                !!data.entries &&
                <List
                    grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 3, }}
                    dataSource={data.entries}
                    pagination={{
                        onChange: (page, size) => { this.tableChange(page, size) },
                        total: data.total_entries,
                        current: data.page_number,
                        pageSize: data.page_size,
                        showQuickJumper: true
                    }}
                    renderItem={item => <HistoryItem channel={this.state.channel} item={item} />}
                />}
        </>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(History)