import useLocalStorageState from "use-local-storage-state"
import { List, Slider } from 'antd';
import { useEffect } from "react";


function ListGrid({ onHeightEdit = () => null, ...props }) {
    // const [grid, setGrid] = useLocalStorageState("/cms/list-grid", { gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 4 })
    const [grid, setGrid] = useLocalStorageState("/cms/list-grid", { gutter: 16, column: 4 })
    const [height, setHeight] = useLocalStorageState("/cms/list-grid/media/height", 500)

    useEffect(() => {
        onHeightEdit(height)
    }, [])

    const onColSliderChange = (value) => {
        // 计算最小的列
        // const minFunc = (step) => value - step > 1 ? value - step : 1
        // setGrid({ gutter: 16, xs: minFunc(3), sm: minFunc(2), md: minFunc(12), lg: minFunc(1), xl: minFunc(1), xxl: value })
        setGrid({ gutter: 16, column: value })
    }

    const handleHeightChange = (v) => {
        setHeight(v)
        onHeightEdit(v)
    }

    return <div >
        <div style={{ padding: "0 20px" }}>
            <Slider value={grid.column} max={4} min={1} marks={{
                1: '每行1列',
                2: '每行2列',
                3: '每行3列',
                4: '每行4列',
            }} onChange={onColSliderChange} />

            <Slider value={height} max={1000} min={400} onChange={handleHeightChange} />
        </div>
        <List grid={grid} {...props} />
    </div>
}

export default ListGrid