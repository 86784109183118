import { connect } from "react-redux";
import { Menu as MenuAction } from '../store/global/actions'
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Radio, Divider, Alert, message } from "antd";
import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";

function GPTPrompts(props) {
    const [channel, setChannel] = useState(null)
    const [prompts, setPrompts] = useState([])
    const [testLoading, setTestLoading] = useState(false)
    const [testResults, setTestResults] = useState([])
    const [externalTestMessages, setExternalTestMessages] = useState([])

    const [form] = Form.useForm()
    const [testForm] = Form.useForm()

    const testMessagesBottomRef = React.createRef()

    useEffect(() => {
        props.setActiveKey("Online")

        let _channel = props.socket.channel("ops:online:customer-service:gpt-prompts", {})
        _channel.join()

        _channel.on("data", (data) => {

            let prompts = data.messages.map(prompt => ({ role: prompt.role, content: prompt.content }));
            setPrompts(prompts)
        })

        _channel.on("external-test-messages", ({ messages }) => {
            setExternalTestMessages(messages)
        })

        setChannel(_channel)
        return () => {
            !!_channel && _channel.leave()
        }
    }, [])

    // 点击添加提示
    const handleClickAddBlock = (index) => {
        const formCurrentValues = Object.values(form.getFieldsValue())
        formCurrentValues.splice(index + 1, 0, { role: "system", content: "" })

        const _prompts = [...formCurrentValues]
        setFormState(_prompts)
        setPrompts(_prompts)
    }

    // 点击删除提示
    const handleClickRemoveBlock = (index) => {
        const formCurrentValues = Object.values(form.getFieldsValue())
        formCurrentValues.splice(index, 1)
        const _prompts = [...formCurrentValues]

        setFormState(_prompts)
        setPrompts(_prompts)
    }

    const setFormState = (prompts) => {
        let _prompts = {}
        prompts.forEach((item, i) => _prompts[i] = item)

        const currentValues = form.getFieldsValue()
        form.setFieldsValue({ ..._prompts })
    }

    const handleFormSubmit = (values) => {
        channel.push("save", { messages: [...Object.values(values)] })
            .receive("ok", () => {
                message.success("保存成功")
            })
    }

    // 测试
    const handleTest = (values) => {
        setTestLoading(true)
        // 重制表单
        testForm.resetFields()
        // 设置表单的值
        const currentValues = form.getFieldsValue()
        const message = { role: "user", content: values.content }

        testResults.push(message)
        setTestResults([...testResults])
        setTimeout(() => {
            document.querySelector("#messages-bottom").scrollIntoView({ behavior: "smooth" })
        }, 200);

        channel.push("test", { messages: [...Object.values(currentValues), ...testResults] })
            .receive("ok", ({ data }) => {
                testResults.push({ content: data, role: "assistant" })
                setTestResults([...testResults])
                setTestLoading(false)

                setTimeout(() => {
                    document.querySelector("#messages-bottom").scrollIntoView({ behavior: "smooth" })
                }, 500);
            })
    }

    const roleToText = (role) => {
        switch (role) {
            case "assistant":
                return "智能客服"
            case "user":
                return "我"
            case "system":
                return "系统"
        }
    }

    return <div className="page pricing" style={{ display: "flex", flexDirection: "column" }}>
        <h1>智能客服提示</h1>
        <div style={{ display: "flex", gap: 20, flex: 1, overflowY: "auto" }}>
            <div style={{ width: "50%" }}>
                <Divider>设置</Divider>
                <Form form={form} onFinish={handleFormSubmit} name="basic" autoComplete="off" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} >
                    <div style={{ maxHeight: 600, marginBottom: 20, overflowY: "scroll" }}>
                        {
                            prompts.map((item, index) => {
                                return <div key={`group-${index}`}>
                                    <Form.Item key={`${index}-role`} name={[index, "role"]} label={<h3># {index + 1} 角色</h3>} initialValue={item.role} rules={[{ required: true, message: '必填' }]}>
                                        <Radio.Group size="small">
                                            <Radio value="system">系统</Radio>
                                            <Radio value="assistant">客服</Radio>
                                            <Radio value="user">用户</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item key={`${index}-content`} name={[index, "content"]} label="内容" initialValue={item.content} rules={[{ required: true, message: '必填' }]}>
                                        <Input.TextArea size="small" autoSize style={{ maxHeight: "20vh" }} onChange={e => { e.target.value }} />
                                    </Form.Item>
                                    <Form.Item wrapperCol={{ span: 16, offset: 4 }}>
                                        <Button.Group style={{ width: "100%" }}>
                                            <Button disabled={prompts.length <= 1} size="small" onClick={() => handleClickRemoveBlock(index)} block icon={<MinusSquareOutlined></MinusSquareOutlined>}>删除此提示</Button>
                                            <Button size="small" onClick={() => handleClickAddBlock(index)} block icon={<PlusSquareOutlined></PlusSquareOutlined>}>后面追加提示</Button>
                                        </Button.Group>
                                    </Form.Item>
                                </div>
                            })
                        }
                    </div>
                    <Form.Item wrapperCol={{ span: 16, offset: 4 }}>
                        <Button htmlType="submit" type="primary" block>保存</Button>
                    </Form.Item>
                </Form>
            </div>
            <div style={{ width: "50%" }}>
                <Divider>测试</Divider>
                <Form form={testForm} onFinish={handleTest} name="basic" autoComplete="off" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} >
                    <Form.Item wrapperCol={{ span: 16, offset: 4 }}>
                        <Alert showIcon message="不用保存也可测试，会结合左边的提示发起询问"></Alert>
                    </Form.Item>
                    <div style={{ overflowY: "scroll", maxHeight: 500 }}>
                        {
                            externalTestMessages.map(({ role, content }) => {
                                return <Form.Item label={roleToText(role)}>
                                    <pre style={{ margin: 0, whiteSpace: "pre-wrap" }}>{content}</pre>
                                </Form.Item>
                            })
                        }
                        {
                            testResults.map(({ role, content }) => {
                                return <Form.Item label={roleToText(role)}>
                                    {content}
                                </Form.Item>
                            })
                        }
                        <div id="messages-bottom" ref={testMessagesBottomRef}></div>
                    </div>
                    <Form.Item key={`test-content`} name="content" label="内容" rules={[{ required: true, message: '必填' }]}>
                        <Input.TextArea size="small" autoSize style={{ minHeight: "10vh" }} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 16, offset: 4 }}>
                        <Button htmlType="submit" type="primary" loading={testLoading}>发送</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    </div>
}



const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        user: global.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GPTPrompts)