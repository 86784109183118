

import { Table, Form, Progress } from "antd";
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import dayjs from "dayjs";
import UserAvatar from "../components/user/UserAvatar"

function TechOperationLog(props) {

    const [channel, setChannel] = useState(null)
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let _channel = props.socket.channel("tech:operation:log", { page: 1, size: 15 })
        _channel.join()

        _channel.on("data", (data) => {
            setLoading(false)
            setData(data)
        })

        setChannel(_channel)
        return () => {
            !!_channel && _channel.leave()
        }
    }, [])

    const handlePageTo = (page, size) => {
        channel.push("page-to", { page, size })
    }

    const operateTypeLabel = (type) => {
        switch (type) {
            case "content":
                return '内容'
            case "membership":
                return '用户'
            case "finance":
                return '财务'
            case "community":
                return '社区'
            case "other":
                return '其他'
            default:
                return type
        }
    }


    return <div className="page coin-code-table">
        <h1>操作日志</h1>

        <div className="content">
            <Table dataSource={data?.entries} loading={loading}
                scroll={{ y: document.documentElement.clientHeight - 350 }}
                pagination={{
                    onChange: (page, size) => { handlePageTo(page, size) },
                    total: data.total_entries,
                    current: data.page_number,
                    pageSize: data.page_size,
                    showQuickJumper: true
                }}>
                <Table.Column align="center" width={200} title="操作时间" dataIndex="inserted_at" key="inserted_at" render={(v) => !!v && dayjs(v).format("YYYY-MM-DD HH:mm")} />
                <Table.Column align="center" width={100} title="操作人员" dataIndex="nickname" key="nickname" render={(v, item) => <UserAvatar nickname={item.nickname || item.operate_id} size={40} avatar={null} id={item.operate_id} />} />
                <Table.Column align="center" width={120} title="被操作用户" dataIndex="reference_user" key="reference_user" render={(v) => !!v && <UserAvatar nickname={v.nickname} size={40} avatar={v.avatar} id={v.id} />} />
                <Table.Column align="left" width={150} title="操作类型" dataIndex="type" key="type" render={(t) => operateTypeLabel(t)} />
                <Table.Column align="left" title="操作内容" dataIndex="content" key="content" />
            </Table>
        </div>
    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TechOperationLog);