
import config from '../config'

class JsonRequest {
    static tokenClient(token) {
        return new JsonRequest({ Authorization: `Bearer ${token}` })
    }

    headers = { 'Content-Type': 'application/json' }

    constructor(headers = {}) {
        this.headers = { ...headers, ...this.headers }
    }

    request(path, method, values, headers = {}) {
        const requestOptions = { method: method, headers: { ...headers, ...this.headers } };
        switch (method) {
            case "GET":
            case "DELETE":
                path = `${path}?${new URLSearchParams(values).toString()}`
                break;
            case "POST":
            case "PATCH":
            case "PUT":
                requestOptions["body"] = JSON.stringify(values);
                break;
            case "FILE":
                requestOptions["body"] = values;
                requestOptions["method"] = "POST"
                delete requestOptions["headers"]["Content-Type"]
                break;
            default:
                break;
        }
        return fetch(config.HttpEndpoint(path), requestOptions)
    }

    post(path, obj, headers = {}) {
        return this.request(path, "POST", obj, headers)
    }

    get(path, obj, headers = {}) {
        return this.request(path, "GET", obj, headers)
    }

    put(path, obj, headers = {}) {
        return this.request(path, "PUT", obj, headers)
    }

    delete(path, obj, headers = {}) {
        return this.request(path, "DELETE", obj, headers)
    }

    upload(path, file, obj, headers = {}) {
        var data = new FormData()
        data.append('cover', file)
        for (const key in obj) {
            data.append(key, obj[key])
        }

        return this.request(path, "FILE", data, headers)
    }

    file(path, formData, headers = {}) {
        return this.request(path, "FILE", formData, headers)
    }
}

export default JsonRequest