import React, { useState } from "react";
import { List, Switch, Button, Popover, message, Modal, Upload, TreeSelect, Divider, Table, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { Menu as MenuAction } from '../../../store/global/actions'

import { RocketOutlined, PlusSquareTwoTone, UploadOutlined, EditTwoTone, FileImageTwoTone, CheckCircleTwoTone, DeploymentUnitOutlined } from '@ant-design/icons';

import MediaViewer from "../../components/media_editor";
import QueryForm from "./query_form";
import iconSettle from "../../components/media_editor/icon_settle";

import DraftForm from "./form";
import PasswordConfirm from "../../../components/passwordConfirm";
import ListGrid from "../../components/ListGrid";

const { TreeNode } = TreeSelect;
const { Column } = Table;

class Index extends React.Component {

    formRef = React.createRef();

    state = {
        channel: null,
        data: {},
        editable: false,
        loading: false,
        query: { name: this.props.match?.params?.name },
        cateTree: [],
        editorHeight: 500,
    }

    joinChannel(props) {
        this.setState({ loading: true })
        const channel = props.socket.channel("ops:resource:term:ancient_book", { page_size: 4, ...this.state.query })
        channel.on("data", ({ data }) => this.setState({ data, loading: false }))

        channel.join()

        // channel.push("cate-tree", {})
        //     .receive("ok", (tree) => this.setState({ cateTree: tree }))
        this.setState({ channel })
    }

    componentDidMount() {
        this.props.setActiveKey("TERM-AncientBook")
        this.joinChannel(this.props)
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        !!this.state.channel && this.state.channel.off("data")
        !!this.state.channel && this.state.channel.leave()

        this.setState({ query: { ...this.state.query, name: nextProps.match?.params?.name } }, () => this.joinChannel(nextProps))

    }

    cateTreeToSelectOption(tree) {
        tree = tree || []

        if (tree.length > 0) {
            return tree.map(({ id, name, children }) => {
                return <TreeNode key={id} value={id} title={name}>{this.cateTreeToSelectOption(children)}</TreeNode>
            })
        } else {
            return null
        }
    }

    // 处理点击是否发布
    handleClickPublish(acupointID) {
        this.state.channel.push("publish!", { id: acupointID }).receive("ok", () => message.success("发布成功"))
    }

    handleClickAdd() {
        let formInstance = null
        const channel = this.state.channel
        Modal.confirm({
            width: 600,
            title: '添加新典籍',
            content: <DraftForm setForm={(form) => formInstance = form} channel={this.state.channel} />,
            onOk: (close) => {
                formInstance
                    .validateFields()
                    .then(values => {
                        channel?.push("add-draft", { ...values })
                            .receive("ok", (resp) => {
                                message.success("创建成功，在列表中编辑")
                                this.handleSearch({ name: resp.name })
                                close()
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    editBasicInfo(initial) {
        const channel = this.state.channel
        let formInstance = null
        Modal.confirm({
            width: 600,
            title: '修改基础信息',
            content: <DraftForm disableIcon initial={initial} channel={this.state.channel} setForm={(form) => formInstance = form} cateTree={this.cateTreeToSelectOption(this.state.cateTree)} />,
            onOk: (close) => {
                formInstance
                    .validateFields()
                    .then(values => {
                        channel.push("edit", { id: initial.id, ...values })
                            .receive("ok", (resp) => {
                                close()
                                message.success("修改成功")
                                this.handleSearch({ name: resp.name })
                                formInstance.resetFields();
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    handlePageTo(page, size) {
        this.setState({ loading: true })
        this.state.channel.push("page-to", { page, size })
            .receive("ok", () =>
                this.setState({ loading: false }))
    }

    handleSearch(values) {
        this.setState({ loading: true, query: values })
        this.state.channel.push("search", values)
            .receive("ok", () =>
                this.setState({ loading: false }))
    }


    // 类别展示
    typeRender(type) {
        switch (type) {
            case "general":
                return <span style={{ color: "#aaa" }}>常用食物</span>

            default:
                break;
        }
    }

    // img 修改表单
    imageForm(item) {
        return <div>
            <div><img alt="" src={item.icon} width={200}></img></div>
            {this.state.editable && <Upload name="avatar"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={(file) => iconSettle(file, (result) => {
                    this.state.channel.push("edit-icon", { id: item.id, icon: result })
                        .receive("ok", () => message.success('修改成功'))
                })}>
                <Button icon={<UploadOutlined />} style={{ width: 200 }} block>点击更换</Button>
            </Upload>
            }
        </div>
    }

    // 处理点击是否删除
    handleClickDrop(acupointID) {
        this.state.channel.push("drop!", { id: acupointID }).receive("ok", () => message.success("删除成功"))
    }

    exportPasswordConfirmRef = React.createRef()
    handleClickExport() {
        PasswordConfirm({
            ref: this.exportPasswordConfirmRef,
            title: '密码确认',
            message: "导出数据会被记录在操作日志里，请谨慎操作。",
            onOk: ({ password }, close) => {
                message.loading({ content: '请求数据中...', key: "exporting", duration: 0 })
                this.state.channel.push("export-from-filter", { password }, 100000)
                    .receive("ok", ({ encrypt_str }) => {
                        navigator.clipboard.writeText(encrypt_str)
                            .then(() => {
                                message.success({ content: '已复制到剪切板中', key: "exporting", duration: 3 })
                                close()
                            })
                            .catch(() => {
                                message.error({ content: '写入剪切板错误', key: "exporting", duration: 3 })
                                navigator.clipboard.writeText("")
                            })

                    })
            }
        })
    }

    handleClickImport() {
        PasswordConfirm({
            ref: this.exportPasswordConfirmRef,
            title: '密码确认',
            message: "导入数据会被记录在操作日志里，请谨慎操作。",
            onOk: ({ password }, close) => {
                message.loading({ content: '导入中...', key: "importing", duration: 3 })
                navigator.permissions.query({ name: 'clipboard-read' })
                    .then(result => {
                        if (result.state === "granted" || result.state === "prompt") {
                            navigator.clipboard.readText()
                                .then(text => {
                                    this.state.channel.push("import-from-encrypt-str", { str: text, password: password }, 100000)
                                        .receive("ok", ({ review_data }) => {
                                            close()
                                            this.handleClickImportConfirm(review_data)
                                            message.warn({ content: '确认导入数据', key: "importing", duration: 3 })

                                            navigator.clipboard.writeText("")
                                        })
                                })
                                .catch(err => {
                                    console.log(err)
                                    message.error({ content: '读入剪切板错误', key: "importing", duration: 3 })
                                    navigator.clipboard.writeText("")
                                });
                        }
                    })
            }
        })
    }

    handleClickImportConfirm(reviewData) {
        Modal.confirm({
            title: '预览导入数据',
            width: 600,
            content: <Table dataSource={reviewData}>
                <Column title="名称" dataIndex="name" key="name"></Column>
                <Column title="数据库中存在(将忽略)" align="center" dataIndex="exists" key="exists" render={(value) => value && <CheckCircleTwoTone />}></Column>
            </Table>,
            onOk: (close) => {
                this.state.channel.push("import-confirm", {}, 100000)
                    .receive("ok", () => {
                        message.success({ content: '已导入', key: "importing", duration: 3 })
                        close()
                    })
                return false
            },
            okText: "确认导入"
        });
    }

    // 添加到图谱推荐表
    handleClickAddToGraphSuggest(id, name) {
        this.state.channel.push("add-to-graph-suggest", { content_type: `term_ancient_book`, content_id: id, content_name: name })
            .receive("ok", () => message.success("添加到图谱推荐列表成功"))
    }

    render() {
        const cateTree = this.cateTreeToSelectOption(this.state.cateTree)
        return <div className="page resource-herb">
            {this.state?.channel &&
                <div className="actions" style={{ display: "flex" }}>
                    <Button icon={<PlusSquareTwoTone />} type="link" onClick={() => this.handleClickAdd()}>
                        新增典籍
                    </Button>
                </div>
            }
            <h1>典籍列表
                <Divider type="vertical" />
                <Switch onChange={checked => this.setState({ editable: checked })} checkedChildren="开启编辑" unCheckedChildren="只读" />
            </h1>

            <div>
                <QueryForm cateTree={cateTree} onSearch={this.handleSearch.bind(this)} values={this.state.query}>
                    {this.state.editable && <>
                        <Divider type="vertical" />
                        <Button.Group>
                            <Button onClick={this.handleClickImport.bind(this)}  >导入</Button>
                            <Button onClick={this.handleClickExport.bind(this)} danger>导出筛选结果</Button>
                        </Button.Group>
                    </>}
                </QueryForm>
            </div>

            <br />
            {
                this.state.data.entries &&
                <ListGrid
                    dataSource={this.state.data.entries}
                    loading={this.state.loading}
                    rowKey={d => d.name}
                    onHeightEdit={(height) => this.setState({ editorHeight: height })}
                    pagination={{
                        onChange: (page, size) => { this.handlePageTo(page, size) },
                        total: this.state.data.total_entries,
                        current: this.state.data.page_number,
                        pageSize: this.state.data.page_size,
                        showQuickJumper: true
                    }}
                    renderItem={item => (
                        <List.Item className="item-herb">
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <small style={{ fontSize: 12, color: "#888" }}>{item.py}</small>
                                    <span style={{ fontSize: 20, fontWeight: "bold" }}>
                                        {item.name}
                                    </span>
                                </div>

                                <span>
                                    <Tooltip title="添加到图谱推荐表">
                                        <Button type="link" icon={<DeploymentUnitOutlined />} onClick={() => this.handleClickAddToGraphSuggest(item.id, item.name)} ></Button>
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Popover content={this.imageForm(item)}>
                                        {item.icon ? <img alt="" src={item.icon} height="30" width="30"></img> : <FileImageTwoTone />}
                                    </Popover>

                                    {this.state.editable && <span>
                                        <Divider type="vertical" />
                                        <Button type="link" icon={<EditTwoTone />} onClick={() => this.editBasicInfo(item)}></Button>
                                        {item.has_update &&
                                            <>
                                                <Divider type="vertical" />
                                                <Popover content={<Button onClick={() => this.handleClickPublish(item.id)} block type="primary" size="small">确认</Button>} title="确认发布？" trigger="click">
                                                    <Button type="link" icon={<RocketOutlined />}></Button>
                                                </Popover>
                                            </>}
                                    </span>}
                                </span>
                            </div>
                            <div>
                                <MediaViewer ownerType="Elixir.Acupoint.Model.STD.Term.AncientBook" hasUpdate={item.has_update} ownerID={item.id} channel={this.state.channel} name={item.name} detail={item.detail} type="herb" editable={this.state.editable} height={this.state.editorHeight} />
                                {this.state.editable && <Popover trigger="click" placement="top" content={<Button onClick={() => this.handleClickDrop(item.id)} danger size="small" type="primary">确认删除</Button>} ><Button block type="primary" size="small">删除</Button></Popover>}

                            </div>
                        </List.Item>
                    )
                    } />
            }
        </div >
    }
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);