import React from "react";
import { Menu, Tag } from 'antd';
import { ProjectTwoTone, CommentOutlined, FileTwoTone } from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";


export default function SideMenu() {

    const location = useLocation()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")

    return <Menu style={{ width: 256 }} defaultSelectedKeys={[pathName]} mode="inline" >
        <Menu.ItemGroup key="question" title="APP在线">
            <Menu.Item key="/online/app-user" icon={<ProjectTwoTone />}>
                <Link to="/online/app-user">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>用户列表</span>
                        <span><Tag color="blue">实时</Tag></span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
        <Menu.Item key="/online/customer-service" icon={<CommentOutlined />}>
            <Link to="/online/customer-service">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>在线客户服务</span>
                    <span><Tag color="blue">实时</Tag></span>
                </div>
            </Link>
        </Menu.Item>
        <Menu.Item key="/online/gpt-prompts" icon={<FileTwoTone />}>
            <Link to="/online/gpt-prompts">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>智能客服提示</span>
                </div>
            </Link>
        </Menu.Item>

{/* 
        <Menu.Item key="/online/chatgpt" icon={<CommentOutlined />}>
            <a href="https://cg.lighttcm.com" target="_blank">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>ChatGPT</span>
                    <span><Tag color="orange">Alpha</Tag></span>
                </div>
            </a>
        </Menu.Item> */}

        {/* <Menu.ItemGroup key="question" title="题库">
            <Menu.Item key="Elixir.AcupointWeb.SQEC.QuestionListLive" onClick={() => linkTo("/sqec/question/list")} icon={<ProjectTwoTone />}>列表</Menu.Item>
            <Menu.Item key="Elixir.AcupointWeb.SQEC.CorrectionListLive" onClick={() => linkTo("/sqec/correction/list")} icon={<HighlightTwoTone />}> 修订反馈</Menu.Item>
        </Menu.ItemGroup> */}
    </Menu >
}