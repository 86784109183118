import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import React from 'react'

export default function GlobalBreadcrumb(props) {
    let crumbs = props["crumbs"] || []
    const latest = crumbs[crumbs.length - 1]
    crumbs = crumbs.splice(0, crumbs.length - 1)

    return <Breadcrumb>
        <Breadcrumb.Item href="/">
            <HomeOutlined /> 中济经络
        </Breadcrumb.Item>
        {!!latest && <>
            {crumbs.map(i => {
                return <Breadcrumb.Item href={i.href} key={i.name}>
                    <span>{i.name}</span>
                </Breadcrumb.Item>
            })}
            <Breadcrumb.Item>
                {latest["name"]}
            </Breadcrumb.Item>
        </>
        }
    </Breadcrumb>
}