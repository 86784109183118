import JsonRequest from '../../request/json_request'

const user = JSON.parse(localStorage.getItem("sign-in-user") || "null")
const initialState = {
    socket: null,
    // 当前用户
    user: user,
    // 导航栏当前激活的key
    navActiveKey: null,
    // 客服服务频道
    customerOperationChannel: null,
    // 客服服务的当前会话
    currentConversationCustomerID: null,
    // 通知频道实例
    notificationChannel: null,
    // 用户在线频道实例
    appOnlineChannel: null,
    // 用户运维频道实例
    userOpsChannel: null,
    // 全局频道实例
    globalChannel: null,
    // 推送频道
    pushChannel: null,
    // json请求客户端
    jsonRequest: JsonRequest.tokenClient(user?.token),
    // 在线用户数据
    onlineUsers: {},
    // 用户信息目前展示得用户id
    userInformationShowingUserID: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // 菜单切换
        case "MenuSetActive":
            return { ...state, navActiveKey: action.key }
        // 服务器的socket连接
        case "GlobalSocket":
            return { ...state, socket: action.socket }
        // 登录
        case "Session:SignedIn":
            return { ...state, user: action.user, jsonRequest: JsonRequest.tokenClient(action.user.token) }
        // 退出
        case "Session:LogOut":
            return { ...state, user: null, jsonRequest: new JsonRequest() }
        // 在线用户设置
        case "Online:User":
            return { ...state, onlineUsers: action.users }
        // 更新用户当前路由
        case "Online:User:Route:Update":
            let cloneOnlineUsers = [...state.onlineUsers.online_user_list]
            let user_meta = cloneOnlineUsers.find(meta => meta?.user?.id === action.user_id)
            if (!!user_meta) {
                user_meta["route"] = action.route
            }
            return { ...state, onlineUsers: { online_user_list: cloneOnlineUsers } }
        // 客服运维频道连接
        case "CustomerOperationChannel:Connect":
            return { ...state, customerOperationChannel: action.channel }
        // 客服运维频道离开
        case "CustomerOperationChannel:Disconnect":
            !!state.customerOperationChannel && state.customerOperationChannel.leave()
            return { ...state, customerOperationChannel: undefined }
        // 激活某个客服
        case "CustomerOperationChannel:Conversation:Active":
            return { ...state, currentConversationCustomerID: action.customer_id }
        // 全局通知频道连接
        case "NotificationChannel:Connect":
            return { ...state, notificationChannel: action.channel }
        // 全局通知频道离开
        case "NotificationChannel:Disconnect":
            !!state.notificationChannel && state.notificationChannel.leave()
            return { ...state, notificationChannel: undefined }
        case "AppUserChannel:Connect":
            return { ...state, appOnlineChannel: action.channel }
        case "AppUserChannel:Disconnect":
            !!state.appOnlineChannel && state.appOnlineChannel.leave()
            return { ...state, appOnlineChannel: undefined }
        // 展示某个用户信息
        case "UserInformationMadal:show":
            return { ...state, userInformationShowingUserID: action.userId }
        // 关闭用户信息展示
        case "UserInformationMadal:close":
            return { ...state, userInformationShowingUserID: null }
        // 连接用户运维频道
        case "UserOpsChannel:Connect":
            return { ...state, userOpsChannel: action.channel }
        // 断开用户运维频道
        case "UserOpsChannel:Disconnect":
            !!state.userOpsChannel && state.userOpsChannel.leave()
            return { ...state, userOpsChannel: undefined }
        // 连接用户运维频道
        case "GlobalChannel:Connect":
            return { ...state, globalChannel: action.channel }
        // 断开用户运维频道
        case "GlobalChannel:Disconnect":
            !!state.globalChannel && state.globalChannel.leave()
            return { ...state, globalChannel: undefined }
        // 连接推送频道
        case "PushChannel:Connect":
            return { ...state, pushChannel: action.channel }
        // 断开用推送频道
        case "PushChannel:Disconnect":
            !!state.pushChannel && state.pushChannel.leave()
            return { ...state, pushChannel: undefined }
        default:
            return state;
    }
}
export default reducer