import { Provider } from 'react-redux';
import Home from './Home'
import reducer from './store'

import { legacy_createStore as createStore } from "redux"

// import Dashboard from "./Dashboard/index"
// import CustomerService from "./Operation/CustomerService"
// import Online from "./Online"
// import SQEC from './SQEC'
// import CMS from './CMS'
// import Statistics from './Statistics'
// import SignIn from './SignIn'
// import UserInformationModal from "./components/user/UserInformationModal";
// import Route from './components/auth_route'
// import Finance from './Finance'
// // import Push from './Push'
// import Marketing from "./Marketing";
// import Tech from './Tech';
// import Operation from "./Operation";
// import Community from "./Community";

// import Articles from './CMS/Articles'
// import ArticleForm from './CMS/ArticleForm'
// import VideoCourseList from './CMS/VideoCourse/List'
// import VideoCourseEditor from './CMS/VideoCourse/Editor'
// import VideoCourseReport from './CMS/VideoCourse/Report'
// import MeridianList from "./CMS/Meridian/List"
// import AcupointList from "./CMS/Acupoint/List"
// import IllnessList from "./CMS/Illness/List"
// import FoodList from "./CMS/Food/List"
// import HerbList from "./CMS/Herb/List"
// import EntryList from "./CMS/Entry"
// import DoctorList from "./CMS/Term/Doctor"
// import Terminology from "./CMS/Term/Terminology";
// import DietTherapy from "./CMS/Term/DietTherapy";
// import AncientBook from "./CMS/Term/AncientBook";
// import BasicTheory from "./CMS/Term/BasicTheory";
// import Regimen from "./CMS/Term/Regimen";

// import Pricing from "./Finance/Pricing";
// import FinanceOrder from "./Finance/FinanceOrder";
// import FinanceOrderRefund from "./Finance/FinanceOrderRefund"
// import AlipayAgreement from "./Finance/AlipayAgreement";

// import Plan from "./Marketing/Plan";
// import InviteCode from "./IMarketing/nviteCode";
// import RewardConvert from "./Marketing/RewardConvert";
// import Contract from "./Marketing/Contract"


// import AppUser from './Online/AppUser'
// import CustomerService from './Online/CustomerService'
// import ChatGPT from './Online/ChatGPT'

// import TechPushHistory from "./Tech/Push/History";
// import TechPushTemplate from "./Tech/Push/TemplateList";
// import TechPushCustomize from "./Tech/Push/Customize"
// import TechException from "./Tech/Exception";
// import TechCache from "./Tech/Cache"


// import SQECCourseList from './SQEC/course/list'
// import SQECQuestionList from './SQEC/question/list'
// import SQECCorrectionList from './SQEC/correction/list'
// import SQECDashboard from "./SQEC/Dashboard";



// import StatisticTongueRecords from "./statistics/records/TongueRecords";
// import StatisticActiveRecords from "./statistics/records/ActiveRecords";
// import StatisticHandAnalysis from "./statistics/records/HandAnalysis";
// import StatisticFaceAnalysis from "./statistics/records/FaceAnalysis";
// import StatisticCorporeityAnalysis from "./statistics/records/CorporeityAnalysis";
// import StatisticNotificationLogs from "./statistics/apple/NotificationLogs";
// import StatisticGraphStatistic from "./statistics/graph"
// import StatisticNinetonLog from './statistics/nineton/log'
// import StatisticAcupointDetect from "./statistics/records/AcupointDetect"


const store = createStore(reducer)
window.store = store

function App() {
  return <Provider store={store}><Home /></Provider >
  // return <Provider store={store}>
  //   <Router>
  //     <Routes>
  //       <Route path="/sign_in" exact element={<SignIn />} ></Route>
  //       <Route path="/" element={<Home />}>
  //         <Route path="/dashboard" element={<Dashboard roles={["acupoint:role::operator"]} />} />
  //         <Route path="/operation" element={<CustomerService roles={["acupoint:role::customer-service", "acupoint:role::operator"]} />} />
  //         <Route path="/ops" element={<Operation roles={["acupoint:role::customer-service", "acupoint:role::operator"]} />} />
  //         <Route path="/online" element={<Online roles={["acupoint:role::customer-service", "acupoint:role::operator"]} />} >
  //           <Route path="/online/app-user" exact element={<AppUser />}></Route>
  //           <Route path="/online/customer-service/:customer_id?" exact element={<CustomerService />}></Route>
  //           <Route path="/online/chatgpt" exact element={<ChatGPT />} ></Route>
  //         </Route>
  //         <Route path="/sqec" element={<SQEC roles={["acupoint:role::operator", "acupoint:role::editor"]} />} >
  //           <Route path="/sqec/dashboard" exact element={<SQECDashboard />}></Route>
  //           <Route path="/sqec/question/list" exact element={<SQECQuestionList />}></Route>
  //           <Route path="/sqec/correction/list" exact element={<SQECCorrectionList />}></Route>
  //           <Route path="/sqec/course/list" exact element={<SQECCourseList />}></Route>
  //         </Route>
  //         <Route path="/cms" element={<CMS roles={["acupoint:role::operator", "acupoint:role::editor"]} />}>
  //           <Route path="/cms/article/new" exact element={<ArticleForm />}></Route>
  //           <Route path="/cms/:column_title/articles/new" exact element={<ArticleForm />}></Route>
  //           <Route path="/cms/:column_title/articles" exact element={<Articles />}></Route>
  //           <Route path="/cms/:column_title/:article_title/edit" exact element={<ArticleForm />}></Route>
  //           <Route path="/cms/video-course-list" exact element={<VideoCourseList />}></Route>
  //           <Route path="/cms/video-course/report" exact element={<VideoCourseReport />}></Route>
  //           <Route path="/cms/video-course-list/:id/editor" exact element={<VideoCourseEditor />}></Route>
  //           <Route path="/cms/entry/list" exact element={<EntryList />}></Route>
  //           <Route path="/cms/meridian/:type" exact element={<MeridianList />}></Route>
  //           <Route path="/cms/meridian/:type/:name" exact element={<MeridianList />}></Route>
  //           <Route path="/cms/acupoint/:type" exact element={<AcupointList />}></Route>
  //           <Route path="/cms/acupoint/:type/:name" exact element={<AcupointList />}></Route>
  //           <Route path="/cms/illness" exact element={<IllnessList />}></Route>
  //           <Route path="/cms/illness/:name" exact element={<IllnessList />}></Route>
  //           <Route path="/cms/food" exact element={<FoodList />}></Route>
  //           <Route path="/cms/food/:name" exact element={<FoodList />}></Route>
  //           <Route path="/cms/herb" exact element={<HerbList />}></Route>
  //           <Route path="/cms/herb/:name" exact element={<HerbList />}></Route>
  //           <Route path="/cms/term/Doctor" exact element={<DoctorList />}></Route>
  //           <Route path="/cms/term/Doctor/:name" exact element={<DoctorList />}></Route>
  //           <Route path="/cms/term/Terminology" exact element={<Terminology />}></Route>
  //           <Route path="/cms/term/Terminology/:name" exact element={<Terminology />}></Route>
  //           <Route path="/cms/term/AncientBook" exact element={<AncientBook />}></Route>
  //           <Route path="/cms/term/AncientBook/:name" exact element={<AncientBook />}></Route>
  //           <Route path="/cms/term/DietTherapy" exact element={<DietTherapy />}></Route>
  //           <Route path="/cms/term/DietTherapy/:name" exact element={<DietTherapy />}></Route>
  //           <Route path="/cms/term/BasicTheory" exact element={<BasicTheory />}></Route>
  //           <Route path="/cms/term/BasicTheory/:name" exact element={<BasicTheory />}></Route>
  //           <Route path="/cms/term/Regimen" exact element={<Regimen />}></Route>
  //           <Route path="/cms/term/Regimen/:name" exact element={<Regimen />}></Route>
  //         </Route>
  //         <Route path="/statistics" element={<Statistics roles={["acupoint:role::operator", "acupoint:role::customer-service", "acupoint:role::finance"]} />}>

  //           <Route path="/statistics/nineton-logs" exact component={<StatisticNinetonLog roles={["acupoint:role::operator"]} />}></Route>
  //           <Route path="/statistics/register-records" exact component={<StatisticActiveRecords roles={["acupoint:role::operator"]} />}></Route>
  //           <Route path="/statistics/apple-notify-logs" exact component={<StatisticNotificationLogs roles={["acupoint:role::operator"]} />}></Route>
  //           <Route path="/statistics/graph-logs" exact component={<GStatisticraphStatistic roles={["acupoint:role::operator"]} />}></Route>

  //           <Route path="/statistics/tongue-analysis" exact component={<StatisticTongueRecords roles={["acupoint:role::operator", "acupoint:role::customer-service"]} />}></Route>
  //           <Route path="/statistics/acupoint-detect" exact component={<StatisticAcupointDetect roles={["acupoint:role::operator", "acupoint:role::customer-service"]} />}></Route>
  //           <Route path="/statistics/hand-analysis" exact component={<StatisticHandAnalysis roles={["acupoint:role::operator", "acupoint:role::customer-service"]} />}></Route>
  //           <Route path="/statistics/face-analysis" exact component={<StatisticFaceAnalysis roles={["acupoint:role::operator", "acupoint:role::customer-service"]} />}></Route>
  //           <Route path="/statistics/corporeity-analysis" exact component={<StatisticCorporeityAnalysis roles={["acupoint:role::operator", "acupoint:role::customer-service"]} />}></Route>

  //         </Route>
  //         <Route path="/finance" element={<Finance roles={["acupoint:role::operator", "acupoint:role::finance"]} />} >
  //           <Route path="/finance/pricing/:meta" exact element={Pricing}></Route>
  //           <Route path="/finance/refund" exact element={<FinanceOrderRefund roles={["acupoint:role::operator", "acupoint:role::finance"]} />}></Route>
  //           <Route path="/finance/trade-order" exact element={<FinanceOrder roles={["acupoint:role::operator", "acupoint:role::finance"]} />}></Route>
  //           <Route path="/finance/alipay/agreement" exact element={<AlipayAgreement roles={["acupoint:role::operator", "acupoint:role::finance"]} />}></Route>
  //         </Route>
  //         <Route path="/marketing" element={<Marketing roles={["acupoint:role::operator", "acupoint:role::marketing"]} />} >
  //           <Route path="/marketing/contract" exact element={<Contract roles={["acupoint:role::operator", "acupoint:role::marketing"]} />}></Route>
  //           <Route path="/marketing/plan" exact element={<Plan roles={["acupoint:role::operator", "acupoint:role::marketing"]} />}></Route>
  //           <Route path="/marketing/invite-code/:ownerType" exact element={<InviteCode roles={["acupoint:role::operator", "acupoint:role::marketing"]} />}></Route>
  //           <Route path="/marketing/invite-reward/convert" exact element={<RewardConvert roles={["acupoint:role::operator", "acupoint:role::marketing"]} />}></Route>
  //         </Route>
  //         <Route path="/tech" element={<Tech roles={["acupoint:role::operator"]} />} >
  //           <Route path="/tech/database/cache/:name" exact element={<TechCache />}></Route>
  //           <Route path="/tech/push/customize/template/:template_id" exact element={<TechPushCustomize />}></Route>
  //           <Route path="/tech/push/customize" exact element={<TechPushCustomize />}></Route>
  //           <Route path="/tech/push/history" exact element={<TechPushHistory />}></Route>
  //           <Route path="/tech/push/template" exact element={<TechPushTemplate />}></Route>
  //           <Route path="/tech/exception" exact element={<TechException />}></Route>
  //         </Route>
  //         <Route path="/community" element={<Community roles={["acupoint:role::operator", "acupoint:role::editor"]} />} />
  //       </Route>
  //     </Routes>
  //     <UserInformationModal />
  //   </Router>
  // </Provider >
}

export default App;