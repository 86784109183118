import React from "react"
import { Menu as MenuAction } from '../store/global/actions'
import { Button, Divider, Tag, Table, Modal, List, Popover, message } from "antd"
import { MobileOutlined, CheckCircleOutlined, TabletOutlined, RocketTwoTone, PlusSquareOutlined } from '@ant-design/icons'
// import ButtonGroup from "antd/lib/button/button-group";
import UserAvatar from "../components/user/UserAvatar"
import { connect } from "react-redux"
import dayjs from "dayjs"
import "./Member.css"
import LocalTimeAgo from "../components/local_time_ago"
import UserAdvanceFilter from "../components/user_advance_filter"
import UserForm from "./components/user_form"
import { legacy_createStore as createStore } from "redux"
import { Provider } from 'react-redux';
import reducer from '../store';
const store = createStore(reducer)

const { Column } = Table;
class Member extends React.Component {

    state = {
        data: {},
        versionData: {},
        channelData: [],
        channel: null,
        loading: true,
        filters: [],
        pushTemplates: []
    }

    componentDidMount() {
        this.props.setActiveKey("MEMBER")

        let channel = this.props.socket.channel("member:filter")
        channel.on("data", ({ data }) => this.setState({ data, loading: false }))
        channel.on("version-data", ({ data }) => this.setState({ versionData: data }))
        channel.on("channel-data", ({ data }) => this.setState({ channelData: data }))
        channel.join()
        this.setState({ channel })
    }

    componentWillUnmount() {
        if (this.state.channel)
            this.state.channel.leave()
    }

    pushEvent(event, params, opts = null) {
        if (!this.state.channel) {
            setTimeout(() => { this.pushEvent(event, params, opts) }, 500)
        } else
            return this.state.channel.push(event, params)
    }

    pageTo = (page, size) => {
        this.setState({ loading: true })
        this.pushEvent("page-to", { page_size: size, page: page })
    }

    handleFilterSet(value) {
        this.setState({ loading: true, filters: value })
        this.pushEvent("filters", { filters: value })
    }

    deviceType(shortest) {
        if (shortest < 600)
            return <Tag color={"green"} icon={<MobileOutlined />}>手机</Tag>
        else
            return <Tag color={"blue"} icon={<TabletOutlined />}>平板</Tag>
    }

    // 点击推送
    handleClickPush(template_id) {
        message.loading({ content: "推送中...", duration: 0, key: "pushing" })
        this.pushEvent("push-from-filters", { template_id: template_id, filters: this.state.filters })
            .receive("ok", () => {
                message.destroy()
                message.success({ content: "推送成功！", key: "pushing", duration: 3 })
                Modal.destroyAll()
            })
    }

    handleClickPushToResult() {
        this.pushEvent("get-push-templates", {})
            .receive("ok", ({ data }) => {
                // this.setState({ pushTemplates: data })
                Modal.info({
                    title: '选择模版',
                    content: <div>
                        <List dataSource={data}
                            renderItem={(item) => (
                                <List.Item key={item.id} actions={[<Popover trigger="click" content={<Button onClick={() => { this.handleClickPush(item.id); }} block type="primary" size="small">确认</Button>}><Button type="link">推送</Button></Popover>]}>
                                    <List.Item.Meta
                                        style={{ width: "100%" }}
                                        title={item.name}
                                        description={item.notification.alert}
                                    />
                                </List.Item>
                            )}>
                        </List>
                    </div>,
                    okText: "关闭"
                })
            })
    }

    formRef = React.createRef()
    handleAddMemberModal() {
        Modal.confirm({
            width: 600,
            title: '添加用户',
            // content: AcupointForm({ ref: this.formRef, initial: {} }),
            content: <Provider store={store}><UserForm formRef={this.formRef} /></Provider>,
            onOk: (close) => {
                const form = this.formRef.current
                form
                    .validateFields()
                    .then(values => {
                        this.state.channel.push("user-create", { ...values })
                            .receive("ok", () => {
                                close()
                                message.success("创建成功")
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    render() {
        return <div className="page member" >
            <h1>用户库 </h1>
            <UserAdvanceFilter channelData={this.state.channelData} onChange={this.handleFilterSet.bind(this)} versionData={this.state.versionData?.entries} />

            <p style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                    <Button type='link' icon={<PlusSquareOutlined />} onClick={this.handleAddMemberModal.bind(this)}>新增会员</Button>
                    <Button disabled={this.state.filters.length === 0} onClick={this.handleClickPushToResult.bind(this)} type="link" icon={<RocketTwoTone />}>推送筛选结果</Button>
                </span>
                <span>
                    总计：{this.state.data.total_entries}
                </span>
            </p>
            <Table dataSource={this.state.data.entries} loading={this.state.loading} size="middle"
                // style={{ overflowY: "auto" }}
                scroll={{ y: document.documentElement.clientHeight - 450 }}
                pagination={{
                    onChange: (page, size) => { this.pageTo(page, size) },
                    total: this.state.data.total_entries,
                    current: this.state.data.page_number,
                    pageSize: this.state.data.size,
                    showQuickJumper: true
                }} rowKey={d => d.id}>
                <Column title="用户" width={100} align="center" dataIndex="avatar" key="avatar" render={(_text, obj) =>
                    <UserAvatar id={obj.id} nickname={obj.nickname} size={40} avatar={obj.avatar} vip={obj.group === "vip"} />} />
                <Column title="VIP到期时间" width={100} dataIndex="vip_ended_at" key="vip_ended_at" />
                <Column title="激活渠道" width={150} align="center" dataIndex="channel" key="channel" render={(text, obj) => <><div>{obj.nt_channel}</div><div style={{ color: "#cecece" }}>{text}</div></>} />
                <Column title="版本号" width={100} align="center" dataIndex="version" key="version" render={(text, obj) => <span>{text}<Divider type="vertical" />{obj.version_code}</span>} />
                <Column title="机型" align="center" dataIndex="device_model" key="device_model" />
                <Column title="系统" align="center" dataIndex="device_version" key="device_version" />
                <Column title="上次登录时间" width={150} align="center" dataIndex="login_at" key="login_at" render={text =>
                    <div><div>{dayjs(text).format("YYYY-MM-DD HH:mm")}</div> <div><span style={{ color: "silver" }}><LocalTimeAgo date={dayjs(text).format("YYYY-MM-DD HH:mm")} /></span></div></div>} />
                <Column title="激活时间" width={150} align="center" dataIndex="active_at" key="active_at" render={text =>
                    <div><div>{dayjs(text).format("YYYY-MM-DD HH:mm")}</div> <div><span style={{ color: "silver" }}><LocalTimeAgo date={dayjs(text).format("YYYY-MM-DD HH:mm")} /></span></div></div>} />
                <Column title="设备类型" align="center" dataIndex="shortest" key="shortest" render={value => this.deviceType(value)} />
                <Column title="微信" align="center" dataIndex="wechat_openid" key="wechat_openid" render={(text) => text && <CheckCircleOutlined />} />
                <Column title="手机" align="center" dataIndex="phone" key="phone" />
                <Column title="Apple ID" align="center" dataIndex="appleid" key="appleid" render={(text) => text && <CheckCircleOutlined />} />
                <Column title="极光推送" width={80} align="center" dataIndex="jpush_register_id" key="jpush_register_id" render={(text) => text && <CheckCircleOutlined />} />
            </Table>
        </div >
    }
}
const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Member)