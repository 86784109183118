import React from "react";
import dayjs from 'dayjs';
import { Table, Image, Progress, Form, Input, Button } from 'antd';
import LocalTimeAgo from "../../components/local_time_ago";
import UserAvatar from "../../components/user/UserAvatar";

export default function CorrectionList(props) {

    // const linkTo = (path) => {
    //     props.pushEvent("push_redirect", path)
    // }

    const columns = [
        {
            title: '头像',
            dataIndex: ["user", "avatar"],
            key: 'avatar',
            render: (text, i) =>
                <UserAvatar id={i.user.id} nickname={i.user.nickname} avatar={i.user.avatar} vip={i.user.group === "vip"} />,
            width: 100,
            align: "center"
        },
        {
            title: '当前课程',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            render: (text, item) => <>
                <Image width={48} shape="square" src={item.icon} />
            </>,
        },
        {
            title: '已学 / 跳过 / 总数',
            dataIndex: ["courses", "history_num"],
            key: ["courses", "history_num"],
            align: 'center',
            render: (text, item) => `${item.courses.history_num} / ${item.courses.skip_num} / ${item.courses.history_num + item.courses.skip_num + 1}`
        },
        {
            title: '当前学时',
            dataIndex: 'current_lesson_index',
            key: 'current_lesson_index',
            align: 'center'
        },
        {
            title: '本课程总学时',
            dataIndex: 'cycle_num',
            key: 'cycle_num',
            align: 'center',
        },
        {
            title: '错题 / 错次 / 精 / 提',
            dataIndex: 'practices',
            key: 'practices',
            align: 'center',
            render: (text, item) => `${item.practices.wrong_num || 0} / ${item.practices.wrong_num_sum || 0} / ${item.practices.master_num} / ${item.practices.hint_num}`
        },
        {
            title: '课程进度',
            dataIndex: 'current_lesson_index',
            key: 'progress',
            align: 'center',
            render: (text, item) => {
                return <Progress type="circle" width={48} percent={((item.current_lesson_index) * 100 / item.cycle_num)} />
            }
        },
        {
            title: '最后提交时间',
            dataIndex: ["practices", "last_submit_at"],
            key: ["practices", "last_submit_at"],
            align: 'center',
            render: (text, item) => {
                return <LocalTimeAgo date={dayjs(text).add(8, "hours").format()} />
            }
        },
    ]

    const tableChange = (pagination, filters, sorter) => {
        // pageTo(pagination.current)
        props.pushEvent("page-to", { page_size: pagination.pageSize, page: pagination.current })
    }

    const onFinish = (values) => {
        props.pushEvent("query", values)
    }

    const onReset = () => {
        props.pushEvent("query-reset", {})
    }

    const data = props.data;

    return <>
        <Form name="query" layout="inline" onFinish={onFinish} initialValues={{ q: props.query }}   >
            <Form.Item name="q" >
                <Input placeholder="用户昵称" />
            </Form.Item>
            <Form.Item>
                <Button.Group>
                    <Button type="primary" htmlType="submit">
                        搜索
                    </Button>
                    <Button htmlType="button" onClick={onReset}>
                        重置
                    </Button>
                </Button.Group>
            </Form.Item>
        </Form>
        <br />
        <Table columns={columns} rowKey="id" dataSource={data.entries} onChange={tableChange}
            pagination={{
                pageSize: data.page_size, current: data.page_number, total: data.total_count, showSizeChanger: false,
                showQuickJumper: true
            }} />
    </>
}