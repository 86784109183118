import React, { useEffect, useState } from "react"
import { Button, List } from "antd"
import UserAvatar from "../../../components/user/UserAvatar";
import dayjs from "dayjs"
import { Divider } from "antd"
import { ClockCircleTwoTone, SelectOutlined } from "@ant-design/icons"
import { auditModal } from "../AuditModal"
import { userInformationModal } from "../../../store/global/actions"
import { connect } from "react-redux";
import CommentAncestors from "./CommentAncestors";

function CommentListItem({ item, channel, simulatorChannel, userShow }) {
    const viewDetail = (report) => {
        auditModal(channel, simulatorChannel, report)
    }

    let review_result = null;
    switch (item.review_result) {
        case "compliant":
            review_result = <span style={{ color: "green" }}>符合规定</span>
            break;
        case "violation":
            review_result = <span style={{ color: "red" }}>违规</span>
            break;
        default:
            break;
    }

    return <List.Item style={{ borderBottom: "1px solid #eee", paddingBottom: 10 }} key={item.id} actions={[<Button onClick={() => viewDetail(item)} type="link" size="small" icon={<SelectOutlined />}>查看并审核</Button>]}>
        <List.Item.Meta avatar={<UserAvatar nickname={item.owner_name} size={40} avatar={item.owner_avatar} id={item.owner_id} />}
            title={<span><span style={{ color: "orange" }}>[{item.type}]</span> <span>{item.reference?.content}</span></span>}
            description={<span>
                <span>累计赞同数：{item?.reference?.total_like_count}</span> <Divider type="vertical" />
                <span>累计回复数：{item?.reference?.total_reply_count}</span> <Divider type="vertical" />
                <span>赞同数：{item?.reference?.like_count}</span>
            </span>} />
        <div style={{ background: "#fafafa", padding: "10px 10px 20px 10px" }}>
            <p>
                <span style={{ color: "#1890ff" }}><ClockCircleTwoTone /> {dayjs(item.inserted_at).format("YYYY-MM-DD HH:mm")}</span> 举报
            </p>
            <div style={{ textIndent: "2em" }}>
                <div>{item.content || "[无内容]"}</div>
            </div>

            {item.status == "settle" && <>
                <p >
                    <span style={{ color: "#1890ff" }}><ClockCircleTwoTone /> {dayjs(item.upadted_at).format("YYYY-MM-DD HH:mm")}</span> 审核结果：{review_result}
                </p>
                <div style={{ textIndent: "2em" }}>
                    <div>{item.review_content || "[无内容]"}</div>
                </div>
            </>}

            <Divider style={{ color: "#666" }}>评论历史</Divider>
            <div className="items">
                <CommentAncestors comments={item?.reference?.ancestors} report={item} />
            </div>
        </div>
    </List.Item>
}


const mapStateToProps = ({ global }) => {
    return {
        onlineUsers: global.onlineUsers
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        userShow: (userId) => {
            dispatch(userInformationModal.show(userId))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentListItem);