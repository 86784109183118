import React, { useState } from "react";
import { List, Switch, Button, Popover, message, Form, Modal, Input, Radio, Upload, Divider, Tooltip, TreeSelect } from 'antd';
import { connect } from 'react-redux';
import useLocalStorageState from 'use-local-storage-state'
import { Menu as MenuAction } from '../../store/global/actions'

import { RocketOutlined, PlusSquareTwoTone, PlusOutlined, UploadOutlined, EditTwoTone, DeploymentUnitOutlined } from '@ant-design/icons';

import MediaViewer from "../components/media_editor";
import ListGrid from "../components/ListGrid";
const { TreeNode } = TreeSelect;


function iconSettle(file, cb = () => null) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('只能上传 JPG/PNG 文件!');
        return false
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('文件不能超过2MB!');
        return false
    }

    const reader = new FileReader();
    reader.addEventListener('load', () => {
        cb(reader.result)
    });
    reader.readAsDataURL(file);

    return false
}

function FoodDraftForm(props) {
    const [form] = Form.useForm()
    const [icon, setIcon] = useState(null)
    const [modalShow, setModalShow] = useState(false)
    const [submiting, setSubmiting] = useState(false)
    const [formCateID, setFormCateID] = useLocalStorageState('food-insert-form-cate_id', null)

    const handleSubmit = () => {
        setSubmiting(true)
        form
            .validateFields()
            .then(values => {
                form.resetFields();
                props.pushEvent("add-draft", { ...values, icon })
                    .receive("ok", (resp) => {
                        message.success("创建成功，在列表中编辑")
                        props.onSuccess(resp)
                        setModalShow(false)
                        setSubmiting(false)
                    })
            })
            .catch(info => {
                console.log('Validate Failed:', info);
                setSubmiting(false)
            });
    }

    const handleAddDraft = () => {
        setModalShow(true)
    }

    return <>
        <Button icon={<PlusSquareTwoTone />} type="link" onClick={() => handleAddDraft()}>
            新增食物
        </Button>
        <Modal title="新增食物" loading={submiting} open={modalShow} onOk={handleSubmit} onCancel={() => setModalShow(false)}>
            <Form form={form} name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
                <Form.Item label="食物名称" name="name" rules={[{ required: true, message: '不能为空' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="type" label="食物类别" initialValue="general" rules={[{ required: true, message: '不能为空' }]}>
                    <Radio.Group>
                        <Radio value="general">常用食物</Radio>
                        <Radio value="advance">其他</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="cate_ids" label="食物分类" help="多选" initialValue={formCateID} rules={[{ required: true, message: '不能为空' }]}>
                    <TreeSelect multiple listHeight="60vh" treeDefaultExpandAll onChange={(v) => setFormCateID(v)}>
                        {props.cateTreeData}
                    </TreeSelect>
                </Form.Item>
                <Form.Item label="封面图" rules={[{ required: true, message: '不能为空' }]}>
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={(file) => iconSettle(file, (result) => {
                            form.setFieldsValue({ icon: result })
                            setIcon(result)
                        })}
                    >
                        {icon ? <img src={icon} alt="avatar" style={{ width: '100%' }} /> : <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>拖到这里</div>
                        </div>}
                    </Upload>
                </Form.Item>
                <Form.Item label="列表简介" name={["data", "summary"]} rules={[{ required: true, message: '必填' }]} >
                    <Input.TextArea size="2" />
                </Form.Item>
            </Form>
        </Modal>
    </>
}

function QueryForm(props) {
    const [form] = Form.useForm()

    form.setFieldsValue(props.values)

    return <div>
        <Form form={form} autoComplete="off" layout="inline" onFinish={props.onSearch} initialValues={props.values}>
            <Form.Item name="name" label="食物名">
                <Input placeholder="模糊查询"></Input>
            </Form.Item>
            <Button.Group>
                <Button type="primary" htmlType="submit">查询</Button>
                <Button onClick={() => props.onSearch({})} htmlType="reset">清空</Button>
            </Button.Group>
        </Form>
    </div>
}

class FoodList extends React.Component {

    state = {
        channel: null,
        data: {},
        editable: false,
        loading: false,
        query: { name: this.props.match?.params?.name },
        cateTreeData: [],
        editorHeight: 500,
    }

    editBasicInfoRef = React.createRef();

    joinChannel(props) {
        this.setState({ loading: true })
        const channel = props.socket.channel("ops:resource:food", { page_size: 4, ...this.state.query })
        channel.on("data", ({ data }) => this.setState({ data, loading: false }))
        channel.on("cate-tree", ({ data: cateTreeData }) => this.setState({ cateTreeData }))
        channel.join()
        this.setState({ channel })
    }

    componentDidMount() {
        this.props.setActiveKey("FOOD")
        this.joinChannel(this.props)
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        !!this.state.channel && this.state.channel.off("data")
        !!this.state.channel && this.state.channel.leave()

        this.setState({ query: { ...this.state.query, name: nextProps.match?.params?.name } }, () => this.joinChannel(nextProps))
    }

    // 处理点击是否发布
    handleClickPublish(acupointID) {
        this.state.channel.push("publish!", { id: acupointID }).receive("ok", () => message.success("发布成功"))
    }

    // 处理点击是否删除
    handleClickDrop(id) {
        this.state.channel.push("drop!", { id: id }).receive("ok", () => message.success("删除成功"))
    }

    handlePageTo(page, size) {
        this.setState({ loading: true })
        this.state.channel.push("page-to", { page, size })
            .receive("ok", () =>
                this.setState({ loading: false }))
    }

    handleSearch(values) {
        this.setState({ loading: true, query: values })
        this.state.channel.push("search", values)
            .receive("ok", () =>
                this.setState({ loading: false }))
    }

    // 添加到图谱推荐表
    handleClickAddToGraphSuggest(id, name) {
        this.state.channel.push("add-to-graph-suggest", { content_type: `food`, content_id: id, content_name: name })
            .receive("ok", () => message.success("添加到图谱推荐列表成功"))
    }

    // 分类树的选项
    cateTreeToSelectOption = tree => {
        tree = tree || []

        if (tree.length > 0) {
            return tree.map(({ id, name, children }) => {
                return <TreeNode key={id} value={id} title={name}>{this.cateTreeToSelectOption(children)}</TreeNode>
            })
        } else {
            return null
        }
    }

    // 类别展示
    typeRender(type) {
        switch (type) {
            case "general":
                return <span style={{ color: "#aaa" }}>常用食物</span>

            default:
                break;
        }
    }

    // img 修改表单
    imageForm(item) {
        return <div>
            <div><img alt="" src={item.icon} width={200}></img></div>
            {this.state.editable && <Upload name="avatar"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={(file) => iconSettle(file, (result) => {
                    this.state.channel.push("edit-icon", { id: item.id, icon: result })
                        .receive("ok", () => message.success('修改成功'))
                })}>
                <Button icon={<UploadOutlined />} style={{ width: 200 }} block>点击更换</Button>
            </Upload>
            }
        </div>
    }

    editBasicInfo(initial) {
        Modal.confirm({
            title: '修改基础信息',
            content: <Form ref={this.editBasicInfoRef} initialValues={initial} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} autoComplete="off">
                <Form.Item label="名称" name="name" rules={[{ required: true, message: '必填' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="type" label="食物类别" rules={[{ required: true, message: '不能为空' }]}>
                    <Radio.Group>
                        <Radio value="general">常用食物</Radio>
                        <Radio value="advance">其他</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="cate_ids" label="食物分类" help="多选" rules={[{ required: true, message: '不能为空' }]}>
                    <TreeSelect multiple listHeight="60vh" treeDefaultExpandAll>
                        {this.cateTreeToSelectOption(this.state.cateTreeData)}
                    </TreeSelect>
                </Form.Item>
                <Form.Item label="拼音" name="py" rules={[{ required: true, message: '必填' }]} >
                    <Input />
                </Form.Item>
                <Form.Item label="搜索拼音" name="search_py" rules={[{ required: true, message: '必填' }]} >
                    <Input />
                </Form.Item>
                <Form.Item label="列表简介" name={["data", "summary"]} rules={[{ required: true, message: '必填' }]} >
                    <Input.TextArea size="2" />
                </Form.Item>
                <Form.Item label="列表简介" name={["data", "free"]} valuePropName="checked" rules={[{ required: true, message: '必填' }]} >
                    <Switch checkedChildren="免费" unCheckedChildren="需要收费"></Switch>
                </Form.Item>
            </Form>,
            onOk: (close) => {
                const form = this.editBasicInfoRef.current
                form
                    .validateFields()
                    .then(values => {
                        this.state.channel.push("edit", { id: initial.id, ...values })
                            .receive("ok", (resp) => {
                                close()
                                message.success("修改成功")
                                form.resetFields();
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    render() {
        return <div className="page resource-food">
            {this.state?.channel && this.state.cateTreeData.length > 0 &&
                <div className="actions" style={{ display: "flex" }}>
                    <FoodDraftForm cateTreeData={this.cateTreeToSelectOption(this.state.cateTreeData)} onSuccess={(food) => this.handleSearch({ name: food.name })} pushEvent={(event, params) => this.state?.channel?.push(event, params)} />
                </div>
            }
            <h1>食物列表
                <Divider type="vertical" />
                <Switch onChange={checked => this.setState({ editable: checked })} checkedChildren="开启编辑" unCheckedChildren="只读" />
            </h1>

            <div>
                <QueryForm onSearch={this.handleSearch.bind(this)} values={this.state.query} />
            </div>
            <br />
            {
                this.state.data.entries &&
                <ListGrid dataSource={this.state.data.entries}
                    loading={this.state.loading}
                    rowKey={d => d.name}
                    pagination={{
                        onChange: (page, size) => { this.handlePageTo(page, size) },
                        total: this.state.data.total_entries,
                        current: this.state.data.page_number,
                        pageSize: this.state.data.page_size,
                        showQuickJumper: true
                    }}
                    onHeightEdit={(height) => this.setState({ editorHeight: height })}
                    renderItem={item => (
                        <List.Item className="item-food">
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <small style={{ fontSize: 12, color: "#888" }}>{item.py}</small>
                                    <span style={{ fontSize: 20, fontWeight: "bold" }}>
                                        {item.name}
                                    </span>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Popover content={this.imageForm(item)}>
                                        <img alt="" src={item.icon} height="30" width="30"></img>
                                    </Popover>
                                    <Divider type="vertical" />
                                    <Tooltip title="添加到图谱推荐表">
                                        <Button type="link" icon={<DeploymentUnitOutlined />} onClick={() => this.handleClickAddToGraphSuggest(item.id, item.name)} ></Button>
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <span>{this.typeRender(item.type)}</span>
                                    {this.state.editable && <span>
                                        <Divider type="vertical" />
                                        <Button type="link" icon={<EditTwoTone />} onClick={() => this.editBasicInfo(item)}></Button>
                                        {
                                            item.has_update &&
                                            <>
                                                <Divider type="vertical" />
                                                <Popover content={<Button onClick={() => this.handleClickPublish(item.id)} block type="primary" size="small">确认</Button>} title="确认发布？" trigger="click">
                                                    <Button type="link" icon={<RocketOutlined />}></Button>
                                                </Popover>
                                            </>
                                        }
                                    </span>}
                                </div>
                            </div>

                            <div style={{ height: "42px", color: "#888" }}> <pre style={{ fontSize: 9, fontWeight: "normal", whiteSpace: "break-spaces" }}>{item.data?.summary}</pre></div>
                            <div>
                                <MediaViewer ownerType="Food" hasUpdate={item.has_update} ownerID={item.id} channel={this.state.channel} name={item.name} detail={item.detail} type="food" editable={this.state.editable} height={this.state.editorHeight} />
                                {this.state.editable && <Popover trigger="click" placement="top" content={<Button onClick={() => this.handleClickDrop(item.id)} danger size="small" type="primary">确认删除</Button>} ><Button block type="primary" size="small">删除</Button></Popover>}
                            </div>
                        </List.Item>
                    )
                    } />
            }
        </div >
    }
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FoodList);