import React from "react"
import { Menu as MenuAction } from '../store/global/actions'
import { Radio, Table, message, Button, Divider, Switch, Form, Input } from "antd"
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, FormOutlined } from '@ant-design/icons'
import PasswordConfirm from "../components/passwordConfirm";
// import ButtonGroup from "antd/lib/button/button-group";

import { connect } from "react-redux"
import "./Pricing.css"
import UserAvatar from "../components/user/UserAvatar"
import dayjs from "dayjs"

const { Column } = Table;
class AliAgreement extends React.Component {

    state = {
        channel: null,
        data: {},
        loading: true,
        query: { status: "NORMAL", can_cost: false, cate: 0 }
    }

    componentDidMount() {
        this.props.setActiveKey("FINANCE")

        let channel = this.props.socket.channel("finance:alipay:agreement", this.state.query)
        channel.on("data", ({ data }) => {
            this.setState({ data: data || {}, loading: false })
        })
        channel.join()
        this.setState({ channel })
    }

    componentWillUnmount() {
        if (this.state.channel)
            this.state.channel.leave()
    }

    handlePageTo(page, size) {
        this.setState({ loading: true })
        this.state.channel.push("page-to", { page, size })
            .receive("ok", () =>
                this.setState({ loading: false }))
    }

    handleRequestCost(agreementNo) {
        this.state.channel.push("request-cost", { agreement_no: agreementNo })
            .receive("ok", () => message.success("扣款成功"))
        // .receive("error", ({ message: msg }) => message.error(msg))
    }

    handleRefreshFromAlipay(agreementNo) {
        this.state.channel.push("refresh-remote", { agreement_no: agreementNo })
            .receive("ok", () => message.success("刷新成功"))
        // .receive("error", ({ message: msg }) => message.error(msg))
    }

    // 解约
    revokePasswordConfirmRef = React.createRef()
    handleRevokeAgreement(agreementNo, userID) {
        PasswordConfirm({
            ref: this.revokePasswordConfirmRef,
            title: '解约密码确认',
            message: "请确认，操作会被记录",
            externalItems: <>
                <Form.Item label="短信" name="sms" initialValue="会员自动续费服务已取消，即日起您的账户将不再自动扣款。为了减少不必要的费用，平台主动为您解约。期待我们下次重逢，为您提供更优质的服务！" rules={[{ required: true, message: '短信内容不能为空' }]}>
                    <Input.TextArea size="large" showCount autoSize />
                </Form.Item>
            </>,
            onOk: ({ password, sms }, close) => {
                message.loading({ content: '解约中...', key: "revoking", duration: 3 })
                this.state.channel.push("revoke-agreement", { agreement_no: agreementNo, password: password, sms, user_id: userID }, 100000)
                    .receive("ok", () => {
                        close()
                        message.success({ content: '解约成功！', key: "revoking", duration: 3 })
                    })
            }
        })
    }

    handleQuery(field, value) {
        if (this.state.query[field] !== value) {
            this.setState({ query: { ...this.state.query, [field]: value } }, () => {
                this.state.channel.push("query", this.state.query)
            })
        }
    }

    render() {

        return <div className="page pricing" >
            <h1>支付宝合约</h1>

            <Radio.Group buttonStyle="solid" size="small" value={this.state.query.status} onChange={(e) => this.handleQuery("status", e.target.value)}>
                <Radio.Button value={0}>全部</Radio.Button>
                <Radio.Button value="NORMAL">履约</Radio.Button>
                <Radio.Button value="UNSIGN">解约</Radio.Button>
                <Radio.Button value="">未签约</Radio.Button>
            </Radio.Group>
            <Divider type="vertical"></Divider>
            <Radio.Group buttonStyle="solid" size="small" value={this.state.query.cate} onChange={(e) => this.handleQuery("cate", e.target.value)}>
                <Radio.Button value={0}>全部</Radio.Button>
                <Radio.Button value="CAN-COST">可手动扣款</Radio.Button>
                <Radio.Button value="OUTDATE">扣款失败并逾期</Radio.Button>
            </Radio.Group>
            <p></p>
            <Table dataSource={this.state.data.entries}
                size="middle"
                scroll={{ y: document.documentElement.clientHeight - 370 }}
                loading={this.state.loading}
                pagination={{
                    onChange: (page, size) => { this.handlePageTo(page, size) },
                    total: this.state.data.total_entries,
                    current: this.state.data.page_number,
                    pageSize: this.state.data.page_size,
                    showQuickJumper: true
                }} rowKey={d => d.id}>
                <Column title="支付宝合约号" width={210} dataIndex="agreement_no" key="agreement_no" />
                <Column title="周期类型" width={100} dataIndex="period_type" key="period_type" render={(_, { period, period_type }) => `${period} ${period_type}`} />
                <Column title="预计下次执行时间" width={160} dataIndex="execute_time" key="execute_time" render={(text, item) => {
                    const diffDays = dayjs(text).diff(dayjs(), "day")

                    if (item.status !== "NORMAL")
                        return ""
                    else if (diffDays > 0 && diffDays < 5)
                        return <span style={{ color: "green" }}>{dayjs(text).format("YYYY-MM-DD HH:mm")}</span>
                    else if (diffDays < 0 && item.status === "NORMAL")
                        return <span style={{ color: "red" }}>{dayjs(text).format("YYYY-MM-DD HH:mm")}</span>
                    else
                        return <span >{dayjs(text).format("YYYY-MM-DD HH:mm")}</span>
                }} />
                {/* <Column title="组内默认" dataIndex="group_default" key="group_default" /> */}
                <Column title="所属用户" width={100} align="center" dataIndex="owner_id" key="owner_id" render={(text, { user }) => <UserAvatar nickname={user.nickname} size={40} avatar={user.avatar} id={user.id} vip={user.group === "vip"} />} />
                <Column title="对应产品" width={100} dataIndex="owner_id" key="owner_id" render={(text, { pricing }) => {
                    return <div>
                        {pricing.name}
                    </div>
                }} />
                <Column title="单次扣款" width={100} align="center" dataIndex="single_amount" key="single_amount" render={text => `${text} 元`} />
                <Column title="状态" width={100} align="center" dataIndex="status" key="status" render={text => {
                    switch (text) {
                        case "NORMAL":
                            return <span style={{ color: "green" }}><CheckCircleOutlined /> 正常</span>
                        case "UNSIGN":
                            return <span style={{ color: "red" }}><CloseCircleOutlined /> 解约</span>
                        case null:
                            return <span style={{ color: "orange" }}><ClockCircleOutlined /> 未签约</span>

                        default:
                            break;
                    }
                }} />
                <Column title="签约时间" width={160} align="center" dataIndex="valid_time" key="valid_time" render={text => {
                    if (text === null)
                        return ""
                    else
                        return dayjs(text).format("YYYY-MM-DD HH:mm")
                }} />
                <Column title="最近消息" dataIndex="recent_message" key="recent_message" />
                <Column title="操作" width={300} render={(_, item) => {
                    // const diffDays = dayjs(item.execute_time).diff(dayjs(), "day")
                    // console.log(dayjs(item.execute_time), Math.abs(diffDays % item.period))
                    return <div>
                        {item.status === "NORMAL" && <Button type="link" onClick={() => this.handleRequestCost(item.agreement_no)}>手动扣款</Button>}
                        {item.status !== "UNSIGN" && <><Button type="link" onClick={() => this.handleRefreshFromAlipay(item.agreement_no)}>刷新状态</Button></>}
                        {item.status !== "UNSIGN" && <><Button danger type="link" onClick={() => this.handleRevokeAgreement(item.agreement_no, item.user?.id)}>解约 <FormOutlined /></Button></>}

                    </div>
                }}></Column>
            </Table>
        </div >
    }
}
const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey,
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AliAgreement)