import React from "react";
import { Menu, Badge, Divider } from 'antd';
import { AimOutlined, HomeTwoTone, AuditOutlined, ContainerOutlined, FileDoneOutlined, ExceptionOutlined, CommentOutlined } from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Tooltip } from "antd";

function SideMenu(props) {
    const location = useLocation()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")
    const fullPathName = location.pathname

    return <Menu style={{ width: 256 }} defaultSelectedKeys={[pathName, fullPathName]} mode="inline" >
        <Menu.Item key="/community/simulator" icon={<HomeTwoTone />}>
            <Link to="/community/simulator">社区</Link>
        </Menu.Item>
        <Divider />
        <Menu.ItemGroup key="post" title="发帖">
            <Menu.Item key="/community/post/question" icon={<AuditOutlined style={{ color: "#1890ff" }} />}>
                <Link to="/community/post/question">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>提问</span>
                        <Tooltip title="发布数"><span><Badge count={props.questionPublishCount} overflowCount={999999999} style={{ backgroundColor: '#52c41a' }} /></span></Tooltip>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/community/post/answer" icon={<AuditOutlined style={{ color: "#1890ff" }} />}>
                <Link to="/community/post/answer">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>回答</span>
                        <Tooltip title="发布数"><span><Badge count={props.answerPublishCount} overflowCount={999999999} style={{ backgroundColor: '#52c41a' }} /></span></Tooltip>
                    </div></Link>
            </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup key="complain-post" title="举报 - 发帖">
            <Menu.Item key="/community/report/audit/post" icon={<AuditOutlined style={{ color: "#1890ff" }} />}>
                <Link to="/community/report/audit/post">审核台</Link>
            </Menu.Item>
            <Menu.Item key="/community/report/post/pending" icon={<ExceptionOutlined style={{ color: "#1890ff" }} />}>
                <Link to="/community/report/post/pending">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>待审核列表</span>
                        <span><Badge count={props.reportPostPendingCount} /></span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/community/report/post/settle" icon={<FileDoneOutlined style={{ color: "#1890ff" }} />}>
                <Link to="/community/report/post/settle">已审核</Link>
            </Menu.Item>
        </Menu.ItemGroup>
        <Divider />
        <Menu.Item key="/community/comments" icon={<CommentOutlined style={{ color: "#1890ff" }} />}>
            <Link to="/community/comments">评论</Link>
        </Menu.Item>
        <Menu.ItemGroup key="complain-comment" title="举报 - 评论">
            <Menu.Item key="/community/report/audit/comment" icon={<AuditOutlined style={{ color: "#1890ff" }} />} >
                <Link to="/community/report/audit/comment">审核台</Link>
            </Menu.Item>
            <Menu.Item key="/community/report/comment/pending" icon={<ExceptionOutlined style={{ color: "#1890ff" }} />} >
                <Link to="/community/report/comment/pending">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>待审核列表</span>
                        <span><Badge count={props.reportCommentPendingCount} /></span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/community/report/comment/settle" icon={<FileDoneOutlined style={{ color: "#1890ff" }} />} >
                <Link to="/community/report/comment/settle">已审核</Link>
            </Menu.Item>
        </Menu.ItemGroup>
        <Divider />
        <Menu.ItemGroup key="manage" title="配置管理">
            <Menu.Item key="/community/topic" icon={<AimOutlined style={{ color: "#1890ff" }} />}>
                <Link to="/community/topic">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>话题</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/community/guidelines" icon={<ContainerOutlined style={{ color: "#1890ff" }} />}>
                <Link to="/community/guidelines">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>社区公约</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
    </Menu >
}


const mapStateToProps = ({ global }) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);