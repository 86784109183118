import { connect } from "react-redux"


function NeedSocket(props) {

    return !!props.socket && props.children

}

const mapStateToProps = ({ global }) => {
    return { socket: global.socket }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NeedSocket)