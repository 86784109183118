


import { HashRouter as Router, Route } from "react-router-dom";
import { Menu as MenuAction } from '../store/global/actions'
import { connect } from 'react-redux';

import AppUser from './AppUser'
import CustomerService from './CustomerService'
import ChatGPT from './ChatGPT'
import SideMenu from "./components/SideMenu"
import React from "react";
import GPTPrompts from "./GPTPrompts";


class Online extends React.Component {

    componentDidMount() {
        this.props.setActiveKey("Online")
    }

    render() {
        return <Router>
            <div className="module module-online">
                <div className="side-menu">
                    <SideMenu />
                </div>
                <div className="content">
                    <Route path="/online/app-user" exact component={AppUser}></Route>
                    <Route path="/online/gpt-prompts" exact component={GPTPrompts} ></Route>
                    <Route path="/online/customer-service/:customer_id?" exact component={CustomerService}></Route>
                    <Route path="/online/chatgpt" exact component={ChatGPT} ></Route>
                </div>
            </div>
        </Router>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        activeKey: global.navActiveKey
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Online)

