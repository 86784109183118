import { Avatar } from "antd";
import UserAvatar from "./UserAvatar";

function UserAvatars({ users, ...props }) {
    return <Avatar.Group {...props}>
        {(users || []).map(user => {
            return <UserAvatar {...user}></UserAvatar>
        })}
    </Avatar.Group>
}

export default UserAvatars;