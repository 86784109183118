import React from "react";
import { List, Modal, Button, Switch } from 'antd';
import 'jsoneditor-react/es/editor.min.css';
import ContractItem from "./components/contract_item";
import { PlusSquareTwoTone } from '@ant-design/icons';
import ContractForm from "./components/contract_form";

import { connect } from "react-redux";

class Contract extends React.Component {
    state = {
        channel: null,
        data: {},
        showCreateForm: false
    }

    componentDidMount() {
        const channel = this.props.socket.channel("marketing:contract")
        channel.on("data", ({ data }) => this.setState({ data }))
        channel.join()
        this.setState({ channel })
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    pushEvent(event, params, opts = null) {
        return this.state.channel.push(event, params)
    }

    handleSetEditor(instance) {
        this.editorInstance = instance
    }

    render() {
        const data = this.state.data;

        return <>
            <h1>合同 Contract <Switch onChange={checked => this.setState({ editable: checked })} checkedChildren="开启编辑" unCheckedChildren="只读" /></h1>
            <div className="actions" style={{ display: "flex" }}>
                <Button icon={<PlusSquareTwoTone />} type="link" onClick={() => this.setState({ showCreateForm: true })}>
                    新增
                </Button>
            </div>

            {
                !!data.entries &&
                <List
                    grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 3, }}
                    dataSource={data.entries}
                    pagination={{
                        onChange: (page, size) => { this.tableChange(page, size) },
                        total: data.total_count,
                        current: data.page_number,
                        pageSize: data.page_size,
                        showQuickJumper: true
                    }}
                    renderItem={item => <List.Item>
                        <ContractItem readonly={!this.state.editable} style={{ border: "1px solid #dfdfdf", borderRadius: 5 }} channel={this.state.channel} item={item} />
                    </List.Item>}
                />
            }

            {this.state.channel &&
                <Modal width={800} title="新增合同" open={this.state.showCreateForm} footer={false} onCancel={() => this.setState({ showCreateForm: false })}>
                    <ContractForm channel={this.state.channel} closeModal={() => this.setState({ showCreateForm: false })} />
                </Modal>
            }
        </>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Contract)