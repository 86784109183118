import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Quill } from "react-quill"
import { Button, Badge, Dropdown, Skeleton } from "antd"
import { EditTwoTone, LineHeightOutlined, LeftOutlined, ShareAltOutlined, MoreOutlined, CrownTwoTone } from "@ant-design/icons"
import { Segmented } from "antd"
import defaultAvatar from "../../images/avatar.png"
import Report from "../actions/Report"
import { message } from "antd"
import Config from "../../config"
import UserAvatar from "../../components/user/UserAvatar"
import { userInformationModal } from "../../store/global/actions"
import { connect } from "react-redux";
import { Avatar } from "antd"
import Comments from "./components/Comments"

import dayjs from "dayjs"

function Question({ channel, inReview, userShow, ...props }) {
    let [question, setQuestion] = useState(null)
    let [expanded, setExpanded] = useState(inReview || false)
    let [answerSortType, setAnswerSortType] = useState("default")
    let [answers, setAnswers] = useState([])

    let id = props.match?.params?.id || props.id

    let history = useHistory()

    useEffect(() => {
        setQuestion(null)
        setAnswers([])
        refresh(answerSortType)

        let refreshRef = channel.on("refresh", () => refresh())

        return () => {
            channel.off("refresh", refreshRef)
        }
    }, [id, answerSortType])

    const refresh = (v) => {
        channel.push("question-with-answers", { id, sort_type: v || answerSortType })
            .receive("ok", ({ question, answers }) => {
                setQuestion(question)
                setAnswers(answers)
            })
    }

    const handleAnswerSortTypeChanged = (v) => {
        setAnswerSortType(v)
    }

    const handleMenuClick = ({ key }) => {
        switch (key) {
            case "report":
                Report({
                    onSubmit: (values) => {
                        channel.push("report", { ...values, id }).receive("ok", () => {
                            message.success("举报成功！")
                        })
                        return true
                    },
                    initial: { type: "违法违规" }
                })
                break;

            default:
                break;
        }
    }

    const tempCont = document.createElement("div");
    (new Quill(tempCont)).setContents(question?.content?.delta);
    const contentHtml = tempCont.getElementsByClassName("ql-editor")[0].innerHTML.replaceAll(/src="(?!(http\:\/\/|https\:\/\/))(.+)"/g, `src="${Config.AttachUrl("")}/$2"`);

    return <div className={`page community simulator question-detail ${inReview && "in-review"}`}>
        <div className="header">
            <span>
                <Button icon={<LeftOutlined />} size="small" type="link" onClick={() => history.push(`/community/simulator`)}></Button>
            </span>
            <span>
                <Button icon={<ShareAltOutlined />} size="small" type="link" onClick={() => history.push(`/community/simulator/question/${id}/answer`)} disabled>分享</Button>
                <Button icon={<EditTwoTone />} size="small" type="link" onClick={() => history.push(`/community/simulator/question/${id}/answer`)}>写回答</Button>

                <Dropdown menu={{ items: [{ key: "report", label: "举报" }], onClick: handleMenuClick }} trigger={['click']}>
                    <Button type="link" icon={<MoreOutlined />}></Button>
                </Dropdown>
            </span>
        </div>
        <div className="scoller">
            <div className="question">
                <div className="title"><span>{question?.title || <Skeleton paragraph={{ rows: 0 }} active />}</span> <span className="avatar"><UserAvatar nickname={question?.owner?.nickname} size={40} avatar={question?.owner?.avatar} id={question?.owner?.id} vip={question?.owner?.group === "vip"} /></span></div>
                <div className="header-info"><span>{question?.view_count} 浏览</span></div>
                {question ?
                    <>
                        <div className={`post-content content ${expanded ? "expanded" : ""}`} dangerouslySetInnerHTML={{ __html: contentHtml }}></div>
                        <div className="footer-info">
                            <div>
                                <span>发布于  {dayjs(question.inserted_at).format("YYYY-MM-DD HH:mm")}</span>
                                <span>IP属地 {question?.ip_belongs_text || "未知"}</span>
                            </div>
                            {!expanded && <div className="loadmore" onClick={() => setExpanded(true)}><LineHeightOutlined /> 点击展开</div>}</div>
                    </>
                    : <Skeleton paragraph={{ rows: 4 }} active></Skeleton>}

                {/* <div className="actions"><Button type="ghost" icon={<HeartTwoTone />}>赞同 {question?.like_count}</Button></div> */}
            </div>
            <div className="answers">
                <div className="actions">
                    <span>回答 {question?.answer_count}</span>
                    <div className="answer">
                        <Segmented onChange={(value) => handleAnswerSortTypeChanged(value)}
                            options={[
                                {
                                    value: 'default',
                                    label: "默认",
                                },
                                {
                                    value: 'latest',
                                    label: "最新",
                                },
                            ]}
                        />
                    </div>
                </div>

                {answers.map((item) => {
                    return <div className="item" key={item.id} onClick={() => !inReview && history.push(`/community/simulator/question/${item.parent_id}/answer/${item.id}`)}>
                        <div className="owner" onClick={() => userShow(item.owner_id)}>
                            <Badge className="user-avatar" count={item?.owner?.group === "vip" ? <CrownTwoTone twoToneColor="#eb2f96" rotate={45} /> : null} style={{ fontSize: 12 }}>
                                <Avatar size="small" src={Config.AvatarUrl(item.owner?.avatar)} />
                            </Badge>
                            &nbsp;{item.owner?.nickname || "匿名用户"}</div>
                        <pre className="content post-content">{item.content_text}</pre>
                        <div className="footer">
                            <span>{item.ip_belongs_text || "未知"}</span>
                            <span>{item.like_count} 赞同</span>
                            <span>{item.view_count} 阅读</span>
                            <span>{item?.favorite_count} 收藏</span>
                            <span> {dayjs(item.inserted_at).format("YYYY-MM-DD HH:mm")}</span>
                        </div>
                        <div className="comments">
                            <div className="summary" >
                                <span>评论 {item?.comments.total}</span>
                                <span>
                                    <Segmented size="small" value={item?.comments.sort_type}
                                        options={item?.comments.sort_types}
                                    />
                                </span>
                            </div>
                            {!!channel && <Comments referenceType="post" referenceID={item?.id} comments={item?.comments} channel={channel} />}
                        </div>
                    </div>
                })}

            </div>
        </div>
    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        onlineUsers: global.onlineUsers
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        userShow: (userId) => {
            dispatch(userInformationModal.show(userId))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Question);