import React, { useState } from "react"
import { List, Tabs, Button, Divider, Row, Col, Statistic, Modal, message, Tooltip } from 'antd';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import dayjs from 'dayjs';
import { ReloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

function HistoryItem(props) {
    const [reload, setReload] = useState(false)

    const pushEvent = (event, params, opts = null) => {
        return props.channel.push(event, params)
    }

    // 加载推送送达的详情
    const handleReportReload = (transaction_id) => {
        setReload(true)
        pushEvent("report-reload", { transaction_id: transaction_id }).receive("ok", () => {
            setReload(false)
            message.success("已刷新")
        })
    }

    // 重新推送一次
    const handleReplay = (transaction_id) => {
        Modal.confirm({
            title: '确定重新推送一次？',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                pushEvent("replay", { transaction_id: transaction_id })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }


    const item = props.item

    return <List.Item>
        <div style={{ border: "1px solid #dfdfdf", padding: 10, borderRadius: 5 }}>
            <div style={{ color: "silver", display: "flex", justifyContent: "space-between" }}>
                <span>{item.msg_id || "发送失败"} </span>
                <Tooltip title={dayjs(item.inserted_at).add(8, 'hours').format("YYYY-MM-DD HH:mm")}>{dayjs(item.inserted_at).add(8, 'hours').fromNow()}</Tooltip>
            </div>
            {item.name && <h3>{item.name}</h3>}
            <Tabs defaultActiveKey="main" tabBarExtraContent={
                <div>
                    <Button size="small" loading={reload} onClick={() => handleReportReload(item.id)} >送达刷新</Button>
                    <Divider type="vertical" />
                    <Button size="small" onClick={() => handleReplay(item.id)} icon={<ReloadOutlined />}>重放</Button>
                    <Divider type="vertical" />
                    <Button disabled size="small">存模板</Button>
                </div>}>
                <TabPane tab="简介" key="main">
                    <p>
                        {item.notification.alert}
                    </p>

                    <Row>
                        <Col span={12}>
                            <Statistic title="提交数量" value={(item.audience?.value?.registration_id?.length || 0 + 0) + (item.audience.alias || 0)} />
                        </Col>
                        <Col span={12}>
                            <Statistic title="极光渠道送达" value={item.receive_details ? item.receive_details.jpush_received : ""} />
                        </Col>
                    </Row>

                    {item.receive_details ? <div>
                        <Row>
                            <Col span={12}>
                                <Statistic title="安卓发送量" value={item.receive_details.android_pns_sent} />
                            </Col>
                            <Col span={12}>
                                <Statistic title="安卓送达量" value={item.receive_details.android_pns_received} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Statistic title="iOS发送量" value={item.receive_details.ios_apns_sent} />
                            </Col>
                            <Col span={12}>
                                <Statistic title="iOS送达量" value={item.receive_details.ios_apns_received} />
                            </Col>
                        </Row>
                    </div> : null}
                </TabPane>
                <TabPane tab="请求体" key="details">
                    <Editor value={{
                        platform: item.platform?.value,
                        audience: item.audience?.value || item.audience,
                        notification: item.notification,
                        options: item.options
                    }} mode="view" language="zh-CN"></Editor>
                </TabPane>
            </Tabs>
        </div>
    </List.Item>
}

export default HistoryItem