import React from "react";
import { List, message, Button, Switch, Form, Input, Radio, Badge, Upload } from 'antd';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { PlusSquareTwoTone, FormOutlined, MobileOutlined, VerticalAlignTopOutlined, EyeOutlined, SortAscendingOutlined, CalendarOutlined } from '@ant-design/icons';
import './Articles.css'
import LocalTimeAgo from "../components/local_time_ago";
import config from '../config'
import ButtonGroup from "antd/lib/button/button-group";
import { Menu as MenuAction } from '../store/global/actions'

class Articles extends React.Component {

    state = {
        articles: {},
        page: 1,
        size: 20,
        loading: false,
        columnTitle: "wiki",
        query: {

        }
    }

    componentDidMount() {
        this.props.setActiveKey("CMS")

        this.loadData(this.props)
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.match.params.column_title !== this.state.columnTitle)
            this.loadData(props)
    }

    loadData(props, page, size) {
        this.setState({ loading: true, columnTitle: props.match.params.column_title })
        const column_title = props.match.params.column_title
        page = page || this.state.page
        size = size || this.state.size
        props.jsonRequest.get(`/api/cms/operation/${column_title}`, { ...this.state.query, page, size })
            .then(response => {
                this.setState({ loading: false })
                return response.json()
            })
            .then(({ data, message: msg }) => {
                if (!!msg) message.warn(msg)
                else
                    this.setState({ articles: data, page: data.page_number, size: data.page_size })
            })
    }

    // 打开app的web页
    appViewUrl(columnTitle, articleTitle) {
        return config.HttpEndpoint(`/api/cms/app/${encodeURI(columnTitle)}/${encodeURI(articleTitle)}`)
    }

    // 修改文章的状态
    handleStatus(columnTitle, articleTitle, status) {
        this.props.jsonRequest.put(`/api/cms/operation/${columnTitle}/${articleTitle}/attrs`, { status: status ? "active" : "disable" })
    }

    // 修改文章是否展示在app的目录中
    handleShowInAppIndex(columnTitle, articleTitle, show) {
        this.props.jsonRequest.put(`/api/cms/operation/${columnTitle}/${articleTitle}/data`, { data: { show_in_app_index: show.toString() } })
    }

    // 搜索
    handleOnSearch(values) {
        let params = {}
        if (values.show_in_app_index !== 'all') params["show_in_app_index"] = values.show_in_app_index
        if (values.status !== 'all') params["status"] = values.status
        if (!!values.query) params["query"] = values.query
        this.setState({ query: params }, () => { this.loadData(this.props) })
    }

    // 重置搜索
    handleSearchReset() {
        this.setState({ query: {} }, () => { this.loadData(this.props) })
    }

    // 搜索
    handlePinTop(columnTitle, articleTitle) {
        this.props.jsonRequest.put(`/api/cms/operation/${columnTitle}/${articleTitle}/pin-top`, {})
            .then(() => {
                message.success("置顶成功")
                this.loadData(this.props)
            })
    }

    // 搜索
    handlePinReset(columnTitle, articleTitle) {
        this.props.jsonRequest.put(`/api/cms/operation/${columnTitle}/${articleTitle}/pin-reset`, {})
            .then(() => {
                message.success("取消置顶成功")
                this.loadData(this.props)
            })
    }

    // 封面上传
    handleCoverUpload(columnTitle, title, obj) {
        this.props.jsonRequest.upload(`/api/cms/operation/${columnTitle}/${title}/cover`, obj.file, {})
            .then(() => {
                message.success("封面上传成功")
                this.loadData(this.props)
            })
    }

    render() {

        return <div className="page cms-articles">
            <div className="actions" style={{ display: "flex" }}>
                <Link to={`/cms/${this.props.match.params.column_title}/articles/new`}><PlusSquareTwoTone /> 新增文章</Link>
            </div>

            <div className="filter">
                <Form name="basic" layout="inline" onReset={this.handleSearchReset.bind(this)} initialValues={{ status: "all", show_in_app_index: "all" }} onFinish={this.handleOnSearch.bind(this)}>
                    <Form.Item name="query" label={false}>
                        <Input className="title-input" placeholder="搜索标题" />
                    </Form.Item>
                    <Form.Item name="status" label={false}>
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="all">全部</Radio.Button>
                            <Radio.Button value="active">激活</Radio.Button>
                            <Radio.Button value="disable">禁用</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="show_in_app_index" label={false}>
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="all">全部</Radio.Button>
                            <Radio.Button value="true">APP的目录显示</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                        <ButtonGroup>
                            <Button type="primary" htmlType="submit" >查询</Button>
                            <Button type="ghost" htmlType="reset">重置</Button>
                        </ButtonGroup>
                    </Form.Item>
                </Form>
            </div>

            <List loading={this.state.loading}
                className="article-list"
                itemLayout="vertical"
                pagination={{
                    onChange: (page, size) => { this.loadData(this.props, page, size) },
                    total: this.state.articles.total_count,
                    current: this.state.page,
                    pageSize: this.state.size,
                    showQuickJumper: true
                }}
                dataSource={this.state.articles.entries}
                renderItem={item => (
                    <List.Item className="item-article" actions={
                        [
                            <Button type="link" onClick={() => this.handlePinTop(item.column_title, item.title)} icon={<VerticalAlignTopOutlined />}>置顶</Button>,
                            <Link to={`/cms/${this.props.match.params.column_title}/${item.title}/edit`}><FormOutlined /> 编辑</Link>,
                            <a target="_blank" rel="noreferrer" href={this.appViewUrl(this.props.match.params.column_title, item.title)}><MobileOutlined /> App页面</a>,
                            <Switch checkedChildren="可用" unCheckedChildren="禁用" onChange={(status) => this.handleStatus(item.column_title, item.title, status)} size="small" defaultChecked={item.status === "active"}></Switch>,
                            <Switch checkedChildren="App目录展示" unCheckedChildren="App目录隐藏" onChange={(status) => this.handleShowInAppIndex(item.column_title, item.title, status)} size="small" defaultChecked={item.data.show_in_app_index === "true"}></Switch>,
                        ]
                    } extra={
                        <Upload
                            name="cover"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={(obj) => this.handleCoverUpload(item.column_title, item.title, obj)}
                        >
                            {item.cover ? <img src={item.cover} alt="avatar" style={{ maxWidth: '100%', maxHeight: "100%" }} /> : <div>
                                <div style={{ marginTop: 8 }}>上传封面</div>
                            </div>}
                        </Upload>
                    }>

                        <div >
                            <div className="title-line">
                                {item.index > 0 ?
                                    <Badge.Ribbon color="silver" text={<Button type="link" onClick={() => this.handlePinReset(item.column_title, item.title)}>顶</Button>} >
                                        <span className="title">{item.title}</span>
                                    </Badge.Ribbon> :
                                    <span className="title">{item.title}</span>
                                }
                            </div>
                            <div className="meta-line">
                                {/* <span className="status"> {item.status === "active" ? "激活" : "禁用"} </span> */}
                                <span className="publishAt">
                                    <CalendarOutlined /> <LocalTimeAgo date={item.publish_at} /> </span>
                                <span className="index">
                                    <SortAscendingOutlined /> {item.index} </span>
                                <span className="view-sum">
                                    <EyeOutlined /> {item.view_sum} </span>
                            </div>
                            <div className="content">{item.summary}</div>
                        </div>
                    </List.Item>
                )
                } >
            </List >

        </div >
    }
}

const mapStateToProps = ({ global }) => {
    return {
        jsonRequest: global.jsonRequest
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Articles);