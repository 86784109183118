import { useEffect, useState } from "react"
import { Button, Radio, Divider, Select, Checkbox, DatePicker, Modal, InputNumber, Tooltip } from "antd"
import "./user_advance_filter.css"
import useLocalStorageState from "use-local-storage-state";
import dayjs from "dayjs";
import { JsonEditor } from 'jsoneditor-react';
import Ajv from 'ajv';
import debounce from 'lodash/debounce';
import { CloseCircleOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;

const ajv = new Ajv({ allErrors: true, verbose: true });

function UserAdvanceFilter({ versionData, channelData, fixedValue = {}, ...props }) {

    const defaultValue = { time_type: "obsolute", login_days_type: "not_in", active_days_type: "not_in", purchase_days_type: "in", ...(props.defautValue || {}), can_push: !!props.canPush, ...fixedValue }
    let [filters, setFilters] = useLocalStorageState("/components/user_advance_filter", defaultValue)
    // push前整理的代码，用于展示筛选项
    const [prePostFilter, setPrePostFilter] = useState([])

    filters = { ...filters, ...fixedValue }

    useEffect(() => {
        onChange(filters)
    }, [])

    const onChange = (clone_filters) => {
        let postFilters = []

        if (!!clone_filters.vip)
            postFilters.push({ type: clone_filters.vip })
        if (!!clone_filters.channel_in && clone_filters.channel_in.length > 0)
            postFilters.push({ type: "channel_in", value: clone_filters.channel_in })
        if (!!clone_filters.version_code_lt)
            postFilters.push({ type: "version_code_lt", value: clone_filters.version_code_lt })
        if (clone_filters.login_days !== null && clone_filters.login_days !== undefined)
            postFilters.push({ type: `login_days_${clone_filters.login_days_type || "not_in"}`, value: clone_filters.login_days })
        if (clone_filters.active_days !== null && clone_filters.active_days !== undefined)
            postFilters.push({ type: `active_days_${clone_filters.active_days_type || "not_in"}`, value: clone_filters.active_days })
        if (clone_filters.purchase_days !== null && clone_filters.purchase_days !== undefined)
            postFilters.push({ type: `purchase_days_${clone_filters.purchase_days_type || "in"}`, value: clone_filters.purchase_days })
        if (!!clone_filters.can_push)
            postFilters.push({ type: "can_push" })
        if (!!clone_filters.channel_not_match)
            postFilters.push({ type: "channel_not_match" })
        if (!!clone_filters.device_brand && clone_filters.device_brand.length > 0)
            postFilters.push({ type: "device_brand", value: clone_filters.device_brand })
        if (!!clone_filters.version_code_eq)
            postFilters.push({ type: "version_code_eq", value: clone_filters.version_code_eq })
        if (!!clone_filters.version_code_gte)
            postFilters.push({ type: "version_code_gte", value: clone_filters.version_code_gte })

        if (!!clone_filters.login_at_between)
            postFilters.push({ type: "login_at_between", value: clone_filters.login_at_between })

        if (!!clone_filters.active_at_between)
            postFilters.push({ type: "active_at_between", value: clone_filters.active_at_between })

        if (!!clone_filters.purchase_at_between)
            postFilters.push({ type: "purchase_at_between", value: clone_filters.purchase_at_between })

        setPrePostFilter(postFilters)
        props.onChange(postFilters)
    }

    const handleFilterSet = (group, value) => {
        let clone_filters = { ...filters, [group]: value }

        // 由于时间的类型更改，则优化筛选项目
        if (clone_filters.time_type === "obsolute") {
            delete clone_filters.login_days
            delete clone_filters.active_days
            delete clone_filters.purchase_days
        } else if (clone_filters.time_type === "relative") {
            delete clone_filters.login_at_between
            delete clone_filters.active_at_between
            delete clone_filters.purchase_at_between
        }

        if (!clone_filters.can_push)
            delete clone_filters.can_push

        setFilters(clone_filters)
        onChange(clone_filters)
        // submit(clone_filters)
    }

    const handleFilterReset = () => {
        setFilters(defaultValue)
        onChange(defaultValue)
        // props.onChange(defaultValue)
    }


    const handleShowRawCode = () => {
        Modal.info({
            title: "筛选源码",
            width: 800,
            content: <JsonEditor ace={ace} mode="code" ajv={ajv} value={prePostFilter} language="zh-CN" />,
            okText: "关闭"
        })
    }

    const versions = () => {
        return versionData?.map(({ name, code }) => ({ name, value: code })) || []
    }

    const brands = ["HUAWEI", "HONOR", "vivo", "Apple", "OPPO", "Redmi", "Xiaomi", "realme", "samsung", "OnePlus", "Hinova", "meizu", "NZONE", "Tianyi", "Liantong", "blackshark", "motorola", "PTAC", "Lenovo", "TDTech", "SMARTISAN", "nubia", "CMDC", "ZTE", "MCI", "Meitu"]

    return <div className={`component user-advance-filter ${props.className}`}>

        {/* <span>
                    模糊搜索：
                    <Input width={150} size="small" onChange={debounce(e => this.handleFilterSet("vip", e.target.value), 500)} />
                </span> */}
        <span className="item">
            <span className="label">时间类型：</span>
            <Radio.Group buttonStyle="solid" value={filters.time_type} onChange={(value) => handleFilterSet("time_type", value.target.value)} size="small">
                <Radio.Button value="relative">相对</Radio.Button>
                <Radio.Button value="obsolute">绝对</Radio.Button>
            </Radio.Group>
        </span>
        <Divider type="vertical" />
        <span className="item">
            <span className="label">会员：</span>
            <Radio.Group size='small' disabled={!!fixedValue.vip}
                onChange={(e) => handleFilterSet("vip", e.target.value)}
                value={filters.vip}
                optionType="button"
                buttonStyle="solid"
            >
                <Radio.Button value="is_vip" >会员</Radio.Button>
                <Radio.Button value="not_vip">不是会员</Radio.Button>
                <Radio.Button value="is_mask">马甲用户</Radio.Button>
                <Radio.Button value="used_be_vip" >曾是会员</Radio.Button>
                <Radio.Button value="not_vip_ordered">下过单非会员</Radio.Button>
                <Radio.Button value="repurchase">复购会员</Radio.Button>
                {/* <Radio.Button value="vip_no_purchase">赠送的会员</Radio.Button> */}
                {/* <Radio.Button value="vip_no_purchase_expired">赠送的会员已过期</Radio.Button> */}
            </Radio.Group>
            <Tooltip title="清空会员类型筛选"><Button icon={<CloseCircleOutlined />} type="link" onClick={() => handleFilterSet("vip", null)}></Button></Tooltip>
        </span>
        {/* <span>
                    模糊搜索：
                    <Input width={150} size="small" onChange={debounce(e => this.handleFilterSet("vip", e.target.value), 500)} />
                </span> */}
        <Divider type="vertical" />
        <span className="item">
            <span className="label">渠道：</span>
            <Select disabled={!!fixedValue.channel_in} allowClear mode="multiple" size="small" value={filters.channel_in} style={{ minWidth: 150 }} onChange={(value) => handleFilterSet("channel_in", value)}>
                {props.channels ? props.channels.map((channel) => <Select.Option key={channel.channel} value={channel.channel}>{channel.name}</Select.Option>) : <>

                    {
                        !!channelData ?
                            channelData.map(channel => <Select.Option key={channel.key} value={channel.key}>{channel.name}</Select.Option>)
                            :
                            <>
                                <Select.Option key="HUAWEI" value="HUAWEI">华为</Select.Option>
                                <Select.Option value="APPLE">苹果</Select.Option>
                                <Select.Option value="BDSEM-JLIOS-LK-1">苹果LK-1</Select.Option>
                                <Select.Option value="BDSEM-JLIOS-XS-1">苹果XS-1</Select.Option>
                                <Select.Option value="TENCENT">腾讯</Select.Option>
                                <Select.Option value="OPPO">OPPO</Select.Option>
                                <Select.Option value="vivo">VIVO</Select.Option>
                                <Select.Option value="MI">小米</Select.Option>
                                <Select.Option value="MEIZU">魅族</Select.Option>
                                <Select.Option value="ALI">阿里</Select.Option>
                                <Select.Option value="BAIDU_STORE">百度商店</Select.Option>
                                <Select.Option value="BDSEM-JL-XS-1">百度推广XS-1</Select.Option>
                                <Select.Option value="BDSEM-JL-ZD-2">百度推广ZD-2</Select.Option>
                                <Select.Option value="BDSEM-JL-LK-1">百度推广LK-1</Select.Option>
                                <Select.Option value="BDSEM-JL-LK-6">百度推广LK-6</Select.Option>
                                <Select.Option value="BDSEM-JL-ZD-1">百度推广ZD-1</Select.Option>
                                <Select.Option value="weibo">微博</Select.Option>
                                <Select.Option value="RAW">自发</Select.Option>
                            </>
                    }
                </>}
            </Select>
        </span>
        <Divider type="vertical" />
        <span className="item">
            <span className="label">手机品牌：</span>
            <Select disabled={!!fixedValue.device_brand} mode="tags" allowClear size="small" value={filters.device_brand} style={{ minWidth: 150 }} onChange={(value) => handleFilterSet("device_brand", value)}>
                {brands.map(item => <Select.Option value={item} key={item}>{item}</Select.Option>)}
            </Select>
        </span>
        <Divider type="vertical" />
        <span className="item">
            <span className="label">版本号小于：</span>
            <Select disabled={!!fixedValue.version_code_lt} allowClear size="small" value={filters.version_code_lt} style={{ minWidth: 100 }} onChange={(value) => handleFilterSet("version_code_lt", value)}>
                {versions().map(item => <Select.Option value={item.value} key={item.value}>{item.name}</Select.Option>)}
            </Select>
        </span>
        <Divider type="vertical" />
        <span className="item">
            <span className="label">版本号等于：</span>
            <Select disabled={!!fixedValue.version_code_eq} allowClear size="small" value={filters.version_code_eq} style={{ minWidth: 100 }} onChange={(value) => handleFilterSet("version_code_eq", value)}>
                {versions().map(item => <Select.Option value={item.value} key={item.value}>{item.name}</Select.Option>)}
            </Select>
        </span>
        <Divider type="vertical" />
        <span className="item">
            <span className="label">版本号不小于：</span>
            <Select disabled={!!fixedValue.version_code_gte} allowClear size="small" value={filters.version_code_gte} style={{ minWidth: 100 }} onChange={(value) => handleFilterSet("version_code_gte", value)}>
                {versions().map(item => <Select.Option value={item.value} key={item.value}>{item.name}</Select.Option>)}
            </Select>
        </span>

        <Divider type="vertical" />
        <span className="item">
            <span className="label">最近登录时间：</span>
            {filters.time_type === "obsolute" ?
                <RangePicker format="YYYY-MM-DD HH:mm:ss" showTime size='small' value={!!filters.login_at_between ? filters.login_at_between.map(dayjs) : null} onChange={(value) => handleFilterSet("login_at_between", value)} />
                : <>
                    <InputNumber min={0} addonAfter={<Select value={filters.login_days_type} onChange={value => handleFilterSet("login_days_type", value)} className="select-after">
                        <Select.Option value="in">天之内</Select.Option>
                        <Select.Option value="not_in">天以前</Select.Option>
                    </Select>} value={filters.login_days} style={{ width: 160 }} size="small" onChange={debounce((value) => handleFilterSet("login_days", value), 500)}></InputNumber>
                </>}
        </span>
        <Divider type="vertical" />
        <span className="item">
            <span className="label">激活时间：</span>
            {filters.time_type === "obsolute" ?
                <RangePicker format="YYYY-MM-DD HH:mm:ss" showTime size='small' value={!!filters.active_at_between ? filters.active_at_between.map(dayjs) : null} onChange={(value) => handleFilterSet("active_at_between", value)} />
                :
                <>
                    <InputNumber min={0} addonAfter={<Select value={filters.active_days_type} onChange={value => handleFilterSet("active_days_type", value)} className="select-after">
                        <Select.Option value="in">天之内</Select.Option>
                        <Select.Option value="not_in">天以前</Select.Option>
                    </Select>} value={filters.active_days} style={{ width: 160 }} size="small" onChange={debounce((value) => handleFilterSet("active_days", value), 500)}></InputNumber>
                </>}
        </span>
        <Divider type="vertical" />
        <span className="item">
            <span className="label">购买会员时间：</span>
            {filters.time_type === "obsolute" ?
                <RangePicker format="YYYY-MM-DD HH:mm:ss" showTime size='small' value={!!filters.purchase_at_between ? filters.purchase_at_between.map(dayjs) : null} onChange={(value) => handleFilterSet("purchase_at_between", value)} />
                : <>
                    <InputNumber min={0} addonAfter={<Select value={filters.purchase_days_type} onChange={value => handleFilterSet("purchase_days_type", value)} className="select-after">
                        <Select.Option value="in">天之内</Select.Option>
                    </Select>} value={filters.purchase_days} style={{ width: 160 }} size="small" onChange={debounce((value) => handleFilterSet("purchase_days", value), 500)}></InputNumber>
                </>}
        </span>
        {props.canPush === undefined && <>
            <Divider type="vertical" />
            <Checkbox disabled={!!fixedValue.can_push} checked={filters.can_push} onChange={e => handleFilterSet("can_push", e.target.checked)}>可推送</Checkbox>
        </>}
        <Divider type="vertical" />
        {props.canPush === undefined && <>
            <Divider type="vertical" />
            <Checkbox disabled={!!fixedValue.channel_not_match} checked={filters.channel_not_match} onChange={e => handleFilterSet("channel_not_match", e.target.checked)}>渠道不匹配</Checkbox>
        </>}
        <Divider type="vertical" />
        <Button type="link" onClick={() => props.onChange(prePostFilter)}>搜索</Button>
        <Divider type="vertical" />
        <Button type="link" onClick={handleFilterReset}>重置</Button>
        <Divider type="vertical" />
        <Button type="link" onClick={handleShowRawCode} >查看筛选代码</Button>
        {props.handles}
    </div>
}

export default UserAdvanceFilter;