import React, { useState } from "react"
import { List, Form, Tabs, Button, Row, Col, Statistic, Modal, Tooltip, Table, InputNumber, Select, message, Upload, Image } from 'antd';
import 'jsoneditor-react/es/editor.min.css';
import { InfoCircleOutlined, DeleteRowOutlined, WechatFilled } from '@ant-design/icons';
import PlanForm from "./plan_form";
import ContractItem from "./contract_item";
import Config from "../../config";
import { UploadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import MarketingPlanRewardBG from "../../images/marketing_plan_reward_bg.png"
import Logo from "../../images/logo.png"
import Sha256Encode from "../../components/sha256";

const { TabPane } = Tabs;
const { Column } = Table;

function PlanItem({ jsonRequest, ...props }) {
    const [rewards, setRewards] = useState([])
    const [sales, setSales] = useState([])
    const [configurableRewards, setConfigurableRewards] = useState([])
    const [configurableSales, setConfigurableSales] = useState([])
    const [showPlanForm, setShowPlanForm] = useState(false)
    const [planContract, setPlanContract] = useState(null)

    const [saleLogo, setSaleLogo] = useState(null)
    const [rewardLogo, setRewardLogo] = useState(null)

    const pushEvent = (event, params, opts = null) => {
        return props.channel.push(event, params)
    }

    const plan = props.item
    const editable = props.editable

    // 结算类型翻译
    const settleTypeLabel = (value) => {
        switch (value) {
            case "cycle":
                return "循环"

            case "single":
                return "单次"
            default:
                break;
        }
    }

    // 周期元数据翻译
    const durationMetaLabel = (value) => {
        switch (value) {
            case "acupoint:duration::day":
                return "天"

            case "acupoint:duration::month":
                return "月"

            case "acupoint:duration::year":
                return "年"

            case "acupoint:duration::lifelong":
                return "终身"
            default:
                break;
        }
    }

    //记载关联的报酬
    const loadRefRewards = () => {
        pushEvent("get-plan-reward", { key: plan.key })
            .receive("ok", ({ data }) => {
                setRewards(data)
            })
    }
    // 加载关联的营销品
    const loadRefSales = () => {
        pushEvent("get-plan-sale", { key: plan.key })
            .receive("ok", ({ data }) => {
                setSales(data)
            })
    }

    const handleUploadFile = (obj, field) => {
        const file = obj.file
        message.loading({ content: "上传中，请稍后...", key: "handleUploadFile", duration: 0 })
        file.arrayBuffer().then(file_content => {
            Sha256Encode(file_content).then(file_name => {
                var data = new FormData()
                data.append('file', file)
                data.append('filename', file_name)
                data.append('owner_type', "Marketing")
                data.append('owner_id', "Plan")

                jsonRequest.file(`/api/operation/media/upload`, data)
                    .then(resp => resp.json())
                    .then(({ data }) => {
                        const { path } = data
                        message.success({ content: "上传成功", key: "handleUploadFile", duration: 2 })
                        switch (field) {
                            case "rewardLogo":
                                setRewardLogo(path)
                                break;
                            case "saleLogo":
                                setSaleLogo(path)

                            default:
                                break;
                        }
                    })
            })
        })

    }

    const onTabsChange = (activeKey) => {
        switch (activeKey) {
            case "reward":
                loadRefRewards()
                pushEvent("get-configurable-rewards", {})
                    .receive("ok", ({ data }) => setConfigurableRewards(data))
                break;

            case "sale":
                loadRefSales()
                pushEvent("get-configurable-sales", {})
                    .receive("ok", ({ data }) => {
                        setConfigurableSales(data)
                    })
                break;

            case "contract":
                pushEvent("get-plan-contract", { key: plan.key })
                    .receive("ok", ({ data }) => setPlanContract(data))
                break;

            default:
                break;
        }
    }

    const onEditClick = () => {
        setShowPlanForm(true)
    }

    // 添加营销品
    const onClickAddSale = (values) => {
        pushEvent("add:reference:sale", { ...values, key: plan.key, logo: saleLogo })
            .receive("ok", () => loadRefSales())
    }

    // 添加报酬
    const onClickAddReward = (values) => {
        pushEvent("add:reference:reward", { ...values, key: plan.key, logo: rewardLogo })
            .receive("ok", () => loadRefRewards())
    }


    // 添加营销品
    const onClickDropSale = (sale_id) => {
        pushEvent("drop:reference:sale", { sale_id: sale_id, key: plan.key })
            .receive("ok", () => loadRefSales())
    }


    // 添加营销品
    const onClickDropReward = (reward_key) => {
        pushEvent("drop:reference:reward", { reward_key: reward_key, key: plan.key })
            .receive("ok", () => loadRefRewards())
    }


    return <List.Item>
        <div style={{ border: "1px solid #dfdfdf", padding: 10, borderRadius: 5 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3>{plan.key}</h3>
                <h3>{plan.name} <small> 版本:{plan.version}</small></h3>
            </div>
            <div style={{ display: "flex" }}>
                <div style={{
                    margin: "0 auto",
                    width: 335,
                    height: 163,
                    backgroundImage: `url(${Config.ResourceUrl(plan?.data?.app_banner_bg_path)})`, backgroundRepeat: "no-repeat", backgroundSize: "contain",
                    position: "relative"
                }}>
                    <h2 style={{ position: "absolute", top: 20, left: 30, color: "#262220" }}>{plan.display_name}</h2>
                    <span style={{ position: "absolute", top: 55, left: 30, color: "#262220" }}>
                        {plan.slogan}
                    </span>
                    <span style={{ position: "absolute", bottom: 20, left: 20, display: "flex", alignItems: "center" }}><img width={36} src={Config.ResourceUrl(plan?.coin_logo)} /> {plan.coin_unit}</span>
                    <button style={{ display: "block", background: plan.data?.theme_color?.hex, position: "absolute", right: 20, bottom: 27, border: 0, color: "#fff" }}>分享好友</button>
                </div>
                <div style={{
                    marginLeft: 5,
                    width: 155,
                    background: "#fafafa",
                    padding: 10,
                    borderRadius: 10
                }}>
                    <div style={{ color: "green" }}>
                        <WechatFilled /> 微信分享消息预览
                    </div>
                    <div style={{ fontSize: 16 }}>{plan.data?.wechat_share_title}</div>
                    <div style={{ color: "#bbb" }}>{plan.data?.wechat_share_summary.length > 40 ? `${plan.data?.wechat_share_summary.substr(0, 40)}..` : plan.data?.wechat_share_summary}</div>
                    <hr style={{ border: "none", height: 1, background: "#ddd" }} />
                    <div style={{ fontSize: 12, display: "flex", alignItems: "center" }}> <img src={Logo} width={18} /> 中济经络穴位</div>
                </div>
            </div>
            <Tabs defaultActiveKey="main" onChange={onTabsChange} tabBarExtraContent={
                props.editable ?
                    <div>
                        <Button size="small" onClick={onEditClick}>编辑</Button>
                    </div> : null}>
                <TabPane tab="简介" key="main">
                    <Row>
                        <Col span={8}>
                            <Tooltip title="结算周期，分为单次和循环，表示此计划下的营销行为可完成单次还是无限次。">
                                <Statistic title={<span>结算周期 <InfoCircleOutlined /></span>} value={settleTypeLabel(plan.settle_type)} />
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Statistic title="积分单位名称" value={plan.coin_unit} />
                        </Col>
                        <Col span={8}>
                            <Statistic title="有效时长" value={`${plan.duration}${durationMetaLabel(plan.duration_meta)}`} />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab="报酬" key="reward">
                    {editable ?
                        <Form labelCol={{ xs: { span: 8 } }} wrapperCol={{ xs: { span: 14 } }} onFinish={onClickAddReward}>
                            <Form.Item name="reward_key" label="报酬" rules={[{ required: true }]} >
                                <Select>
                                    {configurableRewards.map(item =>
                                        <Select.Option key={item.key} value={item.key}>{item.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item name="coin_num" label="兑换所需积分" rules={[{ required: true }]} >
                                <InputNumber min={1} addonAfter={plan.coin_unit} />
                            </Form.Item>
                            <Form.Item label="图标" >
                                <Upload.Dragger showUploadList={false} customRequest={(obj) => handleUploadFile(obj, "rewardLogo")}
                                >
                                    {rewardLogo ? <Image src={Config.ResourceUrl(rewardLogo)} /> : <> <p className="ant-upload-drag-icon">
                                        <UploadOutlined />
                                    </p>
                                        <p className="ant-upload-text">点击或拖拽上传</p></>}

                                </Upload.Dragger>
                            </Form.Item>
                            <Form.Item wrapperCol={{ xs: { span: 4, offset: 6, } }}>
                                <Button type="primary" htmlType="submit" >
                                    添加
                                </Button>
                            </Form.Item>
                        </Form> : null}

                    {rewards.map(reward => {
                        return <div style={{
                            margin: "10px auto",
                            background: `url('${MarketingPlanRewardBG}') center no-repeat`, backgroundSize: "contain", width: 340, height: 120,
                            padding: 30,
                            position: "relative"
                        }}>
                            <div style={{ fontSize: 20 }}>{reward.name}</div>
                            <div>{reward.coin_num}{plan.coin_unit}兑换</div>
                            <div style={{ margin: "10px 0" }}><Button danger size="small" type="primary" onClick={() => onClickDropReward(reward.key)} icon={<DeleteRowOutlined />}>删除</Button></div>
                            <div style={{ position: "absolute", right: 0, bottom: 0 }}><Image src={Config.ResourceUrl(reward.logo)}></Image></div>
                        </div>
                    })}
                </TabPane>
                <TabPane tab="营销品" key="sale">
                    {editable ?
                        <Form labelCol={{ xs: { span: 6 } }} wrapperCol={{ xs: { span: 14 } }} onFinish={onClickAddSale}>
                            <Form.Item name="sale_id" label="营销品" rules={[{ required: true }]} >
                                <Select>
                                    {configurableSales.map(item =>
                                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item name="price" label="出售价格" rules={[{ required: true }]} help="单位分">
                                <InputNumber min={1} />
                            </Form.Item>
                            <Form.Item name="coin_num" label="获得积分" initialValue={1} rules={[{ required: true }]} help="可以为小数，只对结算单位为积分时有效" >
                                <InputNumber min={1} addonAfter={plan.coin_unit} />
                            </Form.Item>
                            <Form.Item label="图标" >
                                <Upload.Dragger
                                    showUploadList={false}
                                    customRequest={(obj) => handleUploadFile(obj, "saleLogo")}
                                >
                                    {saleLogo ? <Image src={Config.ResourceUrl(saleLogo)} /> : <> <p className="ant-upload-drag-icon">
                                        <UploadOutlined />
                                    </p>
                                        <p className="ant-upload-text">点击或拖拽上传</p></>}

                                </Upload.Dragger>
                            </Form.Item>
                            <Form.Item wrapperCol={{ xs: { span: 4, offset: 6, } }}>
                                <Button type="primary" htmlType="submit" >
                                    添加
                                </Button>
                            </Form.Item>
                        </Form> : null}

                    {sales.map(sale => {
                        return <div style={{
                            margin: "10px auto",
                            background: `url('${MarketingPlanRewardBG}') center no-repeat`,
                            backgroundSize: "contain", width: 340, height: 120,
                            padding: 30,
                            position: "relative"
                        }}>
                            <div style={{ fontSize: 20 }}>{sale.name}</div>
                            <div>{sale.price}人民币分 / {sale.price / 100}人民币元</div>
                            <div>可得 <span style={{ fontWeight: "800", color: plan?.data?.theme_color?.hex }}>{sale.coin_num}</span> {plan.coin_unit}</div>
                            <div style={{ margin: "10px 0" }}>
                                <Button danger size="small" type="primary" onClick={() => onClickDropSale(sale.id)} icon={<DeleteRowOutlined />}>删除</Button></div>
                            <div style={{ position: "absolute", right: 0, bottom: 0 }}>
                                <img src={Config.ResourceUrl(sale.logo)} style={{ maxWidth: 200 }}></img>
                            </div>
                        </div>
                    })}
                </TabPane>
                <TabPane tab="协议" key="contract">
                    {planContract &&
                        <ContractItem item={planContract} readonly={!editable} channel={props.channel} ></ContractItem>}
                </TabPane>
            </Tabs>

            <Modal title="新增计划" open={showPlanForm} width={1400} footer={false} onCancel={() => setShowPlanForm(false)}>
                <PlanForm plan={plan} channel={props.channel} closeModal={() => setShowPlanForm(false)} />
            </Modal>
        </div>
    </List.Item >
}

const mapStateToProps = ({ global }) => {
    return {
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(PlanItem)