import { useEffect, useState } from "react"
import { List } from "antd"
import UserAvatar from "../../components/user/UserAvatar"
import dayjs from "dayjs"
import LocalTimeAgo from "../../components/local_time_ago"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import "./Report.css"
import { LinkOutlined } from "@ant-design/icons"

function VideoCourseReport(props) {
    let [data, setData] = useState({})
    let [channel, setCannel] = useState(null)

    useEffect(
        () => {
            const _channel = props.socket.channel("ops:resource:video:course:report", { size: 21 })
            _channel.join()
            _channel.on("data", (data) => {
                console.log(data)
                setData(data)
            })
            setCannel(_channel)

            return () => {
                !!_channel && _channel.leave()
            }
        }, []
    )

    const handlePageTo = (page, size) => {
        channel.push("page-to", { page, size })
    }
    console.log(data)
    return <div className="page cms-video-course-report">
        <h1>课程反馈</h1>
        <List dataSource={data.entries}
            grid={{ gutter: 16, column: 3 }}
            itemLayout="horizontal"
            pagination={{
                onChange: (page, size) => { handlePageTo(page, size) },
                total: data.total_entries,
                current: data.page_number,
                pageSize: data.page_size || 0,
                showQuickJumper: true
            }}
            renderItem={item => (
                <List.Item key={`report-${item.id}`} className="item" actions={[
                    <Link target="_blank" to={`/cms/video-course-list/${item.album.id}/editor`} > <LinkOutlined /> {item.album.name}</Link>
                ]}>
                    <List.Item.Meta
                        avatar={<UserAvatar nickname={item.user.nickname} size={40} avatar={item.user.avatar} id={item.user.id} vip={item.user.group === "vip"} />}
                        title={<span>{dayjs(item.inserted_at).add(8, 'hour').format("YYYY-MM-DD HH:mm")} <LocalTimeAgo date={dayjs(item.inserted_at).add(8, 'hours').format("YYYY-MM-DD HH:mm")} /></span>}
                        description={item.content}

                    />
                </List.Item>
            )}
        />
    </div>
}



const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        user: global.user
    }
}
const mapDispatchToProps = (dispatch) => {
    return {

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VideoCourseReport)