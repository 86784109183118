import { Form, Input, Button, message, Checkbox } from 'antd'
import useLocalStorageState from 'use-local-storage-state'
import { connect } from "react-redux";
import { Session } from './store/global/actions'
import { useState } from 'react';

function SignIn(props) {
    const [user, setLocalStorageUser] = useLocalStorageState('sign-in-user', null)
    const [currentEvidence, setCurrentEvidence] = useLocalStorageState('current-evidence', null)
    const [submiting, setSubmitting] = useState(false)

    const signInAndRedirect = (user, remember) => {
        if (remember) setLocalStorageUser(user)
        props.signIn(user)
        props.history.replace("/")
    }

    if (!!user) signInAndRedirect(user)

    const onFinish = (values) => {
        setSubmitting(true)

        setCurrentEvidence({ account: values.account, type: "password" })
        props.jsonRequest.post("/api/password/login", values)
            .then(response => {
                setSubmitting(false)
                return response.json()
            })
            .then(({ code, data: user, message: msg }) => {
                switch (code) {
                    case 0:
                        message.success("登录成功")
                        signInAndRedirect(user, values.remember)
                        break;
                    default:
                        message.warning(msg)
                        break;
                }
            });
    }

    const onFinishFailed = () => {

    }


    return <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Form
            style={{ width: 300, marginRight: 100 }}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true, account: currentEvidence?.account }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item label="用户名" name="account" rules={[{ required: true, message: '账号不能为空' }]}>
                <Input />
            </Form.Item>

            <Form.Item label="密码" name="password" rules={[{ required: true, message: '密码不能为空' }]}>
                <Input.Password />
            </Form.Item>
            <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                <Checkbox>记住我？</Checkbox>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" block loading={submiting}>
                    登录
                </Button>
            </Form.Item>
        </Form>
    </div>
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        user: global.user,
        jsonRequest: global.jsonRequest
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (user) => dispatch(Session.signedIn(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)