import { useEffect, useState } from "react"
import { Table, Progress } from 'antd'
import LocalTimeAgo from "../../local_time_ago";

function CoinPool(props) {
    // 校验日志数据
    const [data, setDate] = useState([])

    const handlePageTo = (page) => {
        props.pushEvent("coin-pool", { currency_meta: "zy:application:acupoint:currency::coin" })
            .receive("ok", (data) => {
                setDate(data)
            })
    }

    useEffect(() => {
        handlePageTo(1)
    }, [props.userId])

    return <>
        <Table dataSource={data} pagination={false}>
            <Table.Column align="left" title="事件名" dataIndex="name" key="name" />
            <Table.Column align="left" title="进度" dataIndex="amount" key="amount" render={(v, item) => <Progress size={item.trigger_times > 5 ? "small" : "default"} steps={item.trigger_times} percent={Math.floor(item.pool?.times / item.trigger_times * 100)} />} />
            <Table.Column align="center" title="重置时间" dataIndex={["pool", "reset_at"]} key={["pool", "reset_at"]} render={(v) => !!v && <LocalTimeAgo date={v * 1000} />} />
        </Table>
    </>
}

export default CoinPool;