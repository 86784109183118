import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Tag, message } from "antd"
import { Menu as MenuAction } from '../store/global/actions'


function GraphSuggests({ socket, setActiveKey }) {
    const [data, setDate] = useState([])
    const [channel, setChannel] = useState(null)

    setActiveKey("GRAPH-SUGGEST")

    useEffect(() => {
        let _channel = socket.channel("operation:graph:suggest")
        _channel.join()

        _channel.on("data", ({ data }) => {
            console.log(data)
            setDate(data)
        })
        setChannel(_channel)

        return () => {
            !!_channel && _channel.leave()
        }
    }, [])

    const handleClickRemove = (content_type, content_id) => {
        channel.push("remove", { content_type, content_id })
            .receive("ok", () => message.success("删除成功"))
    }

    return <div className="page" style={{ display: "flex", height: "100%" }}>
        <div style={{ display: "flex", flexGrow: 1 }}>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }} >
                <div className="phone-wraper" >
                    <div className="title" >知识图谱</div>
                    <div className="body" style={{ justifyContent: "flex-start", padding: 20 }}>
                        <div style={{ textAlign: "center" }}>
                            <p>探索中医关系 认识中医真谛</p>
                            <p>更专业的知识图谱</p>
                        </div>
                        <div style={{ width: "90%", height: 36, background: "#eee", borderRadius: "30px" }}></div>
                        <div style={{ width: "85%", marginTop: 20 }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}><span>大家关注</span><span>换一换</span></div>
                            <div>
                                {data.map(item => <Tag key={`${item.content_type}_${item.content_id}`} closable onClose={() => handleClickRemove(item.content_type, item.content_id)} style={{ background: "#fff", margin: 2 }}>
                                    {item.content_name}
                                </Tag>)}
                            </div>
                        </div>
                    </div>
                    {/* <div className="footer"></div> */}
                </div>
            </div>
        </div>
    </div>
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        user: global.user
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(GraphSuggests)