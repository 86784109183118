import React, { useState, useEffect } from "react"
import { Timeline, Pagination, Tooltip, Empty } from 'antd'
import { Comment } from '@ant-design/compatible';
import dayjs from 'dayjs';
import { CrownTwoTone } from '@ant-design/icons';

import "./BehaviorLog.css"

// 当评价页数变更时执行
function handlePageChange(setPagedData, props, page, pageSize) {
    props.pushEvent("behavior-log-list", { page, size: pageSize })
        .receive("ok", ({ data }) => {
            setPagedData(data)
        })
}

function BehaviorLog(props) {
    // 记事
    const [pagedData, setPagedData] = useState({})

    useEffect(() => {
        handlePageChange(setPagedData, props, 1, 5)
    }, [props.userId])


    return !!pagedData && pagedData.total_entries > 0 ?
        <div class="user-information-tabs behavior-logs">
            <Pagination size="small" onChange={(page, size) => handlePageChange(setPagedData, props, page, size)} showQuickJumper pageSize={pagedData?.page_size} current={pagedData?.page_number} total={pagedData?.total_entries} />
            <br />
            <Timeline>
                {pagedData.entries.map((log) => {
                    return <Timeline.Item key={log.id} >
                        <Comment
                            // actions={item.actions}
                            author={<span style={{ color: "green" }}>{log.is_vip && <CrownTwoTone twoToneColor="#eb2f96" />} {log.page?.name || "未知页面"}</span>}
                            // avatar={""}
                            content={<>
                                <p>页面路径：{log.page?.path}</p>
                                <p>行为类型：{log.event.action_type || "未分类"}</p>
                                <p>目标：{log.event.target}</p>
                                <p>模块：{log.event.module}</p>
                                <p>版本：{log.version_code}</p>
                            </>}
                            datetime={<Tooltip title={dayjs(log.inserted_at).add(8, 'hours').format("YYYY-MM-DD HH:mm:ss")}>
                                <span>{dayjs(log.inserted_at).add(8, 'hours').fromNow()}</span>
                            </Tooltip>}
                        />
                    </Timeline.Item>
                })}
            </Timeline>
            <Pagination size="small" onChange={(page, size) => handlePageChange(setPagedData, props, page, size)} showQuickJumper pageSize={pagedData?.page_size} current={pagedData?.page_number} total={pagedData?.total_entries} />
        </div>
        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
}

export default BehaviorLog;