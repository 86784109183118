
import { Table, Progress } from "antd";
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import dayjs from "dayjs";
import UserAvatar from "../components/user/UserAvatar"

function CoinPool(props) {

    const [channel, setChannel] = useState(null)
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let _channel = props.socket.channel("finance:coin:pool", { page: 1, size: 15 })
        _channel.join()

        _channel.on("data", ({ data }) => {
            setLoading(false)
            setData(data)
        })

        setChannel(_channel)
        return () => {
            !!_channel && _channel.leave()
        }
    }, [])

    const handlePageTo = (page, size) => {
        channel.push("page-to", { page, size })
    }


    return <div className="page coin-code-table">
        <h1>积分池</h1>

        <div className="content">
            <Table dataSource={data?.entries} loading={loading}
                scroll={{ y: document.documentElement.clientHeight - 350 }}
                pagination={{
                    onChange: (page, size) => { handlePageTo(page, size) },
                    total: data.total_entries,
                    current: data.page_number,
                    pageSize: data.page_size,
                    showQuickJumper: true
                }}>
                <Table.Column align="left" title="事件key" dataIndex="event_key" key="event_key" />
                <Table.Column align="left" title="事件名" dataIndex="name" key="name" />
                <Table.Column align="center" title="用户" dataIndex="owner" key="owner" render={v => !!v && <UserAvatar nickname={v.nickname} size={40} avatar={v.avatar} id={v.id} />} />
                <Table.Column align="left" title="进度" dataIndex="amount" key="amount" render={(v, item) => <Progress size={item.trigger_times > 5 ? "small" : "default"} steps={item.trigger_times} percent={Math.floor(item.times / item.trigger_times * 100)} />} />
                <Table.Column align="center" title="重置时间" dataIndex="reset_at" key="reset_at" render={(v) => !!v && dayjs(v * 1000).format("YYYY-MM-DD HH:mm")} />
                <Table.Column align="center" title="完成时间" dataIndex="completed_at" key="completed" render={(v) => !!v && dayjs(v * 1000).format("YYYY-MM-DD HH:mm")} />
                <Table.Column align="center" title="完成获得积分" dataIndex="completed_coin_amount" key="completed_coin_amount" />
            </Table>
        </div>
    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CoinPool);