import { useEffect } from "react";
import { connect } from "react-redux";
import { AppUserChannel as AppUserChannelAction, OnlineUsers } from "../store/global/actions"

function AppOnlineUserChannel(props) {
    let channel = props.channel

    useEffect(() => {
        if (!channel) {
            channel = props.socket.channel("ops:online:app-user")
            channel.join()
            channel.on("data", (data) => {
                data.online_user_list.forEach(meta => {
                    meta.channel = meta.user?.nt_channel || meta.app_info?.channel
                    meta.version = meta.user?.version || meta.app_info?.version
                })

                props.onlineUsersSet(data)
            })
            channel.on("route:update", ({ user_id, route }) => {
                props.routeUpdate(user_id, route)
            })
            props.join(channel)
        }

        return () => {
            channel.off("data");
            channel.off("route:update");
        }
    }, [])


    return <></>
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        channel: global.appOnlineChannel,
        onlineUsers: global.onlineUsers
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        join: (channel) => {
            dispatch(AppUserChannelAction.join(channel))
        },
        routeUpdate: (user_id, route) => {
            dispatch(OnlineUsers.routeUpdate(user_id, route))
        },
        onlineUsersSet: (users) => {
            dispatch(OnlineUsers.set(users))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppOnlineUserChannel)