import { HashRouter as Router, Route } from "react-router-dom";
import { Menu as MenuAction } from '../store/global/actions'
import { connect } from 'react-redux';
import SideMenu from "./components/side_menu"
import React from "react";
import Pricing from "./Pricing";
import FinanceOrder from "./FinanceOrder";
import FinanceOrderRefund from "./FinanceOrderRefund"
import AlipayAgreement from "./AlipayAgreement";
import CoinCodeTable from "./CoinCodeTable";
import CoinPool from "./CoinPool";
import DistributedAccountFlow from "./DistributedAccountFlow";
import CoinUnAchievedCloakIn from "./CoinUnAchievedCloakIn";

import RequireRolesOr from '../components/require_roles_or'

class Finance extends React.Component {

    componentDidMount() {
        this.props.setActiveKey("FINANCE")
    }

    render() {
        return <Router>
            <div className="module module-statistics">
                <div className="side-menu">
                    <SideMenu />
                </div>
                <div className="content">
                    <Route path="/finance/pricing/:meta" exact component={Pricing}></Route>

                    <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::finance"]}>
                        <Route path="/finance/refund" exact component={FinanceOrderRefund}></Route>
                        <Route path="/finance/trade-order" exact component={FinanceOrder}></Route>
                        <Route path="/finance/alipay/agreement" exact component={AlipayAgreement}></Route>
                        <Route path="/finance/coin/code-table" exact component={CoinCodeTable}></Route>
                        <Route path="/finance/coin/pool" exact component={CoinPool}></Route>
                        <Route path="/finance/coin/unachieved/cloak-in" exact component={CoinUnAchievedCloakIn}></Route>
                        <Route path="/finance/coin/distributed/finance/account-flow" exact component={DistributedAccountFlow}></Route>
                        {/* <Route path="/marketing/target" exact component={NinetonLog}></Route>
                        <Route path="/marketing/plan" exact component={ActiveRecords}></Route> */}
                    </RequireRolesOr>
                </div>
            </div>
        </Router>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        activeKey: global.navActiveKey
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Finance)