import React, { useEffect } from "react";
import { Menu, message } from 'antd';
import { useLocation, Link } from "react-router-dom";
import { useState } from 'react';
import { connect } from 'react-redux';
import { OrderedListOutlined } from '@ant-design/icons';
import RequireRolesOr from '../../components/require_roles_or'

function SideMenu(props) {
    const location = useLocation()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")
    const fullPathName = location.pathname

    let [colums, setColumns] = useState([])

    useEffect(() => {
        props.jsonRequest.get("/api/cms/operation/columns")
            .then(response => response.json())
            .then(({ data, message: msg }) => {
                if (!!msg) message.warn(msg)
                else
                    setColumns(data)
            })
    }, [props.jsonRequest])

    return <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::editor"]} >
        <Menu style={{ width: 256 }} selectedKeys={[pathName, fullPathName]} mode="inline" >
            {/* <Menu.ItemGroup key="articles" title="文章">
            <Menu.Item key="/cms/article" icon={<ProjectTwoTone />}>
                <Link to="/cms/article/new">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>新建文章</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup> */}
            <Menu.ItemGroup key="columns" title="栏目">
                {colums.length > 0 && colums.map(col => {
                    return <Menu.Item key={`/cms/${col.title}`}>
                        <Link to={`/cms/${col.title}/articles`}>
                            <span>{col.title}</span>
                        </Link>
                    </Menu.Item>
                })}
            </Menu.ItemGroup>
            <Menu.ItemGroup key="entry" title="词条 Entry">
                <Menu.Item key="/cms/entry/list">
                    <Link to="/cms/entry/list">
                        <span><OrderedListOutlined /> 词条列表</span>
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="video_course" title="视频课程 Video Course">
                <Menu.Item key="/cms/video-course-list">
                    <Link to="/cms/video-course-list">
                        <span><OrderedListOutlined /> 课程列表</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/cms/video-course/report">
                    <Link to="/cms/video-course/report">
                        <span><OrderedListOutlined /> 课程反馈</span>
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="book" title="图书 Book">
                <Menu.Item key="/cms/book">
                    <Link to="/cms/book">
                        <span><OrderedListOutlined /> 图书列表</span>
                    </Link>
                </Menu.Item>
                {/* <Menu.Item key="/cms/book/category">
                    <Link to="/cms/book/category">
                        <span><OrderedListOutlined /> 图书分类</span>
                    </Link>
                </Menu.Item> */}
            </Menu.ItemGroup>
            <Menu.ItemGroup key="meridian" title="经络 Meridian">
                <Menu.Item key="/cms/meridian/ancestor">
                    <Link to="/cms/meridian/ancestor">
                        <span><OrderedListOutlined /> 成人</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/cms/meridian/baby">
                    <Link to="/cms/meridian/baby">
                        <span><OrderedListOutlined /> 小儿</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/cms/meridian/dongs">
                    <Link to="/cms/meridian/dongs">
                        <span><OrderedListOutlined /> 董氏奇穴</span>
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="acupoint" title="穴位 Acupoint">
                <Menu.Item key="/cms/acupoint/ancestor">
                    <Link to="/cms/acupoint/ancestor">
                        <span><OrderedListOutlined /> 成人</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/cms/acupoint/baby">
                    <Link to="/cms/acupoint/baby">
                        <span><OrderedListOutlined /> 小儿</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/cms/acupoint/dongs">
                    <Link to="/cms/acupoint/dongs">
                        <span><OrderedListOutlined /> 董氏奇穴</span>
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="illness" title="疾病 Illness">
                <Menu.Item key="/cms/illness">
                    <Link to="/cms/illness">
                        <span><OrderedListOutlined /> 列表</span>
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="food" title="食物 Food">
                <Menu.Item key="/cms/food">
                    <Link to="/cms/food">
                        <span><OrderedListOutlined /> 列表</span>
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="herb" title="中草药 Herb">
                <Menu.Item key="/cms/herb">
                    <Link to="/cms/herb">
                        <span><OrderedListOutlined /> 列表</span>
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="term" title="名词解释 Term">
                <Menu.Item key="/cms/term/BasicTheory">
                    <Link to="/cms/term/BasicTheory">
                        <span><OrderedListOutlined /> 基础理论</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/cms/term/Terminology">
                    <Link to="/cms/term/Terminology">
                        <span><OrderedListOutlined /> 术语</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/cms/term/Doctor">
                    <Link to="/cms/term/Doctor">
                        <span><OrderedListOutlined /> 医家</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/cms/term/AncientBook">
                    <Link to="/cms/term/AncientBook">
                        <span><OrderedListOutlined /> 典籍</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/cms/term/DietTherapy">
                    <Link to="/cms/term/DietTherapy">
                        <span><OrderedListOutlined /> 药膳</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/cms/term/Regimen">
                    <Link to="/cms/term/Regimen">
                        <span><OrderedListOutlined /> 保健养生</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/cms/term/Poetics">
                    <Link to="/cms/term/Poetics">
                        <span><OrderedListOutlined /> 经络歌赋</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/cms/term/Divergence">
                    <Link to="/cms/term/Divergence">
                        <span><OrderedListOutlined /> 十二经别</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/cms/term/Sinew">
                    <Link to="/cms/term/Sinew">
                        <span><OrderedListOutlined /> 十二经筋</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/cms/term/Cortex">
                    <Link to="/cms/term/Cortex">
                        <span><OrderedListOutlined /> 六经皮部</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/cms/term/Channel">
                    <Link to="/cms/term/Channel">
                        <span><OrderedListOutlined /> 十五络脉</span>
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>
            {/* <Menu.ItemGroup key="graph" title="知识图谱 Graph">
                <Menu.Item key="/cms/graph">
                    <Link to="/cms/graph">
                        <span><OrderedListOutlined /> 列表</span>
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup> */}
        </Menu >
    </RequireRolesOr >
}


const mapStateToProps = ({ global }) => {
    return {
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);