import { Radio, Form, Row, Col } from "antd";
import QuestionDetail from "../simulator/QuestionDetail"
import AnswerDetail from "../simulator/AnswerDetail"
import { Input } from "antd";
import React from "react"
import UserAvatar from "../../components/user/UserAvatar";
import dayjs from "dayjs";
import CommentAncestors from "./component/CommentAncestors";
import { LinkOutlined } from "@ant-design/icons";

function AuditForm({ simulatorChannel, report, formRef }) {
    let content = null;

    switch (report.reference_type) {
        case "post":
            switch (report.reference?.type) {
                case "answer":
                    content = <AnswerDetail answer_id={report.reference?.id} channel={simulatorChannel} inReview />
                    break;
                case "question":
                    content = <QuestionDetail id={report.reference?.id} channel={simulatorChannel} inReview />
                    break;

                default:
                    break;
            }
            break
        case "comment":
            let linkPath = "";
            switch (report.reference?.reference?.type) {
                case "answer":
                    linkPath = `#/community/simulator/question/${report.reference?.reference?.parent_id}/answer/${report.reference?.reference?.id}`
                    break;
                case "question":
                    linkPath = `#/community/simulator/question/${report.reference?.reference?.id}`
                    break;

                default:
                    break;
            }
            content = <div style={{ padding: 10 }}>
                <p><a target="_blank" href={linkPath} style={{color: "#1677ff", textDecoration: "none"}}>关联评论目标 <LinkOutlined/></a></p>
                <CommentAncestors report={report} comments={report.reference?.ancestors} />
            </div>
            break;
    }



    return <div style={{ display: "flex" }}>
        <div style={{ width: 600 - 20, marginRight: 20, background: "#fafafa", borderRadius: 10 }}>{content}</div>
        <div style={{ flexGrow: 1, maxWidth: 700 }}>
            <Form ref={formRef} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                <Form.Item label="举报人">
                    <UserAvatar nickname={report.owner_name} size={40} avatar={report.owner_avatar} id={report.owner_id} />
                </Form.Item>
                <Form.Item label="举报时间">
                    {dayjs(report.inserted_at).format("YYYY-MM-DD HH:mm")}
                </Form.Item>
                <Form.Item label="举报内容">
                    {report.content || "[无内容]"}
                </Form.Item>
                <Form.Item name="review_result" label="审核意见" rules={[{ required: true, message: '不能为空' }]}>
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value="violation">违规</Radio.Button>
                        <Radio.Button value="compliant">符合规定</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="review_content" label="审核内容" rules={[{ required: true, message: '不能为空' }]}>
                    <Input.TextArea style={{ minHeight: 100 }} autoSize placeholder="此内容会通过站内信发送给用户"></Input.TextArea>
                </Form.Item>
            </Form>
        </div>
    </div>
}

export default AuditForm