

import { connect } from "react-redux";
import { notification } from 'antd'
import { CustomerOperationChannel as COAction } from "../store/global/actions"

import Config from "../config"


function CustomerOperationChannel(props) {
    let channel = props.channel

    const simplifyMessageContent = (message) => {
        let info = ""
        switch (message.content.type) {
            case "image":
                info = "[图片]"
                break;
            case "text":
                info = message.content.content
                break;
            default:
                break;
        }
        return info
    }

    // 浏览器内通知
    const handleMessageDocumentNotify = (conversation) => {
        notification.info({
            message: `${conversation.sender.nickname || "匿名用户"}的新消息`,
            description: simplifyMessageContent(conversation.message),
            icon: <img src={Config.AvatarUrl(conversation.sender.avatar)} alt="" width={24} />,
            key: conversation.channel,
            onClick: () => {
                window.location = "/#/online/customer-service/" + conversation.owner.id
                notification.destroy(conversation.channel)
            }
        })
    }

    // 浏览器原生通知
    const messageBrowserNotify = (conversation) => {
        if (!!window.lastNotifyAt && Date.now() - window.lastNotifyAt < 1000) return;
        window.lastNotifyAt = Date.now()
        Notification.requestPermission().then(function (permission) {
            if (permission === 'granted') {
                if (!!window.lastNotify) window.lastNotify.close()
                window.lastNotify = new Notification(`${conversation.sender.nickname || "匿名用户"}的新消息`, {
                    body: simplifyMessageContent(conversation.message),
                    icon: conversation.sender.avatar,
                    tag: conversation.channel
                });
            }
        });
    }

    if (!channel) {
        channel = props.socket.channel("ops:online:customer-service")
        channel.join()
        props.join(channel)
    }

    // 设置事件监听的ref，以免波及整个event的监听
    !!window.conversationUpdateRef && channel.off("conversation-update", window.conversationUpdateRef)

    // 会话更新
    window.conversationUpdateRef = channel.on("conversation-update", (conversation) => {
        // 是否是房主的消息
        const isOwnerMessage = conversation.sender.id === conversation.owner.id

        // 如果会话消息更新，而不是当前会话，则添加未读标记
        if (props.currentConversationCustomerID?.toString() !== conversation.owner?.id?.toString()) {
            // 如果是房主发送在自己的房间内，则提示
            if (isOwnerMessage) handleMessageDocumentNotify(conversation)
        }

        // 如果文档不可见，则浏览器消息提示. 且发送消息的是房主
        if (document.visibilityState !== "visible" && isOwnerMessage) messageBrowserNotify(conversation)
    })


    return <></>
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket,
        channel: global.customerOperationChannel,
        currentConversationCustomerID: global.currentConversationCustomerID
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        join: (channel) => {
            dispatch(COAction.join(channel))
        },
        leave: () => {
            dispatch(COAction.leave())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOperationChannel)