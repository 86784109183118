import { useState } from "react";
import React from "react";
import { List, Button, Select, Form, Input, InputNumber, Empty, Divider, message } from 'antd';
import dayjs from "dayjs";
import { connect } from 'react-redux';

import { UploadOutlined, ClearOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import "./MultiUploadForm.css"

function MultiUploadForm(props) {

    // 还未上传的文件
    const [unsubmitFiles, setUnsubmitFiles] = useState([])
    // 统一设置的表单
    const [uniformForm, setUniformForm] = useState({ labels: { "acupoint:resource:label::playback": 0 }, app_version_code: 58, permission: "vip", state: "publish" })

    // 全局设置是否在上传中
    const [globalUploading, setGlobalUploading] = useState(false)

    // 由于上传表单用了form，在统一表单更新值后，无法更新子表单，所用用version控制key来重新刷新
    const [formVersion, setFormVersion] = useState(0)

    const handleDragMultiFiles = (e) => {
        e.stopPropagation();
        e.preventDefault()
        let files = [...e.dataTransfer.files]
        files = files.filter(({ type }) => type === "video/mp4").map(file => {
            return { file, name: file.name.substr(0, file.name.lastIndexOf('.')), labels: {}, ...uniformForm }
        })

        const checkSrc = () => {
            if (files.every(({ src }) => !!src))
                setUnsubmitFiles(files)
            else
                setTimeout(checkSrc, 100);
        }

        files.forEach(({ file }, i) => {
            var video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = () => {
                const secondsAgo = dayjs().subtract(video.duration, 'second')
                const dur = dayjs.duration(dayjs().diff(secondsAgo))
                files[i]["labels"] = { ...files[i]["labels"], "acupoint:resource:label::duration": dayjs.utc(dur.asMilliseconds()).format('HH:mm:ss') }

                files[i]["src"] = video.src
                files[i]["video"] = video
                if (i === files.length - 1) checkSrc()
            }
            video.src = URL.createObjectURL(file);
        })

        document.querySelector(".view-multi-upload-form").classList.remove("active")
    }

    // 拖动到上方时
    const handleDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault()
        document.querySelector(".view-multi-upload-form").classList.add("active")
    }

    // 单个表单变更时
    const handleFileValueChange = (index, values) => {
        let newUnsubmitFiles = [...unsubmitFiles]
        newUnsubmitFiles[index] = { ...newUnsubmitFiles[index], ...values }
        setUnsubmitFiles(newUnsubmitFiles)
    }

    // 统一表单变更时，统一设置整个表
    const uniformFormChanged = (changedValues, allValues) => {
        let newUnsubmitFiles = [...unsubmitFiles]

        newUnsubmitFiles = newUnsubmitFiles.map(item => {
            if (!!item.success || !!item.uploading) {
                return item
            } else {
                let labels = { ...item.labels, ...changedValues.labels }
                return { ...item, ...changedValues, ["labels"]: labels }
            }
        })
        setUnsubmitFiles(newUnsubmitFiles)
        setFormVersion(formVersion + 1)
        setUniformForm(allValues)
    }

    const fileValid = ({ file, name, app_version_code, permission, state, labels }) => {
        return !!file && !!name && !!app_version_code && !!permission && !!state
            && !!labels["acupoint:resource:label::uploader"]
            && !!labels["acupoint:resource:label::duration"]
            && labels["acupoint:resource:label::playback"] != null
    }

    // 上传全部
    const handleUploadAll = (files, currentIndex = 0) => {
        let head = files[currentIndex]
        // 迭代选择当前则文件， 如果存在则上传
        if (!!head) {
            setGlobalUploading(true)
            let nextIndex = currentIndex + 1

            // 如果已经成功了，则跳过
            if (!!head.success) {
                return handleUploadAll(files, nextIndex)
            }

            // 校验文件是否有效
            if (!fileValid(head)) {
                files[currentIndex] = { ...files[currentIndex], uploading: false, fail: true, message: "表单不能留空" }
                setUnsubmitFiles([...files])
                return handleUploadAll(files, nextIndex)
            }
            // 修改参数为正在上传
            files[currentIndex] = { ...files[currentIndex], uploading: true }
            setUnsubmitFiles([...files])

            const ossPath = ["video", "course", props.albumName, uuidv4()].join("/")
            props.jsonRequest.get("/api/attach/sign/policy", { bucket: "acupoint-resource" })
                .then(response => response.json())
                .then(({ data }) => {
                    var formData = new FormData()
                    formData.append('key', ossPath)
                    formData.append('policy', data.policy)
                    formData.append('success_action_status', 200)
                    formData.append('OSSAccessKeyId', data.accessid)
                    formData.append('Signature', data.signature)
                    formData.append('file', head.file)

                    // 上传到oss
                    fetch(data.host, { method: "POST", body: formData, mode: "cors" })
                        .then(() => {
                            // 整理上传的内容
                            const videoData = {
                                path: ossPath,
                                name: head.name,
                                app_version_code: head.app_version_code,
                                permission: head.permission,
                                state: head.state,
                                album_id: props.albumID,
                                labels: head.labels
                            }

                            props.jsonRequest.post(`/api/std/video-course/${props.albumID}/upload/video/multipart`, videoData)
                                .then(resp => resp.json())
                                .then(data => {
                                    if (data.code === -1) {
                                        files[currentIndex] = { ...files[currentIndex], uploading: false, fail: true, message: data.message }
                                    } else {
                                        files[currentIndex] = { ...files[currentIndex], uploading: false, success: true, fail: undefined }
                                    }
                                    setUnsubmitFiles([...files])
                                    handleUploadAll(files, nextIndex)
                                })


                            message.success({ content: "上传成功", key: "handleUploadFile", duration: 2 })
                        })
                    // .catch((e) => {
                    //     message.warning({ content: "上传失败", key: "handleUploadFile", duration: 2 })
                    // })
                })
                .catch((e) =>
                    message.warning({ content: "请求签名失败", key: "handleUploadFile", duration: 2 }))
        } else {
            message.success("已全部上传完毕")
            setGlobalUploading(false)
            props.onUploadFinished()
        }
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 16 },
        },
    };

    // 单个表单视图
    const viewForm = (item = {}, onValuesChange) => {
        let state = <span style={{ color: "#aaa" }}>等待上传</span>
        if (!!item.uploading)
            state = <span style={{ color: "orange" }}>上传中...</span>
        else if (!!item.success)
            state = <span style={{ color: "green" }}>上传成功</span>
        else if (!!item.fail)
            state = <span style={{ color: "red" }}>上传失败: {item.message}</span>
        return <Form onValuesChange={onValuesChange} {...formItemLayout}>

            <Form.Item label="状态">
                {state}
            </Form.Item>

            <Form.Item name="name" initialValue={item?.name} label="标题" rules={[{ required: true }]}>
                {globalUploading || item.success ? <span className="ant-form-text">{item?.name}</span> : <Input size="small" />}
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::uploader"]} initialValue={item?.labels["acupoint:resource:label::uploader"]} label="上传者" rules={[{ required: true }]}>
                {globalUploading || item.success ? <span className="ant-form-text">{item?.labels["acupoint:resource:label::uploader"]}</span> : <Input size="small" />}
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::playback"]} initialValue={item?.labels["acupoint:resource:label::playback"]} label="播放次数" rules={[{ required: true }]}>
                {globalUploading || item.success ? <span className="ant-form-text">{item?.labels["acupoint:resource:label::playback"]}</span> : <InputNumber min={0} size="small" />}
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::duration"]} initialValue={item?.labels["acupoint:resource:label::duration"]} label="时长" rules={[{ required: true }]}>
                <span className="ant-form-text">{item?.labels["acupoint:resource:label::duration"]}</span>
            </Form.Item>
            <Form.Item name="app_version_code" initialValue={item?.app_version_code} label="APP最低版本" rules={[{ required: true }]} help="低于此版本的APP，看不到本视频">
                {globalUploading || item.success ? <span className="ant-form-text">{item?.app_version_code}</span> : <Select size="small">
                    <Select.Option value={56}>v2.6.4</Select.Option>
                    <Select.Option value={58}>v2.6.6</Select.Option>
                </Select>}
            </Form.Item>
            <Form.Item name="permission" initialValue={item?.permission} label="权限" rules={[{ required: true }]}>
                {globalUploading || item.success ? <span className="ant-form-text">{item?.permission}</span> : <Select size="small">
                    <Select.Option value="free">免费</Select.Option>
                    <Select.Option value="vip">会员</Select.Option>
                </Select>}
            </Form.Item>
            <Form.Item name="state" initialValue={item?.state} label="状态" rules={[{ required: true }]}>
                {globalUploading || item.success ? <span className="ant-form-text">{item?.state}</span> : <Select size="small" >
                    <Select.Option value="draft">草稿</Select.Option>
                    <Select.Option value="publish">发布</Select.Option>
                    <Select.Option value="complain">举报下架</Select.Option>
                </Select>}
            </Form.Item>
            <Form.Item name={["labels", "acupoint:resource:label::ip_belongs"]} initialValue={item?.labels["acupoint:resource:label::ip_belongs"]} label="IP归属地" >
                {globalUploading || item.success ? <span className="ant-form-text">{item?.labels["acupoint:resource:label::ip_belongs"]}</span> : <Input size="small" />}
            </Form.Item>
        </Form>
    }

    return <div className="view-multi-upload-form" onDragOver={handleDragOver} onDrop={handleDragMultiFiles} onDragEnter={(e) => document.querySelector(".view-multi-upload-form").classList.add("active")} onDragLeave={(e) => document.querySelector(".view-multi-upload-form").classList.remove('active')}>
        <div style={{ display: "flex", justifyContent: "space-between", justifyItems: "center" }}>
            <div>
                <Button onClick={() => handleUploadAll([...unsubmitFiles])} type="link" icon={<UploadOutlined />} disabled={unsubmitFiles.length === 0 || globalUploading}>全部上传</Button>
                <Divider type="vertical" />
                <Button onClick={() => setUnsubmitFiles([])} type="link" icon={<ClearOutlined />} disabled={globalUploading}>清空</Button>
            </div>
            <div style={{ display: "flex", alignItems: "center" }} >
                <span style={{ width: 80, color: "silver" }}>批量设置</span>
                <Form layout="inline" onValuesChange={uniformFormChanged} initialValues={uniformForm}>
                    <Form.Item name={["labels", "acupoint:resource:label::uploader"]} label="上传者" >
                        <Input size="small" disabled={globalUploading} />
                    </Form.Item>
                    <Form.Item name={["labels", "acupoint:resource:label::playback"]} label="播放次数" >
                        <InputNumber min={0} size="small" disabled={globalUploading} />
                    </Form.Item>
                    <Form.Item name="app_version_code" label="APP最低版本"  >
                        <Select size="small" disabled={globalUploading}>
                            <Select.Option value={56}>v2.6.4</Select.Option>
                            <Select.Option value={58}>v2.6.6</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="permission" label="权限" >
                        <Select size="small" disabled={globalUploading}>
                            <Select.Option value="free">免费</Select.Option>
                            <Select.Option value="vip">会员</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="state" label="状态" >
                        <Select size="small" disabled={globalUploading}>
                            <Select.Option value="draft">草稿</Select.Option>
                            <Select.Option value="publish">发布</Select.Option>
                            <Select.Option value="complain">举报下架</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name={["labels", "acupoint:resource:label::ip_belongs"]} label="IP归属地" >
                        <Input size="small" disabled={globalUploading} placeholder="比如：海南省、广东省" />
                    </Form.Item>
                </Form>
            </div>
        </div>

        {unsubmitFiles.length > 0 ? <List dataSource={unsubmitFiles}
            locale={{ emptyText: "拖动多个视频文件到这里" }}
            grid={{ gutter: 16, column: 2 }}
            itemLayout="vertical"
            size="large"
            renderItem={(item, index) =>
                <List.Item key={`unsubmit-${formVersion}-${item.src}`} className={`item-article ${item.uploading ? "active" : ""} ${item.success ? "success" : ""} ${item.fail ? "fail" : ""}`}
                    extra={
                        <div>
                            <video controls height={180} width={240} preload="none">
                                <source src={item.src} type="video/mp4"></source>
                            </video>
                        </div>
                    }>

                    <div className="content">
                        {viewForm(item, (_changedFields, allValues) => handleFileValueChange(index, allValues))}
                    </div>
                </List.Item>} /> :
            <Empty style={{ height: 300, lineHeight: "300px" }} image={Empty.PRESENTED_IMAGE_SIMPLE} description="拖动多个视频文件到这里" />}



    </div >
}



const mapStateToProps = ({ global }) => {
    return {
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(MultiUploadForm)
