import React from "react";
import { List, Switch, Button, Popover, message, Tooltip, Divider, Form, Select, Input } from 'antd';
import { connect } from 'react-redux';
import { Menu as MenuAction } from '../../store/global/actions'

import { RocketOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
import ListGrid from "../components/ListGrid";

import MediaViewer from "../components/media_editor";

class MeridianList extends React.Component {

    state = {
        channel: null,
        data: [],
        editable: false,
        loading: false,
        classType: this.props.match?.params?.type,
        query: { name: this.props.match?.params?.name },
        editorHeight: 500,
    }

    joinChannel(props) {
        this.setState({ loading: true })
        const channel = this.props.socket.channel("ops:resource:meridian", { type: this.state.classType, ...this.state.query })
        channel.on("data", ({ data }) => this.setState({ data, loading: false }))
        channel.join()
        this.setState({ channel })
    }

    componentDidMount() {
        this.props.setActiveKey("MERIDIAN")
        this.joinChannel(this.props)
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        !!this.state.channel && this.state.channel.off("data")
        !!this.state.channel && this.state.channel.leave()

        this.setState({ query: { ...this.state.query, name: nextProps.match?.params?.name }, classType: nextProps.match?.params?.type }, () => this.joinChannel(nextProps))
    }

    // 处理点击是否发布
    handleClickPublish(meridianID) {
        this.state.channel.push("publish!", { id: meridianID }).receive("ok", () => message.success("发布成功"))
    }


    // 添加到图谱推荐表
    handleClickAddToGraphSuggest(id, name) {
        this.state.channel.push("add-to-graph-suggest", { content_type: `meridian_${this.state.classType}`, content_id: id, content_name: name })
            .receive("ok", () => message.success("添加到图谱推荐列表成功"))
    }

    // 搜索
    handleSearch(values) {
        this.setState({ loading: true, query: values })
        this.state.channel.push("search", { ...values })
            .receive("ok", () =>
                this.setState({ loading: false }))
    }

    render() {
        return <div className="page resource-meridian">
            <h1>经络列表 <Switch onChange={checked => this.setState({ editable: checked })} checkedChildren="开启编辑" unCheckedChildren="只读" /></h1>

            <div >
                <Form autoComplete="off" layout="inline" onFinish={this.handleSearch.bind(this)} initialValues={this.state.query} style={{ display: "flex", alignItems: "center" }}>
                    <Form.Item name="name" label="经络名">
                        <Input placeholder="模糊查询"></Input>
                    </Form.Item>
                    <Button type="primary" htmlType="submit">查询</Button>
                    <Button onClick={() => this.handleSearch({})} htmlType="reset">清空</Button>
                </Form>
            </div>

            {this.state.data &&
                <ListGrid
                    dataSource={this.state.data}
                    loading={this.state.loading}
                    onHeightEdit={(height) => this.setState({ editorHeight: height })}
                    renderItem={item => (
                        <List.Item className="item-meridian">
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <span style={{ fontSize: 20, fontWeight: "bold" }}>{item.name}</span>
                                <span>
                                    <Tooltip title="添加到图谱推荐表">
                                        <Button type="link" icon={<DeploymentUnitOutlined />} onClick={() => this.handleClickAddToGraphSuggest(item.id, item.name)} ></Button>
                                    </Tooltip>
                                    {this.state.editable && <span>{item.has_update &&
                                        <>
                                            <Divider type="vertical" />

                                            <Popover content={<Button onClick={() => this.handleClickPublish(item.id)} block type="primary" size="small">确认</Button>} title="确认发布？" trigger="click">
                                                <Button type="link" icon={<RocketOutlined />}></Button>
                                            </Popover>
                                        </>}
                                    </span>}
                                </span>
                            </div>
                            <div>
                                <MediaViewer ownerType="Meridian" hasUpdate={item.has_update} ownerID={item.id} channel={this.state.channel} name={item.name} detail={item.detail} type="meridian" editable={this.state.editable} height={this.state.editorHeight} />
                            </div>
                        </List.Item>
                    )
                    } />
            }
        </div >
    }
}

const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveKey: (key) => {
            dispatch(MenuAction.setActive(key))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeridianList);