import Form from "./Form"
import React from "react"
import { Modal, message } from "antd";

function FormModal({ id, commitReason, status, content, channel }) {
    let formRef = React.createRef()
    let quillRef = React.createRef()

    Modal.confirm({
        width: 800,
        title: "编辑社区公约",
        content: <Form formRef={formRef} quillRef={quillRef} status={status} content={content} commitReason={commitReason} />,
        okText: null,
        closable: true,
        okText: "提交",
        onOk: (close) => {
            const form = formRef.current
            form
                .validateFields()
                .then(values => {
                    form.resetFields();
                    const params = { ...values, content: quillRef.current.getEditor().getContents().ops, id }
                    channel.push("update", params)
                        .receive("ok", () => {
                            close()
                            message.success("提交成功")
                        })
                })
                .catch(info => {
                    console.log('Validate Failed:', info);
                });
            return false
        }
    });
}



export { FormModal }