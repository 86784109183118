import React from "react";
import { List, Tabs, Button, Divider, Modal, message } from 'antd';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import dayjs from 'dayjs';
import { Link } from "react-router-dom"

import { DeleteOutlined, RocketOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// import LocalTimeAgo from "../../components/local_time_ago";
import { connect } from "react-redux";
const { TabPane } = Tabs;
class TemplateList extends React.Component {

    state = {
        channel: null,
        data: null
    }

    componentDidMount() {
        const channel = this.props.socket.channel("jpush:template")
        channel.on("data", ({ data }) => this.setState({ data }))
        channel.join()
        this.setState({ channel })
    }

    componentWillUnmount() {
        !!this.state.channel && this.state.channel.leave()
    }

    pushEvent(event, params, opts = null) {
        return this.state.channel.push(event, params)
    }

    tableChange = (page, size) => {
        this.pushEvent("page-to", { page_size: size, page: page })
    }

    onFinish = (values) => {
        this.pushEvent("query", values)
    }

    onReset = () => {
        this.pushEvent("query-reset", {})
    }

    // 重新推送一次
    handleReplay(transaction_id) {
        this.pushEvent("replay", { transaction_id: transaction_id })
    }

    // 存成模板
    handleDelete(transaction_id) {
        Modal.confirm({
            title: '确定删除？',
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
                this.pushEvent("delete", { id: transaction_id })
                    .receive("ok", () => message.success("删除成功"))
            },
            onCancel: () => {
                console.log('Cancel');
            },
        });
    }

    render() {
        const data = this.state.data;

        return <>
            <h1>推送模板</h1>
            {!!data &&
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 4,
                        lg: 4,
                        xl: 6,
                        xxl: 3,
                    }}
                    dataSource={data}
                    renderItem={item => (
                        <List.Item>
                            <div style={{ border: "1px solid #dfdfdf", padding: 10, borderRadius: 5 }}>
                                <div style={{ color: "#444", display: "flex", justifyContent: "space-between", alignItems: "center" }}><h3 style={{ color: "#444" }}>{item.name}</h3> <span>{dayjs(item.inserted_at).add(8, 'hours').fromNow()}</span></div>

                                <Tabs defaultActiveKey="main" tabBarExtraContent={
                                    <div>
                                        <Link to={`/tech/push/customize/template/${item.id}`}><RocketOutlined /> 去推送</Link>
                                        <Divider type="vertical" />
                                        <Button size="small" type="link" danger icon={<DeleteOutlined />} onClick={() => this.handleDelete(item.id)}></Button>
                                    </div>}>
                                    <TabPane tab="简介" key="main">
                                        <p>
                                            {item.notification.alert}
                                        </p>
                                    </TabPane>
                                    <TabPane tab="请求体" key="details">
                                        <Editor value={{
                                            platform: item.platform.value,
                                            notification: item.notification,
                                            options: item.options
                                        }} mode="view" language="zh-CN"></Editor>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </List.Item>
                    )}
                />}
        </>
    }
}


const mapStateToProps = ({ global }) => {
    return {
        socket: global.socket
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TemplateList)