import React, { useEffect, useState } from "react"
import useLocalStorageState from 'use-local-storage-state'
import { Tabs } from 'antd'

import Notes from './information_tabs/Notes'
import SimilarUserMerge from './information_tabs/SimilarUserMerge'
import Orders from './information_tabs/Orders'
import Devices from './information_tabs/Devices'
import AuthenticateLogs from './information_tabs/AuthenticateLogs'
import OnlineLogs from './information_tabs/OnlineLog'
import XunAi from './information_tabs/Xun-Ai'
import CustomerService from "../../Online/CustomerService"
import InviteCode from "./information_tabs/InviteCode"
import SearchLog from './information_tabs/SearchLog'
import BehaviorLog from './information_tabs/BehaviorLog'

import RequireRolesOr from '../require_roles_or'

import PricingsTab from "./information_tabs/Pricings"
import AlipayAgreement from "./information_tabs/AlipayAgreement"
import DistributedAccountFlow from "./information_tabs/DistributedAccountFlow"
import CoinPool from "./information_tabs/CoinPool"

import "./InformationTabs.css"

const { TabPane } = Tabs;

function InformationTabs(props) {
    // 标签记录
    const [tabKey, setTabKey] = useLocalStorageState('userListsTabKey', 'order')

    useEffect(() => {
        if (!!props.tabKey) setTabKey(props.tabKey)
    }, [props.tabKey])

    // 用户id
    const [userId, setUserId] = useState(null)

    // 右边的tabs的切换调用
    function handleTabsKeyClick(key) {
        setTabKey(key)
    }

    // 给外部设置方法
    if (!!props.handleTabChange)
        props.handleTabChange(handleTabsKeyClick)

    if (!!props.userId && props.userId !== userId) {
        setUserId(props.userId)
        handleTabsKeyClick(tabKey)
    }

    return <Tabs className="user-information-tabs" tabPosition="right" style={{ padding: 5 }} activeKey={tabKey} onTabClick={handleTabsKeyClick}>
        {RequireRolesOr.allow(["acupoint:role::operator", "acupoint:role::customer-service", "acupoint:role::finance"]) &&
            <TabPane tab="订单" key="order">
                {tabKey === "order" && <Orders {...props} />}
            </TabPane>}
        {RequireRolesOr.allow(["acupoint:role::operator", "acupoint:role::customer-service"]) && <>
            <TabPane tab="记事" key="note">
                {tabKey === "note" && <Notes {...props} />}
            </TabPane>
            <TabPane tab="登录历史" key="authenticate_logs">
                {tabKey === "authenticate_logs" && <AuthenticateLogs {...props} />}
            </TabPane>
            <TabPane tab="在线时长" key="online_logs">
                {tabKey === "online_logs" && <OnlineLogs {...props} />}
            </TabPane>
            <TabPane tab="设备" key="devices" className="device-list">
                {tabKey === "devices" && <Devices {...props} />}
            </TabPane>
            <TabPane tab="相似用户" key="similar-user-merge" className="similar-user-list">
                {tabKey === "similar-user-merge" && <SimilarUserMerge {...props} />}
            </TabPane >
            <TabPane tab="寻艾" key="xun-ai" className="xun-ai-transaction-list">
                {tabKey === "xun-ai" && <XunAi {...props} />}
            </TabPane >
            <TabPane tab="客服服务" key="customer-service" className="xun-ai-customer-service">
                {tabKey === "customer-service" && <CustomerService {...props} customerId={props.userId} hideConversation hideSidebar hideAction />}
            </TabPane >
            <TabPane tab="邀请码" key="invite-code" className="invite-code">
                {tabKey === "invite-code" && <InviteCode {...props} hideAvatar />}
            </TabPane>
            <TabPane tab="搜索历史" key="search-log" className="search-log">
                {tabKey === "search-log" && <SearchLog {...props} />}
            </TabPane>
            <TabPane tab="行为历史" key="behavior-log" className="behavior-log">
                {tabKey === "behavior-log" && <BehaviorLog {...props} />}
            </TabPane>
            <TabPane tab="会员价格" key="pricings" className="pricings">
                {tabKey === "pricings" && <PricingsTab {...props} meta="acupoint:shelve::vip" group="vip" />}
            </TabPane>
            <TabPane tab="支付宝合约" key="alipay-agreement" className="alipay-agreement">
                {tabKey === "alipay-agreement" && <AlipayAgreement {...props} />}
            </TabPane>
            <TabPane tab="积分池" key="coin-pool" className="coin-pool">
                {tabKey === "coin-pool" && <CoinPool {...props} />}
            </TabPane>
            {/* <TabPane tab="社区" key="community" className="community">
                {tabKey === "community" && <AlipayAgreement {...props} />}
            </TabPane>
            <TabPane tab="消息通知" key="notification" className="notification">
                {tabKey === "notification" && <AlipayAgreement {...props} />}
            </TabPane> */}
        </>
        }
        {RequireRolesOr.allow(["acupoint:role::operator", "acupoint:role::customer-service", "acupoint:role::finance"]) &&
            <TabPane tab="积分账户流水" key="distributed-account-flow" className="distributed-account-flow">
                {tabKey === "distributed-account-flow" && <DistributedAccountFlow {...props} />}
            </TabPane>}
    </Tabs >
}

export default InformationTabs;